import React from 'react'
import styled from 'styled-components'
import { Colors } from '@walter/shared'
import { stripUnit } from 'polished'
import { animationCurve, animationTime, boxShadow } from '../../styles/global'
import { Label } from '../Label'

export const toggleDimensions = {
  width: '40px',
  height: '24px',
  handleSize: '18px',
  padding: '3px',
}

const Container = styled.div<{ checked?: boolean; size?: ToggleSize }>`
  display: flex;
`

const Input = styled.input<{ label?: string }>`
  display: none;
`

const Indicator = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: ${toggleDimensions.width};
  height: ${toggleDimensions.height};
  background-color: ${Colors.borderColor};
  border-radius: ${`${(stripUnit(toggleDimensions.height) as number) / 2}px`};
  cursor: pointer;
  transition: background-color ${animationTime} ${animationCurve};

  ${Input}:checked + & {
    background-color: ${Colors.secondaryColor};
  }

  &:before {
    content: '';
    position: absolute;
    top: ${toggleDimensions.padding};
    left: ${toggleDimensions.padding};
    width: ${toggleDimensions.handleSize};
    height: ${toggleDimensions.handleSize};
    border-radius: 50%;
    background-color: ${Colors.white};
    transition: transform ${animationTime} ${animationCurve};
    ${boxShadow};

    ${Input}:checked + & {
      transform: translateX(16px);
    }
  }
`

export type ToggleSize = 'small'

type ToggleProps = {
  checked?: boolean
  onClick?: () => any
  label?: string
  size?: ToggleSize
  dataTestId?: string
}

export const Toggle = ({ checked, label, onClick, size, dataTestId }: ToggleProps) => (
  <>
    {!!Label && <Label>{label}</Label>}
    <Container checked={checked} onClick={onClick} size={size} data-test-id={dataTestId ? dataTestId : label}>
      <Input label={label} type="checkbox" id="toggle" checked={checked} />
      <Indicator />
    </Container>
  </>
)
