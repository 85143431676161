import { Message } from '../messaging'

export enum CustomMessageType {
  contact = 'contact.id',
  rule = 'rule.id',
  faqItem = 'faqItem.id',
  post = 'post.id',
  event = 'event.id',
  file = 'file.id',
  task = 'task.id',
  emailsnapshot = 'email.snapshot',
}

type EmailParticipant = {
  name?: string
  email?: string
}

type EmailFile = {
  contentType?: string
  size?: number
  filename?: string
  messageIds?: string[]
  contentId?: string
  contentDisposition?: string
  data?: unknown
}

export type EmailSnapshot = {
  id?: string
  subject?: string
  from?: EmailParticipant[]
  replyTo?: EmailParticipant[]
  to?: EmailParticipant[]
  cc?: EmailParticipant[]
  bcc?: EmailParticipant[]
  date?: number
  threadId?: string
  snippet?: string
  body?: string
  unread?: boolean
  starred?: boolean
  files?: EmailFile[]
}

const customMessageTypeValues = Object.values(CustomMessageType).map((t) => t.toString())

export const isCustomMessageType = (msg: Message) => {
  return msg.customType && msg.customType.length && customMessageTypeValues.indexOf(msg.customType) >= 0
}
