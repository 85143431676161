import React from 'react'
import styled, { css } from 'styled-components'
import { Colors, Spacing } from '@walter/shared'
import { rgba, stripUnit } from 'polished'
import { animationCurve, animationTime } from '../../styles/global'
import { fontSizes, fontWeights } from '../../styles/typography'
import { Icon } from '../Icon'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Step = styled.button<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  color: ${Colors.greyLight};
  border-radius: ${Spacing.large};
  padding: ${Spacing.tiny} ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
  transition: all ${animationTime} ${animationCurve};
  align-items: center;

  & + & {
    margin-left: ${Spacing.tiny};
  }

  &:hover,
  &:focus {
    color: ${Colors.grey};
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`

const Label = styled.span<{ isActive: boolean }>`
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.small};

  ${(props) =>
    props.isActive &&
    css`
      color: ${Colors.primaryColor};
      &:hover,
      &:focus {
        color: ${Colors.primaryColor};
      }
    `}
`

const Connector = styled.div`
  height: 2px;
  background-color: ${rgba(Colors.grey, 0.1)};
  width: 100%;
  margin: 0 ${Spacing.large};
`

const Number = styled.div<{ isActive: boolean }>`
  border-radius: 100%;
  height: ${Spacing.xLarge};
  width: ${Spacing.xLarge};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${rgba(Colors.grey, 0.1)};
  font-weight: ${fontWeights.bold};
  margin-right: ${Spacing.small};

  ${(props) =>
    props.isActive &&
    css`
      color: ${Colors.primaryColor};
      background-color: ${rgba(Colors.primaryColor, 0.2)};

      &:hover,
      &:focus {
        color: ${Colors.primaryColor};
        background-color: ${rgba(Colors.primaryColor, 0.2)};
      }
    `}
`

export type Step = { isActive: boolean; label: string; disabled?: boolean; hint?: string; handleClick?: () => void }

export const Steps = ({ steps, dataTestId }: { steps: Step[]; dataTestId?: string }) => {
  const activeStepIndex = steps.findIndex(({ isActive }) => isActive)
  return (
    <Container data-test-id={`${dataTestId}_Steps_Container`}>
      {steps.map((step, i) => (
        <React.Fragment key={i}>
          <Step
            data-test-id={`Step_${step.label}`}
            disabled={step.disabled ?? false}
            data-tip={step.hint}
            onClick={!step.disabled ? step.handleClick : undefined}
            type="button"
          >
            <Number data-test-id="Step_Number" isActive={step.isActive}>
              {activeStepIndex > i ? <Icon size="small" icon="checkmark" /> : i + 1}
            </Number>
            <Label data-test-id="Step_Label" isActive={step.isActive}>
              {step.label}
            </Label>
          </Step>
          {steps.length - 1 !== i && <Connector />}
        </React.Fragment>
      ))}
    </Container>
  )
}
