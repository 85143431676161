import React from 'react'
import styled from 'styled-components'

import { Colors, Spacing } from '@walter/shared'
import { typography } from '@walter/shared-web'
import { Heading } from '../Heading'

const Container = styled.div`
  display: block;
  padding-bottom: ${Spacing.xLarge};
  margin-bottom: ${Spacing.xLarge};
  border-bottom: 1px solid ${Colors.borderColor};

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
`

const Inner = styled.div`
  display: flex;
  align-items: flex-start;
`

const Sidebar = styled.aside`
  position: static; /* Sticky? */
  top: ${Spacing.xLarge};
  width: 40%;
  padding-right: 5%;
`

const StyledHeading = styled(Heading)`
  margin-bottom: ${Spacing.small};
`
const Description = styled.p`
  font-size: ${typography.fontSizes.small};
  margin-bottom: ${Spacing.medium};
`

const Content = styled.div`
  width: 60%;
`

type AnnotatedSectionProps = {
  dataTestId?: string
  heading: string
  description?: string
  children: React.ReactNode
  action?: React.ReactNode
}

export const AnnotatedSection = ({ dataTestId, heading, description, action, children }: AnnotatedSectionProps) => (
  <Container data-test-id={`${dataTestId}_Container`}>
    <Inner>
      <Sidebar data-test-id={`${dataTestId}_Sidebar`}>
        <StyledHeading data-test-id={`${dataTestId}_Title`}>{heading}</StyledHeading>
        {description && (
          <Description data-test-id={`${dataTestId}_Description`}>{description}</Description>
        )}
        {action && <div>{action}</div>}
      </Sidebar>
      <Content data-test-id={`${dataTestId}_Content`}>{children}</Content>
    </Inner>
  </Container>
)
