import * as React from 'react'

export function useEffectOnce(callback: () => void) {
  const hasRun = React.useRef(false)
  React.useEffect(() => {
    if (hasRun.current) return
    callback()
    hasRun.current = true
  }, [])
}
