import React from 'react'
import styled, { css } from 'styled-components'
import { rgba, stripUnit } from 'polished'

import { Colors, Spacing } from '@walter/shared'
import { Icon, typography, globalStyles } from '@walter/shared-web'

const Container = styled.div<{ type?: TagType; onClick?: any; customColor?: string }>`
  display: inline-flex;
  align-items: center;
  height: ${Spacing.xLarge};
  border-radius: ${Spacing.large};
  background-color: ${rgba(Colors.grey, 0.15)};
  padding: 0 ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
  color: ${Colors.greyDark};

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

  ${(props) =>
    props.type === 'custom' &&
    props.customColor &&
    css`
      color: ${props.customColor};
      background-color: ${rgba(props.customColor, 0.2)};
    `}
`

const Label = styled.span`
  position: relative;
  top: -1px; /* Optically align */
  font-weight: ${typography.fontWeights.medium};
  font-size: ${typography.fontSizes.small};
`

const Close = styled.button`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: ${Spacing.tiny};
  padding-right: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
  margin-right: -${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
  opacity: 0.8;
  transition: opacity ${globalStyles.animationTime} ${globalStyles.animationCurve};

  &:hover,
  &:focus {
    opacity: 1;
  }
`

const IconWrap = styled.div`
  display: flex;
  transform: rotate(45deg);
`

export type TagType = 'custom' | undefined

export type TagProps = {
  label: any
  onCloseClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  type?: TagType
  customColor?: string
}

export const Tag = ({ label, type, customColor, onCloseClick, ...rest }: TagProps) => (
  <Container type={type} customColor={customColor} {...rest}>
    <Label>{label}</Label>
    {onCloseClick && (
      <Close data-test-id="Tag_Close_Button" onClick={(e) => onCloseClick(e)}>
        <IconWrap>
          <Icon icon="plus" size="small" />
        </IconWrap>
      </Close>
    )}
  </Container>
)
