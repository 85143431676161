import React from 'react'
import styled from 'styled-components'

import { Spacing } from '@walter/shared'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${Spacing.tiny};
`

const Item = styled.div`
  padding: ${Spacing.tiny};
`

type ResidentGroupProps = {
  children: React.ReactNode
}

export const ResidentGroup = ({ children }: ResidentGroupProps) => (
  <Container>{React.Children.map(children, (child, i) => child && <Item key={i}>{child}</Item>)}</Container>
)
