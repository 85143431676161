import { z } from 'zod'
import { t } from '../../../utils'

export const thirdPartiesCondoManagerApii18nFields = {
  condoManagerApiKey: t('condo-manager-id'),
  shouldSkipProjectCreation: t('third-party-auto-add-missing-buildings'),
  shouldRemoveFamilyMembersAddedByUsers: t('third-party-remove-family-members-added-by-users'),
  shouldRemoveTenantsAddedByUsers: t('third-party-remove-tenants-added-by-users'),
  shouldRemoveOwnersAddedByUsers: t('third-party-remove-owners-added-by-users'),
}

export const thirdPartiesCondoManagerApiSchemaUpdate = z.object({
  condoManagerApiKey: z.string().optional(),
  shouldSkipProjectCreation: z.boolean().optional().nullable().default(true),
  shouldRemoveFamilyMembersAddedByUsers: z.boolean().optional().nullable().default(false),
  shouldRemoveTenantsAddedByUsers: z.boolean().optional().nullable().default(false),
  shouldRemoveOwnersAddedByUsers: z.boolean().optional().nullable().default(true),
})

export type ThirdPartiesCondoManagerApi = z.infer<typeof thirdPartiesCondoManagerApiSchemaUpdate>
