import { ApolloUtils, Config } from '@walter/shared'

export function isSessionValid() {
  const token = localStorage.getItem('token') ?? ''
  const refreshToken = localStorage.getItem('refreshToken') ?? ''

  const sessionStatus = ApolloUtils.getApolloSessionRefreshStatus(token, refreshToken)

  switch (sessionStatus) {
    case ApolloUtils.SESSION_REFRESH_STATUS_TYPE.NO_TOKEN:
    case ApolloUtils.SESSION_REFRESH_STATUS_TYPE.EXPIRED:
      return false
    case ApolloUtils.SESSION_REFRESH_STATUS_TYPE.VALID:
      return true
    case ApolloUtils.SESSION_REFRESH_STATUS_TYPE.SHOULD_REFRESH:
      return refreshBothTokens(refreshToken)
  }
}

async function refreshBothTokens(refreshToken: string) {
  try {
    const newTokenResult = await fetch(`${Config.restEndPoint}/auth/refreshToken`, {
      headers: {
        authorization: `Bearer ${refreshToken}`,
      },
      method: 'GET',
    })

    const result = await newTokenResult?.json()

    if (!result?.token) {
      return false
    }

    localStorage.setItem('token', result.token)
    localStorage.setItem('refreshToken', result.refreshToken)

    return true
  } catch (error) {
    console.error('Error refresh session', error)
    return false
  }
}
