import { z } from 'zod'
import { t } from '../../../utils'

export const thirdPartiesMagexi18nFields = {
  magexClientId: t('magex-client-identifier-please-note-that'),
  shouldSkipProjectCreation: t('third-party-auto-add-missing-buildings'),
}

export const thirdPartiesMagexSchemaUpdate = z.object({
  magexClientId: z.string().optional(),
  shouldSkipProjectCreation: z.boolean().nullable().default(true),
})

export type ThirdPartiesMagex = z.infer<typeof thirdPartiesMagexSchemaUpdate>
