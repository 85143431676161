import { Colors, Spacing } from '@walter/shared'
import React from 'react'
import styled from 'styled-components'
import { getLanguage } from '../utils/i18n'
import { Icon } from './Icon'

const SHOW_FRENCH = getLanguage().toLowerCase().startsWith('fr')

interface Props {
  iconImgUrl: string
  iosUrl: string
  androidUrl: string
}

export function MobileBanner(props: Props) {
  const { androidUrl, iconImgUrl, iosUrl } = props
  const [show, setShow] = React.useState(false)

  const url = React.useMemo(() => {
    return navigator.userAgent.match(/Android/i)
      ? androidUrl
      : navigator.userAgent.match(/iPhone|iPad|iPod/i)
      ? iosUrl
      : '#'
  }, [androidUrl, iosUrl])

  React.useEffect(() => {
    if (url !== '#') {
      setShow(true)
    }
  }, [androidUrl, iosUrl])

  if (!show) return null
  return (
    <Backdrop onClick={() => setShow(false)}>
      <Modal onClick={(e) => e.stopPropagation()}>
        <ModalContent>
          <CloseButton onClick={() => setShow(false)}>
            <Icon icon="close" color={Colors.grey} />
          </CloseButton>
          <AppIcon url={url} iconImgUrl={iconImgUrl} />
          {(url.includes('apple') || url === '#') && <AppleBadge url={url} />}
          {(url.includes('google') || url === '#') && <GoogleBadge url={url} />}
        </ModalContent>
      </Modal>
    </Backdrop>
  )
}

const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`

const Modal = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: content-fit;
  background-color: ${Colors.offWhite};
  box-shadow: -4px -16px 36px rgba(0, 0, 0, 0.1);
  z-index: 1001;
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.xLarge};
  position: relative;
  align-items: center;
  padding: ${Spacing.xExtraLarge} ${Spacing.xxLarge};
`

const CloseButton = styled.button`
  position: absolute;
  top: ${Spacing.large};
  right: ${Spacing.large};
  height: 30px;
  width: 30px;
`

const AppIcon = ({ url, iconImgUrl }: { url: string; iconImgUrl: string }) => {
  return (
    <a
      href={url}
      style={{
        width: 236,
        height: 236,
        borderRadius: '22%',
        overflow: 'hidden',
        display: 'inline-block',
        verticalAlign: 'middle',
        filter: 'drop-shadow(0px 0px 10px rgba(0,0,0,0.1))',
      }}
    >
      <img
        src={iconImgUrl}
        alt="Usewalter"
        style={{
          width: 236,
          height: 236,
          borderRadius: '22%',
          overflow: 'hidden',
          display: 'inline-block',
          verticalAlign: 'middle',
        }}
      />
    </a>
  )
}

const AppleBadgeEn = ({ url = '' }) => (
  <a
    href={url}
    style={{ borderRadius: 13, overflow: 'hidden', display: 'inline-block', width: 250, height: 83, padding: 5 }}
  >
    <img
      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1539648000"
      alt="Download on the App Store"
      style={{ borderRadius: 13, width: 250, height: 83, padding: 5 }}
    />
  </a>
)

const AppleBadgeFr = ({ url = '' }) => (
  <a href={url} style={{ borderRadius: 13, overflow: 'hidden', display: 'inline-block', width: 250, height: 83 }}>
    <img
      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/fr-ca?size=250x83&amp;releaseDate=1539648000"
      alt="Download on the App Store"
      style={{ borderRadius: 13, width: 250, height: 83 }}
    />
  </a>
)

const AppleBadge = ({ url = '' }) => (SHOW_FRENCH ? <AppleBadgeFr url={url} /> : <AppleBadgeEn url={url} />)

const GoogleBadgeEn = ({ url }: { url: string }) => (
  <a href={url}>
    <img
      alt="Get it on Google Play"
      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
      style={{ width: 250 }}
    />
  </a>
)
const GoogleBadgeFr = ({ url }: { url: string }) => (
  <a href={url}>
    <img
      alt="Disponible sur Google Play"
      src="https://play.google.com/intl/en_us/badges/static/images/badges/fr-ca_badge_web_generic.png"
      style={{ width: 250, paddingRight: 15, paddingLeft: 15 }}
    />
  </a>
)

const GoogleBadge = ({ url = '' }) => (SHOW_FRENCH ? <GoogleBadgeFr url={url} /> : <GoogleBadgeEn url={url} />)
