import React from 'react'
import styled from 'styled-components'
import { Colors, Spacing } from '@walter/shared'
import { rgba } from 'polished'
import { animationCurve, animationTime, borderRadius, square } from '../../styles/global'
import { Icon } from '../Icon'
import { Pill } from '../Pill'

const Container = styled.button`
  position: relative;
`

const Plus = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.white};
  border-radius: ${borderRadius};
  border: 1px solid ${rgba(Colors.white, 0.25)};
  cursor: pointer;
  transition: border-color ${animationTime} ${animationCurve};
  ${square('40px')};

  ${Container}:hover &,
  ${Container}:focus & {
    border-color: ${rgba(Colors.white, 0.45)};
  }
`

const Count = styled.div`
  position: absolute;
  display: flex;
  top: -${Spacing.tiny};
  right: -${Spacing.tiny};
  border-radius: ${Spacing.large};
  box-shadow: 0 0 0 3px ${Colors.white};
`

type NavActionProps = {
  label?: string
  icon: any
  handleClick: any
  smallIcon?: boolean
  notifications?: any
}

export const NavAction = ({ label, icon, handleClick, smallIcon, notifications }: NavActionProps) => (
  <Container data-tip={label} data-place="right" data-class="tooltip large" onClick={handleClick}>
    <Plus>
      <Icon icon={icon} size={smallIcon ? 'small' : undefined} />
      {notifications > 0 && (
        <Count>
          <Pill text={notifications} type="danger" />
        </Count>
      )}
    </Plus>
  </Container>
)
