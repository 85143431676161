import type { DebounceSettings } from 'lodash'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useRef, useState } from 'react'

export function useDebouncedState<T>(value: T, delay = 0, options?: DebounceSettings) {
  const previousValue = useRef(value)
  const [current, setCurrent] = useState(value)
  const debouncedCallback = useDebouncedCallback<T>((value) => setCurrent(value), delay, options)
  useEffect(() => {
    // doesn't trigger the debounce timer initially.
    if (value !== previousValue.current) {
      debouncedCallback(value)
      previousValue.current = value
      // cancel the debounced callback on clean up
      return debouncedCallback.cancel
    }
  }, [value])

  return [current, setCurrent] as const
}

function useDebouncedCallback<T>(callback: (value: T) => void, delay = 0, options?: DebounceSettings) {
  return useCallback(debounce(callback, delay, options), [callback, delay, options])
}
