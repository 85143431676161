import { z } from 'zod'
import { i18n, t } from '../../utils'
import { fieldIsTooLong } from './common'

const fieldIsLongerThan10 = fieldIsTooLong(10)

export const Remotei18nFields = {
  number: t('number'),
  unit: t('unit'),
  owners: t('residents-uppercase'),
}

export const remoteSchema = z.object({
  number: z
    .string({ required_error: i18n.t('input:is-required', { field: Remotei18nFields.number }) })
    .min(1, { message: i18n.t('input:is-required', { field: Remotei18nFields.number }) })
    .max(10, fieldIsLongerThan10(Remotei18nFields.number)),
  property: z
    .object({
      id: z.string().optional(),
    })
    .optional(),
  owners: z.array(z.object({ id: z.string() })).optional(),
})

export type RemoteManagerWeb = z.infer<typeof remoteSchema> & { id: string }
