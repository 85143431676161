import React from 'react'
import { Colors } from '@walter/shared'
import { SendBirdUtils } from '@walter/shared'
import { customMessageCardIconName, customMessageCardName } from './CustomMessageCard.entities'
import ChatEvent from '../ChatEvent'

export interface IPostCustomItem {
  id: string
  createdAt: any
  title: string | null
  description: string | null
}

export type PostItemCardProps = {
  post: IPostCustomItem
  formattedTime: string
}

export const PostItemCard = (props: PostItemCardProps) => {
  const { post, formattedTime } = props
  return (
    <ChatEvent
      color={Colors.news}
      icon={customMessageCardIconName(SendBirdUtils.CustomMessageType.post)}
      name={customMessageCardName(SendBirdUtils.CustomMessageType.post)}
      time={formattedTime}
      title={post.title}
      description={post.description}
      isHTML
    />
  )
}
