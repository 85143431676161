import { Api } from '@walter/shared'
import { z } from 'zod'
import { i18n, t } from '../../utils'

export const Reservationsi18nFields = {
  customer: t('fields-reservation:resident'),
  amenity: t('fields-reservation:amenity'),
  status: t('fields-reservation:status'),
  note: t('fields-reservation:note'),
  start: t('fields-reservation:start'),
  end: t('fields-reservation:end'),
  numberOfResidents: t('fields-reservation:number-of-residents'),
}

export const ReservationsSchema = z
  .object({
    customer: z.object(
      {
        id: z.string({
          required_error: i18n.t('input:is-required', { field: Reservationsi18nFields.customer }),
          invalid_type_error: i18n.t('input:is-required', { field: Reservationsi18nFields.customer }),
        }),
      },
      {
        required_error: i18n.t('input:is-required', { field: Reservationsi18nFields.customer }),
        invalid_type_error: i18n.t('input:is-required', { field: Reservationsi18nFields.customer }),
      },
    ),
    amenity: z.object(
      {
        id: z.string({
          required_error: i18n.t('input:is-required', { field: Reservationsi18nFields.amenity }),
          invalid_type_error: i18n.t('input:is-required', { field: Reservationsi18nFields.amenity }),
        }),
      },
      {
        invalid_type_error: i18n.t('input:is-required', { field: Reservationsi18nFields.amenity }),
        required_error: i18n.t('input:is-required', { field: Reservationsi18nFields.amenity }),
      },
    ),
    status: z.nativeEnum(Api.Reservation_Status),
    note: z.string().nullable(),
    start: z
      .string({ required_error: i18n.t('input:is-required', { field: Reservationsi18nFields.start }) })
      .or(z.date({ required_error: i18n.t('input:is-required', { field: Reservationsi18nFields.start }) })),
    end: z
      .string({ required_error: i18n.t('input:is-required', { field: Reservationsi18nFields.end }) })
      .or(z.date({ required_error: i18n.t('input:is-required', { field: Reservationsi18nFields.end }) })),
    numberOfResidents: z
      .string({
        required_error: i18n.t('input:is-required', { field: Reservationsi18nFields.numberOfResidents }),
      })
      .or(
        z.number({ required_error: i18n.t('input:is-required', { field: Reservationsi18nFields.numberOfResidents }) }),
      )
      .transform((val) => {
        const num = Number(val)
        return isNaN(num) ? 1 : num
      }),
  })
  .refine(
    (data) => {
      if (data.end && data.start) {
        return new Date(data.end).getTime() >= new Date(data.start).getTime()
      }
      return true
    },
    {
      message: t('end-must-be-after-start'),
      path: ['end'],
    },
  )

export type Reservations = z.infer<typeof ReservationsSchema>
