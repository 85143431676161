import React from 'react'
import { Mention, MentionsInput, OnChangeHandlerFunc } from 'react-mentions'

export type MentionableInputOnChange<ItemType> = (
  event: React.FormEvent<HTMLInputElement>,
  newValue: string,
  newPlainTextValue: string,
  mentions: ItemType[],
) => void

type MentionableInputProps<ItemType> = {
  dataTestId?: string
  mentionDisplay: (item: ItemType) => string
  mentionId: (item: ItemType) => string
  value?: string
  placeholder?: string
  mentionOptions?: ItemType[]
  inputProps?: React.ComponentProps<typeof MentionsInput>
  onChange?: OnChangeHandlerFunc
  disabled?: boolean
}

export function MentionableInput<ItemType>({
  dataTestId,
  value,
  placeholder,
  mentionOptions = [],
  mentionDisplay,
  mentionId,
  onChange,
  inputProps,
  disabled,
}: MentionableInputProps<ItemType>) {
  const formattedMentionOptions = React.useMemo(() => {
    return mentionOptions.map((opt: ItemType) => ({
      id: mentionId(opt),
      display: mentionDisplay(opt),
    }))
  }, [mentionOptions, mentionDisplay, mentionId])

  return (
    <MentionsInput
      {...inputProps}
      data-test-id={dataTestId}
      placeholder={placeholder}
      className="mentionWrapper"
      value={value}
      onChange={onChange}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (disabled) {
          e.preventDefault()
          e.stopPropagation()
        }
      }}
      allowSpaceInQuery
      allowSuggestionsAboveCursor
    >
      <Mention
        trigger="@"
        data={formattedMentionOptions}
        displayTransform={(_id, display) => `@${display}`}
        className="mentionedFriend"
        markup="@[__display__](#__id__)"
      />
    </MentionsInput>
  )
}
