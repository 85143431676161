// Adapted from the package country-region-data, which is almost 200k.
import { getTranslation } from '../utils/localization-utils'

// If/when new places are added, only keys need to be adjusted for form use.
export const CountriesEnum = (languageCode: string) => {
  const firstTwoCharsLanguageCode = languageCode.substring(0, 2)
  return {
    CANADA: getTranslation('canada', firstTwoCharsLanguageCode),
    UNITED_STATES: getTranslation('united-states', firstTwoCharsLanguageCode),
  }
}

const Canada = (languageCode: string) => {
  const firstTwoCharsLanguageCode = languageCode.substring(0, 2)
  return {
    label: CountriesEnum(firstTwoCharsLanguageCode).CANADA,
    value: 'CA',
    subOptions: [
      {
        label: getTranslation('alberta', firstTwoCharsLanguageCode),
        value: 'AB',
      },
      {
        label: getTranslation('british-columbia', firstTwoCharsLanguageCode),
        value: 'BC',
      },
      {
        label: getTranslation('manitoba', firstTwoCharsLanguageCode),
        value: 'MB',
      },
      {
        label: getTranslation('new-brunswick', firstTwoCharsLanguageCode),
        value: 'NB',
      },
      {
        label: getTranslation('newfoundland-and-labrador', firstTwoCharsLanguageCode),
        value: 'NL',
      },
      {
        label: getTranslation('northwest-territories', firstTwoCharsLanguageCode),
        value: 'NT',
      },
      {
        label: getTranslation('nova-scotia', firstTwoCharsLanguageCode),
        value: 'NS',
      },
      {
        label: getTranslation('nunavut', firstTwoCharsLanguageCode),
        value: 'NU',
      },
      {
        label: getTranslation('ontario', firstTwoCharsLanguageCode),
        value: 'ON',
      },
      {
        label: getTranslation('prince-edward-island', firstTwoCharsLanguageCode),
        value: 'PE',
      },
      {
        label: getTranslation('quebec', firstTwoCharsLanguageCode),
        value: 'QC',
      },
      {
        label: getTranslation('saskatchewan', firstTwoCharsLanguageCode),
        value: 'SK',
      },
      {
        label: getTranslation('yukon', firstTwoCharsLanguageCode),
        value: 'YT',
      },
    ],
  }
}

const UnitedStates = (languageCode: string) => {
  const firstTwoCharsLanguageCode = languageCode.substring(0, 2)
  return {
    label: CountriesEnum(firstTwoCharsLanguageCode).UNITED_STATES,
    value: 'US',
    subOptions: [
      {
        label: getTranslation('alabama', firstTwoCharsLanguageCode),
        value: 'AL',
      },
      {
        label: getTranslation('alaska', firstTwoCharsLanguageCode),
        value: 'AK',
      },
      {
        label: getTranslation('american-samoa', firstTwoCharsLanguageCode),
        value: 'AS',
      },
      {
        label: getTranslation('arizona', firstTwoCharsLanguageCode),
        value: 'AZ',
      },
      {
        label: getTranslation('arkansas', firstTwoCharsLanguageCode),
        value: 'AR',
      },
      {
        label: getTranslation('california', firstTwoCharsLanguageCode),
        value: 'CA',
      },
      {
        label: getTranslation('colorado', firstTwoCharsLanguageCode),
        value: 'CO',
      },
      {
        label: getTranslation('connecticut', firstTwoCharsLanguageCode),
        value: 'CT',
      },
      {
        label: getTranslation('delaware', firstTwoCharsLanguageCode),
        value: 'DE',
      },
      {
        label: getTranslation('district-of-columbia', firstTwoCharsLanguageCode),
        value: 'DC',
      },
      {
        label: getTranslation('micronesia', firstTwoCharsLanguageCode),
        value: 'FM',
      },
      {
        label: getTranslation('florida', firstTwoCharsLanguageCode),
        value: 'FL',
      },
      {
        label: getTranslation('georgia', firstTwoCharsLanguageCode),
        value: 'GA',
      },
      {
        label: getTranslation('guam', firstTwoCharsLanguageCode),
        value: 'GU',
      },
      {
        label: getTranslation('hawaii', firstTwoCharsLanguageCode),
        value: 'HI',
      },
      {
        label: getTranslation('idaho', firstTwoCharsLanguageCode),
        value: 'ID',
      },
      {
        label: getTranslation('illinois', firstTwoCharsLanguageCode),
        value: 'IL',
      },
      {
        label: getTranslation('indiana', firstTwoCharsLanguageCode),
        value: 'IN',
      },
      {
        label: getTranslation('iowa', firstTwoCharsLanguageCode),
        value: 'IA',
      },
      {
        label: getTranslation('kansas', firstTwoCharsLanguageCode),
        value: 'KS',
      },
      {
        label: getTranslation('kentucky', firstTwoCharsLanguageCode),
        value: 'KY',
      },
      {
        label: getTranslation('louisiana', firstTwoCharsLanguageCode),
        value: 'LA',
      },
      {
        label: getTranslation('maine', firstTwoCharsLanguageCode),
        value: 'ME',
      },
      {
        label: getTranslation('marshall-islands', firstTwoCharsLanguageCode),
        value: 'MH',
      },
      {
        label: getTranslation('maryland', firstTwoCharsLanguageCode),
        value: 'MD',
      },
      {
        label: getTranslation('massachusetts', firstTwoCharsLanguageCode),
        value: 'MA',
      },
      {
        label: getTranslation('michigan', firstTwoCharsLanguageCode),
        value: 'MI',
      },
      {
        label: getTranslation('minnesota', firstTwoCharsLanguageCode),
        value: 'MN',
      },
      {
        label: getTranslation('mississippi', firstTwoCharsLanguageCode),
        value: 'MS',
      },
      {
        label: getTranslation('missouri', firstTwoCharsLanguageCode),
        value: 'MO',
      },
      {
        label: getTranslation('montana', firstTwoCharsLanguageCode),
        value: 'MT',
      },
      {
        label: getTranslation('nebraska', firstTwoCharsLanguageCode),
        value: 'NE',
      },
      {
        label: getTranslation('nevada', firstTwoCharsLanguageCode),
        value: 'NV',
      },
      {
        label: getTranslation('new-hampshire', firstTwoCharsLanguageCode),
        value: 'NH',
      },
      {
        label: getTranslation('new-jersey', firstTwoCharsLanguageCode),
        value: 'NJ',
      },
      {
        label: getTranslation('new-mexico', firstTwoCharsLanguageCode),
        value: 'NM',
      },
      {
        label: getTranslation('new-york', firstTwoCharsLanguageCode),
        value: 'NY',
      },
      {
        label: getTranslation('north-carolina', firstTwoCharsLanguageCode),
        value: 'NC',
      },
      {
        label: getTranslation('north-dakota', firstTwoCharsLanguageCode),
        value: 'ND',
      },
      {
        label: getTranslation('northern-mariana-islands', firstTwoCharsLanguageCode),
        value: 'MP',
      },
      {
        label: getTranslation('ohio', firstTwoCharsLanguageCode),
        value: 'OH',
      },
      {
        label: getTranslation('oklahoma', firstTwoCharsLanguageCode),
        value: 'OK',
      },
      {
        label: getTranslation('oregon', firstTwoCharsLanguageCode),
        value: 'OR',
      },
      {
        label: getTranslation('palau', firstTwoCharsLanguageCode),
        value: 'PW',
      },
      {
        label: getTranslation('pennsylvania', firstTwoCharsLanguageCode),
        value: 'PA',
      },
      {
        label: getTranslation('puerto-rico', firstTwoCharsLanguageCode),
        value: 'PR',
      },
      {
        label: getTranslation('rhode-island', firstTwoCharsLanguageCode),
        value: 'RI',
      },
      {
        label: getTranslation('south-carolina', firstTwoCharsLanguageCode),
        value: 'SC',
      },
      {
        label: getTranslation('south-dakota', firstTwoCharsLanguageCode),
        value: 'SD',
      },
      {
        label: getTranslation('tennessee', firstTwoCharsLanguageCode),
        value: 'TN',
      },
      {
        label: getTranslation('texas', firstTwoCharsLanguageCode),
        value: 'TX',
      },
      {
        label: getTranslation('utah', firstTwoCharsLanguageCode),
        value: 'UT',
      },
      {
        label: getTranslation('vermont', firstTwoCharsLanguageCode),
        value: 'VT',
      },
      {
        label: getTranslation('virgin-islands', firstTwoCharsLanguageCode),
        value: 'VI',
      },
      {
        label: getTranslation('virginia', firstTwoCharsLanguageCode),
        value: 'VA',
      },
      {
        label: getTranslation('washington', firstTwoCharsLanguageCode),
        value: 'WA',
      },
      {
        label: getTranslation('west-virginia', firstTwoCharsLanguageCode),
        value: 'WV',
      },
      {
        label: getTranslation('wisconsin', firstTwoCharsLanguageCode),
        value: 'WI',
      },
      {
        label: getTranslation('wyoming', firstTwoCharsLanguageCode),
        value: 'WY',
      },
      {
        label: getTranslation('armed-forces-americas', firstTwoCharsLanguageCode),
        value: 'AA',
      },
      {
        label: getTranslation('armed-forces-europe-canada-africa-and-middle-east', firstTwoCharsLanguageCode),
        value: 'AE',
      },
      {
        label: getTranslation('armed-forces-pacific', firstTwoCharsLanguageCode),
        value: 'AP',
      },
    ],
  }
}

export const AvailableCountries = (languageCode: string) => [Canada(languageCode), UnitedStates(languageCode)]
