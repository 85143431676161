import { Colors, Spacing } from '@walter/shared'
import { mix } from 'polished'
import styled from 'styled-components'
import { animationCurve, animationTime, borderRadius, boxShadow, square } from '../../../styles/global'
import { fontSizes, fontWeights } from '../../../styles/typography'

export const DatePickerStyled = styled.div`
  .react-calendar {
    width: 320px;
    max-width: 100vw;
    background-color: ${Colors.white};
    border: 1px solid ${Colors.borderColor};
    border-radius: ${borderRadius};
    overflow: hidden;
    ${boxShadow};
  }

  .react-calendar__tile {
    position: relative;
    color: ${Colors.grey};
    transition: color ${animationTime} ${animationCurve};
    ${square('40px')};

    &:hover {
      color: ${Colors.greyDark};
    }
  }

  .react-calendar__tile abbr {
    position: relative;
    z-index: 10;
  }

  .react-calendar__tile--active {
    color: ${Colors.white};

    &:hover {
      color: ${Colors.white};
    }

    &:after {
      content: '';
      position: absolute;
      top: ${Spacing.tiny};
      right: ${Spacing.tiny};
      bottom: ${Spacing.tiny};
      left: ${Spacing.tiny};
      background-color: ${Colors.primaryColor};
      border-radius: 50%;
    }
  }

  .react-calendar__navigation__arrow {
    display: flex;
    color: ${Colors.greyLight};
    transition: color ${animationTime} ${animationCurve};

    &:hover {
      color: ${Colors.grey};
    }
  }

  .react-calendar__navigation {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${Colors.borderColor};
    padding: ${Spacing.small};
    background-color: ${mix(0.5, Colors.white, Colors.offWhite)};
  }

  .react-calendar__navigation__label {
    pointer-events: none; /* Disable click */
    color: ${Colors.greyDark};
    font-weight: ${fontWeights.bold};
  }

  .react-calendar__viewContainer {
    padding: ${Spacing.medium};
    padding-bottom: ${Spacing.small};
  }

  .react-calendar__month-view__weekdays {
    margin-bottom: ${Spacing.tiny};
  }

  .react-calendar__month-view__weekdays__weekday {
    font-size: ${fontSizes.tiny};
    font-weight: ${fontWeights.medium};
    color: ${Colors.greyLight};
    text-align: center;

    abbr {
      text-decoration: none;
      border: 0;
    }
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none; /* Hide year navigation */
  }
`
