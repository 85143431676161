import { z } from 'zod'
import { i18n, t } from '../../utils'

export const FileI18n = {
  name: t('name-uppercase'),
  folderParent: t('folder-parent'),
  files: t('file-s'),
  sharedWithResidents: t('shared-with-residents'),
}

const BaseFileSchema = z.object({
  name: z
    .array(
      z
        .string({ required_error: i18n.t('input:is-required', { field: FileI18n.name }) })
        .min(1, { message: i18n.t('input:is-required', { field: FileI18n.name }) }),
    )
    .min(1, { message: i18n.t('input:is-required', { field: FileI18n.name }) }),
  sharedWithResidents: z.boolean({
    required_error: i18n.t('input:is-required', { field: FileI18n.sharedWithResidents }),
  }),
  folderParent: z
    .object({
      id: z
        .string({ required_error: i18n.t('input:is-required', { field: FileI18n.folderParent }) })
        .min(1, { message: i18n.t('input:is-required', { field: FileI18n.folderParent }) }),
      name: z.string().nullable().optional(),
    })
    .nullable()
    .optional(),
})

export const FileCreateSchema = BaseFileSchema.extend({
  files: z
    .array(z.any(), {
      required_error: i18n.t('input:is-required', { field: FileI18n.files }),
    })
    .min(1, { message: i18n.t('input:is-required', { field: FileI18n.files }) }),
})

export const FileUpdateSchema = BaseFileSchema.extend({
  files: z.array(z.any()).nullable().optional(),
})

export type File = z.infer<typeof FileCreateSchema>
