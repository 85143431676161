import React from 'react'
import { BubbleContent } from './Bubble'
import { ButtonGroup } from '../ButtonGroup'
import { Button } from '../Button'
import { t } from '../../utils'
import { Modal } from '../Modal'
import { Message } from '@walter/shared'

export interface DeleteMessageModalProps {
  message: Message | null
  onCancel: () => void
  onConfirmDelete: (message: Message) => void
}

export const DeleteMessageModal = (props: DeleteMessageModalProps) => {
  const { message, onCancel, onConfirmDelete } = props

  const isOpen = !!message
  const isDeletingMessage = false

  const onDeleteMessageConfirm = () => {
    if (message) {
      onConfirmDelete(message)
    }
  }

  return (
    <Modal title={t('delete-message')} visible={isOpen} close={onCancel}>
      <p>{t('are-you-sure-you-want-to-delete-this-message')}</p>
      <p>
        <BubbleContent message={message} isNote={false} />
      </p>
      <ButtonGroup>
        <Button
          testID="yes-delete-button"
          theme="negative"
          isLoading={isDeletingMessage}
          onClick={onDeleteMessageConfirm}
        >
          {t('yes-delete')}
        </Button>
        <Button testID="no-delete-button" isLoading={isDeletingMessage} onClick={onCancel}>
          {t('no-cancel')}
        </Button>
      </ButtonGroup>
    </Modal>
  )
}
