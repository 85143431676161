/* eslint-disable react/prop-types */
import React, { useState } from 'react'

type PopoverContextType = {
  popoverVisible: boolean
  setPopoverVisible: (b: boolean) => void
}
export const PopoverContext = React.createContext<PopoverContextType>({} as PopoverContextType)

export function PopoverProvider({ children }: { children: React.ReactNode }) {
  const [popoverVisible, setPopoverVisible] = useState(false)

  const value = {
    popoverVisible,
    setPopoverVisible,
  }

  return <PopoverContext.Provider value={value}>{children}</PopoverContext.Provider>
}
