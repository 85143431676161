import Interweave from 'interweave'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  word-break: break-word;
  p {
    margin-bottom: 0;
  }
`

const DisplayContent = ({ dataTestId, content, testID }: { dataTestId?: string; content: any; testID?: string }) => {
  return (
    <Container data-test-id={`${dataTestId}_Content`} data-cy={testID}>
      <Interweave content={content} />
    </Container>
  )
}

export { DisplayContent }
