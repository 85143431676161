import React from 'react'
import styled from 'styled-components'
import { Spacing, Colors } from '@walter/shared'
import { Icon } from '..'
import { rgba } from 'polished'

const getBackgroundColor = ({ type }: { type: AlertType }) => {
  if (type === 'success') return rgba(Colors.green, 0.3)
  if (type === 'info') return rgba(Colors.greyLight, 0.3)
  if (type === 'warning') return rgba(Colors.warning, 1)
  if (type === 'danger') return rgba(Colors.red, 0.3)
}

const getBorderColor = ({ type }: { type: AlertType }) => {
  if (type === 'success') return Colors.green
  if (type === 'info') return Colors.greyLight
  if (type === 'warning') return Colors.warning
  if (type === 'danger') return Colors.red
}

const Container = styled.div`
  display: flex;
  padding: ${Spacing.medium};
  /* border: 1px solid ${getBorderColor}; */
  background-color: ${getBackgroundColor};
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${Spacing.medium};
`

const TextsWrapper = styled.div``

const Title = styled.span`
  color: ${Colors.white};
`

const Description = styled.span`
  color: ${Colors.white};
  margin-top: ${Spacing.medium};
`

type AlertType = 'success' | 'warning' | 'danger' | 'info'

type AlertProps = {
  type: AlertType
  title: string
  description?: string
  onClick: () => void
}

export const Alert = ({ type, title, description, onClick }: AlertProps) => (
  <Container type={type} onClick={onClick}>
    <IconWrapper>
      <Icon size="small" icon="checkmark" color={Colors.white} />
    </IconWrapper>
    <TextsWrapper>
      <Title>{title}</Title>
      {!description && <Description></Description>}
    </TextsWrapper>
  </Container>
)
