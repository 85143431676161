import { Api, NotificationsUtils } from '@walter/shared'

export function getNotificationRoutePathForType(notification: {
  type?: Api.Notification_Type | null
  data?: any
  project?: { id: string } | null
}) {
  const { type, data } = notification

  if (!notification.project?.id) {
    return '/'
  }

  let routeQuery = ''

  /* eslint-disable camelcase */
  if (type === 'NEW_CHAT_MESSAGE' && (data?.channel?.channel_url || data?.channel_url)) {
    routeQuery = `?channelUrl=${data?.channel?.channel_url || data?.channel_url}`
  } else if (data && data.id) {
    routeQuery = `?item=${data.id}`
  }

  let route = `/${notification.project.id}`

  if (!type) return route

  if (NotificationsUtils.CALENDAR_NOTIFICATION_TYPES.includes(type)) {
    route += `/calendar`
  }
  if (NotificationsUtils.CHAT_NOTIFICATION_TYPES.includes(type)) {
    route += `/chat`
  }
  if (NotificationsUtils.TASKS_NOTIFICATION_TYPES.includes(type)) {
    route += `/tasks`
  }
  if (NotificationsUtils.SALES_NOTIFICATION_TYPES.includes(type)) {
    route += `/marketplace`
  }
  if (NotificationsUtils.ORDER_NOTIFICATION_TYPES.includes(type)) {
    route += `/orders`
  }
  if (NotificationsUtils.USER_NOTIFICATIONS_TYPES.includes(type)) {
    route += `/residents`
  }

  route += routeQuery

  return route
}
