import { keyframes } from 'styled-components'

export const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

export const elasticScale = keyframes`
  0% {
    transform: scale(0.7);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`

export const animationTime = '0.7'

export const animationCurve = 'ease-out'
