import { Colors, Spacing } from '@walter/shared'
import { mix, rgba, stripUnit } from 'polished'
import React from 'react'
import styled, { css } from 'styled-components'
import {
  animationCurve,
  animationTime,
  borderRadius,
  boxShadow,
  boxShadowDark,
  cover,
  square,
} from '../../styles/global'
import { fontSizes } from '../../styles/typography'
import { Button, ButtonProps } from '../Button'
import { ButtonGroup } from '../ButtonGroup'

const Container = styled.div<{ type: ResourceItemType }>`
  position: relative;
  background-color: ${Colors.white};
  width: 100%;
  transition: all ${animationTime} ${animationCurve};

  ${(props) =>
    props.type === 'list' &&
    css`
      padding: ${Spacing.large};

      &:hover {
        background-color: ${mix(0.5, Colors.offWhite, Colors.white)};
      }

      & + & {
        border-top: 1px solid ${Colors.borderColor};
      }
    `}

  ${(props) =>
    props.type === 'grid' &&
    css`
      display: flex;
      flex-direction: column;
      border-radius: ${borderRadius};
      border: 1px solid ${Colors.borderColor};
      overflow: hidden;
      ${boxShadow};

      &:hover {
        transform: translateY(-2px);
        ${boxShadowDark};
      }
    `}
`

const Inner = styled.div<{ type: ResourceItemType }>`
  display: flex;

  ${(props) =>
    props.type === 'list' &&
    css`
      align-items: center;
    `}

  ${(props) =>
    props.type === 'grid' &&
    css`
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
    `}
`

const Overlay = styled.div`
  display: block;
  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

  ${cover('absolute')};
`

const Actions = styled.div`
  position: relative;
  /* Position above overlay */
  z-index: 1;
  flex-shrink: 0;
`

const HoverActions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  bottom: 0;
  padding-right: ${Spacing.large};
  background-color: ${Colors.white};
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity ${animationTime} ${animationCurve}, visibility ${animationTime} ${animationCurve};

  ${Container}:hover & {
    opacity: 1;
    visibility: visible;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    width: 160px;
    background: linear-gradient(90deg, ${rgba(Colors.white, 0)} 0%, ${Colors.white} 100%);
  }
`

type ResourceItemType = 'list' | 'grid'

type ResourceItemProps = {
  name: string
  type?: ResourceItemType
  handleClick?: () => void
  hoverActions?: React.ReactNode
  children?: React.ReactNode
}

export const ResourceItem = ({ type = 'list', name, handleClick, hoverActions, children }: ResourceItemProps) => {
  return (
    <Container type={type} data-cy={`resource-${name}`} data-test-id={`ResourceItem_${name}`}>
      <Inner type={type}>{children}</Inner>
      {hoverActions && <HoverActions>{hoverActions}</HoverActions>}
      <Overlay onClick={handleClick} />
    </Container>
  )
}

ResourceItem.displayName = 'ResourceItem'

ResourceItem.Main = styled.div<{ type: ResourceItemType }>`
  flex: 2;

  ${(props) =>
    props.type === 'list' &&
    css`
      padding-right: ${Spacing.large};
    `}

  ${(props) =>
    props.type === 'grid' &&
    css`
      width: 100%;
      padding: ${Spacing.large};
    `}
`

ResourceItem.Name = styled.strong`
  display: block;
  color: ${Colors.greyDark};
  margin-bottom: ${`${(stripUnit(Spacing.tiny) as number) * 0.5}px`};

  &:last-child {
    margin-bottom: 0;
  }
`

ResourceItem.Description = styled.div`
  white-space: pre-wrap; /* Needed because sometimes we put break line and some formatting in the text */
  font-size: ${fontSizes.small};
  margin-bottom: ${Spacing.small};
`

type ActionsFunctions = {
  primaryAction?: ButtonProps & { label: string }
  secondaryAction?: (ButtonProps & { label: string }) | null
}

ResourceItem.Actions = ({ primaryAction, secondaryAction }: ActionsFunctions) => {
  return (
    <Actions data-test-id="Actions_Container">
      <ButtonGroup spacing="tight">
        {primaryAction && (
          <Button theme="primary" size="small" dataTestId={`${primaryAction.label}_Button`} {...primaryAction}>
            {primaryAction.label}
          </Button>
        )}
        {secondaryAction && (
          <Button theme="tertiary" size="small" dataTestId={`${secondaryAction.label}_Button`} {...secondaryAction}>
            {secondaryAction.label}
          </Button>
        )}
      </ButtonGroup>
    </Actions>
  )
}

ResourceItem.Meta = styled.p`
  font-size: ${fontSizes.small};
  color: ${Colors.greyLight};
`

ResourceItem.HighlightedBar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: ${Spacing.small};
  background: ${Colors.primaryColor};
`

ResourceItem.FigureWrap = styled.div<{ type: ResourceItemType }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  img {
    ${cover('absolute')};
    object-fit: cover;
  }

  ${(props) =>
    props.type === 'list' &&
    css`
      margin-right: ${Spacing.medium};
      border-radius: ${borderRadius};
      overflow: hidden;
      ${square('40px')};
    `}

  ${(props) =>
    props.type === 'grid' &&
    css`
      width: 100%;
      height: 130px;
    `}
`

ResourceItem.Extra = styled.div`
  padding: 0 ${Spacing.large};
  min-width: 216px;
`
