import { z } from 'zod'
import { i18n, t } from '../../utils'

export const FaqItemi18nFields = {
  projects: t('selected-project'),
  author: t('author'),
  type: t('state'),
  question: t('question'),
  answer: t('answer'),
}

export const FaqItemSchema = z.object({
  projects: z
    .array(
      z.object(
        { id: z.string(), name: z.string().optional().nullable() },
        { required_error: i18n.t('input:is-required', { field: FaqItemi18nFields.projects }) },
      ),
    )
    .min(1, { message: i18n.t('input:is-required', { field: FaqItemi18nFields.projects }) }),
  author: z.object({
    id: z.string(),
    firstName: z.string().nullable().optional(),
    lastName: z.string().nullable().optional(),
  }),
  type: z.string().optional().nullable(),
  question: z.string().min(1, { message: i18n.t('input:is-required', { field: FaqItemi18nFields.question }) }),
  answer: z
    .string()
    .min(1, { message: i18n.t('input:is-required', { field: FaqItemi18nFields.answer }) })
    .refine(
      (val) => {
        return val !== '<p><br></p>'
      },
      { message: i18n.t('input:is-required', { field: FaqItemi18nFields.answer }), path: ['answer'] },
    ),
})

export const faqItemUpdateSchema = FaqItemSchema.extend({ id: z.string() })

export type FaqFormItem = z.infer<typeof FaqItemSchema>
export type FaqFormItemUpdate = z.infer<typeof faqItemUpdateSchema>
