import React from 'react'

function ConditionalWrap({
  condition,
  wrap,
  children,
}: {
  condition: boolean
  wrap: (children: React.ReactNode) => React.ReactNode
  children: React.ReactNode
}) {
  return condition ? <> {wrap(children)}</> : <>{children}</>
}

export { ConditionalWrap }
