import { NetworkStatus } from '@apollo/client'
import { Api, SharedUtils } from '@walter/shared'
import { Form, convertToOptionValue, t, useDebouncedState } from '@walter/shared-web'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import uniqBy from 'lodash/uniqBy'
import React, { useCallback, useEffect, useMemo } from 'react'
import { SelectorProps } from './types'
import { useSelectorProjectId } from './useSelectorProjectId'

type DefaultValue = { id: string; number?: string }

type Props<Model, OptionValue> = SelectorProps<Model, OptionValue> & {
  defaultValue?: DefaultValue | DefaultValue[]
}

export function LockerSelector<Model, OptionValue>(props: Props<Model, OptionValue>) {
  const [textFilter, setTextFilter] = React.useState<string>('')
  const [debouncedTextFilter, forceUpdateDebouncedTextFilter] = useDebouncedState(textFilter, 750)
  const { fetchMoreLockers, lockers, isLoading, hasMore } = useLockersQuery(debouncedTextFilter, props)

  const onAdditionalChange = useCallback(
    (selected: Parameters<NonNullable<typeof props.additionalOnChange>>[0]) => {
      if (textFilter) {
        forceUpdateDebouncedTextFilter('')
      }
      if (props?.additionalOnChange) {
        props.additionalOnChange(selected)
      }
    },
    [textFilter, props.additionalOnChange],
  )

  const defaultValue = useMemo(() => {
    return props?.defaultValue
      ? Array.isArray(props.defaultValue)
        ? convertToOptionValue(
            props.defaultValue,
            props.defaultValue,
            (locker) => (locker as DefaultValue)?.number ?? t('unknown'),
          )
        : {
            value: { id: props.defaultValue.id },
            label: props.defaultValue?.number ?? t('unknown'),
          }
      : undefined
  }, [props.defaultValue])

  return (
    <Form.Select<Model, OptionValue>
      {...props}
      options={lockers.map((locker) => {
        return {
          value: { id: locker.id },
          label: locker.number,
        }
      })}
      isLoading={isLoading || textFilter !== debouncedTextFilter}
      inputValue={hasMore || textFilter ? textFilter : undefined}
      onInputChange={hasMore || textFilter ? setTextFilter : undefined}
      additionalOnChange={onAdditionalChange}
      onMenuScrollToBottom={fetchMoreLockers}
      value={defaultValue}
      noOptionsText={t('no-lockers')}
    />
  )
}

type Locker = NonNullable<Api.GetLockersV2Query['lockersV2']['edges']>[number]['node']

const previousProjectIdAtom = atomWithStorage<string>('previousProjectIdAtom', '')
export const accumulatedLockersAtom = atomWithStorage<Locker[]>('accumulatedLockersAtom', [])

function useLockersQuery<Model, OptionValue>(textFilter: string, props: Props<Model, OptionValue>) {
  const projectId = useSelectorProjectId()
  const [previousProjectId, setPreviousProjectId] = useAtom(previousProjectIdAtom)
  const [accumulated, setAccumulated] = useAtom(accumulatedLockersAtom)

  const {
    data: { lockersV2: { edges = [], pageInfo = {}, totalCount = 0 } = {} } = {},
    loading: loadingLockers,
    fetchMore,
    networkStatus,
  } = Api.useGetLockersV2Query({
    notifyOnNetworkStatusChange: true,
    skip: projectId === 'all',
    variables: {
      projectId,
      first: 24,
      textFilter,
    },
    onCompleted(data) {
      const nodes = data.lockersV2.edges?.map((edge) => edge.node) ?? []
      const defaultValue: Locker[] = SharedUtils.convertToArray(props.defaultValue)
      setAccumulated((prev) => {
        return uniqBy([...prev, ...defaultValue, ...nodes], 'id').filter((locker) => locker?.id?.length > 0)
      })
    },
  })

  useEffect(() => {
    if (projectId !== previousProjectId) {
      setPreviousProjectId(projectId)
      setAccumulated(edges?.map((edge) => edge.node) ?? [])
    }
  }, [edges, projectId])

  const fetchMoreLockers = useCallback(async () => {
    if ((edges?.length || 0) < totalCount && networkStatus !== NetworkStatus.setVariables) {
      try {
        await fetchMore({
          variables: {
            after: pageInfo.endCursor,
          },
        })
      } catch (err) {
        console.error('[LockerSelector/useLockersQuery]', 'Error fetching more', err)
      }
    }
  }, [pageInfo?.endCursor, edges?.length, totalCount])

  return {
    hasMore: (edges?.length || 0) < totalCount,
    lockers: accumulated,
    fetchMoreLockers,
    isLoading:
      loadingLockers || networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.setVariables,
  }
}
