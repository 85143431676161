import { DocumentNode, QueryHookOptions, useQuery } from '@apollo/client'
import { useEffect } from 'react'

type ErrorInput =
  | Error
  | Error[]
  | {
      graphQLErrors: Error[]
    }

export const getGraphQLErrorsMessages = (error: ErrorInput) => {
  if (!error) return []
  if (Array.isArray(error)) {
    return error.map(({ message }) => message)
  }

  if (error instanceof Error) {
    return [error.message]
  }

  if (!Array.isArray(error) && error.graphQLErrors && error.graphQLErrors.length) {
    return error.graphQLErrors.map(({ message }) => message)
  }

  return []
}

export const getGraphQLErrorMessage = (err?: ErrorInput | Error) => {
  if (!err) {
    return 'Something went wrong'
  }

  const graphQlErrorMessages = getGraphQLErrorsMessages(err)

  if (graphQlErrorMessages && graphQlErrorMessages.length) {
    return graphQlErrorMessages[0]
  }

  if (err instanceof Error && err.message) {
    return err.message
  }

  return err
}

export const formatGraphQLErrorMessage = (message?: string) => {
  if (message && message.includes('A unique constraint')) {
    const uniqueField = message.split(' ').pop()
    return `This ${uniqueField} is already used`
  }
  return 'error'
}

type FetchWithPaginationArgs<ApiType extends Record<string, { id: string }[] | string>> = {
  query: DocumentNode
  variables: QueryHookOptions<ApiType>['variables']
  skip: boolean
  model: keyof ApiType
}
export function useFetchWithPagination<ApiType extends Record<string, { id: string }[] | string>>({
  query,
  variables,
  skip,
  model,
}: FetchWithPaginationArgs<ApiType>) {
  const { data, error, loading, fetchMore, refetch } = useQuery<ApiType>(query, {
    skip,
    variables,
  })

  useEffect(() => {
    const cursor = data ? data[model][data[model].length - 1] : null
    if (data && cursor && typeof cursor !== 'string') {
      fetchMore({
        variables: { cursorId: cursor?.id },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const previousOfModel = previousResult[model] ?? []
          const fetchMoreOfModel = fetchMoreResult[model] ?? []
          // eslint-disable-next-line no-console
          console.log('got more', model)
          return {
            [model]: [...previousOfModel, ...fetchMoreOfModel],
          } as unknown as ApiType
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return {
    data,
    error,
    loading,
    refetch,
  }
}
