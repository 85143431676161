import React from 'react'
import styled from 'styled-components'
import { Spacing } from '@walter/shared'

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 10px;
  padding: ${Spacing.large};
`

type InfoBlockProps = {
  children: React.ReactNode
}

export const InfoBlock = ({ children }: InfoBlockProps) => <Container>{children}</Container>
