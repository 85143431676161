import * as React from 'react'

type PillGroupProps = {
  dataTestId?: string
  children: React.ReactNode
}

export const PillGroup = ({ dataTestId, children }: PillGroupProps) => (
  <div className="inline-flex flex-wrap items-center justify-start -m-1" data-test-id={dataTestId}>
    {React.Children.map(
      children,
      (child, i) =>
        child && (
          <div className="p-1 flex" key={i}>
            {child}
          </div>
        ),
    )}
  </div>
)
