import React from 'react'
import styled, { css } from 'styled-components'
import { Colors, Spacing } from '@walter/shared'
import { borderRadius, boxShadow } from '../../styles/global'

const Container = styled.div<{ type: string }>`
  display: block;
  margin-bottom: ${Spacing.xLarge};

  ${(props) =>
    props.type === 'list' &&
    css`
      border-radius: ${borderRadius};
      border: 1px solid ${Colors.borderColor};
      background-color: ${Colors.white};
      overflow: hidden;
      ${boxShadow};
    `}

  ${(props) =>
    props.type === 'grid' &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: ${Spacing.medium};
      grid-column-gap: ${Spacing.medium};
    `}
`

type ResourceListProps = {
  children: React.ReactNode
  type?: string
  testID?: string
  dataTestId?: string
}

export const ResourceList = ({ type = 'list', children, testID, dataTestId }: ResourceListProps) => {
  const dataTestIdValue = dataTestId ? dataTestId + '_Container' : 'Container'
  return (
    <Container data-test-id={dataTestIdValue} data-testid={testID ?? ''} type={type}>
      {children}
    </Container>
  )
}
