import { Colors, Types } from '@walter/shared'
import { mix } from 'polished'
import React, { forwardRef } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import {
  animationCurve,
  animationTime,
  borderRadius,
  boxShadow,
  inputHeight,
  inputHeightSmall,
  square,
} from '../../styles/global'
import { ButtonTheme } from '../Button'
import { Icon, IconSize } from '../Icon'

const Container = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Inner = styled.div<{ variant?: Types.Variant; size?: IconSize; disabled?: boolean }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${borderRadius};
  text-align: center;
  transition: all ${animationTime} ${animationCurve};
  ${square(inputHeight)};
  ${boxShadow};

  ${(props) =>
    props.disabled
      ? css`
          cursor: default;
        `
      : css`
          cursor: pointer;
        `}

  ${(props) =>
    props.variant === 'circle' &&
    css`
      border-radius: 50%;
    `}

  ${(props) =>
    props.size === 'small' &&
    css`
      ${square(inputHeightSmall)};
    `}

  ${(props) =>
    props.theme === 'primary' &&
    css`
      color: ${Colors.white};
      background-color: ${Colors.primaryColor};

      ${Container}:hover &,
      ${Container}:focus &,
      ${Container}:active & {
        background-color: ${mix(0.93, Colors.primaryColor, Colors.black)};
      }
    `}

  ${(props) =>
    props.theme === 'secondary' &&
    css`
      color: ${Colors.white};
      background-color: ${Colors.secondaryColor};

      ${Container}:hover &,
      ${Container}:focus &,
      ${Container}:active & {
        background-color: ${mix(0.93, Colors.secondaryColor, Colors.black)};
      }
    `}

  ${(props) =>
    props.theme === 'tertiary' &&
    css`
      color: ${Colors.greyDark};
      background-color: ${Colors.white};
      border: 1px solid ${Colors.primaryColor};

      ${Container}:hover &,
      ${Container}:focus &,
      ${Container}:active & {
        border-color: ${mix(0.9, Colors.primaryColor, Colors.black)};
      }
    `}

    ${(props) =>
    props.theme === 'transparent' &&
    css`
      color: ${Colors.greyDark};
      background-color: 'transparent';
      box-shadow: none;
    `}

  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.4;
    user-select: none;
  }
`

const getElement = (props: { to?: string; href?: string }) => {
  if (props.to) return Link
  if (props.href) return 'a'
  return 'button'
}

type IconButtonProps = {
  dataTestId?: string
  icon: Types.IconName
  hint?: string
  theme?: ButtonTheme
  variant?: Types.Variant
  label?: string
  size?: IconSize
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    { theme = 'tertiary', dataTestId, icon, variant, label, size, onClick, hint, disabled, ...rest }: IconButtonProps,
    ref,
  ) => (
    <Container
      data-test-id={dataTestId}
      ref={ref}
      as={getElement(rest)}
      {...rest}
      data-tip={label || null}
      data-cy={label || null}
      disabled={disabled}
      onClick={onClick}
    >
      <Inner theme={theme} variant={variant} size={size} disabled={disabled}>
        <Icon icon={icon} size={size} hint={hint} />
      </Inner>
    </Container>
  ),
)

IconButton.displayName = 'IconButton'
