// https://github.com/colinhacks/zod/discussions/2134#discussioncomment-6581986

import { ZodType, ZodNullable, ZodOptional, ZodArray, ZodObject, ZodEffects } from 'zod'

export const getSchemaKeys = (schema: ZodType): string[] => {
  if (schema instanceof ZodNullable || schema instanceof ZodOptional) {
    return getSchemaKeys(schema.unwrap())
  }
  if (schema instanceof ZodArray) {
    return getSchemaKeys(schema.element)
  }
  if (schema instanceof ZodObject) {
    const entries = Object.entries<Zod.ZodType>(schema.shape)
    return entries.flatMap(([key, value]) => {
      const nested = getSchemaKeys(value).map((subKey) => `${key}.${subKey}`)
      return nested.length ? nested : key
    })
  }
  if (schema instanceof ZodEffects) {
    return getSchemaKeys(schema._def.schema)
  }
  return []
}
