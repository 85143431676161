import residentWebLicenses from './rw-licenses.json'
import managerWebLicenses from './mw-licenses.json'
import residentMobileLicenses from './rm-licenses.json'
import managerMobileLicenses from './mm-licenses.json'

export const OpenSourceLicenses = {
  residentWeb: residentWebLicenses,
  managerWeb: managerWebLicenses,
  residentMobile: residentMobileLicenses,
  managerMobile: managerMobileLicenses,
}
