import { Config } from '@walter/shared'

import { UserUtils } from '@walter/shared-web'
import React from 'react'
import AuthContext from './Auth'

export function IntercomProvider({ children }: { children: React.ReactNode }) {
  const { currentUser } = React.useContext(AuthContext)

  React.useEffect(() => {
    if (currentUser) {
      if (Config.isProduction && currentUser.managingCompany) {
        const userIsWalterAdmin = currentUser.email?.startsWith('admin') && currentUser.email.includes('usewalter.com')

        if (!userIsWalterAdmin) {
          (window as any).Intercom('boot', {
            app_id: 'vfcoetd2',
            email: currentUser.email,
            user_id: currentUser.id,
            name: UserUtils.getUserName(currentUser),
            ...(currentUser.managingCompany && {
              managingCompanyName: currentUser.managingCompany.shortName,
              company: {
                company_id: currentUser.managingCompany.id,
                name: currentUser.managingCompany.shortName,
                number_of_projects: currentUser.managingCompany.projects?.length ?? 0,
                number_of_launched_projects: currentUser.managingCompany.projects?.filter(
                  (project) => (project as any)?.isLaunched,
                ).length,
                total_number_of_doors: 0,
                total_number_of_doors_launched: 0,
              },
            }),
          })
        }
      }
    }
  }, [currentUser])

  return <>{children}</>
}
