import { z } from 'zod'
import { i18n, t } from '../../utils'
import { fieldIsTooLong } from './common'

const fieldIsLongerThan10 = fieldIsTooLong(10)

export const Parkingi18nFields = {
  number: t('fields-parking:number'),
  cadastreNumber: t('fields-parking:cadastre-number'),
  proportionateShare: t('fields-parking:proportion-share'),
  owners: t('residents-uppercase'),
  properties: t('fields-parking:units'),
}

export const parkingSchemaCreate = z.object({
  number: z
    .string({ required_error: i18n.t('input:is-required', { field: Parkingi18nFields.number }) })
    .min(1, { message: i18n.t('input:is-required', { field: Parkingi18nFields.number }) })
    .max(10, fieldIsLongerThan10(Parkingi18nFields.number)),
  cadastreNumber: z
    .string()
    .max(10, fieldIsLongerThan10(t('fields-parking:cadastre-number')))
    .optional()
    .nullable(),
  proportionateShare: z
    .string()
    .transform((val) => {
      const num = Number(val)
      return isNaN(num) ? 0 : num
    })
    .refine(
      (val) => {
        return val >= 0 && val <= 100
      },
      {
        message: t('proportionate-share-should-be-percentage'),
        path: ['proportionateShare'],
      },
    )
    .or(
      z
        .number()
        .max(100, { message: t('proportionate-share-should-be-percentage') })
        .min(0, { message: t('proportionate-share-should-be-percentage') }),
    )
    .default(0),
  owners: z.array(
    z.object({
      id: z.string({ required_error: i18n.t('input:is-required', { field: Parkingi18nFields.owners }) }),
      firstName: z.string().optional(),
      lastName: z.string().optional(),
    }),
    { required_error: i18n.t('input:is-required', { field: Parkingi18nFields.owners }) },
  ),
  properties: z.array(
    z.object({
      id: z.string(i18n.t('input:is-required', { field: Parkingi18nFields.properties })),
      address: z
        .object({
          id: z.string(),
          apartmentNumber: z.string().optional(),
        })
        .nullable()
        .optional(),
    }),
    { required_error: i18n.t('input:is-required', { field: Parkingi18nFields.properties }) },
  ),
})

export type ParkingItem = z.infer<typeof parkingSchemaCreate> & { id: string }
export const parkingSchemaUpdate = parkingSchemaCreate.extend({ id: z.string() })
