import { Api } from '@walter/shared'
import { t } from './i18n'

export function getReservationValueDisplayText(eventType: Api.Reservation_Status) {
  switch (eventType) {
    case Api.Reservation_Status.Confirmed:
      return t('confirmed')
    case Api.Reservation_Status.Pending:
      return t('pending')
    case Api.Reservation_Status.Canceled:
      return t('canceled')
    case Api.Reservation_Status.Rejected:
      return t('rejected')
    default:
      return t('undefined')
  }
}

export function labelForPackageStatus(status: Api.Package_Status) {
  switch (status) {
    case 'AT_RECEPTION':
      return t('package-at-reception')
    case 'DELIVERED':
      return t('package-delivered')
    case 'DELIVERED_AT_YOUR_DOOR':
      return t('package-delivered-at-your-door')
  }
}
