import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { rgba, stripUnit } from 'polished'

import { Colors, Spacing } from '@walter/shared'
import { globalStyles, Icon } from '@walter/shared-web'
import { animationCurve, animationTime, borderRadius } from '../../styles/global'
import { fontSizes } from '../../styles/typography'
import { t } from '../../utils'

const Container = styled.div`
  position: relative;
  width: 100%;
`

const Input = styled.input`
  display: flex;
  font-size: ${fontSizes.small};
  padding-left: 36px;
  height: ${globalStyles.inputHeightSmall};
  width: 100%;
  color: ${Colors.grey};
  background-color: ${Colors.offWhite};
  transition: background-color ${animationTime} ${animationCurve}, box-shadow ${animationTime} ${animationCurve};
  border-radius: ${borderRadius};

  &:focus {
    background-color: ${Colors.white};
    box-shadow: inset 0 0 0 1px ${Colors.primaryColor}, 0 0 0 1px ${Colors.primaryColor};
  }

  &::placeholder {
    color: ${rgba(Colors.grey, 0.5)};
  }
`

const SearchIcon = styled.div`
  position: absolute;
  top: 50%;
  left: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
  transform: translateY(-50%);
  display: flex;
  pointer-events: none;
  opacity: 0.6;
  transition: opacity ${globalStyles.animationTime} ${globalStyles.animationCurve};

  ${Input}:focus + & {
    opacity: 0.8;
  }
`

const MinChar = styled.span`
  font-size: ${fontSizes.tiny};
  position: absolute;
  right: ${Spacing.small};
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.6;
  transition: opacity ${globalStyles.animationTime} ${globalStyles.animationCurve};
`

type SearchProps = {
  onTextChange?: (value: string) => void
  uniqId?: string
  customPlaceholder?: string
  autoFocused?: boolean
  minChar?: number
  dataTestId?: string
  initValue?: string
}
export const Search = ({
  onTextChange,
  customPlaceholder,
  uniqId,
  autoFocused,
  minChar = 0,
  dataTestId = '',
  initValue = '',
}: SearchProps) => {
  const searchInputRef = useRef<HTMLInputElement | null>(null)
  const [value, setValue] = React.useState(initValue)

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const value: string = (e.target as HTMLInputElement).value
    if (value.length >= minChar) {
      onTextChange?.(value)
    } else {
      onTextChange?.('')
    }
    setValue(value)
  }

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.blur()
    }
  }, [uniqId])

  useEffect(() => {
    if (autoFocused) {
      searchInputRef.current?.focus()
    }
  }, [autoFocused])

  return (
    <>
      <Container data-test-id={`${dataTestId}_Container`} data-cy="search-input-container">
        <Input
          data-test-id={dataTestId + '_Input'}
          ref={searchInputRef}
          placeholder={customPlaceholder || t('search-input:placeholder')}
          onKeyUp={onKeyUp}
          defaultValue={value}
        />
        <SearchIcon>
          <Icon icon="search" size="small" />
        </SearchIcon>
        {value && minChar > value.length && <MinChar>{t('need-at-least-x-characters', { minChar })}</MinChar>}
      </Container>
    </>
  )
}
