import styled from 'styled-components'
import { animationCurve, animationTime } from '../../styles/global'
import { fontSizes } from '../../styles/typography'

const Link = styled.button`
  justify-self: flex-end;
  font-size: ${fontSizes.tiny};
  text-decoration: underline;
  transition: opacity ${animationTime} ${animationCurve};

  &[disabled] {
    opacity: 0;
  }
`

export { Link }
