import { getLanguage } from '../../utils'

// eslint-disable-next-line no-irregular-whitespace
// Space character used by Intl.NumberFormat. Ex: 10 000,00
const NARROW_NO_BREAK_SPACE_CHAR = String.fromCharCode(8239)

export function emptyStringToNull(value: number | string): number | null {
  const isString = typeof value === 'string'
  if (isString && value === '') {
    return null
  }
  return isString ? toNumberFromI18nNumberString(value) : value
}

function toNumberFromI18nNumberString(val: string): number {
  const value = val.replace(',', getLanguage().startsWith('fr') ? '.' : '').replaceAll(NARROW_NO_BREAK_SPACE_CHAR, '')
  return Number(value)
}
