import React from 'react'
import styled from 'styled-components'

import { Colors, Spacing, Types } from '@walter/shared'
import { borderRadius, boxShadow } from '../../styles/global'
import { fontWeights, fontSizes } from '../../styles/typography'

import { Icon } from '../Icon'

const Container = styled.div`
  padding: ${Spacing.medium};
  border-radius: ${borderRadius};
  border: 1px solid ${Colors.borderColor};
  ${boxShadow};

  & + & {
    margin-top: ${Spacing.medium};
  }
`

const Name = styled.span`
  display: block;
  color: ${Colors.greyDark};
  font-weight: ${fontWeights.bold};
  margin-bottom: ${Spacing.tiny};
`

const Meta = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fontSizes.small};
`

const IconWrap = styled.div`
  display: flex;
  color: ${(props) => props.color};
  margin-right: ${Spacing.small};
`

const Method = styled.div`
  display: flex;
  margin-left: ${Spacing.medium};
  opacity: 0.9;
`

type ProfilePostType = 'positive' | 'negative'

type ProfilePostProps = {
  name: string
  description?: string
  type: ProfilePostType
  icon: Types.IconName
  method?: Types.IconName
}

export const ProfilePost = ({ name, icon, description, type, method }: ProfilePostProps) => (
  <Container>
    <Name>{name}</Name>
    <Meta>
      <IconWrap color={type === 'positive' ? Colors.green : Colors.red}>
        <Icon icon={icon} size="small" />
      </IconWrap>
      <span>{description}</span>
      {method && (
        <Method data-tip={method === 'mobile-phone' ? 'Mobile app' : 'Email'}>
          <Icon icon={method} size="small" />
        </Method>
      )}
    </Meta>
  </Container>
)
