import './global.css'
import 'react-loading-skeleton/dist/skeleton.css'

import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { router } from './routes/router'

// React.StrictMode is removed due to react-beautiful-dnd: https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1111169234
ReactDOM.createRoot(document.getElementById('root')!).render(<RouterProvider router={router} />)
