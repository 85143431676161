import { z } from 'zod'
import { i18n, t } from '../../utils'

export const Groupsi18nFields = {
  titleFr: t('form-label-title-french'),
  descriptionFr: t('description-french'),
  titleEn: t('form-label-title-english'),
  descriptionEn: t('description-english'),
  model: t('fields-segment:model'),
  path: t('fields-segment:field'),
  comparison: t('fields-segment:comparison'),
  value: t('fields-segment:value'),
  properties: t('fields-segment:units'),
  users: t('residents-uppercase'),
  residentCount: t('resident-count-title'),
}

const SegmentFieldsSchema = z
  .object({
    id: z.string().nullable().optional(),
    model: z.string().nullable().optional(),
    path: z.string().nullable().optional(),
    comparison: z.string().nullable().optional(),
    value: z.string().nullable().optional(),
  })
  .refine(
    (data) => {
      if (data.path) {
        return !!data.model
      }
      return true
    },
    {
      message: i18n.t('input:is-required', { field: Groupsi18nFields.model }),
      path: ['model'],
    },
  )
  .refine(
    (data) => {
      if (data.model) {
        return !!data.path
      }
      return true
    },
    {
      message: i18n.t('input:is-required', { field: Groupsi18nFields.value }),
      path: ['path'],
    },
  )
  .refine(
    (data) => {
      if (data.path) {
        return !!data.comparison
      }
      return true
    },
    {
      message: i18n.t('input:is-required', { field: Groupsi18nFields.path }),
      path: ['comparison'],
    },
  )
  .refine(
    (data) => {
      if (data.comparison) {
        return !!data.value
      }
      return true
    },
    {
      message: i18n.t('input:is-required', { field: Groupsi18nFields.comparison }),
      path: ['value'],
    },
  )

  .optional()

export const GroupsSchemaCreate = z.object({
  titleFr: z.string().nullable().optional(),
  titleEn: z.string().nullable().optional(),
  descriptionFr: z.string().optional(),
  descriptionEn: z.string().optional(),
  segmentFields: z.array(SegmentFieldsSchema).nullable(),
  residentCount: z.number().optional().nullable(),
  properties: z
    .array(
      z.object({
        id: z.string({ required_error: i18n.t('input:is-required', { field: Groupsi18nFields.properties }) }),
      }),
    )
    .optional()
    .nullable(),
  users: z
    .array(
      z.object({
        id: z.string({ required_error: i18n.t('input:is-required', { field: Groupsi18nFields.users }) }),
      }),
    )
    .optional()
    .nullable(),
})

export type Groups = z.infer<typeof GroupsSchemaCreate> & { id: string }
