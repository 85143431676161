import SendbirdChat, { ConnectionState, User } from '@sendbird/chat'
import {
  GroupChannel,
  GroupChannelHandler,
  GroupChannelListQuery,
  GroupChannelModule,
  Member,
  MessageCollection,
  MessageCollectionEventHandler,
  MessageCollectionInitPolicy,
  MessageCollectionInitResultHandler,
  MessageFilter,
  SendbirdGroupChat,
  UnreadChannelFilter,
} from '@sendbird/chat/groupChannel'
import {
  FileMessage,
  GroupChannelHandlerParams,
  Module,
  SendbirdChatParams,
  UserMessage,
} from '@sendbird/chat/lib/__definition'
import { BaseMessage, FileMessageCreateParams, UserMessageCreateParams } from '@sendbird/chat/message'
import { Config } from '../config'

const UNREAD_CHANNEL_QUERY_ID = 'UNREAD_CHANNEL_QUERY_ID'
const ALL_CHANNEL_QUERY_ID = 'ALL_CHANNEL_QUERY_ID'

const MESSAGES_BATCH_SIZE = 25

export interface Channel extends GroupChannel {
  lastMessage: GroupChannel['lastMessage'] & {
    message?: string
    sender?: {
      userId: string
      nickname: string
    }
  }
  members: (Member & { metaData: { role?: 'RESIDENT' | 'MANAGER' } })[]
}

export type Message = UserMessage | FileMessage

export type ChannelsByUrl = Map<string, GroupChannel>

type SendbirdSession = {
  userId: string
  sessionToken: string
  expiresAt: string
}

interface ConnectParams {
  session: SendbirdSession
  userId: string
  channelHandler: GroupChannelHandlerParams
}

interface LoadMessagesParams {
  channelUrl: GroupChannel['url']
  messageHandlers: MessageCollectionEventHandler
  privateMessageHandlers?: MessageCollectionEventHandler
  onCacheResult: MessageCollectionInitResultHandler
  onApiResult: MessageCollectionInitResultHandler
  limit?: number
  includePrivate?: boolean
}

interface SendUserMessageParams {
  channel?: GroupChannel
  channelUrl?: string
  params: UserMessageCreateParams & { mentions?: string[] }
}

interface SendFileMessageParams extends Omit<SendUserMessageParams, 'params'> {
  params: {
    file?: File
    fileUrl?: string
  }
}

interface BuildChannelUrl {
  managingCompanyId: string
  projectId: string
  conversationType: 'dm' | 'unit'
  entityId: string
  isPrivate?: boolean
}

interface FormatLastMessageParams {
  t: (key: string) => string
  lastMessage?: Channel['lastMessage'] | null
  currentUserId?: string
}

type AsyncStorage = SendbirdChatParams<Module[]>['useAsyncStorageStore']

export class MessagingService {
  sendbird: SendbirdGroupChat
  unreadChannelQuery?: GroupChannelListQuery
  allChannelQuery?: GroupChannelListQuery
  user?: User
  messageCollection?: MessageCollection
  privateMessageCollection?: MessageCollection

  constructor(mobileAsyncStorage?: AsyncStorage) {
    this.sendbird = SendbirdChat.init({
      appId: Config.sendBirdAppId,
      modules: [new GroupChannelModule()],
      localCacheEnabled: true,
      useAsyncStorageStore: mobileAsyncStorage,
    }) as SendbirdGroupChat
  }

  static messagesBatchSize = MESSAGES_BATCH_SIZE

  static projectIdFromChannelUrl(channelUrl: string) {
    return channelUrl.split('-')[1]
  }

  static entityIdFromChannelUrl(channelUrl: string) {
    return channelUrl.split('-')[3]
  }

  static channelIsPrivate(channelUrl: string) {
    return channelUrl.includes('-private')
  }

  static channelIsDm(channelUrl: string) {
    return channelUrl.includes('-dm-')
  }
  static channelIsUnit(channelUrl: string) {
    return channelUrl.includes('-unit-')
  }

  static formatLastMessage({ lastMessage, t, currentUserId }: FormatLastMessageParams) {
    if (!lastMessage || !currentUserId) {
      return null
    }
    const sender = lastMessage?.sender?.userId === currentUserId ? t('me') : lastMessage?.sender?.nickname || '?'
    const messageType = getLastMessageType(lastMessage?.message || '')
    const message = lastMessage.isFileMessage()
      ? t('file-message')
      : messageType !== 'text'
      ? t(messageType)
      : lastMessage.message || t('message')

    return `${sender}: ${message}`
  }

  static buildChannelUrl({
    managingCompanyId,
    projectId,
    conversationType,
    entityId,
    isPrivate = false,
  }: BuildChannelUrl) {
    return `${managingCompanyId}-${projectId}-${conversationType}-${entityId}${isPrivate ? '-private' : ''}`
  }

  async connect({ userId, session, channelHandler }: ConnectParams) {
    if (
      this.sendbird.connectionState === ConnectionState.OPEN ||
      this.sendbird.connectionState === ConnectionState.CONNECTING
    ) {
      return null
    }

    this.user = await this.sendbird.connect(userId, session.sessionToken)

    if (!this.user) return null

    this.unreadChannelQuery = this.sendbird.groupChannel.createMyGroupChannelListQuery({
      limit: 100, // 100 is the maximum
      includeEmpty: false,
      unreadChannelFilter: UnreadChannelFilter.UNREAD_MESSAGE,
      metadataKey: 'archive',
      metadataValues: ['false'],
    })

    this.allChannelQuery = this.sendbird.groupChannel.createMyGroupChannelListQuery({
      limit: 100, // 100 is the maximum
      includeEmpty: true,
      unreadChannelFilter: UnreadChannelFilter.ALL,
      metadataKey: 'archive',
      metadataValues: ['false'],
    })

    const handlers = new GroupChannelHandler(channelHandler)

    this.sendbird.groupChannel.addGroupChannelHandler(UNREAD_CHANNEL_QUERY_ID, handlers)
    this.sendbird.groupChannel.addGroupChannelHandler(ALL_CHANNEL_QUERY_ID, handlers)

    return this.getNextUnreadChannels()
  }

  async getNextUnreadChannels() {
    if (!this.unreadChannelQuery) {
      throw new Error('channel-query-not-initialized')
    }
    const channelsFromQuery = await this.unreadChannelQuery.next()
    const publicChannelsByUrl: ChannelsByUrl = new Map()
    const privateChannelsByUrl: ChannelsByUrl = new Map()

    for (const channel of channelsFromQuery) {
      if (channel.url.includes('-private')) {
        privateChannelsByUrl.set(channel.url, channel)
        continue
      }
      publicChannelsByUrl.set(channel.url, channel)
    }

    return { public: publicChannelsByUrl, private: privateChannelsByUrl, hasNext: this.unreadChannelQuery.hasNext }
  }

  async getNextChannels() {
    if (!this.allChannelQuery) {
      throw new Error('channel-query-not-initialized')
    }
    const channelsFromQuery = await this.allChannelQuery.next()
    const publicChannelsByUrl: ChannelsByUrl = new Map()
    const privateChannelsByUrl: ChannelsByUrl = new Map()

    for (const channel of channelsFromQuery) {
      if (channel.url.includes('-private')) {
        privateChannelsByUrl.set(channel.url, channel)
        continue
      }
      publicChannelsByUrl.set(channel.url, channel)
    }

    return { public: publicChannelsByUrl, private: privateChannelsByUrl, hasNext: this.allChannelQuery.hasNext }
  }

  clearCache() {
    return this.sendbird.clearCachedData()
  }

  async disconnect() {
    this.user = undefined
    this.messageCollection && this.messageCollection.dispose()
    this.messageCollection = undefined
    this.unreadChannelQuery = undefined
    this.allChannelQuery = undefined
    if (this.sendbird.connectionState === ConnectionState.OPEN) {
      return await this.sendbird.disconnect()
    }
  }

  async enterChannel({
    channelUrl,
    messageHandlers,
    onCacheResult,
    onApiResult,
    includePrivate = false,
  }: LoadMessagesParams) {
    if (this.sendbird.connectionState !== ConnectionState.OPEN) return
    if (this.messageCollection) {
      this.messageCollection.dispose()
    }
    if (this.privateMessageCollection) {
      this.privateMessageCollection.dispose()
    }

    const messageFilter = new MessageFilter()
    const channel = await this.sendbird.groupChannel.getChannel(channelUrl)

    if (!channel) {
      throw new Error('channel-not-found')
    }

    if (channel.unreadMessageCount > 0) {
      await channel.markAsRead()
    }

    const messageCollection = channel.createMessageCollection({
      filter: messageFilter,
      startingPoint: Date.now(),
      limit: MESSAGES_BATCH_SIZE * 2,
    })

    messageCollection.setMessageCollectionHandler(messageHandlers)
    messageCollection
      .initialize(MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API)
      .onCacheResult(onCacheResult)
      .onApiResult(onApiResult)

    this.messageCollection = messageCollection

    if (!includePrivate) return

    const privateChannel = await this.sendbird.groupChannel.getChannel(channelUrl + '-private')

    if (!privateChannel) {
      return
    }

    if (privateChannel.unreadMessageCount > 0) {
      await privateChannel.markAsRead()
    }

    const privateMessageCollection = privateChannel.createMessageCollection({
      filter: messageFilter,
      startingPoint: Date.now(),
      limit: MESSAGES_BATCH_SIZE * 2,
    })

    privateMessageCollection.setMessageCollectionHandler(messageHandlers)
    privateMessageCollection
      .initialize(MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API)
      .onCacheResult(onCacheResult)
      .onApiResult(onApiResult)

    this.privateMessageCollection = privateMessageCollection
  }

  async markChannelAsRead(channelUrl: string) {
    const channel = await this.sendbird.groupChannel.getChannel(channelUrl)
    if (channel.unreadMessageCount > 0) {
      await channel.markAsRead()
    }
  }

  exitChannel() {
    this.messageCollection && this.messageCollection.dispose()
    this.messageCollection = undefined
    this.privateMessageCollection && this.privateMessageCollection.dispose()
    this.privateMessageCollection = undefined
    return
  }
  async loadPreviousMessages(includePrivate = false) {
    if (!this.messageCollection) {
      throw new Error('message-collection-not-initialized')
    }
    let publicMessages: BaseMessage[] = []
    let privateMessage: BaseMessage[] = []
    if (this.messageCollection.hasPrevious) {
      publicMessages = await this.messageCollection.loadPrevious()
    }
    if (includePrivate && this.privateMessageCollection && this.privateMessageCollection.hasPrevious) {
      privateMessage = await this.privateMessageCollection.loadPrevious()
    }

    return includePrivate
      ? publicMessages.concat(privateMessage).sort((a, b) => a.createdAt - b.createdAt)
      : publicMessages
  }

  async sendUserMessage({ channel, params, channelUrl }: SendUserMessageParams) {
    if (channelUrl) {
      const foundChannel = await this.sendbird.groupChannel.getChannel(channelUrl)
      return foundChannel.sendUserMessage(params)
    }
    return channel?.sendUserMessage(params)
  }

  async sendFileMessage({ channel, params, channelUrl }: SendFileMessageParams) {
    const fileMessageParams: FileMessageCreateParams = {}
    if (params.file) {
      fileMessageParams.file = params.file
      fileMessageParams.fileName = params.file?.name || 'Attachment'
      fileMessageParams.fileSize = params.file?.size
      fileMessageParams.mimeType = params.file?.type
    } else {
      fileMessageParams.fileUrl = params.fileUrl
    }

    if (channelUrl) {
      const foundChannel = await this.sendbird.groupChannel.getChannel(channelUrl)
      return foundChannel.sendFileMessage(params)
    }
    return channel?.sendFileMessage(params)
  }

  async deleteMessage(channelUrl: string, message: BaseMessage) {
    if (message.messageType === 'admin') {
      throw new Error('cannot-delete-admin-message')
    }
    const channel = await this.sendbird.groupChannel.getChannel(channelUrl)
    return channel.deleteMessage(message)
  }
}

function getLastMessageType(msg: string) {
  if (msg.includes('email.id')) {
    return 'email'
  }
  if (msg.includes('task.id')) {
    return 'task'
  }

  if (msg.includes('rule.id')) {
    return 'rule-singular'
  }
  if (msg.includes('contact.id')) {
    return 'contact'
  }
  if (msg.includes('event.id')) {
    return 'event'
  }
  if (msg.includes('faqItem.id')) {
    return 'faq'
  }
  if (msg.includes('file.id')) {
    return 'file'
  }
  if (msg.includes('post.id')) {
    return 'memo'
  }

  return 'text'
}
