import { z } from 'zod'
import { i18n, t } from '../../utils'
import { emptyStringToNull } from './transformationUtils'

export const TaskInterventioni18nFields = {
  date: t('date'),
  hours: t('hours-uppercase-first-character'),
  minutes: t('minutes-uppercase-first-character'),
  kilometers: t('kilometers'),
  materialFees: t('material-fees'),
  fees: t('fees'),
  subcontractingFees: t('subcontracting-fees'),
  description: t('description'),
  files: t('files'),
  shouldCloseParentTask: t('should-close-parent-task'),
  category: t('category'),
  subcategory: t('subcategory'),
  location: t('location'),
  item: t('item'),
  problem: t('problem'),
}

export const taskInterventionSchema = z.object({
  date: z.date({ required_error: i18n.t('input:is-required', { field: TaskInterventioni18nFields.date }) }).or(
    z
      .string({ required_error: i18n.t('input:is-required', { field: TaskInterventioni18nFields.date }) })
      .transform((val) => {
        return new Date(val)
      }),
  ),
  hours: z
    .string({ required_error: i18n.t('input:is-required', { field: TaskInterventioni18nFields.hours }) })
    .transform(emptyStringToNull)
    .refine(
      (val) => {
        return val == null || (!isNaN(val) && Number.isInteger(val))
      },
      { message: i18n.t('input:must-be-integer', { field: TaskInterventioni18nFields.hours }) },
    )
    .refine(
      (val) => {
        return val == null || val >= 0
      },
      { message: i18n.t('input:too-low', { field: TaskInterventioni18nFields.hours, min: 0 }) },
    )
    .refine(
      (val) => {
        return val == null || val < 24
      },
      { message: i18n.t('input:too-high', { field: TaskInterventioni18nFields.hours, max: 23 }) },
    )
    .or(
      z
        .number({ required_error: i18n.t('input:is-required', { field: TaskInterventioni18nFields.hours }) })
        .int()
        .min(0, i18n.t('input:too-low', { field: TaskInterventioni18nFields.hours, min: 0 }))
        .max(23, i18n.t('input:too-high', { field: TaskInterventioni18nFields.minutes, max: 23 })),
    )
    .nullable()
    .optional(),
  minutes: z
    .string({ required_error: i18n.t('input:is-required', { field: TaskInterventioni18nFields.minutes }) })
    .transform(emptyStringToNull)
    .refine(
      (val) => {
        return val == null || (!isNaN(val) && Number.isInteger(val))
      },
      { message: i18n.t('input:must-be-integer', { field: TaskInterventioni18nFields.minutes }) },
    )
    .refine(
      (val) => {
        return val == null || val >= 0
      },
      { message: i18n.t('input:too-low', { field: TaskInterventioni18nFields.minutes, min: 0 }) },
    )
    .refine(
      (val) => {
        return val == null || val < 60
      },
      { message: i18n.t('input:too-high', { field: TaskInterventioni18nFields.minutes, max: 59 }) },
    )
    .or(
      z
        .number({ required_error: i18n.t('input:is-required', { field: TaskInterventioni18nFields.minutes }) })
        .int()
        .min(0, i18n.t('input:too-low', { field: TaskInterventioni18nFields.minutes, min: 0 }))
        .max(59, i18n.t('input:too-high', { field: TaskInterventioni18nFields.minutes, max: 59 })),
    ),
  kilometers: z
    .string()
    .transform(emptyStringToNull)
    .refine(
      (val) => {
        return val == null || !isNaN(val)
      },
      { message: i18n.t('input:must-be-number', { field: TaskInterventioni18nFields.kilometers }) },
    )
    .refine(
      (val) => {
        return val == null || val >= 0
      },
      { message: i18n.t('input:too-low', { field: TaskInterventioni18nFields.kilometers, min: 0 }) },
    )
    .or(z.number().min(0, i18n.t('input:too-low', { field: TaskInterventioni18nFields.kilometers, min: 0 })))
    .nullable()
    .optional(),
  materialFees: z
    .string()
    .transform(emptyStringToNull)
    .refine(
      (val) => {
        return val == null || !isNaN(val)
      },
      { message: i18n.t('input:must-be-number', { field: TaskInterventioni18nFields.materialFees }) },
    )
    .refine(
      (val) => {
        return val == null || val >= 0
      },
      { message: i18n.t('input:too-low', { field: TaskInterventioni18nFields.materialFees, min: 0 }) },
    )
    .or(z.number().min(0, i18n.t('input:too-low', { field: TaskInterventioni18nFields.materialFees, min: 0 })))
    .nullable()
    .optional(),
  fees: z
    .string()
    .transform(emptyStringToNull)
    .refine(
      (val) => {
        return val == null || !isNaN(val)
      },
      { message: i18n.t('input:must-be-number', { field: TaskInterventioni18nFields.fees }) },
    )
    .refine(
      (val) => {
        return val == null || val >= 0
      },
      { message: i18n.t('input:too-low', { field: TaskInterventioni18nFields.fees, min: 0 }) },
    )
    .or(z.number().min(0, i18n.t('input:too-low', { field: TaskInterventioni18nFields.fees, min: 0 })))
    .nullable()
    .optional(),
  subcontractingFees: z
    .string()
    .transform(emptyStringToNull)
    .refine(
      (val) => {
        return val == null || !isNaN(val)
      },
      { message: i18n.t('input:must-be-number', { field: TaskInterventioni18nFields.subcontractingFees }) },
    )
    .refine(
      (val) => {
        return val == null || val >= 0
      },
      { message: i18n.t('input:too-low', { field: TaskInterventioni18nFields.subcontractingFees, min: 0 }) },
    )
    .or(z.number().min(0, i18n.t('input:too-low', { field: TaskInterventioni18nFields.subcontractingFees, min: 0 })))
    .nullable()
    .optional(),
  description: z
    .string()
    .trim()
    .min(1, { message: t('required') }),
  files: z.any().nullable().optional(),
  shouldCloseParentTask: z.boolean().nullable().optional(),
  category: z.object({ id: z.string(), code: z.string() }).nullable().optional(),
  subcategory: z.object({ id: z.string(), code: z.string() }).nullable().optional(),
  location: z.object({ id: z.string(), code: z.string() }).nullable().optional(),
  item: z.object({ id: z.string(), code: z.string() }).nullable().optional(),
  problem: z.object({ id: z.string(), code: z.string() }).nullable().optional(),
})

export const taskInterventionUpdateSchema = taskInterventionSchema.extend({
  id: z.number(),
  taskId: z.string(),
})

export type TaskInterventionFormSchema = z.infer<typeof taskInterventionSchema>
export type TaskInterventionUpdateFormSchema = z.infer<typeof taskInterventionUpdateSchema>
