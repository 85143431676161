import { Colors, Spacing } from '@walter/shared'
import React from 'react'
import styled from 'styled-components'
import { t } from '../../utils'
import { Icon } from '../Icon'

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
`

const IconWrap = styled.div<{ type: 'yes' | 'no' }>`
  display: flex;
  margin-right: ${Spacing.small};
  color: ${(props) => (props.type === 'yes' ? Colors.green : Colors.red)};
`
IconWrap.displayName = 'IconWrap'

type YesNoProps = {
  type: 'yes' | 'no'
}

export const YesNo = ({ type }: YesNoProps) => (
  <Container data-test-id={`YesNo_Container`}>
    <IconWrap data-test-id={`${type}`} type={type}>
      <Icon icon={type === 'yes' ? 'checkmark' : 'close'} size="small" />
    </IconWrap>
    {t(type)}
  </Container>
)
