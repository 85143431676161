import { z } from 'zod'
import { t } from '../../../utils'

export const thirdPartiesHopemi18nFields = {
  hopemClientId: t('hopem-client-identifier-please-note-that'),
  shouldSkipProjectCreation: t('third-party-auto-add-missing-buildings'),
  shouldSyncBuildingComplexesAsOneBuilding: t('third-party-sync-building-complexes-as-one-building'),
  shouldRemoveFamilyMembersAddedByUsers: t('third-party-remove-family-members-added-by-users'),
  shouldRemoveTenantsAddedByUsers: t('third-party-remove-tenants-added-by-users'),
  shouldRemoveOwnersAddedByUsers: t('third-party-remove-owners-added-by-users'),
}

export const thirdPartiesHopemSchemaUpdate = z.object({
  hopemClientId: z.string().optional(),
  shouldSkipProjectCreation: z.boolean().nullable().default(false),
  shouldSyncBuildingComplexesAsOneBuilding: z.boolean().optional().nullable().default(false),
  shouldRemoveFamilyMembersAddedByUsers: z.boolean().optional().nullable().default(false),
  shouldRemoveTenantsAddedByUsers: z.boolean().optional().nullable().default(false),
  shouldRemoveOwnersAddedByUsers: z.boolean().optional().nullable().default(false),
})

export type ThirdPartiesHopem = z.infer<typeof thirdPartiesHopemSchemaUpdate>
