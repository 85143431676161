import { Api } from '@walter/shared'
import { apolloClient } from '../apollo-client'

export async function getCurrentUser() {
  const { data } = await apolloClient.query<Api.GetMeManagerWebQuery | undefined, Api.GetMeManagerWebQueryVariables>({
    query: Api.GetMeManagerWebDocument,
  })
  return data?.me
}

export async function getProject(projectId?: string) {
  if (!projectId || projectId === 'all') {
    return null
  }
  const { data } = await apolloClient.query<Api.ProjectQuery | undefined, Api.ProjectQueryVariables>({
    query: Api.ProjectDocument,
    variables: { where: { id: projectId } },
  })
  return data?.project
}
