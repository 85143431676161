import React from 'react'
import styled from 'styled-components'
import { Spacing } from '@walter/shared'
import { Heading } from '../Heading'

const Container = styled.div`
  display: block;
  padding: ${Spacing.large};
`

const StyledHeading = styled(Heading)`
  display: flex;
  align-items: center;
  margin-bottom: ${Spacing.small};
  flex: 1;
  font-size: 14px;
`

type InfoTextProps = {
  title?: string
  content?: string
}

export const InfoText = ({ title, content }: InfoTextProps) => (
  <Container>
    <StyledHeading size={4}>{title}</StyledHeading>
    {content}
  </Container>
)
