import React from 'react'
import { Button } from '../Button'
import { ButtonGroup } from '../ButtonGroup'
import { Modal } from '../Modal'

type OnPressEnterSendMessageWarningProps = {
  visible: boolean
  close: () => void
  onSend: () => void
  onOpenSettings: () => void
}

export const OnPressEnterSendMessageWarning = ({
  close,
  visible,
  onSend,
  onOpenSettings,
}: OnPressEnterSendMessageWarningProps) => {
  return (
    <Modal icon="chat" visible={visible} close={close}>
      <p>
        You're about to send your message. Are you sure?
        <br />
        <br />
        To make your job easier and faster, by pressing <b>"ENTER"</b>, we automatically send the message. If you only
        wanted to <b>jump a line</b>, press <b>"SHIFT" + "ENTER"</b> at the same time.
        <br />
        <br />
        If you want to always jump a line when pressing on enter, edit your <b>chat settings</b>.
      </p>
      <ButtonGroup>
        <Button onClick={onSend} theme="primary">
          Send
        </Button>
        <Button onClick={onOpenSettings}>Open chat settings</Button>
        <Button onClick={close}>No, cancel</Button>
      </ButtonGroup>
    </Modal>
  )
}
