import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Colors, Spacing } from '@walter/shared'
import { rgba, stripUnit } from 'polished'
import { ChromePicker, ColorResult } from 'react-color'
import { borderRadius } from '../../styles/global'
import { useOutsideAlerter } from '../../utils/hooks'
import { Button } from '../Button/index'
import { Label } from '../Label'
import { t } from '../../utils'

const Container = styled.div`
  display: block;
  position: relative;
`

const ContainerInline = styled.div``

const Inner = styled.div`
  display: flex;
  align-items: center;
`

const Swatch = styled.div`
  width: ${Spacing.xLarge};
  height: ${Spacing.large};
  margin-right: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
  border-radius: ${borderRadius};
  box-shadow: inset 0 0 0 1px ${rgba(Colors.black, 0.15)};
  background-color: ${(props) => props.color};
  cursor: pointer;
`

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  margin-top: ${Spacing.small};
`

const Hint = styled.span`
  margin-left: ${`${(stripUnit(Spacing.tiny) as number) * 0.5}px`};
`

type ColorPickerProps = {
  label: string
  initialColor?: string
  onChange: (colorHex: string) => void
  hint?: string
  disabled?: boolean
}

export const ColorPicker = React.memo(({ label, initialColor, hint, onChange, disabled }: ColorPickerProps) => {
  const [pickerOpen, setPickerOpen] = useState(false)
  const [color, setColor] = useState(Colors.greyLight)
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  useOutsideAlerter(wrapperRef, () => setPickerOpen(false))

  useEffect(() => {
    if (initialColor) {
      setColor(initialColor)
    }
  }, [initialColor])

  const handleChange = React.useCallback((color: { hex: string }) => {
    setColor(color.hex)
  }, [])

  function handleOnChangeComplete(color: ColorResult) {
    onChange(color.hex)
  }

  return (
    <Container data-test-id="ColorPicker_Container">
      <ContainerInline ref={wrapperRef}>
        {label && (
          <Label>
            {label}
            {hint && <Hint data-tip={hint}>(?)</Hint>}
          </Label>
        )}
        <Inner>
          <Swatch
            data-test-id="Color"
            onClick={() => {
              setPickerOpen(!pickerOpen)
            }}
            color={color}
          />
          <Button
            dataTestId="Select_Button"
            theme="tertiary"
            size="small"
            onClick={() => {
              setPickerOpen(!pickerOpen)
            }}
            disabled={disabled}
          >
            {t('select')}
          </Button>
        </Inner>
        {pickerOpen && (
          <Dropdown data-test-id="Color_Select_Container">
            <ChromePicker color={color} onChange={handleChange} onChangeComplete={handleOnChangeComplete} />
          </Dropdown>
        )}
      </ContainerInline>
    </Container>
  )
})
