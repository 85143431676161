import { NotificationsUtils } from '@walter/shared'
import {
  Button,
  Drawer,
  DrawerSection,
  EmptyState,
  Icon,
  NavStyled,
  Pill,
  ResourceItem,
  ResourceList,
  ToastContext,
  UserUtils,
  WebDateUtils,
  getLanguage,
} from '@walter/shared-web'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../contexts/Auth'
import NotificationsContext from '../../contexts/Notifications'
import { t } from '../../utils/i18n'
import { getNotificationRoutePathForType } from '../../utils/notifications'

export default function NotificationsAction() {
  const { showToast } = React.useContext(ToastContext)
  const navigate = useNavigate()
  const { currentUser } = React.useContext(AuthContext)
  const {
    unSeenNotifications,
    notifications,
    onNotificationsSeen,
    markAllNotificationsAsSeen,
    isUpdatingNotifications,
  } = React.useContext(NotificationsContext)
  const [showNotificationsDrawer, setShowNotificationsDrawer] = React.useState(false)

  function viewAllNotifications() {
    setShowNotificationsDrawer(false)
    navigate(`/all/profile?tab=activities`)
  }

  return (
    <>
      <NavStyled.Bell
        data-test-id="ViewNotifications_Button"
        data-tip={t('view-notifications')}
        data-placement="bottom"
        onClick={() => setShowNotificationsDrawer(true)}
      >
        <Icon dataTestId="Bell" icon="bell" />
        {unSeenNotifications?.length > 0 && (
          <NavStyled.NotificationCount>
            <Pill
              dataTestId="Count_Pill"
              text={unSeenNotifications?.length > 9 ? '9+' : unSeenNotifications?.length}
              type="danger"
            />
          </NavStyled.NotificationCount>
        )}
      </NavStyled.Bell>

      <Drawer
        dataTestId="RecentNotifications"
        visible={showNotificationsDrawer}
        title={`${t('recent-notifications-for')} ${UserUtils.getUserName(currentUser)}`}
        close={() => setShowNotificationsDrawer(false)}
        isLoading={isUpdatingNotifications}
        actions={[
          ...(notifications.length > 0
            ? [
                {
                  dataTestId: 'MarkAllAsRead',
                  icon: 'checkmark' as const,
                  onClick: async () => {
                    await markAllNotificationsAsSeen()
                    showToast('positive', t('toast:all-notifications-marked-as-read'))
                  },
                  label: t('mark-all-as-read'),
                },
              ]
            : []),
          {
            dataTestId: 'ViewAll',
            icon: 'list',
            onClick: viewAllNotifications,
            label: t('general:view-all'),
          },
        ]}
      >
        <DrawerSection dataTestId="RecentNotifications">
          {notifications?.length > 0 ? (
            <>
              <ResourceList dataTestId="RecentNotifications">
                {notifications.map((notification) => (
                  <ResourceItem
                    key={notification.id}
                    name={notification.title ?? ''}
                    handleClick={() => {
                      if (!notification.hasBeenSeen) {
                        onNotificationsSeen([notification])
                      }
                      const notificationRoutePath = getNotificationRoutePathForType(notification)
                      setShowNotificationsDrawer(false)
                      navigate(notificationRoutePath)
                    }}
                  >
                    <ResourceItem.HighlightedBar />
                    <ResourceItem.Main type="list">
                      {!!NotificationsUtils.getNotificationLabelForType(notification.type, getLanguage()) && (
                        <Pill text={NotificationsUtils.getNotificationLabelForType(notification.type, getLanguage())} />
                      )}
                      <ResourceItem.Name>{notification.title ?? ''}</ResourceItem.Name>
                      <ResourceItem.Description>
                        {notification.subtitle || notification.message}
                      </ResourceItem.Description>
                      <ResourceItem.Meta>{WebDateUtils.fromNow(notification.createdAt, true)}</ResourceItem.Meta>
                    </ResourceItem.Main>
                  </ResourceItem>
                ))}
              </ResourceList>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={viewAllNotifications}>{t('view-all')}</Button>
              </div>
            </>
          ) : (
            <EmptyState
              heading={t('general:empty')}
              icon="inbox"
              description={t('general:dont-worry-we-will-ping-you-when-theres-')}
            />
          )}
        </DrawerSection>
      </Drawer>
    </>
  )
}
