import React from 'react'
import styled, { css } from 'styled-components'
import { Spacing } from '@walter/shared'

const Container = styled.div<{ spacing?: string; fullWidth?: boolean }>`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: -${Spacing.small};

  ${(props) =>
    props.spacing === 'tight' &&
    css`
      margin: -${Spacing.tiny};
    `}

  ${(props) =>
    props.fullWidth &&
    css`
      display: flex;
      flex-direction: column;
      align-items: stretch;
    `}
`

const Item = styled.div<{ spacing?: string }>`
  padding: ${Spacing.small};

  ${(props) =>
    props.spacing === 'tight' &&
    css`
      padding: ${Spacing.tiny};
    `}
`

type ItemGroupProps = {
  children?: React.ReactNode
  spacing?: string
  fullWidth?: boolean
}

export const ItemGroup = ({ children, spacing, fullWidth }: ItemGroupProps) => (
  <Container spacing={spacing} fullWidth={fullWidth}>
    {React.Children.map(
      children,
      (child, i) =>
        child && (
          <Item spacing={spacing} key={i}>
            {child}
          </Item>
        ),
    )}
  </Container>
)
