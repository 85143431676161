import { SendBirdUtils, Types } from '@walter/shared'
import { t } from '../../../../../shared-web/src/utils'

export const CustomMessageCardTitles = {
  [SendBirdUtils.CustomMessageType.contact]: t('contacts'),
  [SendBirdUtils.CustomMessageType.rule]: t('rules'),
  [SendBirdUtils.CustomMessageType.faqItem]: t('faqs'),
  [SendBirdUtils.CustomMessageType.post]: t('news'),
  [SendBirdUtils.CustomMessageType.event]: t('events'),
  [SendBirdUtils.CustomMessageType.file]: t('files'),
  [SendBirdUtils.CustomMessageType.task]: t('tasks'),
  [SendBirdUtils.CustomMessageType.emailsnapshot]: t('emails'),
}

export const CustomMessageCardName = {
  [SendBirdUtils.CustomMessageType.contact]: t('contact'),
  [SendBirdUtils.CustomMessageType.rule]: t('rule-singular'),
  [SendBirdUtils.CustomMessageType.faqItem]: t('faq'),
  [SendBirdUtils.CustomMessageType.post]: t('news'),
  [SendBirdUtils.CustomMessageType.event]: t('event'),
  [SendBirdUtils.CustomMessageType.file]: t('file'),
  [SendBirdUtils.CustomMessageType.task]: t('task'),
  [SendBirdUtils.CustomMessageType.emailsnapshot]: t('email'),
}

export const CustomMessageCardIconName: Record<SendBirdUtils.CustomMessageType, Types.IconName> = {
  [SendBirdUtils.CustomMessageType.contact]: 'profile',
  [SendBirdUtils.CustomMessageType.rule]: 'compose',
  [SendBirdUtils.CustomMessageType.faqItem]: 'info',
  [SendBirdUtils.CustomMessageType.post]: 'news',
  [SendBirdUtils.CustomMessageType.event]: 'booking',
  [SendBirdUtils.CustomMessageType.file]: 'folder',
  [SendBirdUtils.CustomMessageType.task]: 'list',
  [SendBirdUtils.CustomMessageType.emailsnapshot]: 'mail',
}

export const CustomMessageRouteSlug: Record<SendBirdUtils.CustomMessageType, string | null> = {
  [SendBirdUtils.CustomMessageType.contact]: 'contacts',
  [SendBirdUtils.CustomMessageType.rule]: 'rules',
  [SendBirdUtils.CustomMessageType.faqItem]: 'faq',
  [SendBirdUtils.CustomMessageType.post]: 'news',
  [SendBirdUtils.CustomMessageType.event]: 'calendar',
  [SendBirdUtils.CustomMessageType.file]: 'files',
  [SendBirdUtils.CustomMessageType.task]: 'tasks',
  [SendBirdUtils.CustomMessageType.emailsnapshot]: null,
}

export const customMessageCardTitle = (type: SendBirdUtils.CustomMessageType) => {
  return CustomMessageCardTitles[type]
}

export const customMessageCardName = (type: SendBirdUtils.CustomMessageType) => {
  return CustomMessageCardName[type]
}

export const customMessageRouteSlug = (type: SendBirdUtils.CustomMessageType) => {
  return CustomMessageRouteSlug[type]
}

export const customMessageCardIconName = (type: SendBirdUtils.CustomMessageType) => {
  return CustomMessageCardIconName[type]
}
