import { DashboardWrapperInner, GlobalLayout, DashboardLayout as SharedDashboardLayout } from '@walter/shared-web'
import * as React from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import Menu from '../components/nav/Menu'
import Nav from '../components/nav/Nav'
import { DashboardProvider } from '../providers/DashboardProvider'

export function DashboardLayout() {
  const { pathname } = useLocation()
  const { projectId = '' } = useParams<{ projectId?: string }>()

  return (
    <DashboardProvider>
      <GlobalLayout>
        <Nav />
        <Menu />
        <DashboardWrapperInner>
          <SharedDashboardLayout
            data-test-id={getDataTestId(pathname, projectId)}
            isFullPage={getIsFullPage(pathname, projectId)}
          >
            <Outlet />
          </SharedDashboardLayout>
        </DashboardWrapperInner>
      </GlobalLayout>
    </DashboardProvider>
  )
}

function getDataTestId(pathname: string, projectId: string) {
  if (pathname === '/' || pathname === '/all' || pathname === `/${projectId}`) {
    return 'Main_Dashboard'
  }
  const segments = pathname.split('/')

  if (segments.length < 3) {
    return `Unknown_Dashboard`
  }

  const route = segments[2] // Main Route Name
    .split('-') // Split in case of multiple words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Uppercase first letter
    .join('') // Join words

  return `${route}_Dashboard`
}

function getIsFullPage(pathname: string, projectId: string) {
  return (
    pathname === '/' ||
    (projectId && pathname === `/${projectId}`) ||
    pathname === '/all' ||
    pathname.includes('/tasks') ||
    pathname.includes('/chat') ||
    pathname.includes('/settings') ||
    pathname.includes('/projectSettings')
  )
}
