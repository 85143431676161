import { cva } from 'class-variance-authority'
import * as React from 'react'

const headingVariants = cva('font-bold text-foreground leading-normal mb-1 last:mb-0', {
  variants: {
    size: {
      '1': 'text-3xl',
      '2': 'text-2xl',
      '3': 'text-xl',
      '4': 'text-lg',
      '5': 'text-base',
      '6': 'text-sm',
    },
  },
  defaultVariants: {
    size: '5',
  },
})

type Heading = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

type Props = {
  size?: 1 | 2 | 3 | 4 | 5 | 6
}

export const Heading = React.forwardRef<HTMLHeadingElement, React.HtmlHTMLAttributes<HTMLHeadingElement> & Props>(
  ({ size = 5, className, ...props }, ref) => {
    const Component = `h${size}` as Heading
    return <Component ref={ref} className={headingVariants({ size: `${size}`, className })} {...props} />
  },
)

Heading.displayName = 'Heading'
