/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Colors, Global, Spacing, Types } from '@walter/shared'
import { rgba, stripUnit } from 'polished'
import * as React from 'react'
import { Portal } from 'react-portal'
import { Transition } from 'react-transition-group'
import styled, { css } from 'styled-components'
import { cn } from '../../shadcn'
import { animationCurve, animationTime, borderRadius, boxShadow, cover, square } from '../../styles/global'
import { Heading } from '../Heading'
import { Icon } from '../Icon'

const Container = styled.div<{ animationState: string }>`
  overflow: auto;
  transition: all ${animationTime} ${animationCurve};
  z-index: 600;
  opacity: 0;
  visibility: hidden;
  ${cover('fixed')};

  ${(props) =>
    (props.animationState === 'entering' || props.animationState === 'entered') &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  ${(props: any) =>
    props.animationState === 'entered' &&
    css`
      transform: none;
    `}

  ${(props) =>
    (props.animationState === 'exiting' || props.animationState === 'exited') &&
    css`
      opacity: 0;
      visibility: hidden;
    `}
`

const Table = styled.div`
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
`

const Cell = styled.div`
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  padding: ${Spacing.xxxLarge};
`

const Inner = styled.div<{ animationState: string; width?: 'small' | 'medium' | 'full' }>`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: ${({ width }) => (width === 'full' ? `100%` : width === 'medium' ? `80%` : `500px`)};
  background: ${Colors.white};
  transition: transform ${animationTime} ${animationCurve}, opacity ${animationTime} ${animationCurve};
  z-index: 450;
  background-color: ${Colors.white};
  overflow: hidden;
  transform: translateY(${Spacing.small});
  border-radius: ${borderRadius};
  ${boxShadow};

  ${(props) =>
    props.animationState === 'entered' &&
    css`
      transform: none;
    `}
`

const Header = styled.header`
  display: flex;
  align-items: center;
  padding: ${`${(stripUnit(Spacing.medium) as number) * 1.25}px`} ${Spacing.large};
  background-color: ${Colors.offWhite};
`

const IconWrap = styled.div`
  display: flex;
  margin-right: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
`

const Main = styled.main`
  padding: ${Spacing.large};
`

const Close = styled.button`
  position: fixed;
  z-index: 450;
  top: ${Spacing.large};
  right: ${Spacing.large};
  color: ${Colors.white};
  transition: background-color ${animationTime} ${animationCurve};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${rgba(Colors.black, 0.3)};
  border-radius: 50%;
  ${square('56px')};

  &:hover,
  &:focus {
    background-color: ${rgba(Colors.black, 0.45)};
  }
`

const Background = styled.div`
  background-color: ${rgba(Colors.black, 0.85)};
  ${cover('fixed')};
`

type ModalProps = {
  children: React.ReactNode
  visible: boolean
  close?: (event: React.MouseEvent<HTMLButtonElement>) => any
  icon?: Types.IconName
  title?: string
  dataTestId?: string
  width?: 'small' | 'medium' | 'full'
  centeredTitle?: boolean
  rightHeader?: React.ReactNode
}

export const Modal = ({
  children,
  icon,
  title,
  visible,
  close,
  dataTestId,
  width,
  centeredTitle,
  rightHeader,
  ...rest
}: ModalProps) => {
  React.useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        // @ts-ignore
        close()
      }
    }

    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [close])

  return (
    <Portal>
      <Transition in={visible} timeout={Global.TRANSITION_LENGTH} unmountOnExit enter>
        {(state) => (
          //@ts-ignore
          <Container data-test-id={dataTestId} animationState={state} {...rest}>
            <Table>
              <Cell>
                <Inner animationState={state} width={width}>
                  {title && (
                    <Header
                      data-test-id={`${dataTestId}_Header`}
                      className={cn(centeredTitle && 'w-full flex justify-center', 'relative')}
                    >
                      {icon && (
                        <IconWrap>
                          <Icon icon={icon} />
                        </IconWrap>
                      )}
                      <Heading size={3}>{title}</Heading>
                      {rightHeader && <div className="absolute right-0">{rightHeader}</div>}
                    </Header>
                  )}
                  <Main>{children}</Main>
                </Inner>
              </Cell>
            </Table>
            <Close data-test-id="Modal_Close_button" data-cy="modal-close" onClick={close}>
              <Icon icon="close" />
            </Close>
            {/* @ts-ignore */}
            <Background onClick={close} />
          </Container>
        )}
      </Transition>
    </Portal>
  )
}
