import React from 'react'
import styled from 'styled-components'
import { stripUnit } from 'polished'

import { Colors, Spacing, Types } from '@walter/shared'
import { Icon, globalStyles, typography, ButtonProps, t } from '@walter/shared-web'
import kebabCase from 'lodash/kebabCase'

const Container = styled.div`
  position: relative;
  width: 320px;
  border-radius: ${globalStyles.borderRadius};
  background-color: ${Colors.white};
  border: 1px solid ${Colors.borderColor};
  ${globalStyles.boxShadow};
`

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing.medium};
  border-bottom: 1px solid ${Colors.borderColor};
`

const Name = styled.span`
  display: block;
  color: ${Colors.greyDark};
  font-weight: ${typography.fontWeights.bold};
  font-size: ${typography.fontSizes.small};
`

const Company = styled.span`
  display: block;
  font-size: ${typography.fontSizes.tiny};
  margin-top: 2px;
`

const Logout = styled.button`
  padding: ${Spacing.small};
  margin: -${Spacing.small};
  font-size: ${typography.fontSizes.tiny};
  color: ${Colors.greyLight};
  transition: color ${globalStyles.animationTime} ${globalStyles.animationCurve};

  &:hover,
  &:focus {
    color: ${Colors.grey};
  }
`

const Main = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
  padding: ${Spacing.medium};
`

const ActionContainer = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  font-size: ${typography.fontSizes.small};
  transition: color ${globalStyles.animationTime} ${globalStyles.animationCurve},
    background-color ${globalStyles.animationTime} ${globalStyles.animationCurve};

  &:hover,
  &:focus {
    color: ${Colors.greyDark};
  }
`

const IconWrap = styled.div`
  display: flex;
  margin-right: ${Spacing.small};
  opacity: 0.9;
`

type ProfileMenuProps = {
  user: {
    id: string
    firstName?: string | null
    lastName?: string | null
    managingCompany?: { shortName: string } | null
  }
  actions: (ButtonProps & { label: string; href?: 'a' | 'button'; dataTestId?: string })[]
  logout?: () => void
}

export const ProfileMenu = ({ user, logout, actions }: ProfileMenuProps) => {
  return (
    <Container data-test-id="Profile_Menu_Container">
      <Header data-test-id="Profile_header">
        <div>
          <Name data-test-id="Profile_Name">
            {user.firstName} {user.lastName}
          </Name>
          <Company data-test-id="Profile_CompanyName">{user.managingCompany?.shortName}</Company>
        </div>
        <Logout data-test-id="Profile_LogOut_Button" onClick={logout}>
          {t('logout')}
        </Logout>
      </Header>
      <Main data-test-id="Profile_Menu" data-cy="profile-menu">
        {actions.map((action, i) => (
          <Action key={i} as={action.href ? 'a' : 'button'} icon={action.icon} {...action} />
        ))}
      </Main>
    </Container>
  )
}

type ActionProps = {
  dataTestId?: string
  icon?: Types.IconName
  label: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => any
  as?: string
  key?: number
}

const Action = ({ dataTestId, icon, label, onClick }: ActionProps) => (
  <ActionContainer data-test-id={`Menu_${dataTestId}`} onClick={onClick} data-cy={`menu-${kebabCase(label)}`}>
    {icon && (
      <IconWrap>
        <Icon icon={icon} size="small" />
      </IconWrap>
    )}
    <span>{label}</span>
  </ActionContainer>
)
