import { css } from 'styled-components'

export default css`
  .u-1-1 {
    width: 100% !important;
  }

  .u-1-4 {
    width: 25% !important;
  }

  .u-1-3 {
    width: 33.3333% !important;
  }

  .u-2-3 {
    width: 66.6666% !important;
  }

  .u-3-4 {
    width: 75% !important;
  }

  .u-1-2 {
    width: 50% !important;
  }

  .u-1-5 {
    width: 20% !important;
  }
`
