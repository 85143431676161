import React from 'react'
import { useParams } from 'react-router-dom'
import { WebDateUtils } from '../../../src/utils/date'
import { t } from '../../utils'
import { Icon } from '../Icon'
import { ResourceItem } from '../ResourceItem'
import { FileType } from './helpers'

type FileProps = {
  file: FileType
  onClick: () => void
  handleClickOnEdit?: () => void
}

export function File({ file, onClick, handleClickOnEdit }: FileProps) {
  const { projectId } = useParams<{ projectId: string }>()

  const secondaryAction = React.useMemo(() => {
    if (handleClickOnEdit) {
      if (file.isFromSharedFolder && projectId !== 'all') {
        return {
          disabled: true,
          label: t('edit'),
          onClick: handleClickOnEdit,
        }
      }
      return {
        label: t('edit'),
        onClick: handleClickOnEdit,
      }
    }
    return null
  }, [handleClickOnEdit])

  return (
    <ResourceItem name={file.name} handleClick={onClick}>
      <ResourceItem.FigureWrap type="list">
        <Icon icon="attachment" />
      </ResourceItem.FigureWrap>
      <ResourceItem.Main type="list">
        <ResourceItem.Name>{file.name}</ResourceItem.Name>
        <ResourceItem.Description>{`Added on ${WebDateUtils.dayjs(file.createdAt).format(
          'LLLL',
        )}`}</ResourceItem.Description>
      </ResourceItem.Main>
      <ResourceItem.Actions secondaryAction={secondaryAction} />
    </ResourceItem>
  )
}
