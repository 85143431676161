import { Colors } from '@walter/shared'
import { stripUnit } from 'polished'
import styled from 'styled-components'
import { globalStyles } from '../styles'

export const DashboardWrapperInner = styled.div`
  padding-top: ${stripUnit(globalStyles.navHeight) as number}px};
  padding-left: ${stripUnit(globalStyles.menuWidth) as number}px};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.offWhite};
`
