import { i18n, t } from '../../utils'
import { z } from 'zod'
import { emptyStringToNull } from './transformationUtils'

export const amenityi18nFields = {
  image: t('image'),
  title: t('fields-amenity:title'),
  description: t('fields-amenity:description'),
  otherInformation: t('fields-amenity:other-information-optional'),
  reservationType: t('fields-amenity:reservation-type'),
  singleDateReservation: t('fields-amenity:single-date-reservation'),
  multipleDaysReservation: t('fields-amenity:multiple-days-reservation'),
  singleDateTimeReservation: t('fields-amenity:single-date-reservation-with-time'),
  terms: t('fields-amenity:terms'),
  disclaimer: t('fields-amenity:disclaimer'),
  sharedWithProject: t('fields-amenity:shared-with-projects'),
  bookingOptions: t('fields-amenity:booking-options'),
  targetedBuilding: t('shared-booking-targeted-building'),
  group: t('fields-segment:group'),
  timeslotIncrementalInSeconds: t('fields-amenity:start-new-booking-every-x'),
  bookingInAdvanceMaxSeconds: t('fields-amenity:amenity-available-x-in-advance'),
  bookingInAdvanceMinSeconds: t('fields-amenity:resident-need-to-book-x-in-advance'),
  usageDurationSeconds: t('fields-amenity:usage-duration'),
  unavailableTimeBetweenBookings: t('fields-amenity:unavailable-time-between-each-booking'),
  residentCanBookOneEvery: t('fields-amenity:resident-can-book-once-every'),
  requireStaffApproval: t('fields-amenity:requires-staff-approval'),
  allowMultipleBooking: t('fields-amenity:allow-multiple-bookings-at-the-same-time'),
  maximumNumberResidentAtSameTime: t('fields-amenity:maximum-number-of-residents-at-the-same-time'),
  maximumResidentPerBooking: t('fields-amenity:maximum-residents-per-booking'),
  businessHours: t('fields-amenity:business-hours'),
  sharedWithProjects: t('fields-amenity:shared-with-projects'),
  bookingIntervalPerUserInSeconds: t('fields-amenity:resident-can-book-once-every'),
  maximumBookingsAtSameTime: t('fields-amenity:maximum-number-of-residents-at-the-same-time'),
  maximumOfResidentsInOneBooking: t('fields-amenity:maximum-residents-per-booking'),
  timeRequiredBetweenEachBookingSeconds: t('fields-amenity:unavailable-time-between-each-booking'),
}

export const businessHour = z.object({
  id: z.string().optional(),
  dayOfWeek: z.number(),
  openAtMS: z.number(),
  closeAtMS: z.number(),
  isClosed: z.boolean(),
})

const objectId = z.object({ id: z.string() })
const arrayIds = z.array(objectId)

export const bookingOptions = z.object({
  id: z.string().optional(), //also used in the creation form as key in the list
  project: objectId,
  segments: arrayIds,
  timeslotIncrementalInSeconds: z.number({
    required_error: i18n.t('input:is-required', { field: amenityi18nFields.timeslotIncrementalInSeconds }),
    invalid_type_error: i18n.t('input:is-required', { field: amenityi18nFields.timeslotIncrementalInSeconds }),
  }),
  bookingInAdvanceMaxSeconds: z.string().transform(emptyStringToNull).or(z.number().positive()).nullable().optional(),
  bookingInAdvanceMinSeconds: z.string().transform(emptyStringToNull).or(z.number().positive()).nullable().optional(),
  usageDurationSeconds: z.array(z.number()),
  timeRequiredBetweenEachBookingSeconds: z
    .string()
    .transform(emptyStringToNull)
    .or(z.number().positive())
    .nullable()
    .optional(),
  bookingIntervalPerUserInSeconds: z
    .string()
    .transform(emptyStringToNull)
    .or(z.number().positive())
    .nullable()
    .optional(),
  maximumBookingsAtSameTime: z.string().transform(emptyStringToNull).or(z.number().positive()).nullable().optional(),
  maximumOfResidentsInOneBooking: z
    .string()
    .transform(emptyStringToNull)
    .or(z.number().positive())
    .nullable()
    .optional(),
  bookingRequiresStaffApproval: z.boolean(),
  allowMultipleBookings: z
    .boolean()
    .nullable()
    .transform((value) => (value === null ? false : value)),
  businessHours: z.array(businessHour),
})

export const reservationTypeDefinition = {
  SingleDate: 'SINGLE_DATE',
  MultipleDate: 'MULTIPLE_DATE',
  SingleDateTime: 'SINGLE_DATE_TIME',
} as const

export const amenitySchema = z
  .object({
    id: z.string().optional(),
    title: z
      .string({ required_error: i18n.t('input:is-required', { field: amenityi18nFields.title }) })
      .min(1, { message: i18n.t('input:is-required', { field: amenityi18nFields.title }) }),
    description: z.string(),
    reservationType: z.enum(['SINGLE_DATE', 'MULTIPLE_DATE', 'SINGLE_DATE_TIME'], {
      required_error: i18n.t('input:is-required', { field: amenityi18nFields.reservationType }),
      invalid_type_error: i18n.t('input:is-required', { field: amenityi18nFields.reservationType }),
    }),
    project: z.object({ id: z.string() }),
    image: z.any(),
    termsFile: z.any(),
    disclaimerFile: z.any(),
    sharedWithProjects: z.array(z.object({ id: z.string(), name: z.string().optional().nullable() })),
    bookingOptions: z.array(bookingOptions),
  })
  .refine(
    (value) => {
      return !doesBusinessHourOverlap(value.bookingOptions)
    },
    {
      message: i18n.t('error-business-hours-overlap-issue-description'),
      path: ['reservationType'], // Error shown at reservation type since unable to show at business hours
    },
  )

export type Amenity = z.infer<typeof amenitySchema>
export type BookingOptions = z.infer<typeof bookingOptions>
export type BusinessHour = z.infer<typeof businessHour>

export const availableReservationTypes = [
  {
    value: reservationTypeDefinition.SingleDate,
    label: t('fields-amenity:single-date-reservation'),
  },
  {
    value: reservationTypeDefinition.MultipleDate,
    label: t('fields-amenity:multiple-days-reservation'),
  },
  {
    value: reservationTypeDefinition.SingleDateTime,
    label: t('fields-amenity:single-date-reservation-with-time'),
  },
] as const

const doesBusinessHourOverlap = (bookingOptions?: BookingOptions[]) => {
  return bookingOptions?.some((bookingOptionA, indexA) => {
    return bookingOptions?.some((bookingOptionB, indexB) => {
      if (indexA === indexB) {
        return false
      }

      return bookingOptionB.businessHours?.some((businessHourB) =>
        bookingOptionA.businessHours?.some((businessHourA) => businessHoursOverlap(businessHourA, businessHourB)),
      )
    })
  })
}

export function businessHoursOverlap(businessHourA: BusinessHour, businessHourB: BusinessHour) {
  const bothAreOpened = !businessHourA.isClosed && !businessHourB.isClosed
  const sameDay = businessHourA.dayOfWeek === businessHourB.dayOfWeek
  return bothAreOpened && sameDay && hoursOverlap(businessHourA, businessHourB)
}

function hoursOverlap(
  businessHourA: { openAtMS?: number; closeAtMS?: number },
  businessHourB: { openAtMS?: number; closeAtMS?: number },
) {
  return (
    (businessHourA.openAtMS ?? 0) < (businessHourB.closeAtMS ?? 0) &&
    (businessHourA.closeAtMS ?? 0) > (businessHourB.openAtMS ?? 0)
  )
}
