import { Types, Colors, Global, Spacing } from '@walter/shared'
import { rgba } from 'polished'
import React from 'react'
import { Portal } from 'react-portal'
import { Transition } from 'react-transition-group'
import styled, { css } from 'styled-components'
import { animationCurve, animationTime, boxShadowDark, square, borderRadius, cover } from '../../styles/global'
import { Heading } from '../Heading'
import { Icon } from '../Icon'

function getColor({ type }: { type: NotificationMessageType }) {
  if (type === 'negative') return Colors.red
  if (type === 'positive') return Colors.green
  return Colors.grey
}

const Container = styled.div<{ animationState: string }>`
  position: fixed;
  top: ${Spacing.large};
  transition: all ${animationTime} ${animationCurve};
  z-index: 601;
  opacity: 0;
  transform: translateX(${Spacing.large});
  visibility: hidden;
  ${(props) =>
    (props.animationState === 'entering' || props.animationState === 'entered') &&
    css`
      opacity: 1;
      visibility: visible;
    `}
  ${(props) =>
    props.animationState === 'entered' &&
    css`
      transform: none;
    `}
  ${(props) =>
    (props.animationState === 'exiting' || props.animationState === 'exited') &&
    css`
      opacity: 0;
      visibility: hidden;
    `}
`

const Inner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${Spacing.medium};
  padding-top: ${Spacing.medium}; /* Compensate for border */
  border-radius: ${borderRadius};
  border: 1px solid ${Colors.borderColor};
  background-color: ${rgba(Colors.white, 0.96)};
  max-width: 360px;
  backdrop-filter: blur(2px);
  ${boxShadowDark};
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: ${getColor};
    border-radius: ${borderRadius} ${borderRadius} 0 0;
  }
`

const IconWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${getColor};
  margin-right: ${Spacing.medium};
  flex-shrink: 0;
  ${square('24px')};
  &:after {
    content: '';
    background-color: ${getColor};
    border-radius: 50%;
    opacity: 0.2;
    ${cover('absolute')};
  }
`

const Content = styled.div`
  flex: 1;
  padding-right: ${Spacing.medium};
`

const Text = styled(Heading)`
  margin-bottom: 0;
`

export type NotificationMessageType = 'positive' | 'negative' | 'info' | 'loading'

type NotificationMessageProps = {
  text: string
  type: NotificationMessageType
  icon: Types.IconName
  visible: boolean
  close: () => void
  duration: number
}

export function NotificationMessage({ icon, type, text, visible, close, duration = 2000 }: NotificationMessageProps) {
  React.useEffect(() => {
    const disappearTimeout = setTimeout(() => {
      // if (close) {
      //   close()
      // }
    }, duration)
    return () => {
      clearTimeout(disappearTimeout)
    }
  }, [duration])

  function handleClickOnMessage() {
    if (close) {
      close()
    }
  }

  return (
    <Portal>
      <Transition in={visible} timeout={Global.TRANSITION_LENGTH} unmountOnExit enter>
        {(state: string) => (
          <Container animationState={state} onClick={handleClickOnMessage}>
            <Inner type={type}>
              {icon && (
                <IconWrap type={type}>
                  <Icon icon={icon} size="tiny" />
                </IconWrap>
              )}
              <Content>{text && <Text>{text}</Text>}</Content>
            </Inner>
          </Container>
        )}
      </Transition>
    </Portal>
  )
}
