import { GroupChannel, GroupChannelEventContext, Member, MessageEventContext } from '@sendbird/chat/groupChannel'
import { BaseMessage, FileMessage, SendingStatus, UserMessage } from '@sendbird/chat/message'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useCallback, useEffect, useMemo } from 'react'
import { privateChannelsMapAtom, publicChannelsMapAtom, serviceAtom, typingMembersMapAtom } from './atoms'
import { MessagingService, Channel } from './MessagingService'

type Message = UserMessage | FileMessage

interface UseChatRoomArgs {
  isManager: boolean
  channelUrl: string
  onChannelDeleted: (ctx: GroupChannelEventContext, channelUrl: string) => void
}

const messagesMapAtom = atom(new Map() as Map<string, Map<string, Message>>)
const chatRoomStateAtom = atom({ isLoading: false, isLoadingMore: false, isSendingMessage: false })
const textInputAtom = atom('')

/**
 * @warning Must be called after `useMessagingService`
 * @description Provides utilities, messages, and state for a selected chat room
 */
export const useChatRoom = (args: UseChatRoomArgs) => {
  const { isManager, channelUrl, onChannelDeleted } = args
  const service = useAtomValue(serviceAtom)
  const [messagesMap, setMessagesMap] = useAtom(messagesMapAtom)
  const [publicChannelMap, setPublicChannelMap] = useAtom(publicChannelsMapAtom)
  const setPrivateChannelMap = useSetAtom(privateChannelsMapAtom)
  const [text, setText] = useAtom(textInputAtom)
  const [{ isLoading, isLoadingMore, isSendingMessage }, setState] = useAtom(chatRoomStateAtom)
  const typingMembers = useAtomValue(typingMembersMapAtom)

  const conversation = useMemo(() => {
    return publicChannelMap.get(channelUrl)
  }, [channelUrl, publicChannelMap])

  const messages: Message[] = useMemo(() => {
    const channelMsgMap = messagesMap.get(channelUrl)
    if (!channelMsgMap) return []
    return Array.from(channelMsgMap.values())?.sort((a, b) => a.createdAt - b.createdAt) ?? []
  }, [messagesMap, channelUrl])

  const fileMessages: FileMessage[] = useMemo(() => {
    return messages.filter((msg) => msg.isFileMessage()) as FileMessage[]
  }, [messages])

  const updateMgs = useCallback(
    async (msgs: Message[]) => {
      setMessagesMap((prev) => {
        const channelMsgMap = prev.get(channelUrl) ?? new Map()
        for (const msg of msgs) {
          if (msg.sendingStatus === SendingStatus.SUCCEEDED || msg.isAdminMessage()) {
            channelMsgMap.set(`${msg.messageId}`, msg)
          }
        }
        prev.set(channelUrl, channelMsgMap)
        return new Map(prev)
      })
    },
    [channelUrl],
  )

  useEffect(() => {
    const initMessages = async () => {
      if (!service || !channelUrl) return

      function updateMessages(_ctx: MessageEventContext, _channel: GroupChannel, msgs: BaseMessage[]) {
        updateMgs(msgs as Message[])
      }

      function deleteMessages(_ctx: MessageEventContext, _channel: GroupChannel, msgIds: number[]) {
        if (!service) return
        setMessagesMap((prev) => {
          const channelMsgMap = prev.get(channelUrl) ?? new Map()

          for (const id of msgIds) {
            channelMsgMap.delete(String(id))
          }
          prev.set(channelUrl, channelMsgMap)
          return new Map(prev)
        })
      }

      async function updateChannel(_ctx: GroupChannelEventContext, channel: Channel) {
        if (!service) return

        const metadata = await channel.getMetaData(['archive'])
        const isArchived = metadata.archive === 'true'

        if (channel.url === channelUrl) {
          setPublicChannelMap((prev) => {
            if (isArchived) {
              prev.delete(channelUrl)
              onChannelDeleted({} as GroupChannelEventContext, '')
              return new Map(prev)
            }
            prev.set(channel.url, channel)
            return new Map(prev)
          })
        }
        if (channel.url === channelUrl.concat('-private')) {
          setPrivateChannelMap((prev) => {
            if (isArchived) {
              prev.delete(channelUrl)
              return new Map(prev)
            }
            prev.set(channel.url, channel)
            return new Map(prev)
          })
        }
      }

      try {
        setState((prev) => ({ ...prev, isLoading: true }))
        await service.enterChannel({
          channelUrl,
          messageHandlers: {
            onChannelDeleted: onChannelDeleted,
            onChannelUpdated: updateChannel,
            onMessagesAdded: updateMessages,
            onMessagesDeleted: deleteMessages,
            onMessagesUpdated: updateMessages,
            onHugeGapDetected: initMessages,
          },
          onApiResult: (err, msgs: BaseMessage[]) => {
            if (err) {
              console.error('[useChatRoom/onApiResult]', err)
            } else {
              console.info('[useChatRoom/onApiResult]', 'message length:', msgs.length)
              updateMgs(msgs as Message[])
            }
          },
          onCacheResult: (err, msgs: BaseMessage[]) => {
            if (err) {
              console.error('[useChatRoom/onCacheResult]', err)
            } else {
              console.info('[useChatRoom/onCacheResult]', 'message length:', msgs.length)
              updateMgs(msgs as Message[])
            }
          },
          includePrivate: isManager, // Notes come from private channels. For managers only
        })
      } catch (error) {
        console.error('[useChatRoom]', 'initializing messages failure', error)
      } finally {
        setState((prev) => ({ ...prev, isLoading: false }))
      }
    }

    initMessages().catch((error) => console.error('[useChatRoom]', 'initMessages', error))

    return () => {
      if (!service) return
      service.exitChannel()
    }
  }, [channelUrl, service.user])

  const loadMoreMessages = useCallback(async () => {
    if (!service || isLoadingMore) return
    try {
      setState((prev) => ({ ...prev, isLoadingMore: true }))
      const previousMessages: Message[] = (await service.loadPreviousMessages(true)) as Message[]
      console.log('previousMessages.length', previousMessages.length)
      if (previousMessages.length === 0) return false
      updateMgs(previousMessages)
      return previousMessages.length > MessagingService.messagesBatchSize - 1
    } catch (error) {
      console.error('[useChatRoom]', 'loading more messages failure', error)
    } finally {
      setState((prev) => ({ ...prev, isLoadingMore: false }))
    }
  }, [channelUrl])

  const readReceiptsByIdMap = useMemo(() => {
    const messagesReadMap = new Map<string, Member[]>()
    if (!conversation || !service) return messagesReadMap

    const othersIds = new Set(conversation.members.filter((m) => !!m.nickname.trim()).map((user) => user.userId))
    const reversedMessages = [...messages].reverse()
    for (const msg of reversedMessages) {
      if (othersIds.size === 0) break

      if (msg.isFileMessage() || msg.isUserMessage()) {
        if (msg.sender.userId !== service.user?.userId) {
          othersIds.delete(msg.sender.userId)
          continue
        }
      }

      const readMembers =
        publicChannelMap
          .get(channelUrl)
          ?.getReadMembers(msg)
          .filter((member) => othersIds.has(member.userId)) ?? []

      if (readMembers.length > 0) {
        messagesReadMap.set(`${msg.messageId}`, readMembers)
        readMembers.forEach((member) => othersIds.delete(member.userId))
      }
    }
    return messagesReadMap
  }, [messages, conversation, publicChannelMap])

  const typingUsers = useMemo(() => {
    if (!conversation) return []
    const members = typingMembers.get(channelUrl) ?? []
    return members
      .map((member) => {
        return conversation.members?.find((user) => user.userId === member.userId)
      })
      .filter(Boolean) as Member[]
  }, [conversation, typingMembers, channelUrl])

  const onlineUserMap = useMemo(() => {
    const onlineMap = new Map<string, boolean>()
    if (!conversation) return onlineMap
    const members = conversation.members
    for (const member of members) {
      onlineMap.set(member.userId, member.connectionStatus === 'online')
    }
    return onlineMap
  }, [conversation])

  const sendMessage = useCallback(
    async (message: string, mentionedUserIds?: string[], isPrivate?: boolean) => {
      if (!service || !conversation) return
      setState((prev) => ({ ...prev, isSendingMessage: true }))

      const sentMessage = await service.sendUserMessage({
        channel: conversation,
        params: {
          message,
          mentionedUserIds,
        },
        channelUrl: isPrivate ? `${channelUrl}-private` : channelUrl,
      })
      sentMessage
        ?.onSucceeded((msg) => {
          setMessagesMap((prev) => {
            const channelMsgMap = prev.get(channelUrl) ?? new Map()
            channelMsgMap.set(`${msg.messageId}`, msg)
            prev.set(channelUrl, channelMsgMap)
            return new Map(prev)
          })
          setState((prev) => ({ ...prev, isSendingMessage: false }))
        })
        .onFailed(() => {
          setState((prev) => ({ ...prev, isSendingMessage: false }))
        })
    },
    [service, channelUrl, conversation],
  )

  const sendCustomMessage = useCallback(
    async (customType: string, data: string, isPrivate?: boolean) => {
      if (!service || !conversation) return
      setState((prev) => ({ ...prev, isSendingMessage: true }))

      const sentMessage = await service.sendUserMessage({
        channel: conversation,
        params: {
          message: `${customType}:${data}`,
          customType,
          data,
        },
        channelUrl: isPrivate ? `${channelUrl}-private` : channelUrl,
      })
      sentMessage
        ?.onSucceeded((msg) => {
          setMessagesMap((prev) => {
            const channelMsgMap = prev.get(channelUrl) ?? new Map()
            channelMsgMap.set(`${msg.messageId}`, msg)
            prev.set(channelUrl, channelMsgMap)
            return new Map(prev)
          })
          setState((prev) => ({ ...prev, isSendingMessage: false }))
        })
        .onFailed(() => {
          setState((prev) => ({ ...prev, isSendingMessage: false }))
        })
    },
    [service, channelUrl, conversation],
  )

  const sendFile = useCallback(
    async (fileUrl: string, file?: File, isPrivate?: boolean) => {
      if (!service || !conversation) return
      const messageSent = await service.sendFileMessage({
        channel: conversation,
        params: {
          fileUrl,
          file,
        },
        channelUrl: isPrivate ? `${channelUrl}-private` : channelUrl,
      })
      messageSent
        ?.onSucceeded((msg) => {
          setMessagesMap((prev) => {
            const channelMsgMap = prev.get(channelUrl) ?? new Map()
            channelMsgMap.set(`${msg.messageId}`, msg)
            prev.set(channelUrl, channelMsgMap)
            return new Map(prev)
          })
          setState((prev) => ({ ...prev, isSendingMessage: false }))
        })
        .onFailed(() => {
          setState((prev) => ({ ...prev, isSendingMessage: false }))
        })
    },
    [service, channelUrl, conversation],
  )

  const deleteMessage = useCallback(
    async (message: Message) => {
      if (!service || !channelUrl) return
      await service.deleteMessage(
        MessagingService.channelIsPrivate(message.channelUrl) ? `${channelUrl}-private` : channelUrl,
        message,
      )
    },
    [service, channelUrl],
  )

  useEffect(() => {
    if (!conversation) return
    if (text.length === 0) conversation.endTyping()
    else conversation.startTyping()

    return () => {
      conversation.isTyping && conversation.endTyping()
    }
  }, [text, conversation])

  useEffect(() => {
    async function markAsRead() {
      const convo = publicChannelMap.get(channelUrl)
      if (convo && convo.unreadMessageCount > 0) {
        await convo.markAsRead()
      }
    }

    markAsRead().catch((error) => {
      console.error('[useChatRoom]', 'mark as read failure', error)
    })
  }, [publicChannelMap, channelUrl])

  const others = useMemo(() => {
    const notCurrentUser = conversation?.members.filter((user) => user.userId !== service?.user?.userId) ?? []
    return notCurrentUser.filter((user: Channel['members'][number]) => user.metaData.role === 'RESIDENT')
  }, [conversation, service?.user?.userId])

  const endTyping = useCallback(async () => {
    if (!conversation) return
    setText('')
    await conversation.endTyping()
  }, [conversation])

  return {
    conversation,
    user: service.user,
    others,
    text,
    setText,
    messages,
    fileMessages,
    isLoading: isLoading || isSendingMessage,
    isLoadingMore,
    loadMoreMessages,
    sendMessage,
    sendFile,
    sendCustomMessage,
    deleteMessage,
    typingUsers,
    readReceiptsByIdMap,
    endTyping,
    onlineUserMap,
  }
}
