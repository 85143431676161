import React from 'react'
import { Colors } from '@walter/shared'
import { SendBirdUtils } from '@walter/shared'
import { customMessageCardIconName, customMessageCardName } from './CustomMessageCard.entities'
import ChatEvent from '../ChatEvent'

export interface ITaskCustomItem {
  id: string
  title: string | null
  description: string | null
}

export type TaskItemCardProps = {
  task: ITaskCustomItem
  formattedTime: string
}

export const TaskItemCard = (props: TaskItemCardProps) => {
  const { task, formattedTime } = props
  return (
    <ChatEvent
      color={Colors.tasks}
      icon={customMessageCardIconName(SendBirdUtils.CustomMessageType.task)}
      name={customMessageCardName(SendBirdUtils.CustomMessageType.task)}
      time={formattedTime}
      title={task.title}
      description={task.description}
      // isHTML
    />
  )
}
