import React from 'react'
import styled from 'styled-components'
import { Spacing, Colors } from '@walter/shared'
import { stripUnit } from 'polished'

const Container = styled.div<{ minHeight?: string }>`
  width: 100%;
  padding: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
`

const Error = styled.span`
  color: ${Colors.red};
  font-size: 0.8em;
`

type FieldsetItemProps = {
  dataTestId?: string
  children: React.ReactNode
  error?: string
  width?: string | number
}

export const FieldsetItem = ({ dataTestId, width, children, error }: FieldsetItemProps) => {
  const dataTestIdValue = dataTestId ? dataTestId + '_Item' : 'Item'
  return (
    <Container data-test-id={dataTestIdValue} className={`u-${width || 'no-width'}`}>
      {children}
      {!!error && <Error>{error}</Error>}
    </Container>
  )
}
