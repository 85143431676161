import { z } from 'zod'
import { i18n, t } from '../../utils'

export const ResidentReservationi18nFields = {
  note: t('fields-reservation:note'),
  numberOfResidents: t('fields-reservation:number-of-residents'),
}

export const ResidentReservationSchema = z.object({
  note: z.string().nullable(),
  numberOfResidents: z
    .string({
      required_error: i18n.t('input:is-required', { field: ResidentReservationi18nFields.numberOfResidents }),
    })
    .min(1, { message: i18n.t('input:is-required', { field: ResidentReservationi18nFields.numberOfResidents }) })
    .or(
      z.number({
        required_error: i18n.t('input:is-required', { field: ResidentReservationi18nFields.numberOfResidents }),
      }),
    )
    .transform((val) => {
      const num = typeof val === 'string' ? parseInt(val) : val
      return isNaN(num) ? 1 : num
    }),
})

export type ResidentReservation = z.infer<typeof ResidentReservationSchema>
