import React from 'react'
import { Colors, SendBirdUtils } from '@walter/shared'
import { customMessageCardIconName, customMessageCardName } from './index'
import ChatEvent from '../ChatEvent'

export interface IEmailParticipant {
  name?: string
  email?: string
}

export interface IEmailFile {
  contentType?: string
  size?: number
  filename?: string
  messageIds?: string[]
  contentId?: string
  contentDisposition?: string
  data?: unknown
}

export interface IEmailCustomItem {
  id?: string
  subject?: string
  from?: IEmailParticipant[]
  replyTo?: IEmailParticipant[]
  to?: IEmailParticipant[]
  cc?: IEmailParticipant[]
  bcc?: IEmailParticipant[]
  date?: number
  threadId?: string
  snippet?: string
  body?: string
  unread?: boolean
  starred?: boolean
  files?: IEmailFile[]
}

export type EmailItemCardProps = {
  email: IEmailCustomItem
  formattedTime: string
}

export const EmailItemCard = (props: EmailItemCardProps) => {
  const { email, formattedTime } = props

  // To remove heads and only get what's inside <body> tags
  const bodyHTML = /<body.*?>([\s\S]*)<\/body>/.exec(email.body ?? '')
  if (bodyHTML) {
    email.body = bodyHTML[1]
  }

  return (
    <ChatEvent
      color={Colors.grey}
      icon={customMessageCardIconName(SendBirdUtils.CustomMessageType.emailsnapshot)}
      name={customMessageCardName(SendBirdUtils.CustomMessageType.emailsnapshot)}
      time={formattedTime}
      title={`Subject: ${email.subject}`}
      description={email.body}
      isHTML
    />
  )
}
