export function getScrollParent(node: HTMLElement | null): HTMLElement | null {
  const isElement = node instanceof HTMLElement
  const overflowY = isElement && window.getComputedStyle(node).overflowY
  const isScrollable = overflowY && !(overflowY.includes('hidden') || overflowY.includes('visible'))

  if (!node) {
    return null
  }
  if (isScrollable && node.scrollHeight >= node.clientHeight) {
    return node
  }

  return getScrollParent(node.parentNode as HTMLElement | null) || document.body
}
