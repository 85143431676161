import React from 'react'
import styled, { css } from 'styled-components'
import { Colors, Spacing } from '@walter/shared'
import { rgba } from 'polished'
import { rotate } from '../../styles/animations'
import { square } from '../../styles/global'

const Container = styled(({ isLoading, children, ...rest }) => <div {...rest}>{children}</div>)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${Spacing.large};
  background-color: ${rgba(Colors.greyLight, 0.2)};
  ${square('64px')};

  ${(props) =>
    props.isLoading &&
    css`
      animation: ${rotate} 1s linear infinite;
    `}
`

type IconWrapProps = {
  icon: string
  size?: string
  type?: string
  color?: string
  isLoading?: boolean
  children: React.ReactNode
}

export const IconWrap = ({ icon, size, type, color, isLoading, children }: IconWrapProps) => (
  <Container icon={icon} size={size} color={color} type={type} isLoading={isLoading}>
    {children}
  </Container>
)
