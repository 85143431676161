import * as React from 'react'
import { cn } from '../utils'

export const DashboardLayout = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { isFullPage?: boolean }
>(({ isFullPage, className, ...props }, ref) => {
  return (
    <main
      ref={ref}
      className={cn('flex flex-1 flex-col w-full', !isFullPage && 'p-8 max-w-[1400px] mx-auto', className)}
      {...props}
    />
  )
})

DashboardLayout.displayName = 'DashboardLayout'
