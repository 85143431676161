import { z } from 'zod'
import { t } from '../../utils'

export const ChatSettingsi18nFields = {
  sendOnPressEnter: t('send-message-when-pressing-enter'),
}

export const ChatSettingsSchema = z.object({
  sendOnPressEnter: z.boolean(),
})

export type ChatSettings = z.infer<typeof ChatSettingsSchema>
