import { Api } from '@walter/shared'
import { useUpdateTimezone } from '@walter/shared-web'
import React from 'react'
import AuthContext from './Auth'

type CurrentUserContextType = {
  currentUser?: Api.GetMeManagerWebQuery['me']
  updateCurrentUser: (data: Api.UserUpdateInput) => Promise<void>
}

const CurrentUserContext = React.createContext({} as CurrentUserContextType)

export default CurrentUserContext

export function CurrentUserProvider({ children }: { children: React.ReactNode }) {
  const { currentUser } = React.useContext(AuthContext)

  const [updateCurrentUserMutation] = Api.useUpdateCurrentUserManagerWebMutation()

  useUpdateTimezone(currentUser as { timezone: string }, updateCurrentUser)

  async function updateCurrentUser(data: Api.UserUpdateInput) {
    await updateCurrentUserMutation({ variables: { data } })
  }

  return (
    <CurrentUserContext.Provider value={{ updateCurrentUser, currentUser }}>{children}</CurrentUserContext.Provider>
  )
}
