import { SharedUtils } from '@walter/shared'
import { z } from 'zod'
import { i18n, t } from '../../utils'

export const EnterPhoneNumberi18nFields = {
  phoneNumber: t('auth:enter-phone-number-page-phone-number-input-label'),
}

export const EnterPhoneNumberSchema = z.object({
  phoneNumber: z
    .string({ required_error: i18n.t('input:is-required', { field: EnterPhoneNumberi18nFields.phoneNumber }) })
    .min(1, i18n.t('input:is-required', { field: EnterPhoneNumberi18nFields.phoneNumber }))
    .regex(SharedUtils.phoneNumberRegex, t('auth:login-page-invalid-phone-number')),
})

export type EnterPhoneNumber = z.infer<typeof EnterPhoneNumberSchema>
