import React from 'react'
import styled from 'styled-components'

import { Spacing } from '@walter/shared'
import { Button } from '../Button'
import { SelectMemoPhoto } from '../Drawer/SelectMemoPhoto'
import { Icon } from '../Icon'
import { Label } from '../Label'
import { PostCoverImage } from '../PostCoverImage'
import { t } from '../../utils'

const PostCoverImageWrapper = styled.div`
  margin-bottom: ${Spacing.medium};
  position: relative;
`

const RemoveWrap = styled.button`
  position: absolute;
  top: ${Spacing.small};
  right: ${Spacing.small};
`

// Need to be moved somewhere else
type File = { size: number; preview: string; name: string }

type CoverImageUploadProps = {
  dataTestId?: string
  value: any // NOT WORKING -> string | { url: string } | { preview: string }
  label?: string
  onChange: (value: string | File | { id: string; url: string; preview?: string } | null) => void
  disabled?: boolean
  maxMegabyteSingle?: number
}

export const CoverImageUpload = ({
  dataTestId,
  value,
  onChange,
  label,
  disabled,
  maxMegabyteSingle = 20,
}: CoverImageUploadProps) => {
  const [openCustomImage, setOpenCustomImage] = React.useState(false)

  const url = React.useMemo(() => {
    return value?.preview || value?.url || value
  }, [value])

  return (
    <>
      <Label>{label}</Label>
      {!!value && (
        <PostCoverImageWrapper>
          <PostCoverImage onClick={() => window.open(url, '_blank')} url={url} />
          <RemoveWrap
            data-test-id={`${dataTestId}_CoverImage_Close_Button`}
            onClick={() => onChange(null)}
            disabled={disabled}
          >
            <Icon icon="close" size="small" />
          </RemoveWrap>
        </PostCoverImageWrapper>
      )}
      <Button
        dataTestId={`${dataTestId}_ChooseCoverImage_Button`}
        data-cy="choose-cover-image"
        onClick={() => setOpenCustomImage(true)}
        disabled={disabled}
      >
        {t('choose-cover-image')}
      </Button>
      <SelectMemoPhoto
        close={() => setOpenCustomImage(false)}
        visible={openCustomImage}
        handlePhotoSelected={(photo) => {
          onChange(photo)
          setOpenCustomImage(false)
        }}
        maxMegabyteSingle={maxMegabyteSingle}
      />
    </>
  )
}
