import { Spacing, Types, Colors } from '@walter/shared'
import { animations, globalStyles, Icon, NotificationMessageType } from '@walter/shared-web'
import 'rc-notification/assets/index.css'
import React from 'react'
import styled, { css } from 'styled-components'

function getColor({ type }: { type: NotificationMessageType }) {
  if (type === 'negative') return Colors.red
  if (type === 'positive') return Colors.green
  return Colors.grey
}

const Inner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const IconWrap = styled.div<{ isLoading: boolean; type: NotificationMessageType }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${getColor};
  margin-right: ${Spacing.small};
  flex-shrink: 0;
  ${globalStyles.square('24px')};

  ${(props) =>
    props.isLoading &&
    css`
      animation: ${animations.rotate} 1s linear infinite;
    `}

  &:after {
    content: '';
    background-color: ${getColor};
    border-radius: 50%;
    opacity: 0.2;
    ${globalStyles.cover('absolute')};
  }
`

const Text = styled.p`
  margin-bottom: 0;
`

type NotificationMessageSimpleContentParams = {
  text: string
  icon?: Types.IconName
  type?: NotificationMessageType
}

export function getNotificationMessageSimpleContent({
  icon,
  type = 'info',
  text,
}: NotificationMessageSimpleContentParams) {
  return (
    <Inner>
      {icon && (
        <IconWrap type={type} isLoading={type === 'loading'}>
          <Icon icon={icon} size="tiny" />
        </IconWrap>
      )}
      <Text>{text}</Text>
    </Inner>
  )
}
