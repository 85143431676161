import { useParams } from 'react-router-dom'
import { useProjectId } from '../../hooks/useProjectId'
import { MessagingService } from '@walter/shared'

export function useSelectorProjectId() {
  const selectedProjectId = useProjectId()
  const { channelUrl } = useParams<{ channelUrl?: string }>()
  return selectedProjectId === 'all' && channelUrl
    ? MessagingService.projectIdFromChannelUrl(channelUrl)
    : selectedProjectId
}
