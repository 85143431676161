import enTexts from '../texts/en.json'
import frTexts from '../texts/fr.json'

export function getTranslation(text: string, languageCode: string) {
  if (text.includes(':')) {
    text = text.substring(text.lastIndexOf(':') + 1, text.length)
  }

  if (languageCode === 'fr') {
    return (frTexts as { [x: string]: string | object })[text] as string
  }

  return (enTexts as { [x: string]: string | object })[text] as string
}
