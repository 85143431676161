import { useContext } from 'react'
import PendingActionsContext from '../contexts/PendingActions'
import { useConversations } from '../contexts/Conversations'

export default function (projectId: string) {
  const { pendingBookingsPerProjects } = useContext(PendingActionsContext)

  const { allUnreadMessagesCount, unreadMessagesCountByProjectMap } = useConversations()

  const unreadCount =
    !projectId || projectId === 'all' ? allUnreadMessagesCount : unreadMessagesCountByProjectMap.get(projectId)

  if (pendingBookingsPerProjects) {
    return (pendingBookingsPerProjects[projectId] || 0) + (unreadCount || 0)
  }

  return 0
}
