import { Colors, Spacing, Types } from '@walter/shared'
import React from 'react'
import styled, { css } from 'styled-components'
import { borderRadius, boxShadow } from '../../styles/global'
import { fontSizes } from '../../styles/typography'
import { ButtonProps } from '../Button'
import { ButtonGroup } from '../ButtonGroup'
import { Heading } from '../Heading'
import { Icon } from '../Icon'

const Container = styled.div<{ isCollapsed: boolean; onClick: any }>`
  border-radius: ${borderRadius};
  border: 1px solid ${Colors.borderColor};
  background-color: ${Colors.white};
  ${boxShadow};

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

  ${(props) =>
    props.isCollapsed === true &&
    css`
      background-color: ${Colors.offWhite};
    `}

  & + & {
    margin-top: ${Spacing.large};
  }
`

const Header = styled.header<{ isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  padding: ${Spacing.large};
  padding-bottom: 0;

  ${(props) =>
    props.isCollapsed === true &&
    css`
      padding-bottom: ${Spacing.large};
    `}
`

const StyledHeading = styled(Heading)`
  flex-grow: 1;
  margin-bottom: 0;
  padding-right: ${Spacing.medium};
`

const IconWrap = styled.div`
  display: flex;
  margin-right: ${Spacing.medium};
  color: ${Colors.grey};
`

const Action = styled.button`
  color: ${Colors.primaryColor};
  font-size: ${fontSizes.small};
  text-decoration: underline;
`

type CardProps = {
  dataTestId?: string
  children: React.ReactNode
  heading?: string
  icon?: Types.IconName
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => any
  actions?: (ButtonProps & { label: string })[]
  isCollapsed?: boolean
  index?: number
}

export const Card = ({ dataTestId, heading, isCollapsed, icon, actions, children, onClick, ...rest }: CardProps) => {
  const cardIndex = rest.index ? rest.index : '0'
  return (
    <Container
      data-test-id={`${dataTestId}_Card_Container_${cardIndex}`}
      onClick={onClick}
      isCollapsed={!!isCollapsed}
      {...rest}
      data-cy={`card-${heading || 'generic'}`}
    >
      {heading && (
        <Header data-test-id={`${dataTestId}_Card_Header`} isCollapsed={!!isCollapsed}>
          {icon && (
            <IconWrap>
              <Icon icon={icon} />
            </IconWrap>
          )}
          <StyledHeading data-test-id={`${dataTestId}_Card_Header_Title`} size={3}>
            {heading}
          </StyledHeading>

          {actions?.length && (
            <ButtonGroup>
              {actions.map((action, index) => (
                <Action
                  data-test-id={`${dataTestId}_Card_Header_${action.label}_Button`}
                  key={index}
                  onClick={
                    action.onClick
                      ? (e: React.MouseEvent<HTMLButtonElement>) => {
                          if (e && onClick) {
                            e.stopPropagation()
                          }
                          if (action.onClick) {
                            action.onClick(e)
                          }
                        }
                      : undefined
                  }
                >
                  {action.label}
                </Action>
              ))}
            </ButtonGroup>
          )}
        </Header>
      )}
      {children}
    </Container>
  )
}
