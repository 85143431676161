import { Spacing } from '@walter/shared'
import React from 'react'
import styled from 'styled-components'
import { Loading } from '../Loading'
import ListEmptyState from './ListEmptyState'

const Container = styled.div`
  display: grid;
  grid-gap: ${Spacing.medium};
`

type ListProps<Item> = {
  children: React.ReactNode
  items?: Item[]
  loading?: boolean
}

export function List<Item>({ items, loading, children }: ListProps<Item>) {
  if (loading) {
    return <Loading type="center" />
  }

  if (items && items.length === 0) {
    return <ListEmptyState />
  }

  return <Container>{children}</Container>
}
