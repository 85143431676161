import { Colors, Global } from '@walter/shared'
import { rgba } from 'polished'
import { CSSProperties, createGlobalStyle, css } from 'styled-components'
import Normalize from './normalize'
import Typography from './typography'
import Vendor from './vendor'
import Width from './width'

// General
export const scale = 1

// Animation
export const animationTime = `${Global.TRANSITION_LENGTH}ms`
export const animationCurve = 'ease-in-out'

// Border Radius
export const borderRadius = '4px'

// Misc
export const navWidth = '72px'
export const navHeight = '64px'
export const menuWidth = '80px'
export const conversationThreadHeight = '60px'
export const wrapperWidth = '1400px'

// Inputs
export const inputHeight = '42px'
export const inputHeightSmall = '32px'
export const controlDimensions = '18px'

// Vertical padding
export const verticalPadding = (padding: string | number) => css`
  padding-top: ${padding};
  padding-bottom: ${padding};
`

// Square
export const square = (size: string) => css`
  width: ${size};
  height: ${size};
`

// Box shadow
export const boxShadow = `box-shadow: 0 2px 5px 0 ${rgba(Colors.black, 0.05)};`
export const boxShadowDark = `box-shadow: 0 3px 7px 0 ${rgba(Colors.black, 0.075)};`

// Cover
export function cover(position: CSSProperties['position']) {
  return `
    position: ${position};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `
}

// Post cover image
export const postCoverImagePaddingTop = '44%'

// Styles
export const GlobalStyle = createGlobalStyle`
  ${Normalize};
  ${Typography};
  ${Width};
  ${Vendor};

  body {
    min-height: 100vh;
  }
`
