import React from 'react'
import { Icon, typography } from '@walter/shared-web'
import styled from 'styled-components'
import { Colors, Spacing } from '@walter/shared'
import { WebDateUtils } from '../../utils/date'

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${Spacing.tiny};
  font-size: ${typography.fontSizes.tiny};
`

const Name = styled.span`
  color: ${Colors.greyDark};
  font-weight: ${typography.fontWeights.bold};
`

const Time = styled.span`
  color: ${Colors.greyLight};
  margin-left: ${Spacing.tiny};
`

const InfoIconWrap = styled.div`
  margin-left: ${Spacing.tiny};
  color: ${Colors.greyLight};
  display: flex;
  align-items: center;
`

export interface MessageUserGroupingHeaderProps {
  authorName?: string
  isFromMe: boolean
  isFromManagement: boolean
  createdAt: number
}

const MessagesUserGroupingHeader = (props: MessageUserGroupingHeaderProps) => {
  const { authorName, isFromMe, isFromManagement, createdAt } = props
  return (
    <Header data-test-id="Message_Usergroup_header">
      {!isFromMe && <Name>{authorName}</Name>}
      <Time data-test-id="Message_Usergroup_header_Time">{WebDateUtils.format(createdAt, 'LT')}</Time>
      {isFromManagement && !isFromMe && (
        <InfoIconWrap
          data-tip={`You're seeing who sent the message from your managing company. Resident can't see this. They will only see that they've received a message from their property management company.`}
        >
          <Icon size="tiny" icon="info" />
        </InfoIconWrap>
      )}
    </Header>
  )
}

export default MessagesUserGroupingHeader
