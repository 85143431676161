import groupBy from 'lodash/groupBy'
import React, { useMemo } from 'react'
import { AnnotatedSection } from '../AnnotatedSection'
import { Card } from '../Card'
import { CardSection } from '../Card/CardSection'
import { Fieldset } from '../Fieldset'
import { FormDataViewContext } from './FormDataView'
import { FormDataViewActions } from './FormDataViewActions'
import { FormDataViewField } from './FormDataViewField'

export function FormDataViewSection() {
  const { fields, values } = React.useContext(FormDataViewContext)

  const fieldsBySection = useMemo(() => {
    return groupBy(fields, (item) => item.section?.title)
  }, [fields])

  return (
    <>
      {Object.keys(fieldsBySection).map((key, index) => {
        const fields = fieldsBySection[key]
        return (
          <AnnotatedSection
            key={fields[0].section?.title || index}
            heading={fields[0].section?.title || ''}
            description={fields[0].section?.description || ''}
          >
            <Card>
              <CardSection>
                <Fieldset>
                  {fields.map((field) => (
                    <FormDataViewField field={field} key={field.path || field.label} values={values} />
                  ))}
                </Fieldset>
              </CardSection>
            </Card>
          </AnnotatedSection>
        )
      })}
      <FormDataViewActions />
    </>
  )
}
