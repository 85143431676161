import { Colors, Spacing } from '@walter/shared'
import styled from 'styled-components'

export const FormSection = styled.div`
  padding: ${Spacing.large};
  border-bottom: 1px solid ${Colors.borderColor};

  &:last-child {
    border-bottom: 0;
  }
`
