import { useApolloClient } from '@apollo/client'
import { MetaData, RestrictedUser, User } from '@sendbird/chat'
import { GroupChannel } from '@sendbird/chat/groupChannel'
import { useAtomValue, useSetAtom } from 'jotai'
import { useHydrateAtoms } from 'jotai/utils'
import { useEffect, useMemo } from 'react'
import { Channel, MessagingService } from './MessagingService'
import {
  initialLoadingAtom,
  privateChannelsMapAtom,
  publicChannelsMapAtom,
  serviceAtom,
  typingMembersMapAtom,
} from './atoms'
import { Api } from '..'
import { GroupChannelHandlerParams } from '@sendbird/chat/lib/__definition'

interface UseMessagingServiceArgs {
  messagingService: MessagingService
  userId: string
}

/**
 * @warning Must be called before other messaging hooks
 * @description Connects to messaging service and gets all conversations for the user
 */
export const useMessagingService = (args: UseMessagingServiceArgs) => {
  const { messagingService, userId } = args
  useHydrateAtoms([[serviceAtom, messagingService]])
  const service = useAtomValue(serviceAtom)
  const setInitialLoading = useSetAtom(initialLoadingAtom)
  const setPublicChannelsMap = useSetAtom(publicChannelsMapAtom)
  const setPrivateChannelsMap = useSetAtom(privateChannelsMapAtom)
  const setTypingMembersMap = useSetAtom(typingMembersMapAtom)
  const client = useApolloClient()

  const channelHandlers = useMemo(() => {
    function updateChannel(channel: Channel) {
      if (MessagingService.channelIsPrivate(channel.url)) {
        setPrivateChannelsMap((prev) => {
          prev.set(channel.url, channel)
          return new Map(prev)
        })
        return
      }
      setPublicChannelsMap((prev) => {
        prev.set(channel.url, channel)
        return new Map(prev)
      })
    }

    function updateChannels(channels: GroupChannel[]) {
      for (const channel of channels) {
        updateChannel(channel)
      }
    }

    function deleteChannel(channelUrl: string) {
      if (MessagingService.channelIsPrivate(channelUrl)) {
        setPrivateChannelsMap((prev) => {
          prev.delete(channelUrl)
          return new Map(prev)
        })
        return
      }
      setPublicChannelsMap((prev) => {
        prev.delete(channelUrl)
        return new Map(prev)
      })
    }

    function hideChannel(channel: GroupChannel) {
      if (MessagingService.channelIsPrivate(channel.url)) {
        setPrivateChannelsMap((prev) => {
          prev.delete(channel.url)
          return new Map(prev)
        })
        return
      }
      setPublicChannelsMap((prev) => {
        prev.delete(channel.url)
        return new Map(prev)
      })
    }

    function userAction(channel: GroupChannel, sendbirdUser: User) {
      if (!service) return
      if (sendbirdUser.userId === service.user?.userId) deleteChannel(channel.url)
      else updateChannel(channel)
    }

    function onUserBanned(channel: GroupChannel, sendbirdUser: RestrictedUser) {
      if (!service) return
      if (sendbirdUser.userId === service.user?.userId) deleteChannel(channel.url)
      else updateChannel(channel)
    }

    function onTypingStatusUpdated(channel: GroupChannel) {
      channel.getTypingUsers()
      setTypingMembersMap((prev) => {
        const typingUsers = channel.getTypingUsers()
        prev.set(channel.url, typingUsers)
        return new Map(prev)
      })
    }

    function onMetaDataChange(channel: GroupChannel, metaData: MetaData) {
      if (metaData.archive === 'true') deleteChannel(channel.url)
      if (metaData.archive === 'false') updateChannel(channel)
    }

    return {
      onChannelChanged: updateChannel,
      onUnreadMemberStatusUpdated: updateChannel,
      onChannelFrozen: updateChannel,
      onChannelUnfrozen: updateChannel,
      onMentionReceived: updateChannel,
      onMessageDeleted: updateChannel,
      onMessageReceived: updateChannel,
      onMessageUpdated: updateChannel,
      onChannelMemberCountChanged: updateChannels,
      onChannelDeleted: deleteChannel,
      onChannelHidden: hideChannel,
      onUserJoined: updateChannel,
      onUserLeft: userAction,
      onUserBanned,
      onTypingStatusUpdated,
      onMetaDataCreated: onMetaDataChange,
      onMetaDataUpdated: onMetaDataChange,
    }
  }, [service])

  useEffect(() => {
    async function connect() {
      if (!service || !userId) return

      const { data: { sendbirdSession } = {} } = await client.query<Api.GetSendbirdSessionQuery>({
        query: Api.GetSendbirdSessionDocument,
        fetchPolicy: 'no-cache',
      })
      if (!sendbirdSession) return
      service
        .connect({
          session: { ...sendbirdSession, userId },
          userId,
          channelHandler: channelHandlers as unknown as GroupChannelHandlerParams,
        })
        .then(async (unreadChannels) => {
          if (!unreadChannels) return
          setPublicChannelsMap(unreadChannels.public)
          setPrivateChannelsMap(unreadChannels.private)
          setInitialLoading(false)

          async function getMoreChannels(type: 'unread' | 'all') {
            const nextChannels =
              type === 'unread' ? await service.getNextUnreadChannels() : await service.getNextChannels()
            setPublicChannelsMap((prev) => {
              for (const [channelUrl, publicChannels] of nextChannels.public) {
                prev.set(channelUrl, publicChannels)
              }
              return new Map(prev)
            })
            setPrivateChannelsMap((prev) => {
              for (const [channelUrl, privateChannels] of nextChannels.private) {
                prev.set(channelUrl, privateChannels)
              }
              return new Map(prev)
            })
            if (nextChannels.hasNext) {
              await getMoreChannels(type)
            }
          }

          if (unreadChannels.hasNext) {
            await getMoreChannels('unread')
          }

          await getMoreChannels('all')
        })
        .catch((error) => {
          service.clearCache().catch((error) => console.error('[useMessagingService]', 'clear cache failure', error))
          if (typeof error === 'string' && !error?.includes('There was a network error')) {
            console.error('[useMessagingService]', 'connection failure', error)
          }
        })
        .finally(() => {
          setInitialLoading(false)
        })
    }

    connect().catch((error) => console.error('[useMessagingService]', 'connect failure', error))

    return () => {
      if (!service?.disconnect) return
      service.disconnect()
    }
  }, [userId])
}

export const useDisconnectMessagingService = () => {
  const service = useAtomValue(serviceAtom)
  return async () => {
    if (!service?.disconnect) return
    await service.disconnect()
  }
}
