import { WebDateUtils } from '../../../../src/utils/date'
import { Pill } from '@walter/shared-web'
import { Colors } from '@walter/shared'
import React from 'react'
import { SendBirdUtils } from '@walter/shared'
import { customMessageCardIconName, customMessageCardName } from './CustomMessageCard.entities'
import ChatEvent from '../ChatEvent'

export interface IEventCustomItem {
  id: string
  title: string | null
  description: string | null
  start: any | null
  end: any | null
  location: string | null
}

export type EventItemCardProps = {
  event: IEventCustomItem
  formattedTime: string
}

export const EventItemCard = (props: EventItemCardProps) => {
  const { event, formattedTime } = props

  const timeInfo = [event.start, event.end]
    .filter((x) => !!x)
    .map((t) => WebDateUtils.dayjs(t).format('llll'))
    .join(' - ')
  const title = [event.title, timeInfo].filter((x) => !!x).join(' - ')

  return (
    <ChatEvent
      color={Colors.events}
      icon={customMessageCardIconName(SendBirdUtils.CustomMessageType.event)}
      name={customMessageCardName(SendBirdUtils.CustomMessageType.event)}
      time={formattedTime}
      title={title}
      footer={!!event.location && <Pill customColor={Colors.blue} text={event.location} />}
      description={event.description}
      isHTML
    />
  )
}
