import { NetworkStatus } from '@apollo/client'
import { Api, SharedUtils } from '@walter/shared'
import { Form, convertToOptionValue, t, useDebouncedState } from '@walter/shared-web'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import uniqBy from 'lodash/uniqBy'
import React, { useCallback, useEffect, useMemo } from 'react'
import { SelectorProps } from './types'
import { useSelectorProjectId } from './useSelectorProjectId'

type DefaultValue = { id: string; number?: string }

type Props<Model, OptionValue> = SelectorProps<Model, OptionValue> & {
  defaultValue?: DefaultValue | DefaultValue[]
}

export function RemoteSelector<Model, OptionValue>(props: Props<Model, OptionValue>) {
  const [textFilter, setTextFilter] = React.useState<string>('')
  const [debouncedTextFilter, forceUpdateDebouncedTextFilter] = useDebouncedState(textFilter, 750)
  const { fetchMoreRemotes, remotes, isLoading, hasMore } = useRemotesQuery(debouncedTextFilter, props)

  const onAdditionalChange = useCallback(
    (selected: Parameters<NonNullable<typeof props.additionalOnChange>>[0]) => {
      if (textFilter) {
        forceUpdateDebouncedTextFilter('')
      }
      if (props?.additionalOnChange) {
        props.additionalOnChange(selected)
      }
    },
    [textFilter, props.additionalOnChange],
  )

  const defaultValue = useMemo(() => {
    return props?.defaultValue
      ? Array.isArray(props.defaultValue)
        ? convertToOptionValue(
            props.defaultValue,
            props.defaultValue,
            (remote) => (remote as DefaultValue)?.number ?? t('unknown'),
          )
        : {
            value: { id: props.defaultValue.id },
            label: props.defaultValue?.number ?? t('unknown'),
          }
      : undefined
  }, [props.defaultValue])

  return (
    <Form.Select<Model, OptionValue>
      {...props}
      options={remotes.map((remote) => {
        return {
          value: { id: remote.id },
          label: remote.number,
        }
      })}
      isLoading={isLoading || textFilter !== debouncedTextFilter}
      inputValue={hasMore || textFilter ? textFilter : undefined}
      onInputChange={hasMore || textFilter ? setTextFilter : undefined}
      additionalOnChange={onAdditionalChange}
      onMenuScrollToBottom={fetchMoreRemotes}
      value={defaultValue}
      noOptionsText={t('no-remotes')}
    />
  )
}

type Remote = NonNullable<Api.GetRemotesV2Query['remotesV2']['edges']>[number]['node']

const previousProjectIdAtom = atomWithStorage<string>('previousProjectIdAtom', '')
export const accumulatedRemotesAtom = atomWithStorage<Remote[]>('accumulatedRemotesAtom', [])

function useRemotesQuery<Model, OptionValue>(textFilter: string, props: Props<Model, OptionValue>) {
  const projectId = useSelectorProjectId()
  const [previousProjectId, setPreviousProjectId] = useAtom(previousProjectIdAtom)
  const [accumulated, setAccumulated] = useAtom(accumulatedRemotesAtom)

  const {
    data: { remotesV2: { edges = [], pageInfo = {}, totalCount = 0 } = {} } = {},
    loading: loadingRemotes,
    fetchMore,
    networkStatus,
  } = Api.useGetRemotesV2Query({
    notifyOnNetworkStatusChange: true,
    skip: projectId === 'all',
    variables: {
      projectId,
      first: 24,
      textFilter,
    },
    onCompleted(data) {
      const nodes = data.remotesV2.edges?.map((edge) => edge.node) ?? []
      const defaultValue: Remote[] = SharedUtils.convertToArray(props.defaultValue)
      setAccumulated((prev) => {
        return uniqBy([...prev, ...defaultValue, ...nodes], 'id').filter((remote) => remote?.id?.length > 0)
      })
    },
  })

  useEffect(() => {
    if (projectId !== previousProjectId) {
      setPreviousProjectId(projectId)
      setAccumulated(edges?.map((edge) => edge.node) ?? [])
    }
  }, [edges, projectId])

  const fetchMoreRemotes = useCallback(async () => {
    if ((edges?.length || 0) < totalCount && networkStatus !== NetworkStatus.setVariables) {
      try {
        await fetchMore({
          variables: {
            after: pageInfo.endCursor,
          },
        })
      } catch (err) {
        console.error('[RemoteSelector/useRemotesQuery]', 'Error fetching more', err)
      }
    }
  }, [pageInfo?.endCursor, edges?.length, totalCount])

  return {
    hasMore: (edges?.length || 0) < totalCount,
    remotes: accumulated,
    fetchMoreRemotes,
    isLoading:
      loadingRemotes || networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.setVariables,
  }
}
