import { useState } from 'react'
import { Message } from '../messaging'

export interface DeleteMessagePromptHookProps {
  deleteMessage: (message: Message) => Promise<void>
}

export const useDeleteMessagePrompt = (props: DeleteMessagePromptHookProps) => {
  const [message, setMessage] = useState<Message | null>(null)
  const [isDeletingMessage, setIsDeletingMessage] = useState<boolean>(false)
  const promptDeleteMessage = (message: Message) => {
    setMessage(message)
  }
  const onCancelDeleteMessage = () => {
    setMessage(null)
  }

  const onConfirmDeleteMessage = async () => {
    if (!message) return
    setIsDeletingMessage(true)
    await props.deleteMessage(message)
    setIsDeletingMessage(false)
    setMessage(null)
  }

  return {
    promptDeleteMessage,
    onCancelDeleteMessage,
    onConfirmDeleteMessage,
    isDeletingMessage,
    message,
  }
}
