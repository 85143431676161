import { z } from 'zod'
import { i18n, t } from '../../utils'

export const folderI18n = {
  projects: t('fields-folder:selected-project'),
  name: t('name-uppercase'),
  folderParent: t('fields-folder:folder-parent'),
  sharedWithResidents: t('shared-with-residents'),
  segments: {
    text: t('fields-segment:group'),
    hint: t('fields-segment:send-your-memo-events-folders-and-more-t'),
    placeholder: t('all-residents'),
  },
}

export const folderSchema = z.object({
  name: z
    .string({ required_error: i18n.t('input:is-required', { field: folderI18n.name }) })
    .min(1, { message: i18n.t('input:is-required', { field: folderI18n.name }) }),
  sharedWithResidents: z.boolean({
    required_error: i18n.t('input:is-required', { field: folderI18n.sharedWithResidents }),
  }),
  folderParent: z
    .object({
      id: z.string({ required_error: i18n.t('input:is-required', { field: folderI18n.folderParent }) }),
    })
    .nullable(),
})

export const singleFolderSchema = folderSchema.extend({
  segments: z.array(z.object({ id: z.string() })),
})

export const sharedFolderSchema = folderSchema.extend({
  projects: z
    .array(z.object({ id: z.string({ required_error: i18n.t('input:is-required', { field: folderI18n.projects }) }) }))
    .min(1, { message: i18n.t('input:is-required', { field: folderI18n.projects }) }),
})

export type FolderMutation = z.infer<typeof singleFolderSchema>
export type SharedFolderMutation = z.infer<typeof sharedFolderSchema>
