import { Api, Colors, Spacing, Types } from '@walter/shared'
import { t } from '../../utils'
import { WebDateUtils } from '../../../src/utils/date'
import { stripUnit } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { borderRadius, boxShadow } from '../../styles/global'
import { fontSizes, fontWeights } from '../../styles/typography'
import { Icon } from '../Icon'
import { Pill } from '../Pill'

const STATUS_PILLS: Record<NonNullable<NotificationCardStatusType>, JSX.Element> = {
  notSent: <Pill text={t('not-sent')} type="custom" customColor={Colors.red} />,
  notDelivered: <Pill text={t('not-delivered')} type="custom" customColor={Colors.red} />,
  processing: <Pill text={t('processing')} />,
}

type ChannelStatus = 'delivered' | 'seen' | 'error'

type ChannelData = {
  icon: Types.IconName
  label: string
  color: string
}

const CHANNEL_STATUS_OPTIONS: Record<ChannelStatus, ChannelData> = {
  delivered: {
    icon: 'checkmark',
    label: t('delivered'),
    color: Colors.grey,
  },
  seen: {
    icon: 'eye',
    label: t('seen'),
    color: Colors.primaryColor,
  },
  error: {
    icon: 'close',
    label: t('error'),
    color: Colors.grey,
  },
}

const Container = styled.div`
  padding: ${Spacing.medium};
  border-radius: ${borderRadius};
  border: 1px solid ${Colors.borderColor};
  ${boxShadow};
`

const Status = styled.div`
  display: flex;
  margin-bottom: ${Spacing.small};
`

const Name = styled.span`
  display: block;
  color: ${Colors.greyDark};
  font-weight: ${fontWeights.bold};
`

const CreatedAt = styled.span`
  display: block;
  margin-top: ${`${(stripUnit(Spacing.tiny) as number) * 0.5}px`};
  font-size: ${fontSizes.small};
`

const Meta = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fontSizes.small};
  margin-top: ${Spacing.tiny};
`

const ItemContainer = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-left: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
  }
`

const ItemIcon = styled.div<{ status: keyof typeof CHANNEL_STATUS_OPTIONS }>`
  display: flex;
  flex-shrink: 0;
  margin-right: ${Spacing.small};
  color: ${(props) => CHANNEL_STATUS_OPTIONS[props.status].color};
`

type NotificationCardStatusType = 'notSent' | 'notDelivered' | 'processing' | null

type NotificationCardProps = {
  notification: Api.GetNotificationsForResidentManagerWebQuery['notifications'][number]
}

export const NotificationCard = ({ notification }: NotificationCardProps) => {
  const status = getStatus(notification)
  const channels = getChannels(notification)
  return (
    <Container data-test-id={`NotificationCard_${notification.id}`}>
      {status && <Status data-test-id="NotificationCard_Status">{STATUS_PILLS[status]}</Status>}
      <Name data-test-id="NotificationCard_Name">{notification.title}</Name>
      {notification.createdAt && (
        <CreatedAt data-test-id="NotificationCard_CreatedAt">
          {WebDateUtils.dayjs(notification.createdAt).format('LLLL')}
        </CreatedAt>
      )}
      {channels?.length > 0 && (
        <Meta data-test-id="NotificationCard_Meta">
          {channels.map((channel, i) => (
            <Item key={i} status={channel.status} name={channel.name} time={channel.time} />
          ))}
        </Meta>
      )}
    </Container>
  )
}

export type ItemProps = {
  status: keyof typeof CHANNEL_STATUS_OPTIONS
  name: string
  time: string | Date
}

const Item = ({ status, name, time }: ItemProps) => {
  return (
    <ItemContainer>
      <ItemIcon
        status={status}
        {...(!!time && WebDateUtils.dayjs(time).isValid() && { 'data-tip': WebDateUtils.dayjs(time).format('LLLL') })}
      >
        <Icon icon={CHANNEL_STATUS_OPTIONS[status].icon} size="small" />
      </ItemIcon>
      <span data-test-id={`NotificationCard_Meta_${name}`}>{name}</span>
    </ItemContainer>
  )
}

function getChannels(notification: NotificationCardProps['notification']): ItemProps[] {
  return [
    {
      name: 'App',
      status: notification.hasBeenSeenByAppAt ? 'seen' : notification.deliveredByAppAt ? 'delivered' : 'error',
      time:
        notification.hasBeenSeenByAppAt ||
        notification.deliveredByAppAt ||
        notification.sentByAppErrorMessage ||
        'Unknown issue',
    },
    {
      name: 'Email',
      status: notification.hasBeenSeenByEmailAt ? 'seen' : notification.deliveredByEmailAt ? 'delivered' : 'error',
      time:
        notification.hasBeenSeenByEmailAt ||
        notification.deliveredByEmailAt ||
        notification.sentByEmailErrorMessage ||
        'Unknown issue',
    },
  ]
}

export function getStatus(notif: NotificationCardProps['notification']): NotificationCardStatusType {
  if (
    !notif.sentAt &&
    WebDateUtils.dayjs.duration(WebDateUtils.dayjs().diff(WebDateUtils.dayjs(notif.createdAt))).asHours() < 5
  ) {
    return 'processing'
  } else if (!notif.sentAt) {
    return 'notSent'
  } else if (!notif.delivered) {
    return 'notDelivered'
  }
  return null
}
