import { Spacing } from '@walter/shared'
import * as React from 'react'
import { Heading } from '../Heading'

type TitleBarProps = {
  dataTestId?: string
  title?: string
  description?: string
  actions?: React.ReactNode
  testID?: string
}

export const TitleBar = ({ dataTestId, title, description, actions, testID }: TitleBarProps) => {
  const dataTestIdValue = dataTestId ? dataTestId + '_TitleBar_Container' : 'TitleBar_Container'
  return (
    <div data-test-id={dataTestIdValue} className="flex mb-6" data-testid={testID}>
      <div className="flex-1 flex flex-col pr-10">
        <Heading data-test-id="Title" id={`title-bar`} data-cy={`title-bar`} size={1} className="mb-2">
          {title}
        </Heading>
        {description && (
          <p className="text-sm max-w-2xl" data-test-id="Description">
            {description}
          </p>
        )}
      </div>
      <div style={{ paddingTop: Spacing.xTiny }}>{actions}</div>
    </div>
  )
}
