import { AvailableCountries } from '../constants'
import { getTranslation } from './localization-utils'

export const convertCountryToCode = (country: string, languageCode: string) => {
  const firstTwoCharsLanguageCode = languageCode.substring(0, 2)
  switch (country) {
    case getTranslation('canada', firstTwoCharsLanguageCode):
      return 'CA'
    case getTranslation('united-states', firstTwoCharsLanguageCode):
      return 'US'
    default:
      return country
  }
}

export const convertCodeToCountry = (country: string, languageCode: string) => {
  const firstTwoCharsLanguageCode = languageCode.substring(0, 2)
  switch (country) {
    case 'CA':
      return getTranslation('canada', firstTwoCharsLanguageCode)
    case 'US':
      return getTranslation('united-states', firstTwoCharsLanguageCode)
    default:
      return country
  }
}

export const convertStateToCode = (state: string, languageCode: string) => {
  const firstTwoCharsLanguageCode = languageCode.substring(0, 2)
  const states: { label: string; value: string }[] = []
  for (const country of AvailableCountries.AvailableCountries(firstTwoCharsLanguageCode)) {
    for (const option of country.subOptions) {
      states.push(option)
    }
  }

  for (const option of states) {
    if (option.label === state) {
      return option.value
    }
  }
}

export const convertCodeToState = (state: string, languageCode: string) => {
  const firstTwoCharsLanguageCode = languageCode.substring(0, 2)
  const states: { label: string; value: string }[] = []

  for (const country of AvailableCountries.AvailableCountries(firstTwoCharsLanguageCode)) {
    for (const option of country.subOptions) {
      states.push(option)
    }
  }

  for (const option of states) {
    if (option.value === state) {
      return option.label
    }
  }
}

export const convertCountryCodeToSuboptions = (stateCode: string, languageCode: string) => {
  const firstTwoCharsLanguageCode = languageCode.substring(0, 2)
  const countryKeys = AvailableCountries.AvailableCountries(firstTwoCharsLanguageCode).map(
    (countryObject) => countryObject.value,
  )
  const index = countryKeys.indexOf(stateCode)

  return AvailableCountries.AvailableCountries(firstTwoCharsLanguageCode)[index]?.subOptions
}
