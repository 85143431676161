import { Api } from '..'

export enum THIRD_PARTY_SERVICE_NAMES {
  HOPEM = 'Hopem',
  HAPI_HOPEM = 'HAPI_HOPEM',
  CONDO_MANAGER_API = 'Condo Manager',
  CONDO_MANAGER_LOCAL = 'Condo Manager Local',
}

export const isProjectThirdParty = (currentProject: {
  thirdPartyServiceSettings: { mappedService: { serviceName: string }; config: any }[]
}) => {
  return (
    isProjectHapiHopem(currentProject) || isProjectHopem(currentProject) || isProjectCondoManagerApi(currentProject)
  )
}

export const isProjectSpecificThirdParty = (
  currentProject: {
    thirdPartyServiceSettings: { mappedService: { serviceName: string }; config: any }[]
  },
  serviceName: THIRD_PARTY_SERVICE_NAMES,
  configTests: { configKey: string; configTest: (value: string | number) => boolean }[],
) => {
  if (currentProject?.thirdPartyServiceSettings?.length > 0) {
    const foundThirdPartyMappedServiceSetting = currentProject.thirdPartyServiceSettings.find(
      (thirdPartyServiceSetting) => thirdPartyServiceSetting.mappedService?.serviceName === serviceName,
    )

    if (foundThirdPartyMappedServiceSetting?.config == null) {
      return false
    }

    for (const configTest of configTests) {
      if (!configTest.configTest(foundThirdPartyMappedServiceSetting?.config[configTest.configKey])) {
        return false
      }
    }
  } else {
    return false
  }

  return true
}

export const isProjectHopem = (currentProject: {
  thirdPartyServiceSettings: { mappedService: { serviceName: string }; config: any }[]
}) => {
  return isProjectSpecificThirdParty(currentProject, THIRD_PARTY_SERVICE_NAMES.HOPEM, [
    { configKey: 'hopemCompanyId', configTest: (hopemCompanyId: number | string) => hopemCompanyId != null },
    { configKey: 'hopemBuildingId', configTest: (hopemBuildingId: number | string) => hopemBuildingId != null },
  ])
}

export const isProjectHapiHopem = (currentProject: {
  thirdPartyServiceSettings: { mappedService: { serviceName: string }; config: any }[]
}) => {
  return isProjectSpecificThirdParty(currentProject, THIRD_PARTY_SERVICE_NAMES.HAPI_HOPEM, [
    { configKey: 'hopemCompanyId', configTest: (hopemCompanyId: number | string) => hopemCompanyId != null },
    { configKey: 'hopemBuildingId', configTest: (hopemBuildingId: number | string) => hopemBuildingId != null },
    {
      configKey: 'hopemCompanyDivisionId',
      configTest: (hopemCompanyDivisionId: number | string) => hopemCompanyDivisionId != null,
    },
  ])
}

export const isProjectCondoManagerApi = (currentProject: {
  thirdPartyServiceSettings: { mappedService: { serviceName: string }; config: any }[]
}) => {
  return isProjectSpecificThirdParty(currentProject, THIRD_PARTY_SERVICE_NAMES.CONDO_MANAGER_API, [
    {
      configKey: 'condoManagerId',
      configTest: (condoManagerId: string | number) => typeof condoManagerId === 'string' && condoManagerId.length > 0,
    },
  ])
}

export const isManagingCompanyThirdParty = (managingCompany: {
  syncServiceSettings: { mappedService: { serviceName: string }; setting: any }[]
}) => {
  return (
    isManagingCompanyHopem(managingCompany) ||
    isManagingCompanyCMApi(managingCompany) ||
    isManagingCompanyCMLocal(managingCompany)
  )
}

export const isManagingCompanySpecificThirdParty = (
  managingCompany?: {
    syncServiceSettings?: { mappedService?: { serviceName?: string } | null; setting?: any }[] | null
  } | null,
  serviceName?: THIRD_PARTY_SERVICE_NAMES,
  settingTests?: { settingKey: string; settingTest: (value: string | number) => boolean }[],
) => {
  if (managingCompany?.syncServiceSettings && managingCompany?.syncServiceSettings?.length > 0) {
    const foundManagingCompanySyncServiceSetting = managingCompany.syncServiceSettings.find(
      (thirdPartyServiceSetting) => thirdPartyServiceSetting.mappedService?.serviceName === serviceName,
    )

    if (!foundManagingCompanySyncServiceSetting?.setting) {
      return false
    }

    for (const settingTest of settingTests ?? []) {
      if (!settingTest.settingTest(foundManagingCompanySyncServiceSetting?.setting[settingTest.settingKey])) {
        return false
      }
    }
  } else {
    return false
  }

  return true
}

export const isManagingCompanyHopem = (
  managingCompany?: {
    syncServiceSettings?: { mappedService?: { serviceName?: string } | null; setting?: any }[] | null
  } | null,
) => {
  return isManagingCompanySpecificThirdParty(managingCompany, THIRD_PARTY_SERVICE_NAMES.HOPEM, [
    {
      settingKey: 'hopemClientId',
      settingTest: (hopemClientId: string | number) => typeof hopemClientId === 'string' && hopemClientId?.length > 0,
    },
  ])
}

export const isManagingCompanyHapiHopem = (
  managingCompany?: {
    syncServiceSettings?: { mappedService?: { serviceName?: string } | null; setting?: any }[] | null
  } | null,
) => {
  return isManagingCompanySpecificThirdParty(managingCompany, THIRD_PARTY_SERVICE_NAMES.HAPI_HOPEM, [
    {
      settingKey: 'key',
      settingTest: (key: string | number) => typeof key === 'string' && key?.length > 0,
    },
  ])
}

export const isManagingCompanyCMApi = (managingCompany: {
  syncServiceSettings: { mappedService: { serviceName: string }; setting: any }[]
}) => {
  return isManagingCompanySpecificThirdParty(managingCompany, THIRD_PARTY_SERVICE_NAMES.CONDO_MANAGER_API, [
    {
      settingKey: 'condoManagerApiKey',
      settingTest: (condoManagerApiKey: string | number) =>
        typeof condoManagerApiKey === 'string' && condoManagerApiKey?.length > 0,
    },
  ])
}

export const isManagingCompanyCMLocal = (managingCompany: {
  syncServiceSettings: { mappedService: { serviceName: string }; setting: any }[]
}) => {
  return isManagingCompanySpecificThirdParty(managingCompany, THIRD_PARTY_SERVICE_NAMES.CONDO_MANAGER_LOCAL, [
    {
      settingKey: 'condoManagerId',
      settingTest: (condoManagerId: string | number) =>
        typeof condoManagerId === 'string' && condoManagerId?.length > 0,
    },
  ])
}

export const getSyncServiceSettingForServiceName = (
  syncServiceSettings: { mappedService: { serviceName: string }; setting: any }[],
  serviceName: string,
) => {
  if (syncServiceSettings?.length > 0) {
    return syncServiceSettings.find(
      (syncServiceSetting) => syncServiceSetting.mappedService?.serviceName === serviceName,
    )
  }
  return null
}

const allowedServicesVerification = {
  [THIRD_PARTY_SERVICE_NAMES.CONDO_MANAGER_API]: (
    serviceSettings: Api.GetMappedServiceSettingsForResidentWebQuery['mappedServiceSettingsResident'],
  ) => !!serviceSettings.config.condoManagerId && serviceSettings.importEnabled === true,
  [THIRD_PARTY_SERVICE_NAMES.CONDO_MANAGER_LOCAL]: (
    serviceSettings: Api.GetMappedServiceSettingsForResidentWebQuery['mappedServiceSettingsResident'],
  ) => !!serviceSettings.config.condoManagerId && serviceSettings.importEnabled === true,
  [THIRD_PARTY_SERVICE_NAMES.HOPEM]: (
    serviceSettings: Api.GetMappedServiceSettingsForResidentWebQuery['mappedServiceSettingsResident'],
  ) =>
    !!serviceSettings.config.hopemCompanyId &&
    !!serviceSettings.config.hopemBuildingId &&
    serviceSettings.importEnabled === true,
}

export const isEmailDisabledBySync = (
  serviceSettings: Api.GetMappedServiceSettingsForResidentWebQuery['mappedServiceSettingsResident'] | null | undefined,
) => {
  if (serviceSettings && serviceSettings?.mappedService?.serviceName != null) {
    return (
      (serviceSettings.mappedService.serviceName === THIRD_PARTY_SERVICE_NAMES.HOPEM ||
        serviceSettings.mappedService.serviceName === THIRD_PARTY_SERVICE_NAMES.CONDO_MANAGER_API ||
        serviceSettings.mappedService.serviceName === THIRD_PARTY_SERVICE_NAMES.CONDO_MANAGER_LOCAL) &&
      allowedServicesVerification[serviceSettings.mappedService.serviceName](serviceSettings)
    )
  }

  return false
}
