import { RolePermissions } from '@walter/shared'
import { LoaderFunction, redirect } from 'react-router-dom'
import { getCurrentUser } from './api'
import { handleApolloAuthError } from './handleApolloAuthError'

type PermissionModule = Parameters<typeof RolePermissions.userCanSeeModule>[1]

async function verifyHasViewPermission(module: PermissionModule) {
  const currentUser = await getCurrentUser()
  return currentUser ? RolePermissions.userCanSeeModule(currentUser, module) ?? false : false
}

export function viewPermissionLoader(module: PermissionModule): LoaderFunction<any> {
  return async () => {
    try {
      const hasPermission = await verifyHasViewPermission(module)
      if (!hasPermission) {
        throw new Error('Not Permitted')
      }
      return null
    } catch (err) {
      handleApolloAuthError(err)
      if (err instanceof Error && err.message === 'Not Permitted') {
        throw redirect('/')
      }
      console.error('Error:', 'viewPermissionLoader.ts', err)
      return null
    }
  }
}
