import * as React from 'react'
import styled, { css } from 'styled-components'
import { stripUnit } from 'polished'
import { Colors, Spacing, Types } from '@walter/shared'
import { typography, globalStyles } from '@walter/shared-web'
import { rotate } from '../../styles/animations'
import { ErrorMessage } from '../ErrorMessage'
import { Icon } from '../Icon'
import { Label } from '../Label'

const Container = styled.div`
  display: block;
  width: 100%;
`

const Wrap = styled.div<{ disabled: boolean }>`
  position: relative;
  border-radius: ${globalStyles.borderRadius};

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      user-select: none;
      opacity: 0.5; /* Overwrite iOS styles */
    `}
`

const Select = styled.select<{ isSmall?: boolean; error: string | boolean; touched: boolean }>`
  display: block;
  height: ${globalStyles.inputHeight};
  border-radius: ${globalStyles.borderRadius};
  background-color: ${Colors.white};
  width: 100%;
  resize: none;
  color: ${Colors.grey};
  outline: 0;
  padding: 0 ${Spacing.medium};
  padding-right: 48px;
  cursor: pointer;
  box-shadow: inset 0 0 0 1px ${Colors.borderColor};
  transition: box-shadow ${globalStyles.animationTime} ${globalStyles.animationCurve};

  ${(props) =>
    props.isSmall &&
    css`
      height: ${globalStyles.inputHeightSmall};
      padding-left: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
      padding-right: ${Spacing.xLarge};
      font-size: ${typography.fontSizes.small};
    `}

  ${(props) =>
    props.error &&
    props.touched &&
    `
    box-shadow: inset 0 0 0 1px ${Colors.red}, 0 0 0 1px ${Colors.red};
  `}

  &:focus {
    background-color: ${Colors.white};
    box-shadow: inset 0 0 0 1px ${Colors.primaryColor}, 0 0 0 1px ${Colors.primaryColor};
  }
`

const IconWrap = styled.div<{ size: string }>`
  display: flex;
  position: absolute;
  top: 50%;
  right: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
  transform: translateY(-50%);
  pointer-events: none;
  opacity: 0.9;

  ${(props) =>
    props.size === 'small' &&
    css`
      right: ${Spacing.small};
    `}
`

const Hint = styled.span`
  margin-left: ${`${(stripUnit(Spacing.tiny) as number) * 0.5}px`};
`

const LoadingWrap = styled.div`
  position: absolute;
  right: 2px;
  top: 0;
  margin-left: ${`${(stripUnit(Spacing.tiny) as number) * 0.5}px`};
  color: ${Colors.primaryColor};
  animation: ${rotate} 1s linear infinite;
`

type OptionValue = string | number

type DropdownProps<T extends OptionValue> = {
  dataTestId?: string
  options: Types.Option[]
  onChange?: (value: T) => void
  label?: string
  name?: string
  hint?: string
  size?: string
  onBlur?: (e: React.FocusEvent<HTMLSelectElement, Element>) => void
  onCopy?: (e: React.ClipboardEvent<HTMLSelectElement>) => void
  value?: T
  placeholder?: string
  disabled?: boolean
  required?: boolean
  selectProps?: React.ComponentPropsWithoutRef<'select'>
  meta?: {
    touched?: boolean
    error?: string
  }
  placeholderIsValid?: boolean
  isLoading?: boolean
  defaultValue?: string
}

export function Dropdown<T extends OptionValue>({
  dataTestId,
  options,
  onChange,
  onBlur,
  value,
  selectProps,
  meta = {},
  label,
  size = 'default',
  hint,
  name,
  isLoading,
  disabled,
  onCopy,
  ...rest
}: DropdownProps<T>) {
  function handleOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const { selectedIndex } = e.currentTarget
    const selectedOption = options[selectedIndex]
    if (onChange) {
      onChange(selectedOption?.value)
    }
  }

  return (
    <Container {...rest}>
      {label && (
        <Label>
          {label}
          {isLoading && (
            <LoadingWrap>
              <Icon size="small" icon="loading" />
            </LoadingWrap>
          )}
          {hint && <Hint data-tip={hint}>(?)</Hint>}
        </Label>
      )}
      <Wrap disabled={!!disabled}>
        <Select
          {...selectProps}
          error={!!meta.error}
          touched={!!meta.touched}
          data-test-id={dataTestId}
          data-cy={label}
          onChange={handleOnChange}
          onBlur={onBlur}
          name={name}
          isSmall={size === 'small'}
          value={value}
          disabled={disabled}
          onCopy={onCopy}
        >
          {options.map((opt, i) => {
            return (
              <option key={i} value={opt.value}>
                {opt.label}
              </option>
            )
          })}
        </Select>
        <IconWrap size={size}>
          <Icon icon="dropdown" />
        </IconWrap>
      </Wrap>
      {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
    </Container>
  )
}

Dropdown.displayName = 'Dropdown'
