import { useApolloClient } from '@apollo/client'
import { Api } from '@walter/shared'
import React from 'react'
import AppContext from './App'

type PendingActionsContextType = {
  refetchPendingBookingsForProject: (projectId: string) => Promise<void>
  pendingBookingsPerProjects: any
}
const PendingActionsContext = React.createContext({} as PendingActionsContextType)

export default PendingActionsContext

export function PendingActionsProvider({ children }: { children: React.ReactNode }) {
  const client = useApolloClient()
  const { currentManagingCompanyProjectIds } = React.useContext(AppContext)

  const [pendingBookingsPerProjects, setPendingBookingsPerProjects] = React.useState({})

  const { data: { getNumberOfPendingBookingsForProjects: numberOfPendingBooking } = {} } =
    Api.useGetNumberOfPendingBookingsForProjectsManagerWebQuery({
      skip: !currentManagingCompanyProjectIds || currentManagingCompanyProjectIds?.length === 0,
      variables: { projectIds: currentManagingCompanyProjectIds },
    })

  React.useEffect(() => {
    if (numberOfPendingBooking) {
      setPendingBookingsPerProjects(
        numberOfPendingBooking.projects.reduce(
          (acc, project) => ({
            ...acc,
            [project.id]: project.count,
          }),
          {},
        ),
      )
    }
  }, [numberOfPendingBooking])

  async function refetchPendingBookingsForProject(projectId: string) {
    const {
      data: { getNumberOfPendingBookingsForProject: numberOfBooking },
    } = await client.query<Api.GetNumberOfPendingBookingsForProjectManagerWebQuery>({
      query: Api.GetNumberOfPendingBookingsForProjectsManagerWebDocument,
      variables: { projectId },
    })

    setPendingBookingsPerProjects({
      ...pendingBookingsPerProjects,
      [projectId]: numberOfBooking,
    })
  }

  return (
    <PendingActionsContext.Provider value={{ pendingBookingsPerProjects, refetchPendingBookingsForProject }}>
      {children}
    </PendingActionsContext.Provider>
  )
}
