import { Colors, Spacing } from '@walter/shared'
import { IconButton, NavBuilding, Popover, PopoverContext, PopoverPosition, t } from '@walter/shared-web'
import orderBy from 'lodash/orderBy'
import take from 'lodash/take'
import { stripUnit } from 'polished'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import AppContext from '../../../contexts/App'
import BuildingNav from '../BuildingNav'
import { MENUS } from '../Menu'
import Menu from './Menu'

const Container = styled.div`
  display: flex;
`

const Divider = styled.div`
  width: 1px;
  height: ${Spacing.xLarge};
  background-color: ${Colors.borderColor};
  margin: 0 ${Number(stripUnit(Spacing.small)) * 1.5 + 'px'};
`

const List = styled.div`
  display: flex;
  > * {
    margin-right: ${Spacing.small};
  }
`

const More = styled.div`
  display: flex;
  position: relative;
`

const NUM_OF_PROJECTS = 6

export default function ProjectSelector() {
  const navigate = useNavigate()
  const { projectId = '' } = useParams<{ projectId?: string }>()
  const { currentManagingCompanyProjects, isAllProjects, moduleSlug } = React.useContext(AppContext)
  const { setPopoverVisible: setPopoverContentVisible } = React.useContext(PopoverContext)
  const previousOrderedProjects = React.useRef<typeof currentManagingCompanyProjects>([])

  const projects = React.useMemo(() => {
    const alphabeticallyOrdered = orderBy(currentManagingCompanyProjects, ['name'], ['asc'])

    const previousProjects =
      previousOrderedProjects.current.length === 0 ? alphabeticallyOrdered : previousOrderedProjects.current

    const selectedProjectIndex = previousProjects.findIndex((proj) => proj.id === projectId)

    const isFirstSix = selectedProjectIndex < NUM_OF_PROJECTS

    const noProjectsSelected = selectedProjectIndex === -1

    if (isFirstSix || noProjectsSelected) {
      previousOrderedProjects.current = previousProjects
      return previousProjects
    }

    const alphabeticallyOrderedWithSelectedLast = moveItem(previousProjects, selectedProjectIndex, NUM_OF_PROJECTS - 1)

    previousOrderedProjects.current = alphabeticallyOrderedWithSelectedLast
    return alphabeticallyOrderedWithSelectedLast
  }, [currentManagingCompanyProjects, projectId])

  const handleProjectClick = (id: string) => {
    setPopoverContentVisible(false)
    if (!moduleSlug) {
      navigate(`/${id}`)
    } else {
      navigate(`/${id}/${moduleSlug}`)
    }
  }

  function handleClickAllProjects() {
    const menu = MENUS.find(({ slug }) => slug === moduleSlug)
    if (!moduleSlug || !menu?.availableInAllProjects) {
      navigate(`/`)
    } else {
      navigate(`/all/${moduleSlug}`)
    }
  }

  return (
    <Container data-test-id="ProjectSelector_Container">
      <NavBuilding
        dataTestId="AllProjects"
        slug="all"
        name={t('all-projects')}
        placeholder
        handleClick={handleClickAllProjects}
        isActive={isAllProjects}
      />
      <Divider />

      <List data-test-id="Projects_List">
        {take(projects, NUM_OF_PROJECTS).map((project) => {
          if (!project) {
            return null
          }
          return <BuildingNav currentProjectIdPathname={projectId} key={project?.id} project={project} />
        })}
      </List>

      <More data-test-id="More_Button">
        <Popover
          dataTestId="More_Popover"
          trigger={({ onClick }, forwardRef) => (
            <IconButton
              size="small"
              icon="disclosure"
              data-tip={t('view-more-projects')}
              ref={forwardRef as React.Ref<HTMLButtonElement>}
              onClick={onClick}
            />
          )}
          position={PopoverPosition.BOTTOM}
        >
          <Menu projects={projects} onProjectClick={handleProjectClick} />
        </Popover>
      </More>
    </Container>
  )
}

function moveItem<T>(array: T[], fromIndex: number, toIndex: number): T[] {
  const result = [...array]

  const [movedItem] = result.splice(fromIndex, 1)

  result.splice(toIndex, 0, movedItem)

  return result
}
