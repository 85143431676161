import dayjs from 'dayjs'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { Maybe } from 'purify-ts/Maybe'

export enum SESSION_REFRESH_STATUS_TYPE {
  NO_TOKEN = 'NO_TOKEN',
  SHOULD_REFRESH = 'SHOULD_REFRESH',
  EXPIRED = 'EXPIRED',
  VALID = 'VALID',
}

export function getApolloSessionRefreshStatus(token: string, refreshToken: string): SESSION_REFRESH_STATUS_TYPE {
  const SESSION_REFRESH_TRIGGER_DELTA_IN_SECONDS = 60 * 120 // 120 minutes

  if (Maybe.fromFalsy(token).isNothing()) {
    return SESSION_REFRESH_STATUS_TYPE.NO_TOKEN
  }

  // For some special cases like resident self removal where we don't have a session refresh token at all so we skip checking if we should attempt to update the token
  if (Maybe.fromFalsy(refreshToken).isNothing()) {
    return SESSION_REFRESH_STATUS_TYPE.VALID
  }

  const decodedToken = Maybe.fromNullable(jwtDecode<JwtPayload>(token))
  const decodedRefreshToken = Maybe.fromNullable(jwtDecode<JwtPayload>(refreshToken))
  const shouldValidateIfSessionIsExpired =
    decodedToken.isJust() &&
    decodedRefreshToken.isJust() &&
    decodedToken.extractNullable().exp != null &&
    decodedRefreshToken.extractNullable().exp != null

  if (shouldValidateIfSessionIsExpired) {
    const currentUnixTime = dayjs(new Date()).unix()

    const sessionShouldBeRefreshed =
      (decodedToken.extract().exp ?? 0) < currentUnixTime + SESSION_REFRESH_TRIGGER_DELTA_IN_SECONDS &&
      (decodedToken.extract().exp ?? 0) > currentUnixTime &&
      (decodedRefreshToken.extract().exp ?? 0) > currentUnixTime
    const sessionIsExpired = (decodedToken.extract().exp ?? 0) < currentUnixTime

    if (sessionShouldBeRefreshed && !sessionIsExpired) {
      return SESSION_REFRESH_STATUS_TYPE.SHOULD_REFRESH
    } else if (sessionIsExpired) {
      return SESSION_REFRESH_STATUS_TYPE.EXPIRED
    }
  }

  return SESSION_REFRESH_STATUS_TYPE.VALID
}
