import jwtDecode, { JwtPayload } from 'jwt-decode'
import { useState } from 'react'
import { WebDateUtils } from '../utils'

export function useWebAuth() {
  const [token, setToken] = useState<string>(localStorage.getItem('token') || '')

  function login(token: string, refreshToken?: string | null) {
    localStorage.setItem('token', token)
    // some pages don't require refresh tokens such as self resident removal in which case we don't want to enter any values in localStorage (setting will use a 'null' string)
    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken)
    }
    setToken(token)
  }

  function register(token: string, refreshToken: string) {
    localStorage.setItem('token', token)
    localStorage.setItem('refreshToken', refreshToken)
    setToken(token)
  }

  function logout() {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    setToken('')
  }

  function updateTokenStateFromLocalStorage() {
    const localStorageToken = localStorage.getItem('token')
    setToken(localStorageToken ?? '')
  }

  function isSessionExpired() {
    if (token) {
      const currentUnixTime = WebDateUtils.getUnixTime(new Date())
      const decodedToken = jwtDecode<JwtPayload>(token)
      if (decodedToken?.exp && decodedToken.exp < currentUnixTime) {
        return true
      }
    }

    return false
  }

  return {
    login,
    logout,
    register,
    token,
    updateTokenStateFromLocalStorage,
    isSessionExpired,
  }
}
