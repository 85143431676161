import { LoaderFunctionArgs, redirect } from 'react-router-dom'
import { getProject } from './api'
import { handleApolloAuthError } from './handleApolloAuthError'

export async function isCondoProjectLoader(args: LoaderFunctionArgs<any>) {
  try {
    const projectId = args.params.projectId
    const currentProject = await getProject(projectId)
    if (currentProject?.isCondoProject) {
      return null
    }
    throw new Error('Not Permitted')
  } catch (err) {
    handleApolloAuthError(err)
    if (err instanceof Error && err.message === 'Not Permitted') {
      throw redirect('/')
    }
    console.error('Error:', 'isCondoProjectLoader.ts', err)
    return null
  }
}
