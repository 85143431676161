import React from 'react'
import styled, { css } from 'styled-components'
import { Spacing } from '@walter/shared'

const Container = styled.div<{ noTop?: boolean; noBottom?: boolean }>`
  padding: ${Spacing.large};
  ${(props) =>
    props.noTop &&
    css`
      padding-top: 0;
    `}
  ${(props) =>
    props.noBottom &&
    css`
      padding-bottom: 0;
    `}
`

type PaddedBlockProps = {
  children: React.ReactNode
  noTop?: boolean
  noBottom?: boolean
}

export const PaddedBlock = ({ noTop, noBottom, children }: PaddedBlockProps) => (
  <Container noTop={noTop} noBottom={noBottom}>
    {children}
  </Container>
)
