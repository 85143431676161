import React from 'react'
import styled from 'styled-components'
import { borderRadius, cover, postCoverImagePaddingTop } from '../../styles/global'

const CoverImageWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  overflow: hidden;
  border-radius: ${borderRadius};

  &:after {
    content: '';
    display: block;
    padding-bottom: ${postCoverImagePaddingTop};
  }

  img {
    ${cover('absolute')};
    object-fit: cover;
  }
`

type PostCoverImageProps = {
  url: string
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => any
}

export const PostCoverImage = ({ url, onClick }: PostCoverImageProps) => {
  if (!url) {
    return null
  }

  return (
    <CoverImageWrap onClick={onClick}>
      <img data-test-id="Content_CoverImage" src={url} />
    </CoverImageWrap>
  )
}
