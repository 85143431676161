import dayjs from 'dayjs'
import { DEFAULT_USAGE_DURATION_OPTIONS_MS } from '../constants/global'

export const MONTHS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]

export function getSecondsFromMs(ms: number) {
  return Math.floor(ms % 1000)
}

export function getMinutesFromMs(ms: number) {
  return Math.floor((ms / (1000 * 60)) % 60)
}

export function getHoursFromMs(ms: number) {
  return Math.floor((ms / (1000 * 60 * 60)) % 24)
}

export function getMillisecondsFromMsTime(ms: number) {
  return Math.floor((ms / (60 * 60 * 24)) % 1000)
}

export function getDaysFromMs(ms: number) {
  return Math.floor(ms / 1000 / 60 / 60 / 24)
}

export function getMsFromSeconds(seconds: number) {
  return seconds * 1000
}

export function getMsFromMinutes(minutes: number) {
  return minutes * 1000 * 60
}

export function getMsFromHours(hours: number) {
  return hours * 1000 * 60 * 60
}

export function getMsFromDays(days: number) {
  return days * 1000 * 60 * 60 * 24
}

export function getAllDaysOfCurrentMonth() {
  return new Array(dayjs().daysInMonth()).fill(null).map((x, i) => dayjs().startOf('month').add(i, 'days'))
}

export function getLabelFromDurationSeconds(durationSeconds: number, language: 'en' | 'fr' = 'en') {
  // Not using day.js humanize method. Doesnt allow for 45 min + switch from "an hour" to "2 hours"
  const selectedDurations = DEFAULT_USAGE_DURATION_OPTIONS_MS.filter((duration) => duration.value === durationSeconds)

  if (selectedDurations.length > 0) {
    return selectedDurations.map((duration) => duration.label[language] || duration.label['en'])[0]
  }

  return null
}
