import React from 'react'
import { Colors, Spacing } from '@walter/shared'
import styled from 'styled-components'

export const Container = styled.div`
  grid-area: conversations;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.white};
  border-right: 1px solid ${Colors.borderColor};
`

const ListWrap = styled.div`
  overflow-y: auto;
`

const HeaderWrap = styled.div`
  display: flex;
  padding: ${Spacing.medium};
  border-bottom: 1px solid ${Colors.borderColor};
`

type ConversationsProps = {
  Header?: React.ReactNode
  List: React.ReactNode
}

export function Conversations({ Header, List }: ConversationsProps) {
  return (
    <Container data-test-id="Conversations_Container">
      {!!Header && <HeaderWrap>{Header}</HeaderWrap>}
      <ListWrap data-test-id="Conversations_List">{List}</ListWrap>
    </Container>
  )
}
