import { getTexts } from '../utils/localizations'

export const TAXE_GST = 0.05
export const TAXE_QST = 0.09975
export const TOTAL_TAXES = TAXE_GST + TAXE_QST
export const CREDIT_CARD_CHARGE = 0.05

export const DEBUG_PHONE_NUMBER = '438-700-1558'
export const SUPPORT_EMAIL = 'support@usewalter.com'

export const TRANSITION_LENGTH = 300

const ONE_HOUR_MS = 1000 * 60 * 60
const ONE_HOUR_SECONDS = 3600

export const DEFAULT_USAGE_DURATION_OPTIONS_MS = [
  { value: 3600 / 4, label: { en: '15 minutes', fr: '15 minutes' } },
  { value: 3600 / 2, label: { en: '30 minutes', fr: '30 minutes' } },
  { value: (3600 * 3) / 4, label: { en: '45 minutes', fr: '45 minutes' } },
  { value: 3600, label: { en: '1 hour', fr: '1 heure' } },
  { value: 2 * 3600, label: { en: '2 hours', fr: '2 heures' } },
  { value: 3 * 3600, label: { en: '3 hours', fr: '3 heures' } },
  { value: 4 * 3600, label: { en: '4 hours', fr: '4 heures' } },
  { value: 5 * 3600, label: { en: '5 hours', fr: '5 heures' } },
  { value: 6 * 3600, label: { en: '6 hours', fr: '6 heures' } },
  { value: 7 * 3600, label: { en: '7 hours', fr: '7 heures' } },
  { value: 8 * 3600, label: { en: '8 hours', fr: '8 heures' } },
  { value: 9 * 3600, label: { en: '9 hours', fr: '9 heures' } },
  { value: 10 * 3600, label: { en: '10 hours', fr: '10 heures' } },
  { value: 11 * 3600, label: { en: '11 hours', fr: '11 heures' } },
  { value: 12 * 3600, label: { en: '12 hours', fr: '12 heures' } },
  { value: 13 * 3600, label: { en: '13 hours', fr: '13 heures' } },
  { value: 14 * 3600, label: { en: '14 hours', fr: '14 heures' } },
  { value: 15 * 3600, label: { en: '15 hours', fr: '15 heures' } },
  { value: 16 * 3600, label: { en: '16 hours', fr: '16 heures' } },
  { value: 17 * 3600, label: { en: '17 hours', fr: '17 heures' } },
  { value: 18 * 3600, label: { en: '18 hours', fr: '18 heures' } },
  { value: 19 * 3600, label: { en: '19 hours', fr: '19 heures' } },
  { value: 20 * 3600, label: { en: '20 hours', fr: '20 heures' } },
  { value: 21 * 3600, label: { en: '21 hours', fr: '21 heures' } },
  { value: 22 * 3600, label: { en: '22 hours', fr: '22 heures' } },
  { value: 23 * 3600, label: { en: '23 hours', fr: '23 heures' } },
  { value: 24 * 3600, label: { en: '24 hours', fr: '24 heures' } },
  // DAYS
  { value: 24 * 3600, label: { en: '1 day', fr: '1 jour' } },
  { value: 2 * 24 * 3600, label: { en: '2 days', fr: '2 jours' } },
  { value: 3 * 24 * 3600, label: { en: '3 days', fr: '3 jours' } },
  { value: 4 * 24 * 3600, label: { en: '4 days', fr: '4 jours' } },
  { value: 5 * 24 * 3600, label: { en: '5 days', fr: '5 jours' } },
  { value: 6 * 24 * 3600, label: { en: '6 days', fr: '6 jours' } },
  { value: 7 * 24 * 3600, label: { en: '7 days', fr: '7 jours' } },
  { value: 8 * 24 * 3600, label: { en: '8 days', fr: '8 jours' } },
  { value: 9 * 24 * 3600, label: { en: '9 days', fr: '9 jours' } },
  { value: 10 * 24 * 3600, label: { en: '10 days', fr: '10 jours' } },
  { value: 11 * 24 * 3600, label: { en: '11 days', fr: '11 jours' } },
  { value: 12 * 24 * 3600, label: { en: '12 days', fr: '12 jours' } },
  { value: 13 * 24 * 3600, label: { en: '13 days', fr: '13 jours' } },
  { value: 14 * 24 * 3600, label: { en: '14 days', fr: '14 jours' } },
  { value: 15 * 24 * 3600, label: { en: '15 days', fr: '15 jours' } },
  { value: 16 * 24 * 3600, label: { en: '16 days', fr: '16 jours' } },
  { value: 17 * 24 * 3600, label: { en: '17 days', fr: '17 jours' } },
  { value: 18 * 24 * 3600, label: { en: '18 days', fr: '18 jours' } },
  { value: 19 * 24 * 3600, label: { en: '19 days', fr: '19 jours' } },
  { value: 20 * 24 * 3600, label: { en: '20 days', fr: '20 jours' } },
  { value: 21 * 24 * 3600, label: { en: '21 days', fr: '21 jours' } },
  { value: 22 * 24 * 3600, label: { en: '22 days', fr: '22 jours' } },
  { value: 23 * 24 * 3600, label: { en: '23 days', fr: '23 jours' } },
  { value: 24 * 24 * 3600, label: { en: '24 days', fr: '24 jours' } },
  { value: 25 * 24 * 3600, label: { en: '25 days', fr: '25 jours' } },
  { value: 26 * 24 * 3600, label: { en: '26 days', fr: '26 jours' } },
  { value: 27 * 24 * 3600, label: { en: '27 days', fr: '27 jours' } },
  { value: 28 * 24 * 3600, label: { en: '28 days', fr: '28 jours' } },
  { value: 29 * 24 * 3600, label: { en: '29 days', fr: '29 jours' } },
  { value: 30 * 24 * 3600, label: { en: '30 days', fr: '30 jours' } },
  { value: 1 * 24 * 3600 * 30.5, label: { en: '1 months', fr: '1 mois' } },
  { value: 2 * 24 * 3600 * 30.5, label: { en: '2 months', fr: '2 mois' } },
  { value: 3 * 24 * 3600 * 30.5, label: { en: '3 months', fr: '3 mois' } },
  { value: 4 * 24 * 3600 * 30.5, label: { en: '4 months', fr: '4 mois' } },
  { value: 5 * 24 * 3600 * 30.5, label: { en: '5 months', fr: '5 mois' } },
  { value: 6 * 24 * 3600 * 30.5, label: { en: '6 months', fr: '6 mois' } },
  { value: 7 * 24 * 3600 * 30.5, label: { en: '7 months', fr: '7 mois' } },
  { value: 8 * 24 * 3600 * 30.5, label: { en: '8 months', fr: '8 mois' } },
  { value: 9 * 24 * 3600 * 30.5, label: { en: '9 months', fr: '9 mois' } },
  { value: 10 * 24 * 3600 * 30.5, label: { en: '10 months', fr: '10 mois' } },
  { value: 11 * 24 * 3600 * 30.5, label: { en: '11 months', fr: '11 mois' } },
  { value: 12 * 24 * 3600 * 30.5, label: { en: '12 months', fr: '12 mois' } },
  { value: 13 * 24 * 3600 * 30.5, label: { en: '13 months', fr: '13 mois' } },
  { value: 14 * 24 * 3600 * 30.5, label: { en: '14 months', fr: '14 mois' } },
  { value: 15 * 24 * 3600 * 30.5, label: { en: '15 months', fr: '15 mois' } },
  { value: 16 * 24 * 3600 * 30.5, label: { en: '16 months', fr: '16 mois' } },
  { value: 17 * 24 * 3600 * 30.5, label: { en: '17 months', fr: '17 mois' } },
  { value: 18 * 24 * 3600 * 30.5, label: { en: '18 months', fr: '18 mois' } },
  { value: 19 * 24 * 3600 * 30.5, label: { en: '19 months', fr: '19 mois' } },
  { value: 20 * 24 * 3600 * 30.5, label: { en: '20 months', fr: '20 mois' } },
  { value: 21 * 24 * 3600 * 30.5, label: { en: '21 months', fr: '21 mois' } },
  { value: 22 * 24 * 3600 * 30.5, label: { en: '22 months', fr: '22 mois' } },
  { value: 23 * 24 * 3600 * 30.5, label: { en: '23 months', fr: '23 mois' } },
  { value: 24 * 24 * 3600 * 30.5, label: { en: '24 months', fr: '24 mois' } },
]

export const DURATIONS_OPTIONS_12_HOURS_MS = (languageCode: 'en' | 'fr') => [
  {
    value: ONE_HOUR_SECONDS / 4,
    label: replaceInMaybeString(getTexts(languageCode, 'minutes-amount-lowercase'), '[[minutesAmount]]', '15'),
  },
  {
    value: ONE_HOUR_SECONDS / 2,
    label: replaceInMaybeString(getTexts(languageCode, 'minutes-amount-lowercase'), '[[minutesAmount]]', '30'),
  },
  {
    value: (ONE_HOUR_SECONDS / 4) * 3,
    label: replaceInMaybeString(getTexts(languageCode, 'minutes-amount-lowercase'), '[[minutesAmount]]', '45'),
  },
  {
    value: ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '1'),
  },
  {
    value: 2 * ONE_HOUR_MS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '2'),
  },
  {
    value: 3 * ONE_HOUR_MS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '3'),
  },
  {
    value: 4 * ONE_HOUR_MS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '4'),
  },
  {
    value: 5 * ONE_HOUR_MS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '5'),
  },
  {
    value: 6 * ONE_HOUR_MS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '6'),
  },
  {
    value: 7 * ONE_HOUR_MS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '7'),
  },
  {
    value: 8 * ONE_HOUR_MS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '8'),
  },
  {
    value: 9 * ONE_HOUR_MS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '9'),
  },
  {
    value: 10 * ONE_HOUR_MS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '10'),
  },
  {
    value: 11 * ONE_HOUR_MS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '11'),
  },
  {
    value: 12 * ONE_HOUR_MS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '12'),
  },
]

export const DURATIONS_OPTIONS_24_HOURS_SECONDS = (languageCode: 'fr' | 'en') => [
  {
    value: ONE_HOUR_SECONDS / 4,
    label: replaceInMaybeString(getTexts(languageCode, 'minutes-amount-lowercase'), '[[minutesAmount]]', '15'),
  },
  {
    value: ONE_HOUR_SECONDS / 2,
    label: replaceInMaybeString(getTexts(languageCode, 'minutes-amount-lowercase'), '[[minutesAmount]]', '30'),
  },
  {
    value: (ONE_HOUR_SECONDS / 4) * 3,
    label: replaceInMaybeString(getTexts(languageCode, 'minutes-amount-lowercase'), '[[minutesAmount]]', '45'),
  },
  {
    value: ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hour-amount-lowercase'), '[[hourAmount]]', '1'),
  },
  {
    value: 2 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '2'),
  },
  {
    value: 3 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '3'),
  },
  {
    value: 4 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '4'),
  },
  {
    value: 5 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '5'),
  },
  {
    value: 6 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '6'),
  },
  {
    value: 7 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '7'),
  },
  {
    value: 8 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '8'),
  },
  {
    value: 9 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '9'),
  },
  {
    value: 10 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '10'),
  },
  {
    value: 11 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '11'),
  },
  {
    value: 12 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '12'),
  },
  {
    value: 13 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '13'),
  },
  {
    value: 14 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '14'),
  },
  {
    value: 15 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '15'),
  },
  {
    value: 16 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '16'),
  },
  {
    value: 17 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '17'),
  },
  {
    value: 18 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '18'),
  },
  {
    value: 19 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '19'),
  },
  {
    value: 20 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '20'),
  },
  {
    value: 21 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '21'),
  },
  {
    value: 22 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '22'),
  },
  {
    value: 23 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'hours-amount-lowercase'), '[[hoursAmount]]', '23'),
  },
]

export const DURATIONS_OPTIONS_1_MONTH_SECONDS = (languageCode: 'fr' | 'en') => [
  ...DURATIONS_OPTIONS_24_HOURS_SECONDS(languageCode),
  {
    value: 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'day-amount-lowercase'), '[[dayAmount]]', '1'),
  },
  {
    value: 2 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '2'),
  },
  {
    value: 3 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '3'),
  },
  {
    value: 4 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '4'),
  },
  {
    value: 5 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '5'),
  },
  {
    value: 6 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '6'),
  },
  {
    value: 7 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '7'),
  },
  {
    value: 8 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '8'),
  },
  {
    value: 9 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '9'),
  },
  {
    value: 10 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '10'),
  },
  {
    value: 11 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '11'),
  },
  {
    value: 12 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '12'),
  },
  {
    value: 13 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '13'),
  },
  {
    value: 14 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '14'),
  },
  {
    value: 15 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '15'),
  },
  {
    value: 16 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '16'),
  },
  {
    value: 17 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '17'),
  },
  {
    value: 18 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '18'),
  },
  {
    value: 19 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '19'),
  },
  {
    value: 20 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '20'),
  },
  {
    value: 21 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '21'),
  },
  {
    value: 22 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '22'),
  },
  {
    value: 23 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '23'),
  },
  {
    value: 24 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '24'),
  },
  {
    value: 25 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '25'),
  },
  {
    value: 26 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '26'),
  },
  {
    value: 27 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '27'),
  },
  {
    value: 28 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '28'),
  },
  {
    value: 29 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '29'),
  },
  {
    value: 30 * 24 * ONE_HOUR_SECONDS,
    label: replaceInMaybeString(getTexts(languageCode, 'days-amount-lowercase'), '[[daysAmount]]', '30'),
  },
]

export const DURATION_OPTIONS_2_YEARS_SECONDS = (languageCode: 'fr' | 'en') => [
  ...DURATIONS_OPTIONS_1_MONTH_SECONDS(languageCode),
  // Months
  {
    value: 1 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'month-amount-lowercase'), '[[monthAmount]]', '1'),
  },
  {
    value: 2 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '2'),
  },
  {
    value: 3 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '3'),
  },
  {
    value: 4 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '4'),
  },
  {
    value: 5 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '5'),
  },
  {
    value: 6 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '6'),
  },
  {
    value: 7 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '7'),
  },
  {
    value: 8 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '8'),
  },
  {
    value: 9 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '9'),
  },
  {
    value: 10 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '10'),
  },
  {
    value: 11 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '11'),
  },
  {
    value: 12 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '12'),
  },
  {
    value: 13 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '13'),
  },
  {
    value: 14 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '14'),
  },
  {
    value: 15 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '15'),
  },
  {
    value: 16 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '16'),
  },
  {
    value: 17 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '17'),
  },
  {
    value: 18 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '18'),
  },
  {
    value: 19 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '19'),
  },
  {
    value: 20 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '20'),
  },
  {
    value: 21 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '21'),
  },
  {
    value: 22 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '22'),
  },
  {
    value: 23 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '23'),
  },
  {
    value: 24 * 24 * ONE_HOUR_SECONDS * 30.5,
    label: replaceInMaybeString(getTexts(languageCode, 'months-amount-lowercase'), '[[monthsAmount]]', '24'),
  },
]

function replaceInMaybeString(str: unknown, toBeReplaced: string, replacer: string) {
  if (typeof str === 'string') {
    return str.replace(toBeReplaced, replacer)
  }
  return ''
}
