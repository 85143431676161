import { Colors, Spacing } from '@walter/shared'
import { rgba } from 'polished'
import React from 'react'
import styled, { css } from 'styled-components'
import { animationCurve, animationTime, borderRadius, boxShadow } from '../../styles/global'
import { fontSizes } from '../../styles/typography'
import { ButtonProps } from '../Button'
import { Icon } from '../Icon'

const Container = styled.div<{ customWidth?: number }>`
  position: relative;
  width: 160px;
  border-radius: ${borderRadius};
  background-color: ${Colors.white};
  border: 1px solid ${Colors.borderColor};
  padding: ${Spacing.small};
  ${boxShadow};

  ${(props) =>
    props.customWidth &&
    css`
      width: ${props.customWidth}px;
    `}
`

const Action = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  text-align: left;
  font-size: ${fontSizes.small};
  border-radius: ${borderRadius};
  padding: ${Spacing.tiny} ${Spacing.small};
  transition: color ${animationTime} ${animationCurve}, background-color ${animationTime} ${animationCurve};

  &:hover {
    color: ${Colors.greyDark};
    background-color: ${rgba(Colors.borderColor, 0.25)};
  }

  & + & {
    margin-top: ${Spacing.tiny};
  }
`

const IconWrap = styled.div`
  display: flex;
  margin-right: ${Spacing.small};
  opacity: 0.9;
`

export type PopoverAction = ButtonProps & { label: string; href?: 'a' | 'button' | string; dataTestId?: string }

type PopoverMenuProps = {
  actions: PopoverAction[]
  customWidth?: number
}

export const PopoverMenu = ({ actions, customWidth }: PopoverMenuProps) => (
  <Container customWidth={customWidth}>
    {actions.map((action, i) => (
      <Action
        key={i}
        as={action.href ? 'a' : 'button'}
        data-cy={action?.testID || 'testid'}
        {...action}
        data-test-id={action.dataTestId ? action.dataTestId : action.label}
      >
        {action.icon && (
          <IconWrap>
            <Icon icon={action.icon} size="small" />
          </IconWrap>
        )}
        {action.children}
        {action.label}
      </Action>
    ))}
  </Container>
)
