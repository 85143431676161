import React from 'react'
import styled, { css } from 'styled-components'
import { Colors, Spacing } from '@walter/shared'
import { rgba } from 'polished'
import { SmallCaps } from '../SmallCaps'

const SectionContainer = styled.section<{ isSubdued: boolean; isNotPadded: boolean }>`
  padding: ${Spacing.large};
  white-space: pre-wrap; /* So that the text is formatted correctly */

  ${(props) =>
    props.isSubdued &&
    css`
      background-color: ${rgba(Colors.offWhite, 0.5)};
    `}

  ${(props) =>
    props.isNotPadded &&
    css`
      padding: 0;
    `}
  
  & + & {
    border-top: 1px solid ${Colors.borderColor};
  }
`

const SectionHeading = styled(SmallCaps)`
  display: block;
  margin-bottom: ${Spacing.small};
  color: ${Colors.greyDark};
`

type CardSectionProps = {
  dataTestId?: string
  heading?: string
  isSubdued?: boolean
  children: React.ReactNode
  isNotPadded?: boolean
}

export const CardSection = ({
  dataTestId,
  heading,
  children,
  isSubdued = false,
  isNotPadded = false,
}: CardSectionProps) => (
  <SectionContainer data-test-id={`${dataTestId}_Card_Section`} isSubdued={isSubdued} isNotPadded={isNotPadded}>
    {heading && (
      <SectionHeading data-test-id={`${dataTestId}_Card_Title`} data-cy={`card-heading-${heading}`}>
        {heading}
      </SectionHeading>
    )}
    {children}
  </SectionContainer>
)
