import { i18n, t } from '../../../utils'
import { z } from 'zod'

export const TaskStatusi18nFields = {
  titleFr: t('form-label-title-french'),
  descriptionFr: t('description-french'),
  titleEn: t('form-label-title-english'),
  descriptionEn: t('description-english'),
  orderPosition: t('task-order-position'),
  actAsComplete: t('act-as-complete'),
}

export const taskStatusSchema = z.object({
  titleFr: z.string().nullable().optional(),
  titleEn: z.string().nullable().optional(),
  descriptionFr: z.string().nullable().optional(),
  descriptionEn: z.string().nullable().optional(),
  orderPosition: z
    .string()
    .transform((value) => parseInt(value))
    .or(z.number().positive())
    .nullable()
    .optional(),
  actAsComplete: z.boolean().optional(),
  managingCompany: z
    .object({
      id: z.string({
        required_error: i18n.t('input:is-required', { field: t('managing-company') }),
        invalid_type_error: i18n.t('input:is-required', { field: t('managing-company') }),
      }),
    })
    .optional(),
})

export type TaskStatusFormItem = z.infer<typeof taskStatusSchema>
