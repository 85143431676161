import React from 'react'
import styled from 'styled-components'
import { Spacing } from '@walter/shared'

const Container = styled.div`
  position: fixed;
  z-index: 602;
  top: ${Spacing.medium};
  right: ${Spacing.medium};
`

const Item = styled.div`
  padding: ${Spacing.small};

  &:last-child {
    margin-bottom: 0;
  }
`

type ToastGroupProps = {
  children: React.ReactNode
}

export const ToastGroup = ({ children }: ToastGroupProps) => {
  return (
    <Container data-cy="toast-container">
      {React.Children.map(
        children,
        (child, i) =>
          child && (
            <Item data-cy={`toast-children-${i}`} key={i}>
              {child}
            </Item>
          ),
      )}
    </Container>
  )
}
