import { LoaderFunctionArgs } from 'react-router-dom'
import { isSessionValid } from '../isSessionValid'
import { getCurrentUser } from './api'
import { handleApolloAuthError } from './handleApolloAuthError'

export async function authenticationLoader(args: LoaderFunctionArgs) {
  try {
    const path = args.params['*']
    localStorage.setItem('last-location', path ? `/${path}` : '/')

    const currentUser = await getCurrentUser()
    if (!currentUser) {
      throw new Error('Unauthenticated')
    }
    if (currentUser?.role === 'MANAGER' && currentUser?.managingCompany) {
      if (isSessionValid()) {
        return null
      }
      throw new Error('Session Expired', { cause: 'session-expired-please-login-again' })
    }
    throw new Error('Not Permitted', { cause: 'user-is-not-a-manager' })
  } catch (err) {
    handleApolloAuthError(err)
    console.error('Error:', 'authenticationLoader.ts', err)
    return null
  }
}
