import { Colors, Spacing } from '@walter/shared'
import React from 'react'
import { Components, EventProps, Formats, Calendar as RBCalendar, ToolbarProps } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import Skeleton from 'react-loading-skeleton'
import styled, { css } from 'styled-components'
import { borderRadius, boxShadow } from '../../styles/global'
import { WebDateUtils, getLanguage, t } from '../../utils'
import { DatePicker } from '../DatePicker'
import { Dropdown } from '../Dropdown'
import { Heading } from '../Heading'
import { Icon } from '../Icon'
import { Styles } from './styles'
import { CalendarView } from './types'
import { momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment-timezone'

moment.tz.setDefault('America/New_York')
moment.locale(getLanguage())

const localizer = momentLocalizer(moment)

interface ContainerProps {
  shouldFlex: boolean
  isLoading?: boolean
}
const Container = styled.div`
  ${(props: ContainerProps) => {
    return css`
      flex: 1;
      display: flex;
      position: relative;
      flex-direction: column;
      padding: ${Spacing.xLarge};
      background-color: ${Colors.white};
      border-radius: ${borderRadius};
      border: 1px solid ${Colors.borderColor};
      max-height: 850px;
      z-index: 0;
      ${boxShadow};
      opacity: ${props.isLoading ? 0.85 : 1};

      .rbc-calendar {
        flex: ${props.shouldFlex ? '1 !important' : 'none'};
      }
    `
  }}
`

type CalendarProps = {
  dataTestId?: string
  localizer?: any
  selectable?: boolean
  view: CalendarView
  isLoadingData?: boolean
  onViewChange?: (view: string) => void
  onNavigate?: React.Dispatch<React.SetStateAction<Date>>
  selectedData?: any
  events?: any
  onSelectEvent?: React.Dispatch<any>
  eventPropGetter?: (event: any) => {
    style: {
      backgroundColor: string
    }
  }
  onSelectSlot?: (data: any) => void
  date: Date
}

export const Calendar = ({ dataTestId, isLoadingData, view = 'month', onViewChange, ...rest }: CalendarProps) => {
  const language = getLanguage()
  const formats: Formats = {
    dayHeaderFormat: (date, _, localizer) => localizer?.format(date, 'LL', language) ?? new Date(date).toString(),
    dayRangeHeaderFormat: ({ start, end }, _, localizer) => {
      return language.startsWith('fr')
        ? localizer?.format(start, 'D', language) + ' au ' + localizer?.format(end, 'D MMMM', language)
        : localizer?.format(start, 'MMMM D', language) + ' — ' + localizer?.format(end, 'D', language)
    },
  }

  const shouldFlex = view === 'day' || view === 'week'
  return (
    <>
      <Styles />
      <Container
        data-test-id={`${dataTestId}_Calendar_Container`}
        data-cy="calendar-container"
        shouldFlex={!shouldFlex}
        isLoading={isLoadingData}
      >
        {isLoadingData && <Skeleton inline style={{ position: 'absolute', inset: 0, opacity: 0.8 }} />}

        <RBCalendar<any>
          {...rest}
          formats={formats}
          style={!shouldFlex ? {} : { height: '54vh', maxHeight: '750px' }}
          localizer={localizer}
          popup
          selectable
          step={30}
          timeslots={1}
          view={view}
          onView={onViewChange}
          components={components}
          scrollToTime={WebDateUtils.date().set('hour', 8).set('minute', 0).toDate()}
        />
      </Container>
    </>
  )
}

const components: Components<any, object> = {
  month: {
    event: (props: EventProps<any>) => {
      return <div data-test-id={`Calendar_Event_DayOfMonth_${props.event.start.getDate()}`}>{props.title}</div>
    },
  },
  toolbar(props: ToolbarProps) {
    return <Toolbar {...props} />
  },
}

function Toolbar(props: ToolbarProps) {
  const monthLabelLowerCase = props.label ? WebDateUtils.date(props.label).format('MMMM YYYY') : ''
  const monthLabelFirstCharUppercase = monthLabelLowerCase.charAt(0).toUpperCase() + monthLabelLowerCase.slice(1)
  return (
    <Root data-test-id="Calendar_Toolbar_Container">
      <Section data-test-id="Calendar_Toolbar_Left_Section">
        <div style={{ width: '100%', maxWidth: 200 }}>
          <Dropdown
            dataTestId="ViewOptions"
            value={props.view}
            onChange={(e) => props.onView(e)}
            options={CALENDAR_VIEW_OPTIONS}
          />
        </div>
      </Section>
      <Month data-test-id="Calendar_Toolbar_Middle_Section" size={3}>
        {props.view === 'month' ? monthLabelFirstCharUppercase : props.label}
      </Month>

      <Section data-test-id="Calendar_Toolbar_Right_Section" style={{ justifyContent: 'flex-end' }}>
        <ArrowButton onClick={() => props.onNavigate('PREV')}>
          <Icon icon={'left-chevron'} size={'small'} />
        </ArrowButton>
        <DatePicker
          dataTestId="Calendar_Toolbar_DatePicker"
          label=""
          onChange={(val) => props.onNavigate('DATE', val ?? undefined)}
          value={props.date}
          preventClose
          readOnly={false}
          side="right"
        />

        <ArrowButton onClick={() => props.onNavigate('NEXT')}>
          <Icon icon={'right-chevron'} size={'small'} />
        </ArrowButton>
      </Section>
    </Root>
  )
}

const CALENDAR_VIEW_OPTIONS = [
  {
    label: t('general:month'),
    value: 'month',
  },
  {
    label: t('general:week'),
    value: 'week',
  },
  {
    label: t('general:day'),
    value: 'day',
  },
]

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${Spacing.large};
`

const Month = styled(Heading)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${Spacing.large};
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
`

const Section = styled.div`
  width: 270px;
  display: flex;
  gap: ${Spacing.xTiny};
  alignitems: center;
  z-index: 50;
`
const ArrowButton = styled.button`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 #00000005;
  color: ${Colors.greyDark};
  background-color: ${Colors.white};
  border: 1px solid ${Colors.borderColor};
`
