import { Colors, Spacing } from '@walter/shared'
import styled from 'styled-components'
import { fontSizes } from '../../styles/typography'

export const ErrorMessage = styled.small`
  color: ${Colors.red};
  display: block;
  margin-top: ${Spacing.small};
  font-size: ${fontSizes.tiny};
  text-align: left;
`
