export type FileWithPreview = File & { preview: string }
export type WalterFile = { id: string; name: string; url: string }
type AnyFile = FileWithPreview | File

export function readFilesFromInput(acceptedFiles: File[]) {
  const filesRead: AnyFile[] = []
  for (let i = 0; i < acceptedFiles.length; i++) {
    const file = acceptedFiles[i]
    if (!file.type.startsWith('image/')) {
      filesRead.push(file)
      continue
    }
    // need to assign - spread operator is not cloning File properties
    filesRead.push(Object.assign(file, { preview: URL.createObjectURL(file) }))
  }
  return filesRead
}
