import { z } from 'zod'
import { i18n, t } from '../../utils'

export const Lockeri18nFields = {
  number: t('number'),
  cadastreNumber: t('cadastre-number'),
  owners: t('residents-uppercase'),
  properties: t('units'),
}

export const lockerSchema = z.object({
  number: z
    .string({ required_error: i18n.t('input:is-required', { field: Lockeri18nFields.number }) })
    .min(1, { message: i18n.t('input:is-required', { field: Lockeri18nFields.number }) }),
  cadastreNumber: z.string().optional().nullable(),
  owners: z
    .array(
      z.object({
        id: z.string({ required_error: i18n.t('input:is-required', { field: Lockeri18nFields.owners }) }),
      }),
    )
    .optional(),
  properties: z
    .array(
      z.object({
        id: z.string({ required_error: i18n.t('input:is-required', { field: Lockeri18nFields.properties }) }),
      }),
    )
    .optional(),
})

export type LockerItem = z.infer<typeof lockerSchema>
