import { Config } from '@walter/shared'
import { LoaderFunctionArgs, redirect } from 'react-router-dom'

export async function healthCheckLoader(args: LoaderFunctionArgs) {
  try {
    await fetch(`${Config.restEndPoint}/healthz`)
  } catch (error) {
    if (args.params['*'] !== 'temporarily-unavailable') {
      throw redirect('/temporarily-unavailable')
    }
  }
  return null
}
