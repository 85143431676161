import { MessagingService, useMessagingService } from '@walter/shared'
import { Loading } from '@walter/shared-web'
import * as React from 'react'
import { AppProvider } from '../contexts/App'
import AuthContext from '../contexts/Auth'
import { ConversationsProvider } from '../contexts/Conversations'
import { CurrentUserProvider } from '../contexts/CurrentUser'
import { ManagingCompanyProvider } from '../contexts/ManagingCompany'
import { NotificationProvider } from '../contexts/Notifications'
import { PendingActionsProvider } from '../contexts/PendingActions'

const messagingService = new MessagingService()

export function DashboardProvider({ children }: { children: React.ReactElement }) {
  const { currentUser, loadingCurrentUserQuery } = React.useContext(AuthContext)

  useMessagingService({
    messagingService,
    userId: currentUser?.id ?? '',
  })
  if (!currentUser || loadingCurrentUserQuery) return <Loading />

  return (
    <CurrentUserProvider>
      <AppProvider>
        <ManagingCompanyProvider>
          <PendingActionsProvider>
            <NotificationProvider>
              <ConversationsProvider>{children}</ConversationsProvider>
            </NotificationProvider>
          </PendingActionsProvider>
        </ManagingCompanyProvider>
      </AppProvider>
    </CurrentUserProvider>
  )
}
