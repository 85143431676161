import { mix, rgba } from 'polished'
import { createGlobalStyle } from 'styled-components'
import { Colors, Spacing } from '@walter/shared'
import { borderRadius } from '../../styles/global'
import { fontSizes, fontWeights } from '../../styles/typography'

export const Styles = createGlobalStyle`
  .rbc-header,
  .rbc-header + .rbc-header,
  .rbc-month-view,
  .rbc-day-bg + .rbc-day-bg,
  .rbc-time-view,
  .rbc-time-header-content,
  .rbc-time-content,
  .rbc-timeslot-group,
  .rbc-time-content > * + * > * {
    border-color: ${Colors.borderColor} !important;
  }

  .rbc-header {
    font-weight: ${fontWeights.bold} !important;
    color: ${Colors.greyDark} !important;
    background-color: ${mix(0.5, Colors.white, Colors.offWhite)} !important;
  }

  .rbc-today {
    background-color: ${rgba(Colors.primaryColor, 0.1)} !important;
  }

  .rbc-agenda-empty {
    flex: 1 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: ${Colors.greyLight} !important;
  }

  .rbc-now {
    color: ${Colors.primaryColor} !important;
  }

  .rbc-off-range {
    color: ${Colors.greyLight} !important;
  }

  .rbc-off-range-bg {
    background-color: ${mix(0.5, Colors.white, Colors.offWhite)} !important;
  }

  .rbc-current-time-indicator {
    background-color: ${Colors.primaryColor} !important;
  }

  .rbc-selected-cell {
    background-color: ${mix(0.5, Colors.white, Colors.offWhite)} !important;
  }

  .rbc-day-slot .rbc-time-slot {
    border-color: ${rgba(Colors.borderColor, 0.35)} !important;
  }

  .rbc-event {
    /* Need to comment because we overwrite it in the eventGetProps */
    /* background-color: ${Colors.grey} !important; */
    font-size: ${fontSizes.small} !important;
  }

  .rbc-overlay {
    border-color: ${Colors.borderColor} !important;
    padding: ${Spacing.medium} !important;
    border-radius: ${borderRadius} !important;
    box-shadow: 0 2px 5px 0 ${rgba(Colors.black, 0.05)} !important;
  }

  .rbc-overlay-header {
    border-color: ${Colors.borderColor} !important;
    font-weight: ${fontWeights.bold} !important;
    color: ${Colors.greyDark} !important;
    margin-left: -${Spacing.medium} !important;
    margin-right: -${Spacing.medium} !important;
    margin-bottom: ${Spacing.medium} !important;
    padding: ${Spacing.tiny} ${Spacing.medium} ${Spacing.small} ${Spacing.medium} !important;
  }

  .rbc-overlay > * + * {
    margin-top: ${Spacing.small} !important;
  }

  .rbc-show-more {
    font-size: ${fontSizes.small} !important;
    font-weight: ${fontWeights.regular} !important;
    color: ${Colors.greyDark} !important;
  }
`
