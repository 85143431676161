import { Colors, Spacing } from '@walter/shared'
import { stripUnit } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { SmallCaps } from '../SmallCaps'

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${Spacing.large};

  &:before,
  &:after {
    content: '';
    display: block;
    flex: 1;
    height: 1px;
    background-color: ${Colors.borderColor};
  }
`

const Label = styled(SmallCaps)`
  color: ${Colors.greyDark};
  padding: 0 ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
`

export const ChatDivider = ({ label }: { label: string }) => (
  <Container data-test-id={`Message_Divider_${label}`}>
    <Label>{label}</Label>
  </Container>
)
