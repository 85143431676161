import { t } from '@walter/shared-web'
import * as React from 'react'
import { useRouteError } from 'react-router-dom'

export function ErrorPage() {
  const error = useRouteError() as Record<string, any> | undefined
  const message = error?.statusText || error?.message
  return (
    <div className="min-h-svh bg-background flex justify-center items-center">
      <div className="bg-card p-8 rounded-xl space-y-4 shadow-md">
        <h1 className="text-foreground font-bold text-5xl text-center">{t('oops')}</h1>
        <p className="text-center text-foreground">{t('unexpected-error-occurred')}</p>
        {!!message && <p className="text-muted-foreground text-sm italic">{message}</p>}
      </div>
    </div>
  )
}
