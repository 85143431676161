import groupBy from 'lodash/groupBy'
import React, { useMemo } from 'react'
import { Accordion } from '../Accordion'
import { ConditionalWrap } from '../ConditionalWrap'
import { Fieldset } from '../Fieldset'
import { FormDataViewContext } from './FormDataView'
import { FormDataViewActions } from './FormDataViewActions'
import { FormDataViewField } from './FormDataViewField'
import { FormSection } from './helpers'

export function FormDataViewAccordion() {
  const { fields, values } = React.useContext(FormDataViewContext)

  const fieldsByAccordion = useMemo(() => {
    return groupBy(fields, (item) => item.accordion?.title)
  }, [fields])

  return (
    <>
      {Object.keys(fieldsByAccordion).map((key, index) => {
        const fields = fieldsByAccordion[key]
        return (
          <FormSection key={fields[0].accordion?.title || index}>
            <ConditionalWrap
              condition={index !== 0}
              wrap={(children) => (
                <Accordion isPadded {...fields[0].accordion} heading={fields[0].accordion?.title || ''}>
                  {children}
                </Accordion>
              )}
            >
              <Fieldset>
                {fields.map((field) => (
                  <FormDataViewField field={field} key={field.path || field.label} values={values} />
                ))}
              </Fieldset>
            </ConditionalWrap>
          </FormSection>
        )
      })}

      <FormSection>
        <FormDataViewActions />
      </FormSection>
    </>
  )
}
