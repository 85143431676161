import { z } from 'zod'
import { i18n, t } from '../../utils'

export const PostContenti18nFields = {
  coverImage: t('cover-image'),
  title: t('title'),
  description: t('description'),
  attachments: t('files'),
}

export const postContentSchema = z.object({
  coverImage: z.any().optional(),
  title: z
    .string({ required_error: i18n.t('input:is-required', { field: PostContenti18nFields.title }) })
    .min(1, { message: i18n.t('input:is-required', { field: PostContenti18nFields.title }) }),
  description: z.string().optional().nullable(),
  attachments: z.any().optional().nullable(),
})

export type PostContentFormSchema = z.infer<typeof postContentSchema>
