import React from 'react'
import { Colors, SendBirdUtils } from '@walter/shared'
import { customMessageCardIconName, customMessageCardName } from './CustomMessageCard.entities'
import ChatEvent from '../ChatEvent'

export interface IContactCustomItem {
  id: string
  title: string | null
  website: string | null
  firstName: string | null
  lastName: string | null
  email: string | null
  phone?: {
    number: string
    extension: string | null
  }
  avatar?: {
    id: string
    url: string | null
  }
}

export type ContactItemCardProps = {
  contact: IContactCustomItem
  formattedTime: string
}

export const ContactItemCard = (props: ContactItemCardProps) => {
  const { contact, formattedTime } = props
  const fullName = [contact.firstName, contact.lastName].join(' ')
  const contactDescription = [contact.email, contact?.phone?.number].filter(Boolean).join(' - ')
  return (
    <ChatEvent
      color={Colors.grey}
      icon={customMessageCardIconName(SendBirdUtils.CustomMessageType.contact)}
      name={customMessageCardName(SendBirdUtils.CustomMessageType.contact)}
      time={formattedTime}
      title={fullName}
      description={contactDescription}
    />
  )
}
