import { Api } from '@walter/shared'
import { WebDateUtils } from './date'
import { RRule, rrulestr, Options } from 'rrule'

const CUSTOM_YEARLY_REPEAT_TYPE = 'CUSTOM_YEARLY'
const DEFAULT_REPETITIONS_DAYS = 366
const DEFAULT_WEEKLY_REPETITIONS = 520
const DEFAULT_REPETITIONS_MONTHS = 120
const DEFAULT_REPETITIONS_YEARS = 20

export function getEventsWithRepeatedEvents(events: Api.Event[]) {
  const eventsWithRRule = events.map((event) => {
    const startEndIntervalInMinutes = WebDateUtils.date(event.end).diff(WebDateUtils.date(event.start), 'minutes')
    const rruleConfig: Options = { dtstart: WebDateUtils.date(event.start).toDate() } as Options
    if (event.repeatType === 'DAILY') {
      rruleConfig.freq = RRule.DAILY
      rruleConfig.dtstart = WebDateUtils.date(event.start).toDate()
      if (event.repeatedXTimes != null && event.repeatedXTimes > 0) {
        rruleConfig.count = event.repeatedXTimes
      }
      if (!event.repeatedXTimes) {
        rruleConfig.until = WebDateUtils.date().add(DEFAULT_REPETITIONS_DAYS, 'days').toDate() // 2 years for daily events
      }
    }
    if (event.repeatType === 'SATURDAYS') {
      rruleConfig.freq = RRule.WEEKLY
      rruleConfig.byweekday = [RRule.SA]
      rruleConfig.dtstart = WebDateUtils.date(event.start).toDate()
      if (event.repeatedXTimes != null && event.repeatedXTimes > 0) {
        rruleConfig.count = event.repeatedXTimes
      }
      if (!event.repeatedXTimes) {
        rruleConfig.until = WebDateUtils.date().add(DEFAULT_WEEKLY_REPETITIONS, 'weeks').toDate() // 10 years for weekly events
      }
    }
    if (event.repeatType === 'SUNDAYS') {
      rruleConfig.freq = RRule.WEEKLY
      rruleConfig.byweekday = [RRule.SU]
      rruleConfig.dtstart = WebDateUtils.date(event.start).toDate()
      if (event.repeatedXTimes != null && event.repeatedXTimes > 0) {
        rruleConfig.count = event.repeatedXTimes
      }
      if (!event.repeatedXTimes) {
        rruleConfig.until = WebDateUtils.date().add(DEFAULT_WEEKLY_REPETITIONS, 'weeks').toDate() // 10 years for weekly events
      }
    }
    if (event.repeatType === 'MONDAYS') {
      rruleConfig.freq = RRule.WEEKLY
      rruleConfig.byweekday = [RRule.MO]
      rruleConfig.dtstart = WebDateUtils.date(event.start).toDate()
      if (event.repeatedXTimes != null && event.repeatedXTimes > 0) {
        rruleConfig.count = event.repeatedXTimes
      }
      if (!event.repeatedXTimes) {
        rruleConfig.until = WebDateUtils.date().add(DEFAULT_WEEKLY_REPETITIONS, 'weeks').toDate() // 10 years for weekly events
      }
    }
    if (event.repeatType === 'TUESDAYS') {
      rruleConfig.freq = RRule.WEEKLY
      rruleConfig.byweekday = [RRule.TU]
      rruleConfig.dtstart = WebDateUtils.date(event.start).toDate()
      if (event.repeatedXTimes != null && event.repeatedXTimes > 0) {
        rruleConfig.count = event.repeatedXTimes
      }
      if (!event.repeatedXTimes) {
        rruleConfig.until = WebDateUtils.date().add(DEFAULT_WEEKLY_REPETITIONS, 'weeks').toDate() // 10 years for weekly events
      }
    }
    if (event.repeatType === 'WEDNESDAYS') {
      rruleConfig.freq = RRule.WEEKLY
      rruleConfig.byweekday = [RRule.WE]
      rruleConfig.dtstart = WebDateUtils.date(event.start).toDate()
      if (event.repeatedXTimes != null && event.repeatedXTimes > 0) {
        rruleConfig.count = event.repeatedXTimes
      }
      if (!event.repeatedXTimes) {
        rruleConfig.until = WebDateUtils.date().add(DEFAULT_WEEKLY_REPETITIONS, 'weeks').toDate() // 10 years for weekly events
      }
    }
    if (event.repeatType === 'THURSDAYS') {
      rruleConfig.freq = RRule.WEEKLY
      rruleConfig.byweekday = [RRule.TH]
      rruleConfig.dtstart = WebDateUtils.date(event.start).toDate()
      if (event.repeatedXTimes != null && event.repeatedXTimes > 0) {
        rruleConfig.count = event.repeatedXTimes
      }
      if (!event.repeatedXTimes) {
        rruleConfig.until = WebDateUtils.date().add(DEFAULT_WEEKLY_REPETITIONS, 'weeks').toDate() // 10 years for weekly events
      }
    }
    if (event.repeatType === 'FRIDAYS') {
      rruleConfig.freq = RRule.WEEKLY
      rruleConfig.byweekday = [RRule.FR]
      rruleConfig.dtstart = WebDateUtils.date(event.start).toDate()
      if (event.repeatedXTimes != null && event.repeatedXTimes > 0) {
        rruleConfig.count = event.repeatedXTimes
      }
      if (!event.repeatedXTimes) {
        rruleConfig.until = WebDateUtils.date().add(DEFAULT_WEEKLY_REPETITIONS, 'weeks').toDate() // 10 years for weekly events
      }
    }
    if (event.repeatType === 'MONTHLY') {
      rruleConfig.freq = RRule.MONTHLY
      rruleConfig.dtstart = WebDateUtils.date(event.start).toDate()
      if (event.repeatedXTimes != null && event.repeatedXTimes > 0) {
        rruleConfig.count = event.repeatedXTimes
      }
      if (!event.repeatedXTimes) {
        rruleConfig.until = WebDateUtils.date().add(DEFAULT_REPETITIONS_MONTHS, 'months').toDate() // 10 years for monthly events
      }
    }
    // adjustments for months with less than 31 days
    if (event.repeatType === 'MONTHLY') {
      if (new Date(event.start).getDate() === 31) {
        rruleConfig.bymonthday = [28, 29, 30, 31]
        rruleConfig.bysetpos = -1
      }
      if (new Date(event.start).getDate() === 30) {
        rruleConfig.bymonthday = [28, 29, 30]
        rruleConfig.bysetpos = -1
      }
      if (new Date(event.start).getDate() === 29) {
        rruleConfig.bymonthday = [28, 29]
        rruleConfig.bysetpos = -1
      }
    }

    if (event.repeatType === 'YEARLY') {
      return {
        ...event,
        rrule: CUSTOM_YEARLY_REPEAT_TYPE,
        minutesDifferenceRange: startEndIntervalInMinutes,
      }
    }

    return {
      ...event,
      rrule: event.repeatType !== 'NOT_REPEATED' ? new RRule(rruleConfig).toString() : undefined,
      minutesDifferenceRange: startEndIntervalInMinutes,
    }
  })

  const eventsWithRepeated = eventsWithRRule.flatMap(generateRepeatedEvents)
  return eventsWithRepeated
}

export function generateRepeatedEvents(
  event: Api.Event & {
    rrule: string | undefined
    minutesDifferenceRange: number
  },
) {
  if (!event.rrule) return [event]

  if (event.rrule === CUSTOM_YEARLY_REPEAT_TYPE) {
    const repetitions = event.repeatedXTimes || DEFAULT_REPETITIONS_YEARS

    return Array(repetitions)
      .fill(0)
      .map((_, index) => ({
        ...event,
        start: WebDateUtils.date(event.start).add(index, 'years').toDate(),
        end: WebDateUtils.date(event.start).add(index, 'years').add(event.minutesDifferenceRange, 'minutes').toDate(),
        isEndOfSeries: index >= repetitions - 1,
      }))
  }

  const rule = rrulestr(event.rrule.toString())
  const dates = rule.all()

  return dates.map((date) => ({
    ...event,
    start: date,
    end: WebDateUtils.date(date).add(event.minutesDifferenceRange, 'minutes').toDate(),
  }))
}

const HUNDRED_YEARS_EARLIER = new Date(new Date().setFullYear(new Date().getFullYear() - 100))
const HUNDRED_YEARS_LATER = new Date(new Date().setFullYear(new Date().getFullYear() + 100))

export function getDatetimeMinMaxValues(withTime = false) {
  if (!withTime) {
    return {
      min: HUNDRED_YEARS_EARLIER.toISOString().split('T')[0],
      max: HUNDRED_YEARS_LATER.toISOString().split('T')[0],
    }
  }

  const arrMin = HUNDRED_YEARS_EARLIER.toISOString().split('.')[0].split(':')
  arrMin.pop()
  const arrMax = HUNDRED_YEARS_LATER.toISOString().split('.')[0].split(':')
  arrMax.pop()

  return { min: arrMin.join(':'), max: arrMax.join(':') }
}
