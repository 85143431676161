import React from 'react'
import Notification from 'rc-notification'

import { getNotificationMessageSimpleContent, NotificationMessageType } from '@walter/shared-web'
import { Types } from '@walter/shared'

let notification: Parameters<Parameters<typeof Notification.newInstance>[1]>[0]

Notification.newInstance(
  {
    style: {
      left: '50%',
      top: 15,
    },
  },
  // eslint-disable-next-line no-return-assign
  (n) => (notification = n),
)

type MessageContextType = {
  showMessage: any
}

const MessageContext = React.createContext({} as MessageContextType)

export default MessageContext

// type MessageType = {
//   title: string
//   text: string
//   type: 'positive'
// }

export function MessageProvider({ children }: { children: React.ReactNode }) {
  // const [messages, setMessages] = React.useState<MessageType[]>([])

  const showMessage = (
    type: NotificationMessageType,
    text: string,
    duration = 2.2,
    onCreate: (notification: any) => void,
  ) => {
    notification.notice({
      duration,
      content: getNotificationMessageSimpleContent({ text, type, icon: getIconForType(type) }),
    })

    if (onCreate) {
      onCreate(notification)
    }
    // setMessages((messages) => [...messages, { type, text }])
  }

  return (
    <MessageContext.Provider value={{ showMessage }}>
      {/* NOT USING BECAUSE STYLING PROBLEM. USING OTHER NOTIFICATION INSTEAD FOR NOW */}
      {/* {messages.map((message, index) => (
        <NotificationMessage
          key={message.text || message.title}
          index={index}
          visible
          icon={message.type === 'positive' ? 'checkmark' : 'close'}
          type={message.type}
          text={message.text}
          close={() => setMessages((messages) => messages.filter((_, i) => i !== index))}
        />
      ))} */}
      <div data-cy="message-notification">{children}</div>
    </MessageContext.Provider>
  )
}

function getIconForType(type: NotificationMessageType): Types.IconName {
  switch (type) {
    case 'positive':
      return 'checkmark'
    case 'loading':
      return 'loading'
    case 'info':
      return 'info'
    default:
      return 'close'
  }
}
