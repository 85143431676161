import { useApolloClient } from '@apollo/client'
import { Api, DefaultAuthContextType, DefaultLoginValues, DefaultRegisterValues, SharedUtils } from '@walter/shared'
import { WebLogger, getLanguage, useWebAuth } from '@walter/shared-web'
import React, { useEffect } from 'react'
import { useClearAllCacheAndStorage } from '../components/selectors'

interface ManagingCompanyRegisterValues extends DefaultRegisterValues {
  shortName: string
  email: string
  password: string
}

interface AuthContextType extends Omit<DefaultAuthContextType, 'register'> {
  currentUser?: Api.GetMeManagerWebQuery['me']
  register: (data: ManagingCompanyRegisterValues) => Promise<void>
  isSessionExpired: () => boolean
}

const AuthContext = React.createContext({} as AuthContextType)

export default AuthContext

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const client = useApolloClient()

  const [loginMutation] = Api.useLoginManagerWebMutation()
  const [registerMutation] = Api.useRegisterManagingCompanyManagerWebMutation()

  const clearAllCacheAndStorage = useClearAllCacheAndStorage()

  const {
    token,
    register: registerSharedWeb,
    login: loginSharedWeb,
    logout: logoutSharedWeb,
    isSessionExpired,
  } = useWebAuth()

  const {
    data,
    loading: loadingCurrentUserQuery,
    error: errorCurrentUserQuery,
    refetch: refetchCurrentUserQuery,
  } = Api.useGetMeManagerWebQuery({
    skip: !token || isSessionExpired(),
  })

  const currentUser = data?.me

  const IS_FRENCH = getLanguage().toLowerCase().startsWith('fr')
  const englishMismatch = IS_FRENCH && currentUser?.preferedLanguage === 'en'
  const frenchMismatch = !IS_FRENCH && currentUser?.preferedLanguage === 'fr'

  if (englishMismatch || frenchMismatch) {
    window.location.reload()
  }

  useEffect(() => {
    if (!currentUser && !loadingCurrentUserQuery && token && isSessionExpired()) {
      logout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, loadingCurrentUserQuery])

  async function login({ email, password }: DefaultLoginValues) {
    const { data } = await loginMutation({
      variables: {
        email,
        password,
      },
    })

    const { token, user, refreshToken } = data?.signinManagerWeb ?? {}

    if (token) {
      loginSharedWeb(token, refreshToken)
      // Fixes a races condition where the token does not have time to be set in the local storage (would have to login twice after being kicked out)
      await SharedUtils.wait(500)
    }

    if (user) {
      client.writeQuery({ query: Api.GetMeManagerWebDocument, data: { me: user } })
    }
  }

  React.useEffect(() => {
    if (currentUser?.preferedLanguage) {
      if (localStorage.getItem('i18nextLng') === 'debug') return
      localStorage.setItem('i18nextLng', currentUser.preferedLanguage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  async function register(registerData: ManagingCompanyRegisterValues) {
    const { data } = await registerMutation({
      variables: { shortName: registerData.shortName, email: registerData.email, password: registerData.password },
    })

    // @ts-ignore (will be fix when we upgrade nexus)
    const { token, user, refreshToken } = data?.registerManagingCompany

    registerSharedWeb(token, refreshToken)

    if (token) {
      client.writeQuery({ query: Api.GetMeManagerWebDocument, data: { me: user } })
    }
  }

  async function logout() {
    logoutSharedWeb()
    clearAllCacheAndStorage()
    client.stop()
    await client.cache.reset().catch(WebLogger.captureError)
    await client.resetStore().catch(WebLogger.captureError)
  }

  const value = {
    currentUser,
    loadingCurrentUserQuery,
    errorCurrentUserQuery,
    refetchCurrentUserQuery,
    login,
    logout,
    register,
    token,
    isSessionExpired,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
