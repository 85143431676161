import { Api } from '@walter/shared'
import { z } from 'zod'
import { t } from '../../utils'

export const Packagesi18nFields = {
  title: t('title'),
  description: t('description'),
  status: t('status'),
  client: t('resident'),
  image: t('image'),
}

export const PackagesSchema = z.object({
  title: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  status: z.nativeEnum(Api.Package_Status),
  client: z
    .object({ id: z.string(), firstName: z.string().nullable().optional(), lastName: z.string().nullable().optional() })
    .nullable()
    .optional(),
  image: z.any().nullable().optional(),
})

export type Packages = z.infer<typeof PackagesSchema>
