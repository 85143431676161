import { SendBirdUtils, Message, useCustomMessageItemLoader } from '@walter/shared'
import { WebDateUtils } from '../../../src/utils/date'
import React from 'react'
import { CustomItemTypeDataMapping, CustomMessageCard } from './CustomMessageCard'

export type CustomMessageBubbleContentProps = {
  message: Message
}

export const CustomMessageBubble = (props: CustomMessageBubbleContentProps) => {
  const { message } = props
  const type = message.customType as SendBirdUtils.CustomMessageType
  const data = message.data as string

  const customMessageItemLoader = useCustomMessageItemLoader()
  const { item } = customMessageItemLoader.useCustomItem(type, data)
  const formattedTime = WebDateUtils.dayjs(message.createdAt).format('h:mm A')

  return (
    <CustomMessageCard
      type={type}
      item={item as CustomItemTypeDataMapping[typeof type]}
      formattedTime={formattedTime}
    />
  )
}
