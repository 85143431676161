import { z } from 'zod'
import { i18n, t } from '../../utils'

export const Registeri18nFields = {
  shortName: t('auth:company-name'),
  email: t('auth:email'),
  password: t('auth:password'),
}

export const RegisterSchema = z.object({
  shortName: z
    .string({ required_error: i18n.t('input:is-required', { field: Registeri18nFields.shortName }) })
    .min(1, { message: i18n.t('input:is-required', { field: Registeri18nFields.shortName }) }),
  email: z
    .string({ required_error: i18n.t('input:is-required', { field: Registeri18nFields.email }) })
    .min(1, { message: i18n.t('input:is-required', { field: Registeri18nFields.email }) })
    .email(t('enter-email-invalid')),
  password: z
    .string({ required_error: i18n.t('input:is-required', { field: Registeri18nFields.password }) })
    .min(8, t('create-strong-password')),
})

export type Register = z.infer<typeof RegisterSchema>
