import React from 'react'
import styled from 'styled-components'
import { Spacing } from '@walter/shared'
import { stripUnit } from 'polished'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: -${`${(stripUnit(Spacing.tiny) as number) * 1.5}px`};
  flex-direction: column;
`

const Item = styled.div`
  padding: ${`${(stripUnit(Spacing.tiny) as number) * 1.5}px`};
  display: flex;
`

type AttachmentListProps = {
  children: React.ReactNode
}

export const AttachmentList = ({ children }: AttachmentListProps) => (
  <Container data-test-id="AttachmentList_Container">
    {React.Children.map(children, (child, i) => (
      <Item data-test-id={`AttachementList_Item_${i}`} key={i}>
        {child}
      </Item>
    ))}
  </Container>
)
