import React from 'react'

export type Panel = {
  title: string
  component: React.ReactNode
}

/**
 * To be used with Drawer component
 */
export function usePanel<Type>({
  creationPanel,
  editionPanel,
  viewPanel,
}: {
  creationPanel: (object: Type) => Panel
  editionPanel: (object: Type) => Panel
  viewPanel?: (object: Type) => Panel
}) {
  const emptyPanel: Panel = { title: '', component: null }
  const [panel, setPanel] = React.useState<Panel>(emptyPanel)

  const struct = {
    showPanel: (panel: (object: Type) => Panel, object: Type) => setPanel(panel(object)),
    hidePanel: () => struct.showPanel(() => emptyPanel, {} as Type),
    creationPanel,
    editionPanel,
    viewPanel,
    panel,
  }
  return struct
}
