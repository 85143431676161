import { Colors } from '@walter/shared'

export function setMultiSelectedColor(color: keyof typeof Colors) {
  const selectedColor = Colors[color]
  return {
    multiValueLabel: (styles: Record<string, unknown>) => {
      return {
        ...styles,
        color: `${selectedColor} !important`,
      }
    },
    multiValueRemove: (styles: Record<string, unknown>) => {
      return {
        ...styles,
        color: `${selectedColor}90 !important`,
        ':hover': {
          color: `${selectedColor} !important`,
        },
      }
    },
    multiValue: (styles: Record<string, unknown>) => {
      return {
        ...styles,
        background: `${selectedColor}20 !important`,
        color: `${selectedColor} !important`,
      }
    },
  } as const
}
