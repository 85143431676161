import React from 'react'
import { ContactItemCard, IContactCustomItem } from './ContactItemCard'
import { IRuleCustomItem, RuleItemCard } from './RuleItemCard'
import { FaqItemCard, IFaqCustomItem } from './FaqItemCard'
import { IPostCustomItem, PostItemCard } from './PostItemCard'
import { EventItemCard, IEventCustomItem } from './EventItemCard'
import { FileItemCard, IFileCustomItem } from './FIleItemCard'
import { SendBirdUtils } from '@walter/shared'
import { ITaskCustomItem, TaskItemCard } from './TaskItemCard'
import { EmailItemCard, IEmailCustomItem } from './EmailItemCard'
import { CustomMessageCardProps } from './CustomMessageCard.props'

export * from './CustomMessageCard.props'
export * from './CustomMessageCard.entities'

export const CustomMessageCard = <T extends SendBirdUtils.CustomMessageType>(props: CustomMessageCardProps<T>) => {
  if (!props.item) return null
  switch (props.type) {
    case SendBirdUtils.CustomMessageType.contact:
      return <ContactItemCard contact={props.item as IContactCustomItem} formattedTime={props.formattedTime} />
    case SendBirdUtils.CustomMessageType.rule:
      return <RuleItemCard rule={props.item as IRuleCustomItem} formattedTime={props.formattedTime} />
    case SendBirdUtils.CustomMessageType.faqItem:
      return <FaqItemCard faqItem={props.item as IFaqCustomItem} formattedTime={props.formattedTime} />
    case SendBirdUtils.CustomMessageType.post:
      return <PostItemCard post={props.item as IPostCustomItem} formattedTime={props.formattedTime} />
    case SendBirdUtils.CustomMessageType.event:
      return <EventItemCard event={props.item as IEventCustomItem} formattedTime={props.formattedTime} />
    case SendBirdUtils.CustomMessageType.file:
      return <FileItemCard file={props.item as IFileCustomItem} formattedTime={props.formattedTime} />
    case SendBirdUtils.CustomMessageType.task:
      return <TaskItemCard task={props.item as ITaskCustomItem} formattedTime={props.formattedTime} />
    case SendBirdUtils.CustomMessageType.emailsnapshot:
      return <EmailItemCard email={props.item as IEmailCustomItem} formattedTime={props.formattedTime} />
    default:
      return null
  }
}
