import React from 'react'
import styled, { css } from 'styled-components'
import { Colors, Spacing, Types } from '@walter/shared'
import { rgba } from 'polished'
import { rotate } from '../../styles/animations'
import { square } from '../../styles/global'
import { Heading } from '../Heading'
import { Icon } from '../Icon'

const Container = styled.div`
  text-align: center;
  max-width: 340px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const IconWrap = styled(({ isLoading, children, ...rest }) => <div {...rest}>{children}</div>)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${Spacing.large};
  background-color: ${rgba(Colors.greyLight, 0.2)};
  ${square('64px')};

  ${(props) =>
    props.isLoading &&
    css`
      animation: ${rotate} 1s linear infinite;
    `}
`

const StyledHeading = styled(Heading)`
  width: 100%;
  margin-bottom: ${Spacing.small};
`

const Image = styled.img`
  max-width: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: ${Spacing.large};
`

type SuccessStateProps = {
  image?: string
  icon?: Types.IconName
  heading: string
  description?: string
}

export const SuccessState = ({ icon, image, heading, description }: SuccessStateProps) => {
  return (
    <Container>
      {icon && (
        <IconWrap>
          <Icon icon={icon} />
        </IconWrap>
      )}
      {image && <Image src={image} />}
      <StyledHeading size={2}>{heading}</StyledHeading>
      {!!description && <p>{description}</p>}
    </Container>
  )
}
