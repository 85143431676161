import i18n from 'i18next'
import { Config, ENJson, FRJson } from '@walter/shared'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

function modifyTextForDebugging(localeObject: typeof ENJson, platformText?: string) {
  const debug: Record<string, Record<string, unknown>> = {}

  Object.keys(localeObject).forEach((key) => {
    const result: Record<string, unknown> = {}
    Object.entries(localeObject[key as keyof typeof ENJson]).map(([insideKey, insideValue]: [string, unknown]) => {
      const modifiedValue = `t('${platformText ? platformText : ''}${key}:${insideKey}') | ${insideValue}`
      result[insideKey] = modifiedValue
    })

    debug[key] = result
  })
  return debug
}

const resources = {
  en: {
    translation: ENJson,
  },
  fr: {
    translation: FRJson,
  },
}

const isLanguageDebugging = Config.isDev
const debuggingOptions = {
  resources: {
    ...resources,
    debug: {
      translation: modifyTextForDebugging(ENJson),
    },
  },
  debug: true,
}
const productionOptions = { resources }
export const getLanguage = () => (i18n.language.startsWith('fr') ? 'fr' : 'en')

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    (() => {
      const options = isLanguageDebugging ? debuggingOptions : productionOptions
      return {
        ...options,
        fallbackLng: 'en',
        keySeparator: ':',
      }
    })(),
  )

export function getTranslation(text: string, options?: any) {
  // Temporary until WAL-4934 is done
  if (text.includes(':')) {
    return i18n.t(text.substring(text.lastIndexOf(':') + 1, text.length), options)
  }

  return i18n.t(text, options)
}

export function t(text: string, options?: any) {
  if (options != null) {
    return getTranslation(text, options)
  }

  return getTranslation(text, options)
}

// To be used in I18nextProvider in manager-web, resident-web, etc...
export { i18n }
