import React from 'react'
import styled from 'styled-components'

import { Spacing, Colors } from '@walter/shared'

const Container = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`

const Item = styled.div`
  display: flex;
  border-radius: 50%;
  box-shadow: 0 0 0 2px ${Colors.white};

  &:nth-child(1) {
    z-index: 9;
  }

  &:nth-child(2) {
    z-index: 8;
  }

  &:nth-child(3) {
    z-index: 7;
  }

  &:nth-child(4) {
    z-index: 6;
  }

  &:nth-child(5) {
    z-index: 5;
  }

  &:nth-child(6) {
    z-index: 4;
  }

  &:not(:first-child) {
    margin-left: -${Spacing.small};
  }
`

type AvatarGroupProps = {
  children: React.ReactNode
}

export const AvatarGroup = ({ children }: AvatarGroupProps) => (
  <Container>{React.Children.map(children, (child, i) => child && <Item key={i}>{child}</Item>)}</Container>
)
