import { Colors, Spacing } from '@walter/shared'
import styled from 'styled-components'
import { fontSizes, fontWeights } from '../../styles/typography'

interface LabelProps {
  large?: boolean
  xlarge?: boolean
  fontWeight?: number
  padding?: string
  fullWidth?: boolean
}

export const Label = styled.label<LabelProps>`
  position: relative;
  display: block;
  margin-bottom: ${Spacing.tiny};
  font-size: ${(props) => (props.large ? fontSizes.small : props.xlarge ? fontSizes.large : fontSizes.tiny)};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : fontWeights.bold)};
  text-align: left;
  color: ${Colors.greyDark};
  ${(props) => (props.padding ? `padding: ${props.padding};` : '')}
  ${(props) => (props.fullWidth ? `width: 100%;` : '')}
`
