import React, { useId } from 'react'
import styled, { css } from 'styled-components'
import { Spacing, Types } from '@walter/shared'
import { stripUnit } from 'polished'
import { Checkbox } from '../Checkbox'
import { Label } from '../Label'
import { Radio } from '../Radio'
import camelCase from 'lodash/camelCase'

const Container = styled.div`
  display: block;
`

const Heading = styled(Label)`
  margin-bottom: ${Spacing.small};
`

const Content = styled.div<{ columns?: number }>`
  display: block;

  ${(props) =>
    props.columns &&
    css`
      column-count: ${props.columns};
    `}
`

const Item = styled.div`
  display: block;

  & + & {
    margin-top: ${`${(stripUnit(Spacing.tiny) as number) * 1.5}px`};
  }
`

type ChoiceListProps = {
  dataTestId?: string
  /**
    Initial value of the choicelist component, if any preselected
    */
  value?: any
  /**
   *REQUIRED. List all the options possible in the choicelist component
   */
  options: { label: string; value: any }[]
  /**
   The Heading shown above choice items in the choicelist component.
   */
  label?: string
  /**
    Name used to regroup all the choice items of the component together.
   */
  name?: string
  /**
    Indicate on how many columns you want the choicelist items to show
    */
  columns?: number
  /**
    Use this to use checkboxes instead of radio buttons
    */
  allowMultiple?: boolean
  /**
    Passe any function to execute when there is a change in the component
    */
  onChange?: (event: any) => void
}

/**
- Use a ChoiceList to group together a set of options user can select into. 
- If adding the boolean props "allowMultiple", it changes each item to a checkbox.
* */
export const ChoiceList = ({
  dataTestId,
  value,
  label,
  name,
  options,
  allowMultiple,
  columns,
  onChange,
}: ChoiceListProps) => {
  const ControlComponent = allowMultiple ? Checkbox : Radio
  const id = useId()

  return (
    <Container>
      <Heading>{label}</Heading>
      <Content columns={columns}>
        {options.map((option, i) => (
          <Item data-test-id={`${dataTestId}_${camelCase(option.label)}`} key={i}>
            <ControlComponent
              style={{ justifyContent: 'flex-start' }}
              label={option.label}
              name={name ?? id}
              checked={
                allowMultiple
                  ? value
                    ? value.some((v: Types.Option) => v === option.value)
                    : false
                  : option.value === value
              }
              onChange={(event) => {
                const { checked } = event.target
                if (allowMultiple) {
                  if (checked) {
                    if (!value) {
                      onChange?.([option.value])
                    } else {
                      onChange?.([...value, option.value])
                    }
                  } else {
                    onChange?.(value.filter((v: Types.Option) => v !== option.value))
                  }
                } else if (checked) {
                  onChange?.(option.value)
                } else {
                  onChange?.(null)
                }
              }}
            />
          </Item>
        ))}
      </Content>
    </Container>
  )
}
