import React from 'react'
import { SharedUtils } from '@walter/shared'

type WebsiteProps = {
  content: string
}

function Website({ content }: WebsiteProps) {
  const makeLink = SharedUtils.convertWebsiteToHttp(content)

  return (
    <a href={makeLink} target="_blank" rel="noopener noreferrer">
      {content}
    </a>
  )
}

export default Website
