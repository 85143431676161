import { z } from 'zod'
import { t } from '../../utils'

export const ResetPasswordi18nFields = {
  password: t('login-page-password'),
  confirmPassword: t('reset-password-page-confirm-password'),
}

export const ResetPasswordSchema = z
  .object({
    password: z.string().min(8, { message: t('password-error-length') }),
    confirmPassword: z.string().min(8, { message: t('password-error-length') }),
  })
  .refine(
    (data) => {
      if (data.password !== data.confirmPassword) {
        return false
      }
      return true
    },
    {
      message: t('reset-password-page-must-match'),
      path: ['newPassword', 'confirmNewPassword'],
    },
  )

export type ResetPassword = z.infer<typeof ResetPasswordSchema>
