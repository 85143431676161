import { z } from 'zod'
import { i18n, t } from '../../utils'
import { emptyStringToNull } from './transformationUtils'

// eslint-disable-next-line no-irregular-whitespace
// Space character used by Intl.NumberFormat. Ex: 10 000,00
const NARROW_NO_BREAK_SPACE_CHAR = String.fromCharCode(8239)

export const uniti18nFields = {
  apartmentNumber: t('fields-unit:number'),
  floor: t('fields-unit:floor'),
  type: t('fields-unit:type'),
  squareFeet: t('fields-unit:square-feet'),
  squareFeetBalcony: t('fields-unit:square-feet-balcony'),
  proportionateShare: t('fields-unit:percentage-square-footage'),
  rooms: t('fields-unit:rooms'),
  note: t('fields-unit:note'),
  plan: t('fields-unit:plan'),

  users: t('fields-unit:residents'),
  owners: t('fields-unit:owners'),

  monthlyCondoFeeAmount: t('fields-unit:monthly-condo-fees'),

  intercomCode: t('fields-unit:intercom-code'),
  buzzCode: t('fields-unit:buzz-code'),
  wifiPassword: t('fields-unit:wifi-password'),
  hasAlarmSystem: t('fields-unit:has-alarm-system'),

  remotes: t('fields-unit:remotes'),
  accessKeys: t('fields-unit:access-keys'),

  parkings: t('fields-unit:parkings'),

  lockers: t('fields-unit:lockers'),

  rentAmount: t('current-rent-amount'),
  rentEnd: t('fields-unit:rent-end'),
  rentStart: t('fields-unit:rent-start'),
  lease: t('fields-unit:lease'),

  annexes: t('fields-unit:annexes'),
  referenceProperty: t('fields-unit:reference-property'),
}

export const unitSchemaCreate = z.object({
  address: z.object(
    {
      apartmentNumber: z
        .string({ required_error: i18n.t('input:is-required', { field: uniti18nFields.apartmentNumber }) })
        .min(1, { message: i18n.t('input:is-required', { field: uniti18nFields.apartmentNumber }) }),
    },
    {
      required_error: i18n.t('input:is-required', { field: uniti18nFields.apartmentNumber }),
    },
  ),

  floor: z.string().optional().nullable(),
  type: z.string().optional().nullable(),
  squareFeet: z
    .string()
    .transform(emptyStringToNull)
    .refine(
      (val) => {
        return val == null || !isNaN(val)
      },
      { message: i18n.t('input:must-be-number', { field: uniti18nFields.squareFeet }) },
    )
    .or(z.number())
    .nullable()
    .optional(),
  squareFeetBalcony: z
    .string()
    .transform(emptyStringToNull)
    .refine(
      (val) => {
        return val == null || !isNaN(val)
      },
      { message: i18n.t('input:must-be-number', { field: uniti18nFields.squareFeetBalcony }) },
    )
    .or(z.number())
    .nullable()
    .optional(),
  proportionateShare: z
    .string()
    .transform(emptyStringToNull)
    .refine(
      (val) => {
        return val == null || !isNaN(val)
      },
      { message: i18n.t('input:must-be-number', { field: uniti18nFields.proportionateShare }) },
    )

    .refine(
      (data) => {
        if (data && data > 100) {
          return false
        }
        return true
      },
      {
        message: i18n.t('proportionate-share-should-be-percentage'),
      },
    )
    .or(z.number().max(100, t('proportionate-share-should-be-percentage')))
    .nullable()
    .optional(),
  rooms: z
    .string()
    .transform((val) => {
      if (val === '') {
        return null
      }
      return val.replace(',', '.').replaceAll(NARROW_NO_BREAK_SPACE_CHAR, '')
    })
    .refine(
      (val) => {
        return val == null || !isNaN(Number(val))
      },
      { message: i18n.t('input:must-be-number', { field: uniti18nFields.rooms }) },
    )
    .nullable()
    .optional(),
  note: z.string().optional().nullable(),
  plan: z.any().optional().nullable(),

  users: z
    .array(
      z.object({
        id: z.string({ required_error: i18n.t('input:is-required', { field: uniti18nFields.users }) }),
      }),
    )
    .optional(),
  owners: z
    .array(
      z.object({
        id: z.string({ required_error: i18n.t('input:is-required', { field: uniti18nFields.users }) }),
      }),
    )
    .optional(),

  monthlyCondoFeeAmount: z
    .string()
    .transform(emptyStringToNull)
    .refine(
      (val) => {
        return val == null || !isNaN(val)
      },
      { message: i18n.t('input:must-be-number', { field: uniti18nFields.monthlyCondoFeeAmount }) },
    )
    .or(z.number())
    .nullable()
    .optional(),

  intercomCode: z.string().optional().nullable(),
  buzzCode: z.string().optional().nullable(),
  wifiPassword: z.string().optional().nullable(),
  hasAlarmSystem: z.boolean().optional().nullable(),

  remotes: z.array(z.object({ id: z.string() })).optional(),
  accessKeys: z.array(z.object({ id: z.string() })).optional(),
  parkings: z.array(z.object({ id: z.string() })).optional(),
  lockers: z.array(z.object({ id: z.string() })).optional(),
  rentAmount: z
    .string()
    .transform(emptyStringToNull)
    .refine(
      (val) => {
        return val == null || !isNaN(val)
      },
      { message: i18n.t('input:must-be-number', { field: uniti18nFields.rentAmount }) },
    )
    .or(z.number())
    .nullable()
    .optional(),
  rentStart: z.string().or(z.date()).optional().nullable(),
  rentEnd: z.string().or(z.date()).optional().nullable(),
  lease: z.any().optional().nullable(),
  annexes: z.any().optional().nullable(),
  referenceProperty: z.any().optional().nullable(),
})

export type Unit = z.infer<typeof unitSchemaCreate>

export const unitSchemaUpdate = unitSchemaCreate
