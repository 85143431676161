import { Colors, Spacing } from '@walter/shared'
import kebabCase from 'lodash/kebabCase'
import { rgba, stripUnit } from 'polished'
import React from 'react'
import styled, { css } from 'styled-components'
import { animationCurve, animationTime } from '../../styles/global'
import { fontSizes, fontWeights } from '../../styles/typography'
import I18n from 'i18n-js'

const Container = styled.div<{ type?: PillType; onClick?: any; customColor?: string }>`
  display: inline-flex;
  align-items: center;
  height: ${Spacing.medium};
  border-radius: ${Spacing.large};
  color: ${Colors.primaryColor};
  background-color: ${rgba(Colors.primaryColor, 0.15)};
  padding: 0 ${`${(stripUnit(Spacing.tiny) as number) * 1.5}px`};
  transition: background-color ${animationTime} ${animationCurve}, color ${animationTime} ${animationCurve};
  font-size: ${fontSizes.tiny};
  font-weight: ${fontWeights.bold};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    `}

  ${(props) =>
    props.type === 'primary' &&
    css`
      color: ${Colors.white};
      background-color: ${Colors.primaryColor};
    `}

  ${(props) =>
    props.type === 'positive' &&
    css`
      color: ${Colors.green};
      background-color: ${rgba(Colors.green, 0.2)};
    `}

  ${(props) =>
    props.type === 'negative' &&
    css`
      color: ${Colors.red};
      background-color: ${rgba(Colors.red, 0.2)};
    `}

  ${(props) =>
    props.type === 'warning' &&
    css`
      color: ${Colors.white};
      background-color: ${Colors.warning};
    `}

  ${(props) =>
    props.type === 'danger' &&
    css`
      color: ${Colors.white};
      background-color: ${Colors.red};
    `}
  
  ${(props) =>
    props.color &&
    css`
      background-color: ${rgba(props.color, 0.2)};
    `}

  ${(props) =>
    props.type === 'custom' &&
    props.customColor &&
    css`
      color: ${props.customColor};
      background-color: ${rgba(props.customColor, 0.2)}};
    `}
`

export type PillType =
  | 'danger'
  | 'negative'
  | 'warning'
  | 'positive'
  | 'contrast'
  | 'primary'
  | 'warning'
  | 'custom'
  | undefined

export type PillProps = {
  text: any
  handleClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  onClick?: (event: React.MouseEvent<HTMLDivElement> | undefined) => void
  type?: PillType
  hint?: string
  customColor?: string
  color?: string
  dataTestId?: string
  isPrice?: boolean
}

export const Pill = ({ text, type, customColor, hint, handleClick, dataTestId, isPrice, ...rest }: PillProps) => {
  return (
    <Container
      type={type}
      onClick={handleClick}
      customColor={customColor}
      data-tip={hint}
      data-cy={`pill-${kebabCase(text)}`}
      data-test-id={dataTestId}
      {...rest}
    >
      {isPrice ? I18n.l('currency', text || '') : text}
    </Container>
  )
}
