import { FormsUtils, SharedUtils } from '@walter/shared'
import React from 'react'
import { FieldsetItem } from '../Fieldset/FieldsetItem'
import { FormDataDisplay } from './FormDataDisplay'

type FieldProps = {
  field?: any
  values?: any
}

export function FormDataViewField({ field, values }: FieldProps) {
  const realFieldType = React.useMemo(() => {
    if (field.type && field.type instanceof Function && SharedUtils.isFunction(field.type)) {
      return field.type(values)
    }

    return field.type
  }, [field, values])

  return (
    <FieldsetItem dataTestId={field.dataTestId} width={field.width || '1'}>
      {field.type === 'component' ? (
        field.component(values)
      ) : (
        <FormDataDisplay
          {...field}
          dataTestId={field.dataTestId}
          type={realFieldType}
          value={FormsUtils.getDisplayValueForField(field.display || field.path, values)}
        />
      )}
    </FieldsetItem>
  )
}
