import { Spacing } from '@walter/shared'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 602;
  width: 100%;
  top: ${Spacing.medium};
`

const Inner = styled.div`
  /* position: fixed;
  z-index: 602;
  top: ${Spacing.medium};
  left: 45%; */
`

const Item = styled.div`
  padding: ${Spacing.small};
  &:last-child {
    margin-bottom: 0;
  }
`

export function NotificationMessageGroup({ children }: { children: React.ReactNode }) {
  return (
    <Container>
      <Inner>{React.Children.map(children, (child, i) => child && <Item key={i}>{child}</Item>)}</Inner>
    </Container>
  )
}
