import { useQuery } from '@apollo/client'
import { CustomMessageType, EmailSnapshot } from '../utils/sendBird'
import { Api } from '..'

type useItem<T> = (data: string) => { item?: T; isLoading: boolean }

const useContactItem: useItem<Api.GetContactByIdForChatQuery['contact']> = (id: string) => {
  const { data, loading } = useQuery<Api.GetContactByIdForChatQuery>(Api.GetContactByIdForChatDocument, {
    variables: { where: { id } },
  })

  return { item: data?.contact, isLoading: loading }
}

const useFaqItem: useItem<Api.GetFaqItemByIdForChatQuery['faqItem']> = (id: string) => {
  const { data, loading } = useQuery<Api.GetFaqItemByIdForChatQuery>(Api.GetFaqItemByIdForChatDocument, {
    variables: { where: { id } },
  })

  return { item: data?.faqItem, isLoading: loading }
}

const useEventItem: useItem<Api.GetEventByIdForChatQuery['event']> = (id: string) => {
  const { data, loading } = useQuery<Api.GetEventByIdForChatQuery>(Api.GetEventByIdForChatDocument, {
    variables: { where: { id } },
  })

  return { item: data?.event, isLoading: loading }
}

const useRuleItem: useItem<Api.GetRuleByIdForChatQuery['rule']> = (id: string) => {
  const { data, loading } = useQuery<Api.GetRuleByIdForChatQuery>(Api.GetRuleByIdForChatDocument, {
    variables: { where: { id } },
  })

  return { item: data?.rule, isLoading: loading }
}

const usePostItem: useItem<Api.GetPostByIdForChatQuery['post']> = (id: string) => {
  const { data, loading } = useQuery<Api.GetPostByIdForChatQuery>(Api.GetPostByIdForChatDocument, {
    variables: { where: { id } },
  })

  return { item: data?.post, isLoading: loading }
}

const useFileItem: useItem<Api.GetFileByIdForChatQuery['file']> = (id: string) => {
  const { data, loading } = useQuery<Api.GetFileByIdForChatQuery>(Api.GetFileByIdForChatDocument, {
    variables: { where: { id } },
  })

  return { item: data?.file, isLoading: loading }
}

const useTaskItem: useItem<Api.GetTaskByIdForChatQuery['task']> = (id: string) => {
  const { data, loading } = useQuery<Api.GetTaskByIdForChatQuery>(Api.GetTaskByIdForChatDocument, {
    variables: { where: { id } },
  })

  return { item: data?.task, isLoading: loading }
}

const useEmailItem: useItem<EmailSnapshot> = (data: string) => {
  const email = JSON.parse(data) as EmailSnapshot
  return { item: email, isLoading: false }
}

const useUnsupported = (id: string) => {
  return { item: { id }, isLoading: false }
}

export interface CustomMessageItemLoader {
  useCustomItem: (type: CustomMessageType, data: string) => { item?: unknown; isLoading: boolean }
}

export const useCustomMessageItemLoader = (): CustomMessageItemLoader => {
  return {
    useCustomItem: (type: CustomMessageType, data: string) => {
      switch (type) {
        case CustomMessageType.contact:
          // eslint-disable-next-line react-hooks/rules-of-hooks
          return useContactItem(data)
        case CustomMessageType.faqItem:
          // eslint-disable-next-line react-hooks/rules-of-hooks
          return useFaqItem(data)
        case CustomMessageType.event:
          // eslint-disable-next-line react-hooks/rules-of-hooks
          return useEventItem(data)
        case CustomMessageType.rule:
          // eslint-disable-next-line react-hooks/rules-of-hooks
          return useRuleItem(data)
        case CustomMessageType.post:
          // eslint-disable-next-line react-hooks/rules-of-hooks
          return usePostItem(data)
        case CustomMessageType.file:
          // eslint-disable-next-line react-hooks/rules-of-hooks
          return useFileItem(data)
        case CustomMessageType.task:
          // eslint-disable-next-line react-hooks/rules-of-hooks
          return useTaskItem(data)
        case CustomMessageType.emailsnapshot:
          // eslint-disable-next-line react-hooks/rules-of-hooks
          return useEmailItem(data)
        default:
          // eslint-disable-next-line react-hooks/rules-of-hooks
          return useUnsupported(data)
      }
    },
  }
}
