import { Spacing } from '@walter/shared'
import { Icon } from '@walter/shared-web'
import React from 'react'

type Props = {
  contact: {
    email?: string
    phone?: {
      number: string
      extension?: string
    }
    website?: string
  }
}

export function ContactCardDescription({ contact }: Props) {
  return (
    <div
      // @ts-ignore -> need to allow this...
      // eslint-disable-next-line react/no-unknown-property
      css={`
        display: inline-flex;
        align-items: center;
      `}
    >
      {contact?.email && (
        <>
          <Icon size="small" icon="inbox" />
          <div
            // @ts-ignore -> need to allow this...
            // eslint-disable-next-line react/no-unknown-property
            css={`
              width: ${Spacing.small};
            `}
          />
          <span>{contact.email}</span>
        </>
      )}

      {contact?.phone?.number && (
        <>
          {contact.email && (
            <div
              // @ts-ignore -> need to allow this...
              // eslint-disable-next-line react/no-unknown-property
              css={`
                margin-left: ${Spacing.small};
              `}
            />
          )}
          <Icon size="small" icon="phone" />
          <div
            // @ts-ignore -> need to allow this...
            // eslint-disable-next-line react/no-unknown-property
            css={`
              width: ${Spacing.small};
            `}
          />
          <span>
            {contact.phone.number}
            {contact.phone.extension ? ` #${contact.phone.extension}` : ''}
          </span>
        </>
      )}

      {!!contact.website && (
        <>
          {(contact.email || contact.phone?.number) && (
            <div
              // @ts-ignore -> need to allow this...
              // eslint-disable-next-line react/no-unknown-property
              css={`
                margin-left: ${Spacing.small};
              `}
            />
          )}
          <Icon size="small" icon="attachment" />
          <div
            // @ts-ignore -> need to allow this...
            // eslint-disable-next-line react/no-unknown-property
            css={`
              width: ${Spacing.small};
            `}
          />
          <span>{contact.website}</span>
        </>
      )}
    </div>
  )
}
