import { Colors, Spacing } from '@walter/shared'
import { globalStyles, typography } from '@walter/shared-web'
import { rgba } from 'polished'
import styled from 'styled-components'
import Logo from '../../static/images/logo.svg'

export const Container = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  height: ${globalStyles.navHeight};
  width: 100%;
  background-color: ${Colors.white};
  border-bottom: 1px solid ${Colors.borderColor};
  z-index: 400;
  padding: 0 ${Spacing.medium};
`

export const Wrap = styled.div`
  height: ${globalStyles.navHeight};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const LogoWrap = styled.div`
  position: relative;
  display: block;
  width: 46px;
  height: 12px;
  flex-shrink: 0;
  margin-left: auto;
  margin-right: auto;
`

export const StyledLogo = styled(Logo)`
  display: block;
  width: 100%;
  height: 100%;
  fill: ${Colors.white};
`

export const Divider = styled.div`
  display: block;
  height: 1px;
  width: 32px;
  background-color: ${rgba(Colors.white, 0.2)};
  margin-top: ${Spacing.small};
  margin-left: auto;
  margin-right: auto;
  flex-shrink: 0;
`

export const NavList = styled.div`
  display: flex;
`

export const Scroll = styled.div`
  position: static;
  display: flex; /* Temporary */
`

export const Profile = styled.div`
  display: flex;
`

export const AvatarWrap = styled.button`
  display: flex;
`

export const Group = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: ${Spacing.medium};
  }

  & > *:last-child {
    margin-right: 0;
  }
`

export const SearchWrap = styled.div`
  padding: 0 ${Spacing.medium};
  flex: 1;
  max-width: 460px;
`

export const Header = styled.header`
  width: 300px;
  margin-right: ${Spacing.large};
`

export const Name = styled.span`
  display: block;
  font-size: ${typography.fontSizes.tiny};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Bell = styled.button`
  position: relative;
  display: flex;
  color: ${Colors.greyDark};
`

export const Project = styled.span`
  display: block;
  font-size: ${typography.fontSizes.small};
  color: ${Colors.greyDark};
  font-weight: ${typography.fontWeights.bold};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const NotificationCount = styled.div`
  position: absolute;
  display: flex;
  display: flex;
  top: -${Spacing.tiny};
  right: -${Spacing.tiny};
  border-radius: ${Spacing.large};
  box-shadow: 0 0 0 2px ${Colors.white};
`
