import React from 'react'
import { Colors, SendBirdUtils } from '@walter/shared'
import { customMessageCardIconName, customMessageCardName } from './CustomMessageCard.entities'
import ChatEvent from '../ChatEvent'

export interface IFaqCustomItem {
  id: string
  question: string
  answer: string
}

export type FaqItemCardProps = {
  faqItem: IFaqCustomItem
  formattedTime: string
}

export const FaqItemCard = (props: FaqItemCardProps) => {
  const { faqItem, formattedTime } = props

  return (
    <ChatEvent
      color={Colors.grey}
      icon={customMessageCardIconName(SendBirdUtils.CustomMessageType.faqItem)}
      name={customMessageCardName(SendBirdUtils.CustomMessageType.faqItem)}
      time={formattedTime}
      title={faqItem.question}
      description={faqItem.answer}
      isHTML
    />
  )
}
