// The x before any variables means that it gets bigger and bigger
export const extraTiny = '1px'
export const tiny = '4px'
export const xTiny = '6px'
export const small = '8px'
export const xSmall = '10px'
export const xxSmall = '12px'
export const xxxSmall = '14px'
export const medium = '16px'
export const xMedium = '18px'
export const xxMedium = '20px'
export const xxxMedium = '22px'
export const large = '24px'
export const xLarge = '32px'
export const xxLarge = '40px'
export const xxxLarge = '56px'
export const extraLarge = '64px'
export const xExtraLarge = '72px'
