import React from 'react'
import { OpenSourceLicenses } from '@walter/shared'

interface Props {
  app: 'managerWeb' | 'residentWeb'
}

export function OpenSourceAttribution({ app }: Props) {
  return (
    <div>
      <h1>Open Source Attribution</h1>
      <ul>
        {OpenSourceLicenses[app].map((l) => {
          const displayName = l.repository ? `${l.name} (${l.licenses})` : l.name
          return (
            <div key={displayName}>
              -{' '}
              {l.repository ? (
                <a href={l.repository} target="_blank" rel="noreferrer">
                  {displayName}
                </a>
              ) : (
                <span>{displayName}</span>
              )}
            </div>
          )
        })}
      </ul>
    </div>
  )
}
