/* eslint-disable react/prop-types */
import { WebDateUtils } from '../utils/date'
import React from 'react'
import { Portal } from 'react-portal'
import { Toast, ToastGroup, ToastType } from '../components'

type ToastContextType = {
  showToast: (
    type: ToastType,
    title: string,
    description?: string,
    duration?: number,
    showEachToastOnce?: boolean,
  ) => void
}

export const ToastContext = React.createContext<ToastContextType>({} as ToastContextType)

type ToastState = {
  id: number
  title: string
  type: ToastType
  text?: string
  description?: string
  duration?: number
  showEachToastOnce?: boolean
}

export function ToastProvider({ children }: { children: React.ReactNode }) {
  const [toasts, setToasts] = React.useState<ToastState[]>([])

  const showToast = (
    type: ToastType,
    title: string,
    description?: string,
    duration?: number,
    showEachToastOnce?: boolean,
  ) => {
    if (showEachToastOnce && toasts.some((toast) => toast.text === description || toast.title === title)) {
      return
    }

    setToasts((toasts) => [...toasts, { type, title, description, id: WebDateUtils.dayjs().unix(), duration }])
  }

  return (
    <ToastContext.Provider value={{ showToast }}>
      <Portal>
        <ToastGroup>
          {toasts.map((toast, index) => (
            <Toast
              key={toast.text || toast.title}
              index={index}
              visible
              icon={toast.type === 'positive' ? 'checkmark' : toast.type === 'negative' ? 'close' : 'info'}
              type={toast.type}
              title={toast.title}
              text={toast.description}
              duration={toast.duration}
              close={() => setToasts((toasts) => toasts.filter((t) => t.id !== toast.id))}
            />
          ))}
        </ToastGroup>
      </Portal>
      {children}
    </ToastContext.Provider>
  )
}
