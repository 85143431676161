// @ts-nocheck
/**
 * TODO: Refactor following this example https://codesandbox.io/s/github/ggascoigne/react-table-example?file=/src/Table/Table.tsx:978-992
 * Typescript
 */
import { Colors, Spacing, Types } from '@walter/shared'
import { mix, rgba, stripUnit } from 'polished'
import React from 'react'
import { CellProps, HeaderProps, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import styled, { css } from 'styled-components'
import { borderRadius, boxShadow } from '../../styles/global'
import { fontSizes } from '../../styles/typography'
import { Checkbox } from '../Checkbox'
import { EmptyState } from '../EmptyState'
import { Icon } from '../Icon'
import { Cell } from './Cell'
import { Pagination } from './Pagination'
import { t } from '../../utils'
export { Pagination }

export const DataTableContainer = styled.div`
  display: block;
  border-radius: ${borderRadius};
  border: 1px solid ${Colors.borderColor};
  background-color: ${Colors.white};
  overflow: hidden;
  margin-bottom: ${Spacing.xLarge};
  ${boxShadow};

  table,
  .table {
    width: 100%;
    min-width: 900px;
    border-collapse: separate;
    border-spacing: 0;
  }

  th,
  .th {
    border-bottom: 1px solid ${Colors.borderColor};
    font-size: ${fontSizes.small};
    color: ${Colors.greyDark};
    background-color: ${mix(0.5, Colors.offWhite, Colors.white)};
  }

  th,
  td,
  .th,
  .td {
    text-align: left;
    padding: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`} ${Spacing.small};

    &:first-child {
      padding-left: ${Spacing.medium};
    }

    &:last-child {
      padding-right: ${Spacing.medium};
    }
  }

  td,
  .td {
    border-bottom: 1px solid ${rgba(Colors.borderColor, 0.5)};
  }

  tr,
  .tr {
    ${(props) =>
      props.rowClickIsEnable &&
      css`
        cursor: pointer;
      `}
  }

  tr:last-child td,
  .tr:last-child .td {
    border-bottom: 0;
  }
`

const TableHeader = styled.span`
  display: flex;
  align-items: center;
`

const Scroll = styled.div`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${Spacing.medium} 0;
`

export type RowAction = { label: string; onClick: (e: MouseEvent) => void }

type DataTableProps<T> = {
  dataTestId?: string
  fields: Types.Field[]
  data: T[]
  loading?: boolean
  rowActions?: ({ row }) => RowAction[]
  onRowClick?: (row: any) => void
  rowCount?: number
  numberOfRowsPerPage?: number
  onChangePage?: (page: number) => void
  initialPageIndex?: number
  withRowSelection?: boolean
  withSelectOnlyOneRow?: boolean
  emptyContentTitle?: string
  testID?: string
  onNextPage?: () => void
  paginationVariant?: 'default' | 'minimal'
}

// Maybe we want to pass fields instead of already formatted columsn. (fields are taken from the Fields folder)
export function DataTable<T extends Record<string, unknown>>({
  dataTestId,
  fields,
  data,
  loading,
  rowActions,
  onRowClick,
  rowCount,
  numberOfRowsPerPage = 10,
  onChangePage,
  initialPageIndex = 0,
  withRowSelection,
  withSelectOnlyOneRow,
  emptyContentTitle,
  testID,
  onNextPage,
  paginationVariant = 'default',
}: DataTableProps<T>) {
  const parsedColumns = React.useMemo(
    () =>
      fields
        .filter((field) => !field.tableHidden)
        .map((field) => ({
          accessor: field.value || field.path,
          Header: field.label,
          canSort: false, // Boolean(field.sortType),
          ...(field.cellWidth && {
            width: field.cellWidth,
          }),
          ...(field.cellMinWidth && {
            width: field.cellMinWidth,
          }),
          ...field,
        })),
    [fields],
  )

  const columnsWithActions = React.useMemo(() => {
    let columns = parsedColumns

    if (rowActions) {
      columns = [
        ...columns,
        {
          // Header: 'Actions',
          accessor: 'actions',
          type: 'actions',
          actions: rowActions,
        },
      ]
    }

    if (withRowSelection || withSelectOnlyOneRow) {
      columns = [
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<any>) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} testID={'datatable-header-checkbox'} />
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }: CellProps<any>) => <Checkbox {...row.getToggleRowSelectedProps()} />,
        },
        ...columns,
      ]
    }

    return columns
  }, [parsedColumns, rowActions, withRowSelection, withSelectOnlyOneRow])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // Pagination
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    setPageSize,
    toggleRowSelected,
    state: { selectedRowIds, pageIndex, pageSize },
  } = useTable<T>(
    {
      data,
      columns: columnsWithActions,
      // filterTypes,
      ...(rowCount && {
        manualPagination: true,
        pageSize: numberOfRowsPerPage,
        pageCount: Math.floor(rowCount / 10),
      }),
      initialState: { pageIndex: initialPageIndex },
      autoResetPage: false,
    },
    useSortBy,
    usePagination,
    useRowSelect,
  )

  // Did that because I wanted to implemented an infinite list.
  // Example in resident module, instead of fetching all residents,
  // We would only fetch a little bit at time
  // And fetch new each time we go to next page
  // Decided not to do it because it saved some loading time but not enougth...
  // We also prefer to load all residents because we use the same query in a lot of place
  // In the app for connecting resident to something (ex: properties)
  const handleGoToPage = (page) => {
    if (onChangePage) {
      onChangePage(page)
    }
    gotoPage(page)
  }

  return (
    <>
      <DataTableContainer
        data-test-id={`${dataTestId}_DataTable_Container`}
        rowClickIsEnable={onRowClick}
        data-testid={testID ?? ''}
      >
        <Scroll>
          {/* MAIN */}
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, headerGroupIndex) => (
                <tr
                  data-test-id={`${dataTestId}_Header_Row`}
                  {...headerGroup.getHeaderGroupProps()}
                  key={headerGroupIndex}
                >
                  {headerGroup.headers.map((column, columnIndex) => {
                    const headerColName = column.dataTestId
                    return (
                      <th
                        data-test-id={`${dataTestId}_Header_Col_${headerColName}`}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        key={columnIndex}
                      >
                        <TableHeader>
                          {column.render('Header')}&nbsp;
                          <>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <Icon icon="down-chevron" size="small" />
                              ) : (
                                <Icon icon="up-chevron" size="small" />
                              )
                            ) : (
                              ''
                            )}
                          </>
                        </TableHeader>
                      </th>
                    )
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr>
                  <td colSpan={12}>
                    <LoadingWrapper>
                      <EmptyState isLoading heading={t('loading')} />
                    </LoadingWrapper>
                  </td>
                </tr>
              ) : data.length === 0 ? (
                <tr>
                  <td colSpan={12}>
                    <LoadingWrapper>
                      <EmptyState
                        icon="inbox"
                        heading={emptyContentTitle ? emptyContentTitle : t('no-data-available')}
                      />
                    </LoadingWrapper>
                  </td>
                </tr>
              ) : (
                page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr
                      data-test-id={`${dataTestId}_Row_${i}`}
                      key={i}
                      {...row.getRowProps()}
                      onClick={() => {
                        if (withSelectOnlyOneRow) {
                          Object.keys(selectedRowIds).forEach((rowId) => {
                            if (rowId) {
                              toggleRowSelected(rowId, false)
                            }
                          })
                          toggleRowSelected(row.id, !selectedRowIds[row.id])
                        }

                        if (withRowSelection) {
                          toggleRowSelected(row.id, !selectedRowIds[row.id])
                        }

                        if (onRowClick) {
                          onRowClick(row)
                        }
                      }}
                    >
                      {row.cells.map((cell, cellIndex) => {
                        const colName = row.cells[cellIndex].column.dataTestId
                        return (
                          <td data-test-id={`${dataTestId}_Col_${colName}`} key={cellIndex}>
                            {cell.render(Cell)}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })
              )}
            </tbody>
          </table>
        </Scroll>
      </DataTableContainer>

      <Pagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        nextPage={async () => {
          onNextPage && onNextPage()
          handleGoToPage(pageIndex + 1)
        }}
        previousPage={() => handleGoToPage(pageIndex - 1)}
        setPageSize={setPageSize}
        pageSize={pageSize}
        pageIndex={pageIndex}
        variant={paginationVariant}
      />
    </>
  )
}
