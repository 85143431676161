import React from 'react'
import styled from 'styled-components'
import { Colors, Spacing } from '@walter/shared'
import { stripUnit } from 'polished'
import { ErrorMessage } from '../ErrorMessage'
import { Label } from '../Label'

const Container = styled.div`
  display: block;
  width: 100%;
`

const Wrap = styled.div<{ error: string | boolean }>`
  position: relative;
  ${(props) =>
    props.error &&
    `
    box-shadow: inset 0 0 0 1px ${Colors.red}, 0 0 0 1px ${Colors.red};
  `}
`

const Hint = styled.span`
  margin-left: ${`${(stripUnit(Spacing.tiny) as number) * 0.5}px`};
`

type FieldContainerProps = {
  children: React.ReactNode
  label?: string
  hint?: string
  error?: string
  dataTestId?: string
}

export const FieldContainer = ({ children, label, hint, error, dataTestId }: FieldContainerProps) => {
  return (
    <Container data-test-id={`${dataTestId}_FieldContainer`} data-cy={label}>
      {label && (
        <Label>
          {label}
          {hint && <Hint data-tip={hint}>(?)</Hint>}
        </Label>
      )}
      <Wrap data-test-id={dataTestId} error={!!error}>
        {children}
      </Wrap>
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  )
}
