import { Message } from '../messaging'
import dayjs from 'dayjs'
import dayOfYear from 'dayjs/plugin/dayOfYear'

dayjs.extend(dayOfYear)

export function groupMessages(messages: Message[]) {
  return groupMessagesByDay(messages).map((dayGrouping) => {
    return {
      title: dayjs(dayGrouping[0].createdAt).format('LL'),
      data: groupMessagesByUser(dayGrouping),
    }
  })
}

function groupMessagesByDay(messages: Message[]) {
  return messages.reduce<Array<Array<Message>>>((acc, msg, index) => {
    const day = dayjs(msg.createdAt).dayOfYear()
    if (index === 0) {
      acc.push([msg])
      return acc
    }

    const lastGroup = acc[acc.length - 1]
    const previousMessage = lastGroup[lastGroup.length - 1]
    const lastDay = dayjs(previousMessage.createdAt).dayOfYear()
    if (day === lastDay) {
      acc[acc.length - 1].push(msg)
    } else {
      acc.push([msg])
    }

    return acc
  }, [])
}

function groupMessagesByUser(messages: Message[]) {
  return messages.reduce<Message[][]>((acc, msg, index) => {
    if (index === 0) {
      acc.push([msg])
      return acc
    }

    const previousGroup = acc[acc.length - 1]
    const previousUser = previousGroup[0].sender.userId
    const thisUser = msg.sender.userId

    if (thisUser === previousUser) {
      acc[acc.length - 1].push(msg)
    } else {
      acc.push([msg])
    }

    return acc
  }, [])
}
