import React from 'react'
import styled from 'styled-components'
import { ConditionalWrap } from '../ConditionalWrap'
import { FormDataViewContext } from './FormDataView'
import { FormDataViewActions } from './FormDataViewActions'
import { FormDataViewField } from './FormDataViewField'
import { FormSection } from './helpers'

const ColumnedDiv = styled.div<{ rowsOnly: boolean }>`
  display: grid;
  grid-template-columns: ${({ rowsOnly }) => (rowsOnly ? '1fr' : '1fr 1fr')};
`

export function FormDataViewSimple() {
  const { fields, values, isPadded, rowsOnly } = React.useContext(FormDataViewContext)

  return (
    <ConditionalWrap condition={!!isPadded} wrap={(children) => <FormSection>{children}</FormSection>}>
      <ColumnedDiv rowsOnly={!!rowsOnly}>
        {fields.map((field) => (
          <FormDataViewField field={field} key={field.path || field.label} values={values} />
        ))}
        <FormDataViewActions />
      </ColumnedDiv>
    </ConditionalWrap>
  )
}
