import React from 'react'
import { customMessageCardIconName, customMessageCardName } from './CustomMessageCard.entities'
import { Colors, SendBirdUtils } from '@walter/shared'
import ChatEvent from '../ChatEvent'

export interface IRuleCustomItem {
  id: string
  createdAt: string
  title?: string
  description?: string | null
}

export type RuleItemCardProps = {
  rule: IRuleCustomItem
  formattedTime: string
}

export const RuleItemCard = (props: RuleItemCardProps) => {
  const { rule, formattedTime } = props

  return (
    <ChatEvent
      color={Colors.grey}
      icon={customMessageCardIconName(SendBirdUtils.CustomMessageType.rule)}
      name={customMessageCardName(SendBirdUtils.CustomMessageType.rule)}
      time={formattedTime}
      title={rule.title}
      description={rule.description}
      isHTML
    />
  )
}
