import { Colors, Spacing } from '@walter/shared'
import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { animationCurve, animationTime, square } from '../../styles/global'
import { Icon } from '../Icon'
import { Label } from '../Label'

const Container = styled.div`
  display: block;
  width: 100%;
`

const Uploadcss = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${Spacing.small};
  border-radius: 50%;
  background-color: ${rgba(Colors.borderColor, 0.5)};
  cursor: pointer;
  flex-shrink: 0;
  transition: color ${animationTime} ${animationCurve}, background-color ${animationTime} ${animationCurve};
  ${square(Spacing.xLarge)};

  &:hover {
    background-color: ${rgba(Colors.borderColor, 0.75)};
    color: ${Colors.greyDark};
  }
`
type UploadProps = {
  label?: string
}

const Upload = ({ label }: UploadProps) => {
  return (
    <Container>
      {!!label && <Label>{label}</Label>}
      <Uploadcss>
        <Icon icon="attachment" size="small" />
        <input data-cy="upload-input" type="file" style={{ display: 'none' }} />
      </Uploadcss>
    </Container>
  )
}
export { Upload }
