import React from 'react'
import styled from 'styled-components'
import { Spacing } from '@walter/shared'
import { stripUnit } from 'polished'

const Container = styled.fieldset`
  display: flex;
  flex-wrap: wrap;
  margin: -${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
`

type FieldsetProps = {
  children: React.ReactNode
}

export const Fieldset = ({ children }: FieldsetProps) => <Container>{children}</Container>
