import { Colors, Spacing, UploadUtils } from '@walter/shared'
import { t, ToastContext } from '@walter/shared-web'
import { rgba, stripUnit } from 'polished'
import { uniqBy } from 'ramda'
import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import { useFormContext } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { borderRadius, cover, square } from '../../../styles/global'
import { ErrorMessage } from '../../ErrorMessage'
import { Icon } from '../../Icon'
import { Label } from '../../Label'
import { Button } from '../Button'
import { FileWithPreview, readFilesFromInput, WalterFile } from './common'

const Inner = styled.div<{ stacked: string | boolean }>`
  display: flex;
  align-items: center;

  ${(props) =>
    props.stacked &&
    css`
      display: block;
    `}
`

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${`${(stripUnit(Spacing.tiny) as number) * 1.5}px`};
`

const ListItem = styled.div`
  padding: ${`${(stripUnit(Spacing.tiny) as number) * 1.5}px`};
`

const PhotoWrap = styled.div<{ stacked: string | boolean }>`
  display: flex;
  position: relative;
  ${square('48px')};
  border-radius: ${borderRadius};
  margin-right: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};

  ${(props) =>
    props.stacked &&
    css`
      margin-right: 0;
    `}

  &:after {
    content: '';
    border-radius: ${borderRadius};
    box-shadow: inset 0 0 0 1px ${rgba(Colors.black, 0.1)};
    ${cover('absolute')};
  }
`

const PhotoMask = styled.div`
  position: relative;
  ${square('48px')};
  border-radius: ${borderRadius};
  overflow: hidden;
`

const Photo = styled.img`
  object-fit: cover;
  ${cover('absolute')};
`

const PhotoDelete = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: ${rgba(Colors.black, 0.75)};
  color: ${Colors.white};
  border-radius: 50%;
  right: -4px;
  top: -4px;
  ${square('20px')};
  z-index: 2; /* Position above overlay */
`

const Hint = styled.span`
  margin-left: ${`${(stripUnit(Spacing.tiny) as number) * 0.5}px`};
`

const Overlay = styled.a`
  ${cover('absolute')};
  z-index: 1;
`

const ButtonWrap = styled.div<{ stacked: string | boolean }>`
  display: flex;

  ${(props) =>
    props.stacked &&
    css`
      margin-top: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
    `}
`

const UploadWrap = styled.span<{ stacked: string | boolean }>`
  ${(props) =>
    props.stacked &&
    css`
      margin-right: ${Spacing.small};
    `}
`
const Padding = styled.div`
  padding-right: ${Spacing.xTiny};
`

type ImageUploadProps = {
  dataTestId?: string
  label?: string
  hint?: string
  name: string
  disabled?: boolean
  maxMegabyteSingle?: number
  maxMegabyteMulti?: number
  preventClear?: boolean
  isLableLarge?: boolean
}

export function ImageUploadSingle({
  label,
  hint,
  name,
  disabled = false,
  dataTestId,
  maxMegabyteSingle = 20,
  preventClear,
  isLableLarge = false,
}: ImageUploadProps) {
  const { showToast } = React.useContext(ToastContext)
  const [, setFileName] = React.useState('Image')
  const {
    formState: { isSubmitting, errors },
    setValue,
    watch,
  } = useFormContext()

  const errorMessage = errors[name as string]?.message as unknown as string
  const fileHandled: FileWithPreview | WalterFile = watch(name)

  const handleInputChange = (acceptedFiles: File[]) => {
    const filesRead = readFilesFromInput(acceptedFiles)
    try {
      UploadUtils.validateFileSize(acceptedFiles, maxMegabyteSingle * 1024 * 1024, maxMegabyteSingle * 1024 * 1024)
    } catch (error) {
      showToast(
        'negative',
        t('error-file-size-limit-exceeded', {
          maxMegabyte: maxMegabyteSingle,
          maxTotalMegabyte: maxMegabyteSingle,
        }),
      )
      return
    }
    setFileName('Image_' + filesRead[0].name)
    setValue(name, filesRead[0], { shouldDirty: true })
  }

  const clearFile = () => {
    setValue(name, null, { shouldDirty: true })
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleInputChange,
    accept: 'image/*',
    multiple: false,
  })

  const previewUrl =
    (fileHandled && (('preview' in fileHandled && fileHandled.preview) || ('url' in fileHandled && fileHandled.url))) ||
    undefined

  return (
    <>
      <Label xlarge={isLableLarge}>
        {label}
        {hint && <Hint data-tip={hint}>(?)</Hint>}
      </Label>
      {fileHandled && (
        <PhotoWrap stacked={false}>
          <PhotoMask>
            <Overlay href={previewUrl} target="_blank" rel="noopener noreferrer" />
            <Photo data-test-id="Item_Img" src={previewUrl} />
          </PhotoMask>
        </PhotoWrap>
      )}

      <ButtonWrap stacked={true}>
        <UploadWrap {...getRootProps()} stacked={false}>
          <input
            {...getInputProps()}
            disabled={disabled || isSubmitting}
            aria-invalid={!!errorMessage}
            aria-errormessage={`${name}-error-message`}
          />
          <Button
            data-test-id={dataTestId + '_Upload_Button'}
            isSmall
            text={t('add-file')}
            disabled={disabled || isSubmitting}
          />
        </UploadWrap>
      </ButtonWrap>
      <Padding></Padding>
      {!preventClear && fileHandled && (
        <ButtonWrap stacked={true}>
          <Button
            data-test-id={dataTestId + '_Remove_Button'}
            isSmall
            text={t('remove')}
            onClick={clearFile}
            disabled={isSubmitting || disabled}
          />
        </ButtonWrap>
      )}
      {errorMessage && (
        <ErrorMessage id={`${name}-error-message`} data-cy="error-message">
          {errorMessage}
        </ErrorMessage>
      )}
    </>
  )
}

export function ImageUploadMulti({
  label,
  hint,
  name,
  disabled,
  dataTestId,
  maxMegabyteSingle = 20,
  maxMegabyteMulti = 24,
  preventClear,
  isLableLarge = false,
}: ImageUploadProps) {
  const { showToast } = React.useContext(ToastContext)
  const {
    formState: { isSubmitting, errors },
    setValue,
    watch,
  } = useFormContext()
  const errorMessage = errors[name as string]?.message as unknown as string
  const filesHandled: (FileWithPreview | WalterFile)[] = watch(name) ?? []

  const handleInputChange = (acceptedFiles: File[]) => {
    const filesRead = readFilesFromInput(acceptedFiles)

    try {
      UploadUtils.validateFileSize(acceptedFiles, maxMegabyteSingle * 1024 * 1024, maxMegabyteMulti * 1024 * 1024)
    } catch (error) {
      showToast(
        'negative',
        t('error-files-size-limit-exceeded', { maxMegabyte: maxMegabyteSingle, maxTotalMegabyte: maxMegabyteMulti }),
      )
      return
    }

    setValue(
      name,
      uniqBy((f) => f.name, [...filesHandled, ...filesRead]),
      { shouldDirty: true },
    )
  }

  const clearFile = (urlOrPreview: string) => {
    setValue(
      name,
      filesHandled.filter(
        (f) => ('preview' in f && f.preview !== urlOrPreview) || ('url' in f && f.url !== urlOrPreview),
      ),
      { shouldDirty: true },
    )
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleInputChange,
    accept: 'image/*',
    multiple: true,
  })

  return (
    <>
      <Label xlarge={isLableLarge}>
        {label}
        {hint && <Hint data-tip={hint}>(?)</Hint>}
      </Label>
      <Inner stacked={true}>
        {filesHandled.length > 0 && (
          <List data-test-id="Images_List">
            {filesHandled.map((file, i) => {
              const previewUrl = ('preview' in file && file.preview) || ('url' in file && file.url) || undefined
              return (
                <ListItem data-test-id={`Item_${file.name}`} key={i}>
                  <PhotoWrap stacked={true}>
                    <PhotoMask>
                      <Overlay href={previewUrl} target="_blank" rel="noopener noreferrer" />
                      <Photo data-test-id="Item_Img" src={previewUrl} />
                    </PhotoMask>

                    {!preventClear && (
                      <PhotoDelete
                        data-test-id="Item_Remove"
                        onClick={() => {
                          if (previewUrl) {
                            clearFile(previewUrl)
                          }
                        }}
                        type="button"
                        disabled={disabled || isSubmitting}
                      >
                        <Icon icon="close" size="tiny" />
                      </PhotoDelete>
                    )}
                  </PhotoWrap>
                </ListItem>
              )
            })}
          </List>
        )}

        <ButtonWrap stacked={!!filesHandled.length}>
          <UploadWrap {...getRootProps()} stacked={true}>
            <input {...getInputProps()} disabled={disabled || isSubmitting} />
            <Button
              data-test-id={dataTestId + '_Upload_Button'}
              isSmall
              text={t('add-photos')}
              disabled={disabled || isSubmitting}
            />
          </UploadWrap>
        </ButtonWrap>
        {!preventClear && filesHandled?.length > 0 && (
          <ButtonWrap stacked={true}>
            <Button
              data-test-id={dataTestId + '_Remove_Button'}
              isSmall
              text={filesHandled.length > 1 ? t('remove-all') : t('remove')}
              onClick={() => {
                setValue(name, [], { shouldDirty: true })
              }}
              disabled={disabled || isSubmitting}
            />
          </ButtonWrap>
        )}
      </Inner>
      {errorMessage && <ErrorMessage data-cy="error-message">{errorMessage}</ErrorMessage>}
    </>
  )
}
