import { Config } from '@walter/shared'
import { UserUtils } from '@walter/shared-web'
import React, { useContext } from 'react'
import { hotjar } from 'react-hotjar'
import AuthContext from './Auth'

export default function HotJarProvider({ children }: { children: React.ReactNode }) {
  const { currentUser } = useContext(AuthContext)

  const shouldNotRecord = React.useMemo(() => {
    return !currentUser || Config.host.includes('localhost') || currentUser?.email?.includes('usewalter.com')
  }, [currentUser])

  React.useEffect(() => {
    // We're sometimes using staging/prod env locally
    if (!shouldNotRecord) {
      return
    }
    if (Config.isStaging) {
      hotjar.initialize(2541783, 6)
    } else if (Config.isProduction) {
      hotjar.initialize(2541794, 6)
    }
  }, [shouldNotRecord])

  React.useEffect(() => {
    if (shouldNotRecord) {
      return
    }
    if (currentUser) {
      hotjar.identify(currentUser.id, {
        name: UserUtils.getUserName(currentUser),
        email: currentUser.email,
        managingCompanyName: currentUser.managingCompany?.shortName,
        roleTitle: currentUser.managingCompanyRole?.title,
      })
    }
  }, [currentUser, shouldNotRecord])

  return <>{children}</>
}
