import { z } from 'zod'
import { i18n, t } from '../../utils'

export const Rulei18nFields = {
  selectedProject: t('selected-project'),
  title: t('form-label-title'),
  description: t('description'),
  attachments: t('attachments'),
}

export const ruleSchema = z.object({
  projects: z
    .array(z.any(), { required_error: i18n.t('input:is-required', { field: Rulei18nFields.selectedProject }) })
    .min(1, i18n.t('input:is-required', { field: Rulei18nFields.selectedProject })),
  title: z
    .string({ required_error: i18n.t('input:is-required', { field: Rulei18nFields.title }) })
    .min(1, { message: i18n.t('input:is-required', { field: Rulei18nFields.title }) }),
  description: z.string().optional(),
  attachments: z.any().optional(),
})

export type RuleFormItem = z.infer<typeof ruleSchema>
