import { Colors } from '@walter/shared'
import { css } from 'styled-components'

// Font family
export const fontFamily =
  "-apple-system, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"

// Font weights
export const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 700,
  black: 800,
}

export const lineHeights = {
  base: 1.5,
  heading: 1.25,
  tight: 1.05,
}

// Font sizes
export const fontSizes = {
  extralarge: '30px',
  large: '20px',
  regular: '16px',
  small: '14px',
  smaller: '13px',
  tiny: '12px',
  minuscule: '10px',
  tiniest: '8px',
}

export default css`
  body {
    color: ${Colors.grey};
    font-size: ${fontSizes.regular};
    font-family: ${fontFamily};
    line-height: ${lineHeights.base};
  }

  small {
    font-size: ${fontSizes.small};
  }

  p {
    margin-bottom: 1.5em;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: ${Colors.greyDark};
      text-decoration: underline;
    }
  }
`
