import { useEffect } from 'react'
import { WebLogger } from '../utils'

export function useUpdateTimezone(
  currentUser: { timezone: string },
  updateCurrentUser: (values: any) => Promise<void>,
) {
  useEffect(() => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    if (currentUser && userTimezone !== currentUser?.timezone) {
      updateCurrentUser({ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }).catch(WebLogger.captureError)
    }
  }, [currentUser])
}
