import dayjsFn from 'dayjs'
import 'dayjs/locale/fr'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import localeData from 'dayjs/plugin/localeData'
import duration from 'dayjs/plugin/duration'
import weekday from 'dayjs/plugin/weekday'

dayjsFn.locale()
dayjsFn.extend(relativeTime)
dayjsFn.extend(localizedFormat)
dayjsFn.extend(timezone)
dayjsFn.extend(localeData)
dayjsFn.extend(duration)
dayjsFn.tz.setDefault(dayjsFn.tz.guess())
dayjsFn.extend(weekday)

type Dayjs = typeof dayjsFn

export type DayjsDate = Parameters<typeof dayjsFn>[0]

export class DateUtilsFactory {
  dayjs: Dayjs

  constructor(dayjsFn: Dayjs) {
    this.dayjs = dayjsFn
  }

  date(date?: DayjsDate) {
    return this.dayjs(date ?? new Date())
  }

  setLocale(locale: 'en' | 'fr') {
    this.dayjs.locale(locale)
  }

  months() {
    return this.dayjs.months()
  }

  /**
   * @info Suffix example: "in" from "in 2 days"
   * */
  from(start: DayjsDate, end: DayjsDate, excludeSuffix = false) {
    const isFuture = this.date(start).isAfter(this.date(end))
    return isFuture ? this.date(start).to(end, excludeSuffix) : this.date(start).from(end, excludeSuffix)
  }

  /**
   * @info Suffix example: "in" from "in 2 days"
   * */
  fromNow(date: DayjsDate, excludeSuffix = false) {
    const isFuture = this.date(date).isAfter(this.date())
    return isFuture ? this.date(date).toNow(excludeSuffix) : this.date(date).fromNow(excludeSuffix)
  }

  format(date?: DayjsDate, template?: string) {
    return this.date(date ?? new Date()).format(template)
  }

  getUnixTime(date?: DayjsDate) {
    return this.date(date ?? new Date()).unix()
  }

  diffInDays(date1: DayjsDate, date2: DayjsDate) {
    return this.date(date1).diff(this.date(date2), 'day')
  }

  startOfMonth(date?: DayjsDate) {
    return this.date(date).startOf('month')
  }

  endOfMonth(date?: DayjsDate) {
    return this.date(date).endOf('month')
  }

  getHour(date?: DayjsDate) {
    return this.date(date ?? new Date()).hour()
  }

  toDate(date?: DayjsDate) {
    return this.date(date ?? new Date()).toDate()
  }

  startOf(date?: DayjsDate, unit?: dayjsFn.OpUnitType) {
    return this.date(date ?? new Date()).startOf(unit ?? 'day')
  }

  endOf(date?: DayjsDate, unit?: dayjsFn.OpUnitType) {
    return this.date(date ?? new Date()).endOf(unit ?? 'day')
  }

  toISOString(date?: DayjsDate) {
    return this.date(date ?? new Date()).toISOString()
  }

  getCurrentMonth() {
    return this.date().month() + 1
  }

  getCurrentYear() {
    return this.date().year()
  }

  isBefore(start: DayjsDate, end: DayjsDate) {
    return this.date(start).isBefore(end)
  }

  isAfter(start: DayjsDate, end: DayjsDate) {
    return this.date(start).isAfter(end)
  }

  isBeforeNow(start: DayjsDate) {
    return this.date(start).isBefore(this.date())
  }

  daysInMonth(date?: DayjsDate) {
    return this.date(date).daysInMonth()
  }

  isSameDay(date1: DayjsDate, date2: DayjsDate) {
    return this.date(date1).isSame(date2, 'day')
  }
  isSame(date1: DayjsDate, date2: DayjsDate) {
    return this.date(date1).isSame(date2)
  }
}
