import { Colors, Spacing } from '@walter/shared'
import { stripUnit } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { borderRadius } from '../../styles/global'
import { fontSizes } from '../../styles/typography'
import { t } from '../../utils'

const Container = styled.div`
  padding: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
  text-align: center;
  font-size: ${fontSizes.small};
  color: ${Colors.greyLight};
  border: 1px dashed ${Colors.borderColor};
  border-radius: ${borderRadius};
`

const ListEmptyState = () => <Container>{t('empty')}</Container>

export default ListEmptyState
