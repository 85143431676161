import React from 'react'
import { EmptyState } from '../EmptyState'

interface ErrorStateProps {
  errorMessage: string
}

export function ErrorState({ errorMessage }: ErrorStateProps) {
  return <EmptyState icon="error" heading="Error" description={errorMessage || 'Something wrong'} />
}
