import { TimesUtils, Types } from '@walter/shared'
import { WebDateUtils } from '../utils/date'
import get from 'lodash/get'
import { t } from '../utils'

type Values<T> = Record<string, T>

export const BUSINESS_HOURS_FIELDS: Types.Field[] = [
  {
    path: 'dayOfWeek',
    label: t('day-of-the-week'),
    type: 'dropdown',
    width: '1-4',
    disabled: true,
    options: [
      { value: 0, label: t('sunday') },
      { value: 1, label: t('monday') },
      { value: 2, label: t('tuesday') },
      { value: 3, label: t('wednesday') },
      { value: 4, label: t('thursday') },
      { value: 5, label: t('friday') },
      { value: 6, label: t('saturday') },
    ],
  },
  {
    path: 'openAtMS',
    label: t('open-at'),
    type: 'time',
    width: '1-4',
    value<T extends Values<number>, K extends keyof Values<number>>(values: T, path: K) {
      const openAtMS: number = get(values, path)
      const newDate = new Date()

      newDate.setHours(TimesUtils.getHoursFromMs(openAtMS))
      newDate.setMinutes(TimesUtils.getMinutesFromMs(openAtMS))
      newDate.setSeconds(TimesUtils.getSecondsFromMs(openAtMS))
      newDate.setMilliseconds(TimesUtils.getMillisecondsFromMsTime(openAtMS))

      return WebDateUtils.format(newDate, 'HH:mm')
    },
    onChange: (time: string) => {
      const [hours, minutes] = time.split(':')
      return TimesUtils.getMsFromHours(parseInt(hours)) + TimesUtils.getMsFromMinutes(parseInt(minutes))
    },
  },
  {
    path: 'closeAtMS',
    label: t('close-at'),
    type: 'time',
    width: '1-4',
    value<T extends Values<number>, K extends keyof Values<number>>(values: T, path: K) {
      const closeAtMS: number = get(values, path)
      const newDate = new Date()

      newDate.setHours(TimesUtils.getHoursFromMs(closeAtMS))
      newDate.setMinutes(TimesUtils.getMinutesFromMs(closeAtMS))
      newDate.setSeconds(TimesUtils.getSecondsFromMs(closeAtMS))
      newDate.setMilliseconds(TimesUtils.getMillisecondsFromMsTime(closeAtMS))

      return WebDateUtils.format(newDate, 'HH:mm')
    },
    onChange: (time: string) => {
      const [hours, minutes] = time.split(':')
      return TimesUtils.getMsFromHours(parseInt(hours)) + TimesUtils.getMsFromMinutes(parseInt(minutes))
    },
  },
  { path: 'isClosed', width: '1-4', label: t('is-closed'), type: 'boolean' },
]

export const AMENITY_BUSINESS_HOURS_FIELDS: Types.Field[] = [
  {
    path: 'dayOfWeek',
    label: t('day-of-the-week'),
    type: 'dropdown',
    width: '1-4',
    disabled: true,
    options: [
      { value: 0, label: 'Sunday' },
      { value: 1, label: 'Monday' },
      { value: 2, label: 'Tuesday' },
      { value: 3, label: 'Wednesday' },
      { value: 4, label: 'Thursday' },
      { value: 5, label: 'Friday' },
      { value: 6, label: 'Saturday' },
    ],
  },
  {
    path: 'openAtMS',
    label: t('open-at'),
    type: 'time',
    width: '1-4',
    value<T extends Values<number>, K extends keyof Values<number>>(values: T, path: K) {
      const openAtMS: number = isNaN(get(values, path)) ? 0 : get(values, path)
      const newDate = new Date()

      newDate.setHours(TimesUtils.getHoursFromMs(openAtMS))
      newDate.setMinutes(TimesUtils.getMinutesFromMs(openAtMS))
      newDate.setSeconds(TimesUtils.getSecondsFromMs(openAtMS))
      newDate.setMilliseconds(TimesUtils.getMillisecondsFromMsTime(openAtMS))

      return WebDateUtils.format(newDate, 'HH:mm')
    },
    onChange: (time: string) => {
      const [hours, minutes] = time.split(':')
      return TimesUtils.getMsFromHours(parseInt(hours)) + TimesUtils.getMsFromMinutes(parseInt(minutes))
    },
  },
  {
    path: 'closeAtMS',
    label: t('close-at'),
    type: 'time',
    width: '1-4',
    value<T extends Values<number>, K extends keyof Values<number>>(values: T, path: K) {
      const closeAtMS: number = isNaN(get(values, path)) ? 0 : get(values, path)
      const newDate = new Date()

      newDate.setHours(TimesUtils.getHoursFromMs(closeAtMS))
      newDate.setMinutes(TimesUtils.getMinutesFromMs(closeAtMS))
      newDate.setSeconds(TimesUtils.getSecondsFromMs(closeAtMS))
      newDate.setMilliseconds(TimesUtils.getMillisecondsFromMsTime(closeAtMS))

      return WebDateUtils.format(newDate, 'HH:mm')
    },
    onChange: (time: string) => {
      const [hours, minutes] = time.split(':')
      return TimesUtils.getMsFromHours(parseInt(hours)) + TimesUtils.getMsFromMinutes(parseInt(minutes))
    },
  },
  { path: 'isClosed', width: '1-4', label: t('is-closed'), type: 'boolean' },
]
