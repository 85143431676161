import React from 'react'
import styled, { css } from 'styled-components'
import { stripUnit } from 'polished'
import { Spacing, Colors, SharedUtils } from '@walter/shared'
import { typography } from '../../styles'
import { Pill } from '../Pill'
import { Avatar } from '../Avatar'
import { Icon } from '../Icon'

const Container = styled.div<{
  isActive?: boolean
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
}>`
  cursor: pointer;
  overflow: hidden;
  outline: 0;
  padding: ${Spacing.xSmall} ${`${(stripUnit(Spacing.tiny) as number) * 1.5}px`};
  box-sizing: border-box;
  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: ${Colors.primaryColor}00;

  border-bottom: 1px solid ${Colors.greyLightest}70;

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${Colors.primaryColor}20;
      border-left-color: ${Colors.primaryColor};
    `}
  ${(props) =>
    !props.isActive &&
    css`
      &:hover {
        background-color: ${Colors.offWhite};
      }
    `}
`
const Row = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spacing.small};
`

const Main = styled.div`
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
`

const MainTextContainer = styled(Main)`
  display: flex;
  justify-content: space-between;
`

const Label = styled.span<{ isBold?: boolean; isActive?: boolean }>`
  padding-right: ${Spacing.small};
  font-size: ${typography.fontSizes.small};

  ${(props) =>
    props.isBold &&
    css`
      font-weight: ${typography.fontWeights.bold};
      color: ${Colors.greyDark};
    `}
`

const Description = styled.span`
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
  display: block;
  height: 1rem;
  font-size: ${typography.fontSizes.tiny};
  opacity: 0.75;
  margin-top: ${`${(stripUnit(Spacing.tiny) as number) * 0.5}px`};
`

const Heading = styled.span`
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
  display: block;
  height: 1rem;
  font-size: ${typography.fontSizes.tiny};
  font-weight: ${typography.fontWeights.medium};
  opacity: 0.65;
  margin-bottom: ${Spacing.xSmall};
  margin-left: ${Spacing.small};
`

const LeftView = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
`

const BadgeContainer = styled.div`
  position: absolute;
  top: -4px;
  right: -2px;
`

type ConversationProps = {
  label: string
  unreadCount: number
  isActive: boolean
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
  description?: string
  isUnit: boolean
  avatarUrl?: string
  rightText?: string
  heading?: string
}

export const Conversation = ({
  label,
  description,
  unreadCount,
  isActive,
  onClick,
  isUnit,
  avatarUrl,
  rightText,
  heading,
}: ConversationProps) => {
  return (
    <Container
      data-test-id="ConversationItem_Container"
      isActive={isActive}
      aria-current={isActive}
      tabIndex={0}
      role="button"
      onClick={onClick}
    >
      {heading && <Heading>{heading}</Heading>}
      <Row>
        <LeftView>
          {isUnit ? (
            <Icon icon="house" color={isActive ? Colors.primaryColor : Colors.greyLight} />
          ) : (
            <Avatar
              size="medium"
              name={label}
              color={isActive ? Colors.primaryColor : Colors.greyLight}
              photo={avatarUrl}
            />
          )}
          {unreadCount > 0 && (
            <BadgeContainer>
              <Pill type="danger" text={unreadCount} />
            </BadgeContainer>
          )}
        </LeftView>
        <Main>
          <MainTextContainer>
            <Label
              data-test-id={`ConversationItem_${label}`}
              isBold={unreadCount > 0}
              data-cy={`chat-${label}`}
              isActive={isActive}
            >
              {SharedUtils.truncate({
                string: label ?? '',
                maxChars: 19,
                onlyFullWords: false,
              })}
            </Label>
            <span style={{ color: Colors.greyLight, fontSize: 11 }}>
              {SharedUtils.truncate({
                string: rightText ?? '',
                maxChars: 10,
                onlyFullWords: false,
              })}
            </span>
          </MainTextContainer>
          {description && <Description>{description}</Description>}
        </Main>
      </Row>
    </Container>
  )
}
