import React from 'react'
import { Button } from '../Button'
import { ButtonGroup } from '../ButtonGroup'
import { ConditionalWrap } from '../ConditionalWrap'
import { Fieldset } from '../Fieldset'
import { FieldsetItem } from '../Fieldset/FieldsetItem'
import { FormDataViewContext } from './FormDataView'
import { t } from '../../utils'
export function FormDataViewActions() {
  const { actions, close, additionalActions, isByAccordion, isBySection, cancelActionText } =
    React.useContext(FormDataViewContext)

  if (thereIsNoActionsToShow(actions, close, additionalActions)) {
    return null
  }

  return (
    <ConditionalWrap condition={isBySection || isByAccordion} wrap={(children) => <Fieldset>{children}</Fieldset>}>
      <FieldsetItem dataTestId="Form_Actions" width="1-1">
        <>
          {actions ? (
            <ButtonGroup>
              {actions.map(({ title, type, ...restActionProps }) => (
                <Button key={title} dataTestId={title} {...restActionProps} {...(type && { type })}>
                  {title}
                </Button>
              ))}
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              {close && (
                <Button theme="tertiary" onClick={close} dataTestId="CloseOrCancel_Button">
                  {cancelActionText || t('close')}
                </Button>
              )}

              {additionalActions?.map((action) => (
                <Button key={action.title} dataTestId={action.title} {...action}>
                  {action.title}
                </Button>
              ))}
            </ButtonGroup>
          )}
        </>
      </FieldsetItem>
    </ConditionalWrap>
  )
}

function thereIsNoActionsToShow(actions?: unknown[], close?: () => void, additionalActions?: unknown[]) {
  return !actions?.length && !close && !additionalActions?.length
}
