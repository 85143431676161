import { Api } from '@walter/shared'
import { useProjectId } from './useProjectId'

export function useProjectManagerWeb() {
  const projectId = useProjectId()

  return Api.useProjectQuery({
    skip: projectId === 'all',
    variables: {
      where: {
        id: projectId,
      },
    },
  })
}

export function useProjectThirdPartySettingManagerWeb() {
  const projectId = useProjectId()

  return Api.useProjectThirdPartySettingQuery({
    variables: {
      where: {
        id: projectId,
      },
    },
    skip: projectId === 'all',
  })
}

export function useGetProjectBoardMembersTenants() {
  const projectId = useProjectId()

  return Api.useProjectBoardMembersTenantsQuery({
    skip: projectId === 'all',
    variables: {
      where: {
        id: projectId,
      },
    },
  })
}
