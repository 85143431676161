import React from 'react'
import styled from 'styled-components'
import { Colors, SendBirdUtils } from '@walter/shared'
import { t, typography } from '@walter/shared-web'
import { customMessageCardIconName, customMessageCardName } from './CustomMessageCard.entities'
import ChatEvent from '../ChatEvent'

const Download = styled.a`
  display: inline-block;
  font-size: ${typography.fontSizes.small};
  text-decoration: underline;
`

export interface IFileCustomItem {
  id: string
  createdAt: any
  updatedAt: any
  name: string
  url: string
  size: number | null
  type: string | null
  format: string | null
}

export type FileItemCardProps = {
  file: IFileCustomItem
  formattedTime: string
}

export const FileItemCard = (props: FileItemCardProps) => {
  const { file, formattedTime } = props

  const size = file.size ? (file.size / 1024).toFixed(1).concat(' Kb') : null
  const description = [file.type, file.format, size].filter((x) => !!x).join(' - ')

  return (
    <ChatEvent
      color={Colors.grey}
      icon={customMessageCardIconName(SendBirdUtils.CustomMessageType.file)}
      name={customMessageCardName(SendBirdUtils.CustomMessageType.file)}
      time={formattedTime}
      title={file.name}
      description={description}
      footer={<Download href={file.url}>{t('download')}</Download>}
      // isHTML
    />
  )
}
