import React from 'react'
import styled, { css } from 'styled-components'
import { stripUnit, rgba } from 'polished'

import { Colors, Spacing, Types } from '@walter/shared'
import { globalStyles, typography, Icon, DisplayContent } from '@walter/shared-web'

const Container = styled.div`
  display: flex;
`

const IconWrap = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: ${(props) => rgba(props.color, 0.25)};
  color: ${(props) => props.color};
  margin-left: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
  ${globalStyles.square(Spacing.xLarge)};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${Spacing.tiny};
  font-size: ${typography.fontSizes.tiny};
`

const Name = styled.span`
  color: ${Colors.greyDark};
  font-weight: ${typography.fontWeights.bold};
`

const Time = styled.span`
  color: ${Colors.greyLight};
  margin-left: ${Spacing.tiny};
`

const Inner = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  border: 1px solid ${Colors.borderColor};
  border-radius: ${globalStyles.borderRadius};
  padding: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`} ${Spacing.medium};
  border-left: 3px solid ${(props) => props.color};
  background-color: ${Colors.white};
  ${globalStyles.boxShadow};
`

const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  font-weight: ${typography.fontWeights.bold};
  font-size: ${typography.fontSizes.small};
  color: ${Colors.greyDark};
  margin-bottom: ${Spacing.tiny};
`

const Description = styled.div`
  font-size: ${typography.fontSizes.small};
  margin-bottom: -${`${(stripUnit(Spacing.tiny) as number) * 0.5}px`};
`

const Overlay = styled.div`
  cursor: pointer;
  ${globalStyles.cover('absolute')};
`

const Figure = styled.div`
  margin-left: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
  width: 56px;
  display: flex;
  justify-content: flex-end;
`

const Footer = styled.div`
  display: flex;
  margin-top: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
`

export const Image = styled.img<{ isRound: boolean }>`
  border-radius: ${globalStyles.borderRadius};
  ${globalStyles.square('40px')};

  ${(props) =>
    props.isRound &&
    css`
      border-radius: 50%;
    `}
`

type ChatEventProps = {
  color: string
  title?: string | null
  name: string
  icon: Types.IconName
  time: string
  description?: string | null
  figure?: React.ReactNode
  footer?: React.ReactNode
  onClick?: () => void
  isHTML?: boolean
}

export default function ChatEvent({
  color,
  icon,
  name,
  time,
  title,
  description,
  onClick,
  figure,
  footer,
  isHTML,
}: ChatEventProps) {
  return (
    <Container data-test-id="ChatEvent_Container">
      <div>
        <Header data-test-id="ChatEvent_Header">
          <Name data-test-id="ChatEvent_Name">{name}</Name>
          <Time data-test-id="ChatEvent_Time">{time}</Time>
        </Header>
        <Inner color={color}>
          <Main>
            {!!title && (
              <Title data-test-id="ChatEvent_Title">{isHTML ? <DisplayContent content={title} /> : title}</Title>
            )}
            {!!description && (
              <Description data-test-id="ChatEvent_Description">
                {isHTML ? <DisplayContent content={description} /> : description}
              </Description>
            )}
            {onClick && <Overlay onClick={onClick} />}
            {footer && <Footer>{footer}</Footer>}
          </Main>
          {figure ? (
            <Figure>{figure}</Figure>
          ) : (
            <IconWrap data-test-id="ChatEvent_Icon" color={color}>
              <Icon icon={icon} size="small" />
            </IconWrap>
          )}
        </Inner>
      </div>
    </Container>
  )
}
