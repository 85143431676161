import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Global, Spacing } from '@walter/shared'
import { Button } from '../Button'
import { Heading } from '../Heading'
import { t } from '../../utils'

const Container = styled.div`
  display: block;
  padding: ${Spacing.large};
`
const Inner = styled.div<{ isOpened: boolean }>`
  white-space: pre-wrap;
  overflow: hidden;
  height: 150px;
  line-height: 1.2;
  transition: max-height 0 ${Global.TRANSITION_LENGTH}s ease;
  max-height: 150px;
  height: auto;
  margin-bottom: ${Spacing.small};
  ${(props) =>
    props.isOpened &&
    css`
      max-height: 1200px;
    `}
`

const StyledHeading = styled(Heading)`
  display: flex;
  align-items: center;
  margin-bottom: ${Spacing.small};
  flex: 1;
  font-size: 14px;
`

type ToggleTextProps = {
  title?: string
  content?: string
}

export const ToggleText = ({ title, content }: ToggleTextProps) => {
  const [isOpened, setIsOpened] = useState(false)

  const onOpenCLick = () => {
    return isOpened ? setIsOpened(false) : setIsOpened(true)
  }

  const isLargeText = (content?.length ?? 0) >= 490

  return (
    <Container>
      <StyledHeading size={4}>{title}</StyledHeading>
      <Inner isOpened={isOpened}>{content}</Inner>
      {isLargeText && (
        <Button onClick={onOpenCLick} theme="link">
          {isOpened ? t('read-less') : t('read-more')}
        </Button>
      )}
    </Container>
  )
}
