const PROD_SENDBIRD_APP_ID = '151E33F6-D313-4C7F-AB31-9249DA865731'
const STAGING_SENDBIRD_APP_ID = 'CCE8F9CA-9C9A-4F27-B59E-04C104F48245'

// API_ENV is for mobile
// CONFIG_ENV is for web
// TODO: refactor to use the same
export const env = process.env.API_ENV || process.env.CONFIG_ENV || 'development'
const common = {
  demoManagingCompanyId: 'cjp9ibdcp001p0914q2n4sau9',
  isProduction: env === 'production',
  isStaging: env === 'staging',
  isPreprod: env === 'preprod',
  isDev: env === 'development',
  isE2E: env === 'e2e',
  walterTransitionManagingCompanyId: 'ckdqannuabn950867apq9sa2q',
  demoProjectId: 'cjnf4ce8600n109703rjkq903',
  isMobile: process.env.IS_MOBILE,
  env,
}

const dev = {
  sendBirdAppId: STAGING_SENDBIRD_APP_ID,
  host: 'http://localhost:3000',
  managerWebDomain: 'http://localhost:3000',
  residentWebDomain: 'http://localhost:3001',
  apiEndpoint: 'http://localhost:5000/graphql',
  restEndPoint: 'http://localhost:5000',
  transitionManagingCompanyId: '',
}

const staging = {
  sendBirdAppId: STAGING_SENDBIRD_APP_ID,
  host: 'https://staging.usewalter.com',
  managerWebDomain: 'https://manager-staging.usewalter.com',
  residentWebDomain: 'https://resident-staging.usewalter.com',
  apiEndpoint: 'https://staging-api.usewalter.com/graphql',
  restEndPoint: 'https://staging-api.usewalter.com',
  transitionManagingCompanyId: '',
}

const preprod = {
  sendBirdAppId: STAGING_SENDBIRD_APP_ID,
  host: 'https://preprod.usewalter.com',
  managerWebDomain: 'https://manager-preprod.usewalter.com',
  residentWebDomain: 'https://resident-preprod.usewalter.com',
  apiEndpoint: 'https://preprod-api.usewalter.com/graphql',
  restEndPoint: 'https://preprod-api.usewalter.com',
  transitionManagingCompanyId: '',
}

const production = {
  sendBirdAppId: PROD_SENDBIRD_APP_ID,
  host: 'https://usewalter.com',
  managerWebDomain: 'https://manager.usewalter.com',
  residentWebDomain: 'https://resident.usewalter.com',
  apiEndpoint: 'https://api.usewalter.com/graphql',
  restEndPoint: 'https://api.usewalter.com',
  transitionManagingCompanyId: 'ckdqannuabn950867apq9sa2q',
}

const e2e = {
  ...staging,
}

function getEnvConfig() {
  if (common.isStaging) return staging
  if (common.isPreprod) return preprod
  if (common.isProduction) return production
  if (common.isDev) return dev
  if (common.isE2E) return e2e
  return production
}

export const Config = { ...common, ...getEnvConfig() }

// Exported so we can use for mobile because on mobile we use a
// different strategy to get which env we're on
export const configCommon = common
export const Envs = { production, staging, dev }
