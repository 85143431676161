import uniqBy from 'lodash/uniqBy'

type Property = {
  address: { apartmentNumber: string }
  owners: { firstName: string; lastName: string }[]
  users: { firstName: string; lastName: string }[]
}

export function getPropertyWithResidentsText(property: Property) {
  let text = `#${property.address.apartmentNumber}`
  if (!property.owners || !property.users) return text
  const residents = uniqBy([...property.owners, ...property.users], 'id')
  if (residents.length) {
    text = `${text} (${residents.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(', ')})`
  }
  return text
}
