import React, { useCallback } from 'react'
import { Colors, Spacing, UploadUtils } from '@walter/shared'
import { t } from '../../utils'
import { rgba, stripUnit } from 'polished'
import { useDropzone } from 'react-dropzone'
import styled, { css } from 'styled-components'
import { borderRadius, cover, square } from '../../styles/global'
import { Button } from '../Button'
import { Icon } from '../Icon'
import { Label } from '../Label'
import { ToastContext } from '@walter/shared-web'

const Inner = styled.div<{ stacked?: string | boolean }>`
  display: flex;
  align-items: center;

  ${(props) =>
    props.stacked &&
    css`
      display: block;
    `}
`

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${`${(stripUnit(Spacing.tiny) as number) * 1.5}px`};
`

const ListItem = styled.div`
  padding: ${`${(stripUnit(Spacing.tiny) as number) * 1.5}px`};
`

const PhotoWrap = styled.div<{ stacked?: string | boolean }>`
  display: flex;
  position: relative;
  ${square('48px')};
  border-radius: ${borderRadius};
  margin-right: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};

  ${(props) =>
    props.stacked &&
    css`
      margin-right: 0;
    `}

  &:after {
    content: '';
    border-radius: ${borderRadius};
    box-shadow: inset 0 0 0 1px ${rgba(Colors.black, 0.1)};
    ${cover('absolute')};
  }
`

const PhotoMask = styled.div`
  position: relative;
  ${square('48px')};
  border-radius: ${borderRadius};
  overflow: hidden;
`

const Photo = styled.img`
  object-fit: cover;
  ${cover('absolute')};
`

const PhotoDelete = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: ${rgba(Colors.black, 0.75)};
  color: ${Colors.white};
  border-radius: 50%;
  right: -4px;
  top: -4px;
  ${square('20px')};
  z-index: 2; /* Position above overlay */
`

const Hint = styled.span`
  margin-left: ${`${(stripUnit(Spacing.tiny) as number) * 0.5}px`};
`

const Overlay = styled.a`
  ${cover('absolute')};
  z-index: 1;
`

const ButtonWrap = styled.div<{ stacked?: string | boolean }>`
  display: flex;

  ${(props) =>
    props.stacked &&
    css`
      margin-top: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
    `}
`

const UploadWrap = styled.span<{ stacked: string | boolean }>`
  ${(props) =>
    props.stacked &&
    css`
      margin-right: ${Spacing.tiny};
    `}
`

type FileOrPreview = {
  id?: string
  preview?: string
  url?: string
  name?: string | null
}

type ImageUploadProps = {
  dataTestId?: string
  onChange: (files: FileOrPreview[]) => void
  files?: FileOrPreview[]
  label?: string
  allowMultiple?: boolean
  hint?: string
  disabled?: boolean
  maxMegabyteSingle?: number
  maxMegabyteMulti?: number
}

export const ImageUpload = React.memo(
  ({
    dataTestId,
    label,
    files = [],
    onChange,
    hint,
    allowMultiple,
    disabled,
    maxMegabyteSingle = 20,
    maxMegabyteMulti = 24,
  }: ImageUploadProps) => {
    const { showToast } = React.useContext(ToastContext)

    const onDrop = useCallback(
      (acceptedFiles: File[]) => {
        try {
          UploadUtils.validateFileSize(acceptedFiles, maxMegabyteSingle * 1024 * 1024, maxMegabyteMulti * 1024 * 1024)
        } catch (error) {
          if (acceptedFiles.length > 1) {
            showToast(
              'negative',
              t('error-files-size-limit-exceeded', {
                maxMegabyte: maxMegabyteSingle,
                maxTotalMegabyte: maxMegabyteMulti,
              }),
            )
            return
          }

          showToast(
            'negative',
            t('error-file-size-limit-exceeded', {
              maxMegabyte: maxMegabyteSingle,
              maxTotalMegabyte: maxMegabyteMulti,
            }),
          )
          return
        }

        onChange([
          ...(files || []),
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
        ])
      },
      [onChange, files],
    )

    const clearFile = (idOrPreview: string) => {
      onChange(files.filter((f) => f.preview !== idOrPreview && f.id !== idOrPreview))
      window.URL.revokeObjectURL(idOrPreview)
    }

    const clearFiles = () => {
      files.map((file) => {
        if (file.preview) {
          window.URL.revokeObjectURL(file.preview)
        }
      })
      onChange([])
    }

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: 'image/*',
      multiple: allowMultiple,
      disabled,
    })

    return (
      <>
        <Label>
          {label}
          {hint && <Hint data-tip={hint}>(?)</Hint>}
        </Label>
        <Inner stacked={allowMultiple} data-test-id={`${dataTestId}`}>
          {files?.length > 0 && (
            <List>
              {files.map((file, i) => (
                <ListItem key={i} data-test-id={`${dataTestId}_Image_${file.name}`}>
                  <PhotoWrap stacked={allowMultiple}>
                    <PhotoMask>
                      <Overlay href={file.preview || file.url} target="_blank" rel="noopener noreferrer" />
                      <Photo src={file.preview || file.url} />
                    </PhotoMask>
                    {allowMultiple && (
                      <PhotoDelete
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          if (file.id) clearFile(file.id)
                          if (file.preview) clearFile(file.preview)
                        }}
                        type="button"
                        disabled={disabled}
                        data-test-id={`${dataTestId}_File_${file.name}_Delete_Button`}
                      >
                        <Icon icon="close" size="tiny" />
                      </PhotoDelete>
                    )}
                  </PhotoWrap>
                </ListItem>
              ))}
            </List>
          )}
          <ButtonWrap stacked={allowMultiple && files?.length > 0}>
            <UploadWrap {...getRootProps()} stacked={allowMultiple}>
              <input {...getInputProps()} />
              {(!files?.length || allowMultiple) && (
                <Button size="small" disabled={disabled} dataTestId={`${dataTestId}_Image_Upload_Button`}>
                  {t('general:add-file')}
                </Button>
              )}
            </UploadWrap>
            {files?.length > 0 && (
              <Button
                size="small"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  clearFiles()
                }}
                disabled={disabled}
                dataTestId={`${dataTestId}_Image_Remove_Button`}
              >
                {allowMultiple && files?.length > 1 ? t('remove-all') : t('remove')}
              </Button>
            )}
          </ButtonWrap>
        </Inner>
      </>
    )
  },
)
