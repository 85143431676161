import { z } from 'zod'
import { t } from '../../utils'

export const Notificationsi18nFields = {
  hasAppNotificationEnable: t('user:app-notification'),
  hasEmailNotificationEnable: t('user:email-notification'),
}

export const NotificationsSchema = z.object({
  hasAppNotificationEnable: z.boolean(),
  hasEmailNotificationEnable: z.boolean(),
})

export type Notifications = z.infer<typeof NotificationsSchema>
