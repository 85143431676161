import { LoaderFunctionArgs, redirect } from 'react-router-dom'
import { viewPermissionLoader } from './viewPermissionLoader'
import { handleApolloAuthError } from './handleApolloAuthError'

export async function allProjectsSettingsLoader(args: LoaderFunctionArgs) {
  if (args.params.projectId && args.params.projectId !== 'all') {
    throw redirect(`/${args.params.projectId}/projectSettings`)
  }
  try {
    await viewPermissionLoader('PROJECT')(args)
  } catch (err) {
    handleApolloAuthError(err)
    console.error('Error:', 'allProjectsSettingsLoader.ts', err)
  }
  return null
}
