import { useApolloClient } from '@apollo/client'
import { useSetAtom } from 'jotai'
import { accumulatedAccessKeysAtom } from './AccessKeySelector'
import { accumulatedParkingsAtom } from './ParkingSelector'
import { accumulatedLockersAtom } from './LockerSelector'
import { accumulatedManagersAtom } from './ManagerSelector'
import { accumulatedRemotesAtom } from './RemoteSelector'
import { accumulatedResidentsAtom } from './ResidentSelector'
import { accumulatedUnitsAtom } from './UnitSelector'
import { accumulatedSegmentsAtom } from './SegmentSelector'

export function useClearCacheAndStorage() {
  const client = useApolloClient()
  const setAccumulatedAccessKeys = useSetAtom(accumulatedAccessKeysAtom)
  const setAccumulatedParkings = useSetAtom(accumulatedParkingsAtom)
  const setAccumulatedLockers = useSetAtom(accumulatedLockersAtom)
  const setAccumulatedManagers = useSetAtom(accumulatedManagersAtom)
  const setAccumulatedRemotes = useSetAtom(accumulatedRemotesAtom)
  const setAccumulatedResidents = useSetAtom(accumulatedResidentsAtom)
  const setAccumulatedUnits = useSetAtom(accumulatedUnitsAtom)
  const setAccumulatedSegments = useSetAtom(accumulatedSegmentsAtom)

  return (
    clearCache: 'accessKeys' | 'parkings' | 'lockers' | 'residents' | 'segments' | 'remotes' | 'managers' | 'units',
  ) => {
    if (clearCache === 'accessKeys') {
      client.cache.evict({ fieldName: 'accessKeys' })
      client.cache.evict({ fieldName: 'accessKeysV2' })
      setAccumulatedAccessKeys([])
      client.cache.gc()
      return
    }

    if (clearCache === 'parkings') {
      client.cache.evict({ fieldName: 'parkingsV2' })
      setAccumulatedParkings([])
      client.cache.gc()
      return
    }

    if (clearCache === 'lockers') {
      client.cache.evict({ fieldName: 'lockersV2' })
      setAccumulatedLockers([])
      client.cache.gc()
      return
    }

    if (clearCache === 'residents') {
      client.cache.evict({ fieldName: 'getResidentsForManager' })
      setAccumulatedResidents([])
      client.cache.gc()
      return
    }

    if (clearCache === 'segments') {
      client.cache.evict({ fieldName: 'getSegments' })
      client.cache.evict({ fieldName: 'segments' })
      setAccumulatedSegments([])
      client.cache.gc()
      return
    }

    if (clearCache === 'remotes') {
      client.cache.evict({ fieldName: 'remotesV2' })
      client.cache.evict({ fieldName: 'remotes' })
      setAccumulatedRemotes([])
      client.cache.gc()
      return
    }

    if (clearCache === 'managers') {
      client.cache.evict({ fieldName: 'getManagers' })
      setAccumulatedManagers([])
      client.cache.gc()
      return
    }

    if (clearCache === 'units') {
      client.cache.evict({ fieldName: 'getProperties' })
      setAccumulatedUnits([])
      client.cache.gc()
      return
    }
  }
}

export function useClearAllCacheAndStorage() {
  const client = useApolloClient()
  const setAccumulatedAccessKeys = useSetAtom(accumulatedAccessKeysAtom)
  const setAccumulatedParkings = useSetAtom(accumulatedParkingsAtom)
  const setAccumulatedLockers = useSetAtom(accumulatedLockersAtom)
  const setAccumulatedManagers = useSetAtom(accumulatedManagersAtom)
  const setAccumulatedRemotes = useSetAtom(accumulatedRemotesAtom)
  const setAccumulatedResidents = useSetAtom(accumulatedResidentsAtom)
  const setAccumulatedUnits = useSetAtom(accumulatedUnitsAtom)
  const setAccumulatedSegments = useSetAtom(accumulatedSegmentsAtom)

  return () => {
    client.cache.evict({ fieldName: 'property' })
    client.cache.evict({ fieldName: 'user' })

    client.cache.evict({ fieldName: 'accessKeys' })
    client.cache.evict({ fieldName: 'accessKeysV2' })
    setAccumulatedAccessKeys([])

    client.cache.evict({ fieldName: 'parkingsV2' })
    setAccumulatedParkings([])

    client.cache.evict({ fieldName: 'lockersV2' })
    setAccumulatedLockers([])

    client.cache.evict({ fieldName: 'getResidentsForManager' })
    setAccumulatedResidents([])

    client.cache.evict({ fieldName: 'getSegments' })
    client.cache.evict({ fieldName: 'segments' })
    setAccumulatedSegments([])

    client.cache.evict({ fieldName: 'remotesV2' })
    client.cache.evict({ fieldName: 'remotes' })
    setAccumulatedRemotes([])

    client.cache.evict({ fieldName: 'getManagers' })
    setAccumulatedManagers([])

    client.cache.evict({ fieldName: 'getProperties' })
    setAccumulatedUnits([])
    client.cache.gc()
  }
}
