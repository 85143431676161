import * as React from 'react'
import styled from 'styled-components'
import { BuildingLogo } from '../BuildingLogo'
import { PillGroup } from '../PillGroup'

const Container = styled.div`
  display: flex;
  align-items: center;
`
const MoreProjectsText = styled.span`
  font-size: 14px;
`

export type MultiProjectsProps = {
  projects: {
    id: string
    name?: string | null
    nameInitials?: string | null
    squareLogo?: {
      url: string
    }
  }[]
}

export const MultiProjects = ({ projects }: MultiProjectsProps) => {
  const firstThreeProjects = React.useMemo(() => {
    return projects.slice(0, 3)
  }, [projects])

  const moreThan3Project = React.useMemo(() => {
    return projects.length > firstThreeProjects.length
  }, [projects, firstThreeProjects])

  return (
    <Container>
      <PillGroup>
        {firstThreeProjects.map((project) => (
          <BuildingLogo
            key={project.id ?? project.name}
            name={project.name ?? ''}
            initial={project.nameInitials ?? ''}
            logo={project.squareLogo?.url}
          />
        ))}
        {moreThan3Project && (
          <MoreProjectsText>{`+ ${projects.length - firstThreeProjects.length} more`}</MoreProjectsText>
        )}
      </PillGroup>
    </Container>
  )
}
