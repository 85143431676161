import { SharedUtils, Types } from '@walter/shared'
import Interweave from 'interweave'
import get from 'lodash/get'
import React from 'react'
import { Row } from 'react-table'
import styled from 'styled-components'
import { WebDateUtils } from '../../../src/utils/date'
import { animationCurve, animationTime } from '../../styles/global'
import { getLanguage } from '../../utils'
import { Attachment } from '../Attachment'
import { AttachmentList } from '../Attachment/AttachmentList'
import { Button } from '../Button'
import { ButtonGroup } from '../ButtonGroup'
import { IconButton } from '../IconButton'
import { PhotoGallery } from '../PhotoGallery'
import { Pill, PillProps } from '../Pill'
import { PillGroup } from '../PillGroup'
import { YesNo } from '../YesNo'

const Actions = styled.div`
  opacity: 0;
  transition: opacity ${animationTime} ${animationCurve}, visibility ${animationTime} ${animationCurve};

  tr:hover & {
    opacity: 1;
    visibility: visible;
  }
`

type CellProps = {
  column: {
    type: string
    cellWidth: number
    actions?: ({ row }: { row: Row }) => { icon: Types.IconName; title: string; label: string }[]
    routeSlug?: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined
    pillAdditionalProps: (val: unknown) => Partial<PillProps>
    options?: Types.Option[]
    itemPillProps: (val: unknown) => Partial<PillProps>
    getItemPillProps: (val: unknown) => Partial<PillProps>
    itemOptionLabel?: (val: unknown) => void
    noValueDisplay?: () => void
    component: (val: unknown) => React.ReactNode
  }
  row: {
    original: any
  }
  cell: {
    value: any
  }
}

const Cell = ({
  column: { type, cellWidth, actions, routeSlug, ...rest },
  row: { original },
  cell: { value },
}: CellProps) => {
  // Only for actions column
  if (type === 'actions') {
    const realActions = actions instanceof Function ? actions({ row: original }) : actions
    if (!realActions || realActions.length === 0) {
      return null
    }
    return (
      <Actions>
        <ButtonGroup>
          {realActions.map((action, key) =>
            action.icon ? (
              <IconButton key={key} {...action} />
            ) : (
              <Button key={action.title || action.label} {...action}>
                {action.title || action.label}
              </Button>
            ),
          )}
        </ButtonGroup>
      </Actions>
    )
  }

  if (type === 'boolean') {
    return (
      <div
        data-test-id="Cell_Value_Boo"
        // @ts-ignore
        // eslint-disable-next-line react/no-unknown-property
        css={`
          width: ${cellWidth}px;
        `}
      >
        <YesNo type={value ? 'yes' : 'no'} />
      </div>
    )
  }

  if (type === 'dropdown') {
    const { pillAdditionalProps, options } = rest
    return (
      <div
        data-test-id="Cell_Value_Ddl"
        // @ts-ignore
        // eslint-disable-next-line react/no-unknown-property
        css={`
          width: ${cellWidth}px;
          overflow: hidden;
        `}
      >
        <Pill
          dataTestId="Cell_Pill_Value"
          text={options?.find((o) => o.value === value)?.label || value}
          {...(pillAdditionalProps && pillAdditionalProps(value))}
        />
      </div>
    )
  }

  if (type === 'html') {
    return <Interweave content={value} />
  }

  if (type === 'textarea') {
    return (
      <small
        data-test-id="Cell_Value_TextArea"
        // @ts-ignore
        // eslint-disable-next-line react/no-unknown-property
        css={`
          display: inline-block;
        `}
      >
        {value}
      </small>
    )
  }

  if (type === 'currency') {
    return (
      <div
        data-test-id="Cell_Value_Currency"
        // @ts-ignore
        // eslint-disable-next-line react/no-unknown-property
        css={`
          width: ${cellWidth}px;
        `}
      >
        {SharedUtils.formatToCurrency(value, getLanguage())}
      </div>
    )
  }

  if (type === 'pill') {
    const { itemPillProps, getItemPillProps } = rest
    const pillProps = getItemPillProps ? getItemPillProps(value) : itemPillProps
    return (
      <div
        data-test-id="Cell_Value"
        // eslint-disable-next-line react/no-unknown-property
        css={`
          width: ${cellWidth}px;
        `}
      >
        <Pill dataTestId="Cell_Pill_Value" text={value} {...pillProps} />
      </div>
    )
  }

  if (type === 'connection') {
    const { itemOptionLabel, itemPillProps, noValueDisplay } = rest
    // Both because there's some place we haven't changed
    const displayFunctionOrPath = itemOptionLabel
    if (value && displayFunctionOrPath) {
      const values = Array.isArray(value) ? value : [value]

      // TODO: refactor
      if (values.length === 0 && noValueDisplay) {
        const text = noValueDisplay instanceof Function ? noValueDisplay() : noValueDisplay

        return <Pill text={text} />
      }

      return (
        <div
          data-test-id="Cell_Value_Connection"
          // @ts-ignore
          // eslint-disable-next-line react/no-unknown-property
          css={`
            width: ${cellWidth}px;
            overflow: hidden;
          `}
        >
          <PillGroup>
            {values.map((value, index) => (
              <Pill
                dataTestId="Cell_Pill_Value"
                key={index}
                handleClick={routeSlug}
                text={
                  (displayFunctionOrPath instanceof Function
                    ? displayFunctionOrPath(value)
                    : get(value, displayFunctionOrPath)) || 'Unknown'
                }
                {...(itemPillProps && itemPillProps(value))}
              />
            ))}
          </PillGroup>
        </div>
      )
    }
    // TMP
    return null
  }

  if (type === 'image') {
    const values = Array.isArray(value) ? value : [value]
    const urls = values.filter(Boolean).map((v) => v.url || v)
    return urls.length ? <PhotoGallery photos={urls} /> : null
  }

  if (type === 'datetime') {
    if (!value) {
      return null
    }
    return (
      <div
        data-test-id="Cell_Value_DateTime"
        // @ts-ignore
        // eslint-disable-next-line react/no-unknown-property
        css={`
          width: ${cellWidth}px;
        `}
      >
        {WebDateUtils.dayjs(value).format('LLLL')}
      </div>
    )
  }

  if (type === 'date' || type === 'singledate') {
    if (!value) {
      return null
    }
    return (
      <div
        data-test-id="Cell_Value_Date"
        // @ts-ignore
        // eslint-disable-next-line react/no-unknown-property
        css={`
          width: ${cellWidth}px;
        `}
      >
        {WebDateUtils.dayjs(value).format('LL')}
      </div>
    )
  }

  if (type === 'component') {
    const { component } = rest
    return <>{component(original)}</>
  }

  if (type === 'percentage') {
    return (
      <div
        data-test-id="Cell_Value_Component"
        // @ts-ignore
        // eslint-disable-next-line react/no-unknown-property
        css={`
          overflow: hidden;
          /* word-break: break-all; */
          width: ${cellWidth}px;
        `}
      >
        {value || 0}%
      </div>
    )
  }

  if (type === 'file') {
    const values = Array.isArray(value) ? value : [value]
    return (
      <AttachmentList>
        {values.map((attachment) => (
          <Attachment
            data-test-id="Cell_Value_File"
            handleClick={(e) => {
              e.stopPropagation()
              window.open(attachment.url, '_blank')
            }}
            key={attachment.id}
            name={attachment.name}
          />
        ))}
      </AttachmentList>
    )
  }

  return (
    <div
      data-test-id="Cell_Value_Other"
      data-cy={`cell-${value}`}
      // @ts-ignore
      // eslint-disable-next-line react/no-unknown-property
      css={`
        overflow: hidden;
        /* word-break: break-all; */
        width: ${cellWidth}px;
      `}
    >
      {value || ''}
    </div>
  )
}

export { Cell }
