import { Colors, Spacing } from '@walter/shared'
import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { animationCurve, animationTime, square } from '../../styles/global'
import { fontSizes } from '../../styles/typography'
import { Icon } from '../Icon'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color ${animationTime} ${animationCurve};
  ${square('32px')};

  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    background-color: ${rgba(Colors.borderColor, 0.5)};
  }
`

const Label = styled.span`
  font-size: ${fontSizes.small};
  margin: 0 ${Spacing.small};
`

type Props = {
  variant?: 'default' | 'minimal'
  gotoPage?: (page: number) => void
  previousPage?: () => void
  nextPage?: () => void
  pageIndex?: number
  pageOptions?: number[]
  canPreviousPage?: boolean
  canNextPage?: boolean
  pageCount?: number
}

const Pagination = ({
  variant = 'default',
  gotoPage,
  previousPage,
  nextPage,
  pageIndex = 0,
  pageOptions,
  // pageSize,
  canPreviousPage,
  canNextPage,
  pageCount,
}: // setPageSize,
Props) => {
  return (
    <Container data-test-id="DataTable_Pagination">
      {variant !== 'minimal' && (
        <Button data-test-id="DataTable_Pagination_FirstPage" onClick={() => gotoPage?.(0)} disabled={!canPreviousPage}>
          <Icon icon="left-chevron-double" size="small" />
        </Button>
      )}
      <Button
        data-test-id="DataTable_Pagination_PreviousPage"
        onClick={() => previousPage?.()}
        disabled={!canPreviousPage}
      >
        <Icon icon="left-chevron" size="small" />
      </Button>
      {variant === 'minimal' ? (
        <Label data-test-id="DataTable_Pagination_Page_label">Page {pageCount === 0 ? 0 : pageIndex + 1}</Label>
      ) : (
        <Label data-test-id="DataTable_Pagination_Page_label">
          Page {pageCount === 0 ? 0 : pageIndex + 1} / {(pageOptions ?? []).length}
        </Label>
      )}
      <Button data-test-id="DataTable_Pagination_NextPage" onClick={() => nextPage?.()} disabled={!canNextPage}>
        <Icon icon="right-chevron" size="small" />
      </Button>
      {variant !== 'minimal' && (
        <Button
          data-test-id="DataTable_Pagination_LastPage"
          onClick={() => gotoPage?.((pageCount ?? 1) - 1)}
          disabled={!canNextPage}
        >
          <Icon icon="right-chevron-double" size="small" />
        </Button>
      )}
    </Container>
  )
}

export { Pagination }
