import { Api } from '@walter/shared'
import { TASK_SORT_METHODS } from '../types/tasks'

const PRIORITY_ORDER = {
  [Api.Task_Priority_Status.Low]: 1,
  [Api.Task_Priority_Status.High]: 2,
  [Api.Task_Priority_Status.Urgent]: 3,
}

export const sortTasks = <
  T extends {
    title?: string | null
    createdAt?: string | number | null
    priorityStatus?: Api.Task_Priority_Status | null
  }[],
>(
  tasks: T,
  method?: TASK_SORT_METHODS,
) => {
  switch (method) {
    case TASK_SORT_METHODS.CREATION_DATE_ASCENDING:
      return tasks.sort((a, b) => {
        if (!a.createdAt || !b.createdAt) return 0
        if (a.createdAt > b.createdAt) return 1
        if (a.createdAt < b.createdAt) return -1
        return 0
      })

    case TASK_SORT_METHODS.CREATION_DATE_DESCENDING:
      return tasks.sort((a, b) => {
        if (!a.createdAt || !b.createdAt) return 0
        if (a.createdAt > b.createdAt) return -1
        if (a.createdAt < b.createdAt) return 1
        return 0
      })

    case TASK_SORT_METHODS.PRIORITY_DESCENDING:
      return tasks.sort((a, b) => {
        const aPriorityOrder = PRIORITY_ORDER[a.priorityStatus || Api.Task_Priority_Status.Low]
        const bPriorityOrder = PRIORITY_ORDER[b.priorityStatus || Api.Task_Priority_Status.Low]
        if (aPriorityOrder !== bPriorityOrder) return bPriorityOrder - aPriorityOrder
        if (!a.createdAt || !b.createdAt) return 0
        if (a.createdAt > b.createdAt) return 1
        if (a.createdAt < b.createdAt) return -1
        return 0
      })

    default:
      return tasks
  }
}
