import { t } from './i18n'

export function getUserName(user?: { firstName?: string | null; lastName?: string | null } | null): string {
  if (!user) {
    return ''
  }

  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`
  }

  if (user.firstName) {
    return user.firstName
  }

  if (user.lastName) {
    return user.lastName
  }

  return t('no-name')
}
