import React from 'react'
import styled, { css } from 'styled-components'
import { Colors, Spacing } from '@walter/shared'
import { rgba, stripUnit } from 'polished'
import { borderRadius } from '../../styles/global'

const Container = styled.div<{ type?: string }>`
  display: inline-block;
  background-color: ${rgba(Colors.grey, 0.15)};
  color: ${Colors.grey};
  border-radius: ${borderRadius};
  padding: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`} ${Spacing.medium};

  ${(props) =>
    props.type === 'positive' &&
    css`
      background-color: ${rgba(Colors.green, 0.15)};
      color: ${Colors.green};
    `}

  ${(props) =>
    props.type === 'negative' &&
    css`
      background-color: ${rgba(Colors.red, 0.15)};
      color: ${Colors.red};
    `}

    ${(props) =>
    props.type === 'locked' &&
    css`
      background-color: ${rgba(Colors.black, 0.1)};
      color: ${Colors.red};
    `}
      background-color: ${rgba(Colors.black, 0.1)};
`

type FormFeedbackProps = {
  children: React.ReactNode
  type?: string
}

export const FormFeedback = ({ type, children }: FormFeedbackProps) => (
  <Container type={type}>
    <span>{children}</span>
  </Container>
)
