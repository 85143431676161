import { redirect } from 'react-router-dom'
import { clearAuthLocalStorage } from './clearAuthLocalStorage'

export function handleApolloAuthError(err: unknown) {
  if (err instanceof Error) {
    if (err.message.startsWith('Forbidden resource')) {
      const token = localStorage.getItem('token')
      const refreshToken = localStorage.getItem('refreshToken')
      clearAuthLocalStorage()
      throw redirect(`/auth/login${token || refreshToken ? '?error=session-expired-please-login-again' : ''}`)
    }
    if (err.cause === 'session-expired-please-login-again' || err.cause === 'user-is-not-a-manager') {
      clearAuthLocalStorage()
      throw redirect(`/auth/login${err.cause ? `?error=${err.cause}` : ''}`)
    }
  }
}
