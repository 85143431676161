import { Colors, Spacing } from '@walter/shared'
import { rgba, stripUnit } from 'polished'
import React from 'react'
import styled, { css } from 'styled-components'
import { animationCurve, animationTime } from '../../styles/global'
import { fontSizes, fontWeights } from '../../styles/typography'
import { Pill } from '../Pill'

const Container = styled.div<{ isPills: boolean }>`
  display: flex;
  border-bottom: 1px solid ${Colors.borderColor};

  ${(props) =>
    props.isPills &&
    css`
      border-bottom: 0;
    `}
`

const Item = styled.button<{ isActive: boolean; isPills: boolean }>`
  display: flex;
  align-items: center;
  color: ${Colors.greyLight};
  padding: 0 ${Spacing.small};
  padding-bottom: ${`${(stripUnit(Spacing.tiny) as number) * 1.5}px`};
  transition: all ${animationTime} ${animationCurve};
  border-bottom: 2px solid transparent;
  margin-bottom: -1px;

  & + & {
    margin-left: ${Spacing.tiny};
  }

  &:hover,
  &:focus {
    color: ${Colors.grey};
  }

  ${(props) =>
    props.isActive &&
    css`
      border-bottom-color: ${Colors.primaryColor};
      color: ${Colors.primaryColor};

      &:hover,
      &:focus {
        border-bottom-color: ${Colors.primaryColor};
        color: ${Colors.primaryColor};
      }
    `}

  ${(props) =>
    props.isPills &&
    css`
      border-radius: ${Spacing.large};
      border-bottom: none;
      margin-bottom: 0;
      padding: ${Spacing.tiny} ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};

      &:hover,
      &:focus {
        background-color: ${rgba(Colors.grey, 0.15)};
      }

      ${props.isActive &&
      css`
        background-color: ${rgba(Colors.primaryColor, 0.2)};

        &:hover,
        &:focus {
          background-color: ${rgba(Colors.primaryColor, 0.2)};
        }
      `}
    `}
`

const Label = styled.span`
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.small};
`

const Count = styled.span<{ isActive: boolean }>`
  display: flex;
  margin-left: ${Spacing.small};
  pointer-events: none;
  opacity: 0.6;
  transition: opacity ${animationTime} ${animationCurve};

  ${Item}:hover &,
  ${Item}:focus & {
    opacity: 1;
  }

  ${(props) =>
    props.isActive &&
    css`
      opacity: 1;
    `}
`

export type TabNavItem = {
  dataTestId?: string
  label: string
  isActive: boolean
  count?: number
  hint?: string
  handleClick: () => void
}

type TabNavProps = {
  items: TabNavItem[]
  isPills?: boolean
  dataTestId?: string
}

export const TabNav = ({ items, isPills = false, dataTestId }: TabNavProps) => {
  return (
    <Container data-test-id={dataTestId} isPills={isPills}>
      {items.map((item, i) => (
        <Item
          data-test-id={item.dataTestId}
          isPills={isPills || false}
          data-tip={item.hint}
          key={i}
          onClick={item.handleClick}
          isActive={item.isActive}
          type="button"
        >
          <Label>{item.label}</Label>
          {!!item?.count && (
            <Count isActive={item.isActive}>
              <Pill text={item.count} type={item.isActive ? 'primary' : undefined} />
            </Count>
          )}
        </Item>
      ))}
    </Container>
  )
}
