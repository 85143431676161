import { z } from 'zod'
import { t } from '../../utils'

export const TaskSettingsi18nFields = {
  sendOnPressEnter: t('send-message-when-pressing-enter'),
}

export const taskSettingsSchema = z.object({
  sendOnPressEnter: z.boolean().optional(),
})

export type TaskSettingsFormSchema = z.infer<typeof taskSettingsSchema>
