import { useApolloClient } from '@apollo/client'
import { Api } from '@walter/shared'
import { WebLogger } from '@walter/shared-web'
import React from 'react'
import AppContext from './App'
import AuthContext from './Auth'

type NotificationsContextType = {
  unSeenNotifications: any[]
  notifications: Api.GetNotificationsForCurrentUserManagerWebQuery['notificationsForUser']
  onNotificationsSeen: (notificationsToUpdate: any) => Promise<void>
  refetchUserNotifications: any
  addNotifications: () => void
  markAllNotificationsAsSeen: () => Promise<void>
  isUpdatingNotifications: boolean
}
const NotificationsContext = React.createContext({} as NotificationsContextType)

export default NotificationsContext

export function NotificationProvider({ children }: { children: React.ReactNode }) {
  const { currentUser } = React.useContext(AuthContext)
  const { currentManagingCompanyProjectIds } = React.useContext(AppContext)
  const client = useApolloClient()

  const { data: { notificationsForUser: notifications = [] } = {}, refetch: refetchUserNotifications } =
    Api.useGetNotificationsForCurrentUserManagerWebQuery({
      skip: !currentUser?.id,
      variables: {
        isRecent: true,
        first: 10,
        userId: currentUser?.id || '',
        projectIds: currentManagingCompanyProjectIds,
        hasBeenSeen: false,
      },
    })
  const [updateNotification, { loading: isUpdatingNotification }] = Api.useUpdateNotificationManagerWebMutation()
  const [markAllNotificationsAsSeenMutation, { loading: isMarkingAllNotificationsAsRead }] =
    Api.useMarkAllNotificationsAsSeenMutation()

  async function markAllNotificationsAsSeen() {
    await markAllNotificationsAsSeenMutation({
      variables: {
        data: {
          platform: Api.Notification_Platform.Web,
        },
      },
    })
    client.cache.evict({ fieldName: 'notificationsForUser' })
    client.cache.gc()
  }

  function addNotifications() {
    // Easier for now to just refetch...
    refetchUserNotifications()
  }

  async function onNotificationsSeen(notificationsToUpdate: typeof notifications) {
    if (!notificationsToUpdate || !notificationsToUpdate.length) {
      return
    }

    try {
      const unseenNotifications = notificationsToUpdate.filter((n) => !n.hasBeenSeen)

      await Promise.all(
        unseenNotifications.map((notification) =>
          updateNotification({
            variables: {
              where: {
                id: notification.id,
              },
              data: {
                hasBeenSeen: true,
                hasBeenSeenByWeb: true,
              },
            },
          })
            .then(({ data }) => {
              return data?.updateNotification
            })
            .catch((error) => WebLogger.captureError(error)),
        ),
      )
      await refetchUserNotifications()
    } catch (error) {
      WebLogger.captureError(error)
      throw new Error(error instanceof Error ? error.message : 'unknown-error')
    }
  }

  const unSeenNotifications = React.useMemo(() => notifications.filter((n) => !n.hasBeenSeen), [notifications])

  const value = {
    refetchUserNotifications,
    notifications,
    unSeenNotifications,
    addNotifications,
    onNotificationsSeen,
    markAllNotificationsAsSeen,
    isUpdatingNotifications: isUpdatingNotification || isMarkingAllNotificationsAsRead,
  }

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>
}
