import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { stripUnit, rgba } from 'polished'

import { Colors, Spacing } from '@walter/shared'
import { borderRadius, cover, square, boxShadow } from '../../styles/global'
import { fontSizes, fontWeights } from '../../styles/typography'

const Container = styled.button<{ size: 'sm' | 'default' }>`
  display: flex;
  align-items: center;
  padding: ${({ size }) =>
    `${size === 'sm' ? Spacing.tiny : Spacing.small} ${`${(stripUnit(Spacing.small) as number) * 1.5}px`}`};
  border: 1px solid ${Colors.borderColor};
  border-radius: ${borderRadius};
  width: 100%;
  cursor: pointer;
  text-align: left;
  ${boxShadow};

  & + & {
    margin-top: ${Spacing.small};
  }
`

const Main = styled.main`
  flex: 1;
`

const Name = styled.span<{ size: 'sm' | 'default' }>`
  display: block;
  font-size: ${({ size }) => (size === 'sm' ? fontSizes.tiny : fontSizes.small)};
  color: ${Colors.greyDark};
  font-weight: ${fontWeights.bold};
  text-align: center;
  width: ${({ size }) => (size === 'sm' ? '150px' : '250px')};
`

const Meta = styled.span`
  display: block;
  font-size: ${fontSizes.tiny};
  color: ${Colors.greyLight};
  margin-top: ${`${(stripUnit(Spacing.tiny) as number) * 0.5}px`};
`

const PhotoWrap = styled.div`
  position: relative;
  border-radius: ${borderRadius};
  overflow: hidden;
  flex-shrink: 0;
  margin-right: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
  ${square('40px')};

  &:after {
    content: '';
    pointer-events: none;
    border-radius: ${borderRadius};
    box-shadow: inset 0 0 0 1px ${rgba(Colors.black, 0.1)};
    ${cover('absolute')};
  }
`

const Photo = styled.img`
  ${cover('absolute')};
`

const getElement = (props: { to?: string; handleClick?: () => void; href?: string }) => {
  if (props.to) return Link
  if (props.handleClick) return 'button'
  if (props.href) return 'a'
}

type AttachmentProps = {
  name: string
  meta?: string
  handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  href?: string
  target?: '_blank'
  size?: 'sm' | 'default'
}

export const Attachment = ({ name, meta, handleClick, size = 'default', ...props }: AttachmentProps) => {
  const attachementName = name.slice(0, 28) + (name.length > 28 ? '...' : '')

  return (
    <Container
      data-test-id={`Attachment_Container_${attachementName}`}
      onClick={handleClick}
      as={getElement(props)}
      size={size}
      {...props}
    >
      {props.href && (
        <PhotoWrap>
          <Photo src={props.href} />
        </PhotoWrap>
      )}
      <Main>
        <Name size={size} data-test-id="Attachment_Name" className="line-clamp-1">
          {attachementName}
        </Name>
        {meta && <Meta data-test-id="Attachment_Meta">{meta}</Meta>}
      </Main>
    </Container>
  )
}
