import { Colors, Spacing } from '@walter/shared'
import { stripUnit } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { borderRadius, cover } from '../../styles/global'
import { fontSizes } from '../../styles/typography'
import { Drawer, DrawerSection } from './Drawer'
import { ImageUpload } from '../ImageUpload'
import { t } from '../../utils'

const Heading = styled.strong`
  display: block;
  margin-bottom: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
  color: ${Colors.greyDark};
`

const Hint = styled.span`
  margin-left: ${`${(stripUnit(Spacing.tiny) as number) * 0.5}px`};
`

const Content = styled.div`
  margin-top: ${Spacing.medium};
`

const CategoryContainer = styled.div`
  position: static;

  & + & {
    margin-top: ${Spacing.medium};
  }
`

const CategoryHeading = styled.strong`
  display: block;
  margin-bottom: ${Spacing.small};
  color: ${Colors.greyDark};
  font-size: ${fontSizes.small};
`

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${`${(stripUnit(Spacing.tiny) as number) * 1.5}px`};
`

const ListItem = styled.div`
  padding: ${`${(stripUnit(Spacing.tiny) as number) * 1.5}px`};
  width: 25%;
`

const PhotoWrap = styled.div`
  position: relative;
  cursor: pointer;
`

const PhotoInner = styled.div`
  position: relative;
  padding-bottom: 75%;
  border-radius: ${borderRadius};
  overflow: hidden;
`

const Photo = styled.img`
  ${cover('absolute')}
  object-fit: cover;
`

type SelectMemoPhotoProps = {
  close: () => void
  handlePhotoSelected: (v: string | any) => void
  visible: boolean
  maxMegabyteSingle?: number
}

export const SelectMemoPhoto = ({
  visible,
  close,
  handlePhotoSelected,
  maxMegabyteSingle = 20,
}: SelectMemoPhotoProps) => {
  return (
    <Drawer visible={visible} title={t('select-photo')} close={close}>
      <DrawerSection>
        <Heading>
          {t('upload-custom-photo')} <Hint data-tip={t('preferred-photo-ratio')}>(?)</Hint>
        </Heading>
        <ImageUpload onChange={(files) => handlePhotoSelected(files[0])} maxMegabyteSingle={maxMegabyteSingle} />
      </DrawerSection>
      <DrawerSection>
        <Heading>{t('use-preset-photos')}</Heading>
        <Content data-cy="preset-photos">
          <Category
            handlePhotoClick={handlePhotoSelected}
            name={t('fire')}
            photos={[
              'https://picsum.photos/seed/1/1200/533.jpg',
              'https://picsum.photos/seed/2/1200/533.jpg',
              'https://picsum.photos/seed/3/1200/533.jpg',
              'https://picsum.photos/seed/4/1200/533.jpg',
            ]}
          />
          <Category
            handlePhotoClick={handlePhotoSelected}
            name={t('general')}
            photos={[
              'https://picsum.photos/seed/5/1200/533.jpg',
              'https://picsum.photos/seed/6/1200/533.jpg',
              'https://picsum.photos/seed/7/1200/533.jpg',
              'https://picsum.photos/seed/8/1200/533.jpg',
            ]}
          />
        </Content>
      </DrawerSection>
    </Drawer>
  )
}

type CategoryProps = {
  name: string
  photos: string[]
  handlePhotoClick: (photo: string) => void
}

const Category = ({ name, photos, handlePhotoClick }: CategoryProps) => (
  <CategoryContainer>
    <CategoryHeading>{name}</CategoryHeading>
    <List>
      {photos.map((photo, i) => (
        <ListItem key={i} onClick={() => handlePhotoClick(photo)}>
          <PhotoWrap>
            <PhotoInner>
              <Photo src={photo} />
            </PhotoInner>
          </PhotoWrap>
        </ListItem>
      ))}
    </List>
  </CategoryContainer>
)
