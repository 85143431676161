import { atom } from 'jotai'
import { MessagingService } from './MessagingService'
import { Member, GroupChannel } from '@sendbird/chat/groupChannel'

/**
 * Shared messaging atoms
 */

export const serviceAtom = atom(new MessagingService())
export const publicChannelsMapAtom = atom(new Map() as Map<string, GroupChannel>)
export const privateChannelsMapAtom = atom(new Map() as Map<string, GroupChannel>)
export const typingMembersMapAtom = atom(new Map() as Map<string, Member[]>)
export const initialLoadingAtom = atom(true)

export const conversationsSearchValueAtom = atom('')

export interface AllowedProject {
  id: string
  name?: string
}

export const allowedProjectsForManagerAtom = atom<AllowedProject[]>([])
