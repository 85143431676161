import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'

import { Member } from '@sendbird/chat/groupChannel'
import { Colors, Spacing } from '@walter/shared'
import { typography } from '@walter/shared-web'

const Container = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  background-color: ${Colors.offWhite};
  padding: ${Spacing.small} ${Spacing.medium};
  padding-top: ${Spacing.large};
  font-size: ${typography.fontSizes.tiny};
  color: ${Colors.greyLight};
  background: linear-gradient(180deg, ${rgba(Colors.offWhite, 0)} 0%, ${Colors.offWhite} 100%);
`

export type TypingProps = {
  typingUsers: Member[]
}

export const Typing = ({ typingUsers }: TypingProps) => {
  if (typingUsers.length < 1) {
    return null
  }

  if (typingUsers.length < 2) {
    return (
      <Container>
        <strong>{typingUsers[0].nickname}</strong> is typing...
      </Container>
    )
  }

  return <Container>Several people are typing...</Container>
}

export default Typing
