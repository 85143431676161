/* eslint-disable react/jsx-handler-names */
import { FormsUtils, Types } from '@walter/shared'
import React from 'react'
import { FormDataViewAccordion } from './FormDataViewAccordion'
import { FormDataViewSection } from './FormDataViewSection'
import { FormDataViewSimple } from './FormDataViewSimple'

type FormDataViewProps = {
  dataTestId?: string
  values?: any
  fields: Types.Field[]
  actions?: any[]
  close?: () => any
  isPadded?: boolean
  cancelActionText?: string
  additionalActions?: any[]
  rowsOnly?: boolean
}

export function FormDataView(props: FormDataViewProps) {
  return (
    <FormDataViewProvider {...props}>
      <Main />
    </FormDataViewProvider>
  )
}

function Main() {
  const { isBySection, isByAccordion } = React.useContext(FormDataViewContext)

  if (isBySection) {
    return <FormDataViewSection />
  }

  if (isByAccordion) {
    return <FormDataViewAccordion />
  }

  return <FormDataViewSimple />
}

type FormDataViewProviderProps = FormDataViewProps & { children: React.ReactNode; rowsOnly?: boolean }
type FormDataViewConextType = FormDataViewProps & { isBySection: boolean; isByAccordion: boolean; rowsOnly?: boolean }

export const FormDataViewContext = React.createContext({} as FormDataViewConextType)

function FormDataViewProvider({
  children,
  values,
  fields,
  actions,
  close,
  isPadded,
  additionalActions,
  cancelActionText,
  rowsOnly,
}: FormDataViewProviderProps) {
  const visibleFields = React.useMemo(() => {
    return FormsUtils.getVisibleFields(fields, values)
  }, [values, fields])

  const isBySection = React.useMemo(() => fields.some((field) => field.section), [fields])
  const isByAccordion = React.useMemo(() => fields.some((field) => field.accordion), [fields])
  return (
    <FormDataViewContext.Provider
      value={{
        isByAccordion,
        isBySection,
        values,
        close,
        isPadded,
        actions,
        additionalActions,
        cancelActionText,
        fields: visibleFields,
        rowsOnly,
      }}
    >
      {children}
    </FormDataViewContext.Provider>
  )
}
