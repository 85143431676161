import { Colors, Spacing } from '@walter/shared'
import React from 'react'
import styled, { css } from 'styled-components'
import { animations, typography } from '../../styles'
import { Icon } from '../Icon'

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${Spacing.large};
  font-size: ${typography.fontSizes.small};
`

const Folder = styled.button<{ isCurrent: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  ${(props) =>
    props.isCurrent &&
    css`
      color: ${Colors.greyLight};
      pointer-events: none;
    `}
`

const Separator = styled.div`
  display: flex;
  margin: 0 ${Spacing.tiny};
  opacity: 0.85;
`

const BreadcrumbItem = styled.div`
  display: flex;
  align-items: center;
`

export type BreadcrumbFolder = {
  name: string
  id: string
}

type BreadcrumbProps = {
  folders: BreadcrumbFolder[]
  onClickOnFolder: (folder: BreadcrumbFolder) => void
  isLoading: boolean
}

export function Breadcrumb({ onClickOnFolder, folders, isLoading }: BreadcrumbProps) {
  return (
    <Container data-test-id="Files_Breadcrumbs_Container">
      {isLoading ? (
        <InputLoader>
          <Icon icon="loading" size="small" color={Colors.primaryColor} />
        </InputLoader>
      ) : (
        folders.map((folder, index) => {
          const isLastItem = index === folders.length - 1
          return (
            <BreadcrumbItem data-test-id="Files_Breadcrumb_Item" key={index}>
              <Folder
                key={folder?.name}
                onClick={() => onClickOnFolder(folder)}
                isCurrent={index === folders.length - 1}
              >
                <span>{folder?.name}</span>
              </Folder>
              {!isLastItem && (
                <Separator>
                  <Icon icon="right-chevron" size="small" />
                </Separator>
              )}
            </BreadcrumbItem>
          )
        })
      )}
    </Container>
  )
}

const InputLoader = styled.div`
  animation: ${animations.rotate} 1.5s linear infinite;
  height: 16px;
  width: 16px;
`
