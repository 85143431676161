import {
  ColumnDef,
  Row,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Colors, Spacing, Types } from '@walter/shared'
import { rgba } from 'polished'
import React, { UIEvent, useCallback, useEffect, useRef, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import { square } from '../../styles/global'
import { fontSizes } from '../../styles/typography'
import { t } from '../../utils'
import { Icon } from '../Icon'
import { FilterToolbar } from './FilterToolbar'
import { TableBody, TableCell, TableHead, TableHeader, TableRoot, TableRow } from './ui'

interface Sorting {
  sort: SortingState
  setSort: React.Dispatch<React.SetStateAction<SortingState>>
  onlyManual?: boolean
}

interface Filtering {
  components: React.ReactNode
  appliedFilters: { id: string; label?: string }[]
  remove: (appliedFilter: { id: string; label?: string }) => void
}

interface EmptyInfo {
  icon: Types.IconName
  text: string
}

interface TableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  fetchMore?: () => Promise<void>
  isFetching: boolean
  totalCount: number
  onRowClick?(row: TData): void
  emptyInfo?: EmptyInfo
  sorting?: Sorting
  filtering?: Filtering
  dataTestId?: string
  maxHeight?: `${string}px` | `${string}%`
  // FOR DEBUGGING PURPOSES ONLY
  sortByBackend?: boolean
}

export function Table<TData, TValue>(props: TableProps<TData, TValue>) {
  const {
    columns,
    data,
    sorting,
    fetchMore,
    isFetching,
    totalCount,
    onRowClick,
    emptyInfo,
    filtering,
    dataTestId,
    sortByBackend = false,
    maxHeight,
  } = props
  const [previousRows, setPreviousRows] = useState<Row<TData>[]>([])

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const forceBackendSorting = process.env.NODE_ENV === 'development' && sortByBackend

  const table = useTable({
    columns,
    data,
    sorting: sorting?.onlyManual || (!forceBackendSorting && data.length >= totalCount) ? undefined : sorting,
  })

  const { rows } = table.getRowModel()

  const isFetchingMoreRef = useRef(false)

  const fetchMoreOnBottomReached = useCallback(
    async (e?: UIEvent<HTMLDivElement>) => {
      if (!fetchMore) return
      const containerRefElement = e?.target as HTMLDivElement | undefined
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement
        //once the user has scrolled within 150px of the bottom of the table, fetch more data if there is any
        if (
          scrollHeight - scrollTop - clientHeight < 150 &&
          (rows?.length || 0) < totalCount &&
          !isFetchingMoreRef.current
        ) {
          isFetchingMoreRef.current = true
          await fetchMore().catch((err) => {
            console.error('[Table]', 'Error fetching more', err)
          })
          isFetchingMoreRef.current = false
        }
      }
    },
    [fetchMore, rows?.length, totalCount],
  )

  useEffect(() => {
    if (rows?.length && rows.length > 0) {
      // Prevents the table from minimizing to nothing when rows change
      setPreviousRows(rows)
    }
  }, [rows])

  return (
    <>
      {filtering && (
        <FilterToolbar
          appliedFilters={filtering.appliedFilters}
          components={filtering.components}
          dataTestId={dataTestId}
          remove={filtering.remove}
        />
      )}
      <TableRoot
        maxHeight={maxHeight}
        onScroll={fetchMoreOnBottomReached}
        ref={tableContainerRef}
        dataTestId={dataTestId}
      >
        <TableHeader data-test-id={`${dataTestId}_Header_Row`}>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow hasRowAction={false} key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const canSort = header.column.getCanSort()
                const sortedValue = header.column.getIsSorted()
                return (
                  <TableHead
                    data-test-id={`${dataTestId}_Header_Col_${
                      header.id ? [header.id.charAt(0).toUpperCase(), header.id.slice(1)].join('') : ''
                    }`}
                    canSort={canSort}
                    onClick={() => {
                      if (canSort) {
                        header.column.toggleSorting()
                        tableContainerRef.current?.scrollTo({ top: 0 })
                      }
                    }}
                    key={header.id}
                    style={{
                      width: header.getSize() !== 150 ? header.getSize() : undefined,
                    }}
                  >
                    <span
                      style={{
                        position: 'relative',
                      }}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      {sortedValue && (
                        <SortingIconContainer>
                          {sortedValue === 'desc' ? (
                            <Icon icon="down-chevron" size="small" />
                          ) : (
                            <Icon icon="up-chevron" size="small" />
                          )}
                        </SortingIconContainer>
                      )}
                    </span>
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {rows?.length ? (
            rows.map((row, index) => {
              return (
                <TableRow
                  data-test-id={`${dataTestId}_Row_` + row.id}
                  key={row.id}
                  hasRowAction={!!onRowClick}
                  style={{ cursor: onRowClick ? 'pointer' : undefined }}
                  onClick={() => {
                    const selection = window && (window.getSelection()?.toString() ?? '')
                    if (onRowClick && selection.length === 0) {
                      onRowClick(row.original)
                    }
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    const lowercasedId = cell.id?.split('_')[1]
                    const id = lowercasedId
                      ? [lowercasedId.charAt(0).toUpperCase(), lowercasedId.slice(1)].join('')
                      : ''
                    return (
                      <TableCell key={cell.id} data-test-id={`${dataTestId}_Cell_${id}`}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        {rows.length - 1 === index && isFetching && (
                          <Skeleton style={{ position: 'absolute', inset: 0, opacity: 0.8 }} />
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })
          ) : (
            <>
              {isFetching ? (
                previousRows.length > 0 ? (
                  previousRows.map((row, i) => (
                    <TableRow key={'loading-row' + i} hasRowAction={false}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableCell key={'loading-cell' + cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            <Skeleton style={{ position: 'absolute', inset: 0, opacity: 0.8 }} />
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))
                ) : (
                  <>
                    <TableRow key={'loading-row-empty'} hasRowAction={false}>
                      <TableCell key={'loading-cell-empty'} style={{ height: 75 }}>
                        <Skeleton style={{ position: 'absolute', inset: 0, opacity: 0.8 }} />
                      </TableCell>
                    </TableRow>
                  </>
                )
              ) : (
                <TableRow hasRowAction={false}>
                  <TableCell colSpan={columns.length}>
                    <EmptyIconWrap>
                      <Icon icon={emptyInfo?.icon ?? 'inbox'} size={'small'} />
                    </EmptyIconWrap>
                    <p style={{ textAlign: 'center', marginBottom: Spacing.large }}>{emptyInfo?.text ?? t('empty')}</p>
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
        </TableBody>
      </TableRoot>
      {(!filtering?.appliedFilters || filtering?.appliedFilters?.length === 0) && !!data.length && (
        <ResultDescription>
          {data.length ?? 0}
          {totalCount ? `/${totalCount}` : ''} {t('results')}
        </ResultDescription>
      )}
    </>
  )
}

interface UseTableArgs<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  sorting?: Sorting
}

export function useTable<TData, TValue>({ columns, data, sorting }: UseTableArgs<TData, TValue>) {
  const [sort, setSort] = useState<SortingState>([])

  return useReactTable({
    data,
    columns,
    state: {
      sorting: sorting?.sort ?? sort,
    },
    manualSorting: !!sorting?.sort,
    onSortingChange: sorting?.setSort ?? setSort,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })
}

const ResultDescription = styled.p`
  font-size: ${fontSizes.small};
  color: ${Colors.greyLight};
  text-align: center;
  margin-top: ${Spacing.medium};
`

const SortingIconContainer = styled.div`
display flex;
alignItems: center;
position: absolute;
top: 0;
bottom: 0;
right: -${Spacing.xLarge};
`

const EmptyIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: ${Spacing.large};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${Spacing.medium};
  background-color: ${rgba(Colors.greyLight, 0.2)};
  color: ${Colors.greyLight};
  ${square('40px')};
`
