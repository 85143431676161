import { Api, Colors, RolePermissions, Spacing } from '@walter/shared'
import { Icon, NavBuilding, Pill, Search, globalStyles, t, typography } from '@walter/shared-web'
import { matchSorter } from 'match-sorter'
import { rgba, stripUnit } from 'polished'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import AuthContext from '../../../contexts/Auth'
import useProjectTotalPendingActions from '../../../hooks/useProjectTotalPendingActions'
import { IS_IN_EMAILS_SUFFIXES } from '../../../utils/employeeConstants'

const Container = styled.div`
  background-color: ${Colors.white};
  border: 1px solid ${Colors.borderColor};
  border-radius: ${globalStyles.borderRadius};
  width: 220px;
  overflow: hidden;
  ${globalStyles.boxShadow};
`

const SearchWrap = styled.div`
  padding: ${Spacing.medium};
  padding-bottom: 0;
`

const List = styled.div`
  padding: ${Spacing.medium};
  display: grid;
  grid-gap: ${Number(stripUnit(Spacing.small)) * 1.5 + 'px'};
  overflow-y: auto;
  max-height: 300px;
`

const Add = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: ${typography.fontSizes.small};
  transition: color ${globalStyles.animationTime} ${globalStyles.animationCurve};
  padding: ${Spacing.medium};
  border-top: 1px solid ${Colors.borderColor};

  &:hover {
    color: ${Colors.greyDark};
  }
`

const AddPlus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${globalStyles.borderRadius};
  margin-right: ${Number(stripUnit(Spacing.small)) * 1.5 + 'px'};
  color: ${Colors.primaryColor};
  background-color: ${rgba(Colors.primaryColor, 0.2)};
  transition: background-color ${globalStyles.animationTime} ${globalStyles.animationCurve};
  ${globalStyles.square('24px')}
  ${Add}:hover & {
    background-color: ${rgba(Colors.primaryColor, 0.3)};
  }
`

const BuildingContainer = styled.div<{ onClick: () => void }>`
  display: flex;
  align-items: center;
  font-size: ${typography.fontSizes.small};
  cursor: pointer;
`

const BuildingLogoWrap = styled.div`
  display: flex;
  margin-right: ${Number(stripUnit(Spacing.small)) * 1.5 + 'px'};
`

const BuildingName = styled.div`
  flex: 1;
`

const Empty = styled.p`
  font-size: ${typography.fontSizes.small};
  padding: ${Spacing.medium};
  text-align: center;
  color: ${Colors.greyLight};
`

type MenuProps = {
  projects: any[]
  onProjectClick: (project: any) => void
}

export default function Menu({ projects, onProjectClick }: MenuProps) {
  const navigate = useNavigate()
  const { currentUser } = useContext(AuthContext)
  const [searchTextValue, setSearchTextValue] = useState('')

  const canCreate = RolePermissions.hasAtLeastOnePermissions(currentUser, [Api.Permission_Type.ProjectWrite])

  const projectsToShow = React.useMemo(() => {
    if (searchTextValue) {
      // Better UX
      return projects
    }
    return projects.slice(6, projects.length)
  }, [projects, searchTextValue])

  const filteredProjects = React.useMemo(
    () => matchSorter(projectsToShow, searchTextValue, { keys: ['name'] }),
    [searchTextValue, projectsToShow, currentUser],
  )

  const handleTextChange = (e: string) => {
    setSearchTextValue(e)
  }

  const handleAddProjectClick = () => {
    navigate(`/newProject`)
  }

  return (
    <Container>
      <SearchWrap>
        <Search dataTestId="Projects_Search" onTextChange={handleTextChange} autoFocused />
      </SearchWrap>
      <List data-test-id="Projects_List">
        {filteredProjects.map((project) => {
          return (
            <Building
              key={project.id}
              onClick={onProjectClick}
              name={project.name}
              id={project?.id}
              logo={project.squareLogo?.url}
              initials={project.nameInitials}
            />
          )
        })}
        {filteredProjects.length === 0 && <Empty>{t('no-matching-projects')}</Empty>}
      </List>
      {/* Not allowing people to create projects for now */}
      {IS_IN_EMAILS_SUFFIXES(currentUser?.email) && canCreate && (
        <Add data-test-id="AddNewProject_Button" onClick={handleAddProjectClick}>
          <AddPlus>
            <Icon icon="plus" size="small" />
          </AddPlus>
          {t('add-new-project')}
        </Add>
      )}
    </Container>
  )
}

type BuildingProps = {
  name: string
  id: string
  logo: string
  initials: string
  onClick: (id: string) => void
}

function Building({ name, id, initials, logo, onClick }: BuildingProps) {
  const totalPendingActions = useProjectTotalPendingActions(id)

  return (
    <BuildingContainer data-test-id={`${initials}_Container`} onClick={() => onClick(id)}>
      <BuildingLogoWrap>
        <NavBuilding name={name} logo={logo} initials={initials} size="small" />
      </BuildingLogoWrap>
      <BuildingName data-test-id="ProjectName">{name}</BuildingName>
      {totalPendingActions > 0 && <Pill text={totalPendingActions} type="danger" />}
    </BuildingContainer>
  )
}
