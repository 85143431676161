import { Colors, Spacing } from '@walter/shared'
import { mix, rgba, stripUnit } from 'polished'
import styled from 'styled-components'
import { menuWidth, navHeight } from '../../styles/global'
import { fontSizes, fontWeights } from '../../styles/typography'

export const MenuContainer = styled.div`
  position: fixed;
  top: ${navHeight};
  left: 0;
  width: ${menuWidth};
  bottom: 0;
  background-color: ${Colors.white};
  border-right: 1px solid ${Colors.borderColor};
  z-index: 300;
  padding: ${Spacing.medium} 0;
  overflow-y: auto;
`

export const MenuSection = styled.section`
  position: static;

  & + & {
    margin-top: ${`${(stripUnit(Spacing.medium) as number) * 1.25}px`};
    padding-top: ${`${(stripUnit(Spacing.medium) as number) * 1.25}px`};
    border-top: 1px solid ${Colors.borderColor};
  }
`

export const MenuHeader = styled.header`
  margin-bottom: ${Spacing.medium};
`

export const MenuHeading = styled.span`
  display: block;
  color: ${Colors.white};
  font-weight: ${fontWeights.bold};
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`

export const MenuSubheading = styled.small`
  display: block;
  color: ${rgba(Colors.white, 0.5)};
  font-size: ${fontSizes.tiny};
  margin-top: ${`${(stripUnit(Spacing.tiny) as number) * 0.5}px`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
