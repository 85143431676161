import React from 'react'
import styled, { css } from 'styled-components'
import { Colors, Spacing } from '@walter/shared'
import { rotate } from '../../styles/animations'
import { cover } from '../../styles/global'
import { Icon, IconSize } from '../Icon'
import { t } from '../../utils'

const Container = styled.div<{ type: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${(props) =>
    props.type === 'fullscreen' &&
    `
    background-color: ${Colors.white};
    ${cover('fixed')};
  `}

  ${(props) =>
    props.type === 'inline' &&
    css`
      display: inline-block;
    `}

  ${(props) =>
    props.type === 'page' &&
    css`
      flex: 1;
      height: 100%;
      width: 100%;
    `}

  ${(props) =>
    props.type === 'absoluteCenter' &&
    css`
      position: absolute;
    `}
`

const IconWrap = styled.div<{ color?: string }>`
  display: flex;
  color: ${Colors.primaryColor};
  animation: ${rotate} 1s linear infinite;

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`

export const Loading = ({ type = 'fullscreen', size, color, testID }: LoadingProps) => {
  // const { showToast } = React.useContext(ToastContext)
  const [isTakingLongTime, setIsTakingLongTime] = React.useState(false)
  const [isRealyTakingLongTime, setIsRealyTakingLongTime] = React.useState(false)

  React.useEffect(() => {
    const longTimeout = setTimeout(() => {
      if (type === 'inline' || type === 'absoluteCenter') {
        // showToast('', 'Still loading...', `Please wait, it's almost finished`)
      } else {
        setIsTakingLongTime(true)
      }
    }, 10000)
    const reallyLongTimeout = setTimeout(() => {
      if (type === 'inline' || type === 'absoluteCenter') {
        // showToast(
        //   '',
        //   'Something wrong might have happened',
        //   `It's not supposed to take that much time, please refresh the page`
        // )
      } else {
        setIsRealyTakingLongTime(true)
      }
    }, 60000)
    return () => {
      clearTimeout(longTimeout)
      clearTimeout(reallyLongTimeout)
    }
  }, [type])

  return (
    <Container type={type} data-testid={testID ?? ''}>
      <IconWrap color={color}>
        <Icon icon="loading" size={size} />
      </IconWrap>

      {isTakingLongTime && !isRealyTakingLongTime && <p style={{ marginTop: Spacing.medium }}>{t('still-loading')}</p>}
      {isRealyTakingLongTime && <p style={{ marginTop: Spacing.medium }}>{t('please-refresh')}</p>}
    </Container>
  )
}

type LoadingProps = {
  type?: string
  size?: IconSize
  color?: string
  testID?: string
}
