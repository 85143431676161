import { z } from 'zod'
import { i18n, t } from '../../utils'

export const EnterPasswordi18nFields = {
  password: t('auth:enter-password-page-password-input-label'),
}

export const EnterPasswordSchema = z.object({
  password: z
    .string({ required_error: i18n.t('input:is-required', { field: EnterPasswordi18nFields.password }) })
    .min(1, i18n.t('input:is-required', { field: EnterPasswordi18nFields.password })),
})

export type EnterPassword = z.infer<typeof EnterPasswordSchema>
