import React from 'react'

export function useCallbackWhenInView(element: React.RefObject<HTMLDivElement>, cb: () => void, deps: any[] = []) {
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          cb?.()
        }
      },
      { rootMargin: '0px' },
    )

    if (element?.current) {
      observer?.observe(element?.current)
    }

    return () => {
      if (element?.current) {
        observer.unobserve(element?.current)
      }
    }
  }, deps)
}
