import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { MessagingService } from '../MessagingService'
import { publicChannelsMapAtom, privateChannelsMapAtom, allowedProjectsForManagerAtom } from '../atoms'

/**
 * @warning Must be called after `useMessagingService`
 * @returns Unread count of messages for the current user
 */
export function useManagerUnreadMessagesCount(selectedProjectId?: string) {
  const allowedProjects = useAtomValue(allowedProjectsForManagerAtom)

  const publicChannelsMap = useAtomValue(publicChannelsMapAtom)
  const privateChannelsMap = useAtomValue(privateChannelsMapAtom)

  const unreadMessagesCountByProjectMap = useMemo(() => {
    const unreadByProject = new Map<string, number>()
    const publicChannels = Array.from(publicChannelsMap.values())
    for (const channel of publicChannels) {
      const projectId = MessagingService.projectIdFromChannelUrl(channel.url)
      const privateChannel = privateChannelsMap.get(channel.url.concat('-private'))
      channel.unreadMessageCount += privateChannel?.unreadMessageCount ?? 0
      const unread = unreadByProject.get(projectId) ?? 0
      unreadByProject.set(projectId, unread + channel.unreadMessageCount)
    }
    return unreadByProject
  }, [publicChannelsMap, privateChannelsMap])

  const allUnreadMessagesCount = useMemo(() => {
    let unreadMessages = 0
    if (allowedProjects.length === 0) return unreadMessages

    for (const [projectId, numOfUnreadMessages] of unreadMessagesCountByProjectMap) {
      if (!allowedProjects.some((project) => project.id === projectId)) continue
      unreadMessages += numOfUnreadMessages
    }
    return unreadMessages
  }, [unreadMessagesCountByProjectMap, allowedProjects])

  const selectedProjectUnreadMessagesCount = useMemo(() => {
    if (!selectedProjectId) return 0
    return unreadMessagesCountByProjectMap.get(selectedProjectId) ?? 0
  }, [unreadMessagesCountByProjectMap, selectedProjectId])

  const otherProjectsUnreadMessagesCount = useMemo(() => {
    if (!selectedProjectId) return 0
    return allUnreadMessagesCount - selectedProjectUnreadMessagesCount
  }, [allUnreadMessagesCount, selectedProjectId, selectedProjectUnreadMessagesCount])

  return {
    unreadMessagesCountByProjectMap,
    allUnreadMessagesCount,
    selectedProjectUnreadMessagesCount,
    otherProjectsUnreadMessagesCount,
  }
}
