import * as React from 'react'
import { Icon } from './Icon'
import { cn, t } from '../utils'
import { Colors, Config } from '@walter/shared'
import { useNavigate } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'

const HEALTH_CHECK_ENDPOINT = `${Config.restEndPoint}/healthz`

export function TemporarilyUnavailable() {
  const [isRetrying, setIsRetrying] = React.useState(true)
  const [numOfAttempts, setNumOfAttempts] = React.useState(0)
  const client = useApolloClient()
  const navigate = useNavigate()

  React.useEffect(() => {
    fetch(HEALTH_CHECK_ENDPOINT)
      .then(() => {
        client.resetStore().then(() => {
          navigate('/')
        })
      })
      .catch(() => {
        setIsRetrying(false)
      })
  }, [])

  async function onRetry() {
    setIsRetrying(true)
    setNumOfAttempts((prev) => prev + 1)
    try {
      await fetch(HEALTH_CHECK_ENDPOINT)
      await client.resetStore()
      navigate('/')
    } catch (_e) {
      setTimeout(() => {
        setIsRetrying(false)
      }, 700)
    }
  }
  return (
    <div className="flex flex-col gap-4 h-dvh flex-1 justify-center items-center bg-background">
      <Icon icon="wrench" size={'medium'} color={Colors.greyDark} />
      <h1 className="text-2xl font-bold text-foreground">{t('temporarily-unavailable')}</h1>
      <h1 className="text-foreground">{t('sorry-were-updating-our-site')}</h1>
      <div />
      <button
        disabled={isRetrying}
        onClick={onRetry}
        className="relative text-primary-foreground bg-primary text-sm px-3 h-9 rounded font-medium flex items-center justify-center [&:not(:disabled)]:active:scale-[0.99]  transition-transform duration-75 ease-in-out focus:outline-none focus:ring-2 focus:ring-input focus:ring-offset-2 active:ring-0 active:ring-offset-0 hover:bg-opacity-95"
      >
        <span className={cn(isRetrying && 'opacity-0')}>{t('try-again')}</span>
        {isRetrying && (
          <div className="absolute inset-0 justify-center items-center flex animate-spin">
            <Icon icon="loading" size={'small'} />
          </div>
        )}
      </button>
      {numOfAttempts > 0 ? (
        <p className="text-xs text-foreground/90 animate-in fade-in zoom-in">
          {t('attempt')} #
          <span key={`attempt-${numOfAttempts}`} className="inline-block animate-in zoom-in">
            {numOfAttempts}
          </span>
        </p>
      ) : (
        <p className="text-xs opacity-0 select-none" aria-hidden>
          No Retry attempts yet
        </p>
      )}
    </div>
  )
}
