import { Colors, Spacing } from '@walter/shared'
import { mix, rgba, stripUnit } from 'polished'
import * as React from 'react'
import styled from 'styled-components'
import { borderRadius, boxShadow } from '../../styles/global'
import { fontSizes } from '../../styles/typography'

const Table = styled.table`
  width: 100%;
  min-width: 900px;
  border-collapse: separate;
  border-spacing: 0;
`

const TableHeader = styled.thead`
  border-bottom: 1px solid ${Colors.borderColor};
  position: sticky;
  top: 0;
  z-index: 1;
`

const TableBody = styled.tbody`
  overflow: hidden;
`
const TableRow = styled.tr<{ hasRowAction: boolean }>`
  position: relative;
  &:nth-child(even) {
    background-color: ${mix(0.1, Colors.offWhite, Colors.white)};
  }

  ${({ hasRowAction }) =>
    hasRowAction
      ? `&:hover {
    background-color: ${mix(0.3, Colors.offWhite, Colors.white)};
  }`
      : ''}
`

const TableHead = styled.th<{ canSort: boolean }>`
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: ${({ canSort }) => (canSort ? 'pointer' : 'default')};
  white-space: nowrap;

  border-bottom: 1px solid ${Colors.borderColor};
  font-size: ${fontSizes.small};
  color: ${Colors.greyDark};
  background-color: ${mix(0.5, Colors.offWhite, Colors.white)};

  ${({ canSort }) =>
    canSort
      ? `&:hover {
    background-color: ${mix(0.9, Colors.offWhite, Colors.white)};
  }`
      : ''};

  text-align: left;
  padding: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`} ${Spacing.small};
  padding-right: ${Spacing.xxLarge};

  &:first-child {
    padding-left: ${Spacing.medium};
    border-radius: ${borderRadius} 0 0 0;
  }

  &:last-child {
    border-radius: 0 ${borderRadius} 0 0;
  }
`

const TableCell = styled.td`
  text-align: left;
  padding: ${Spacing.xSmall};
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;

  &:first-child {
    padding-left: ${Spacing.medium};
  }

  &:last-child {
    padding-right: ${Spacing.medium};
  }
  border-bottom: 1px solid ${rgba(Colors.borderColor, 0.5)};
`

const Root = styled.div<{ maxHeight: `${string}px` | `${string}%` }>`
  border-radius: ${borderRadius};
  border: 1px solid ${Colors.borderColor};
  background-color: ${Colors.white};
  ${boxShadow};
  max-height: ${({ maxHeight }) => maxHeight};
  overflow: overlay;

  &::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0);
    width: 16px;
    height: 16px;
    z-index: 999999;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 16px;
    border: 0px solid rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: #a0a0a5;
    border: 4px solid ${Colors.white};
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4;
  }
`

const TableRoot = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { dataTestId?: string; maxHeight?: `${string}px` | `${string}%` }
>(({ onScroll, dataTestId, maxHeight = '475px', ...props }, ref) => (
  <Root ref={ref} onScroll={onScroll} data-test-id={`${dataTestId}_Table_Container`} maxHeight={maxHeight}>
    <Table {...props} data-test-id={`${dataTestId}_Table`} />
  </Root>
))

TableRoot.displayName = 'TableRoot'

export { TableRoot, TableHeader, TableBody, TableHead, TableRow, TableCell }
